import api from './index';


const gerarExtracao = (filtrosAplicados = []) => api.get(
    'exportar', 
    { params: {filtros: filtrosAplicados}, responseType: 'blob' } 
);


export {
    gerarExtracao,

}
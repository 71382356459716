<template>
    <div v-if="Object.keys(this.servidor).length">
        <div v-if="ultimoCicloNaoVigente.nm_ciclo">
            <v-alert  type="error" prominent>
                <div class="text-h6">
                    <strong>Sem ciclo vigente.</strong>
                </div>
                <span>
                    No momento as informações apresentadas são referente ao último ciclo.
                </span>
            </v-alert> 
        </div>
        <div v-if="showInformativo">
            <v-alert  :type="informativo.ds_tipo" prominent>
                <div class="text-h6">
                    <strong>{{informativo.ds_titulo}}</strong>
                </div>
                <span>
                    {{informativo.ds_mensagem}}
                </span>
            </v-alert> 
        </div>

        <v-alert v-if="(!servidor.equipes_trabalho[0] && !usuarioAutenticado.id_servidor_externo && !servidor.cd_unidade_exercicio_externo)" type="warning" prominent>
            <div class="text-h6">
                <strong>Servidor sem plano de trabalho</strong>
            </div>
            <span v-if="servidorAvaliado()">
                Você não está vinculado à um plano de trabalho para o {{ ultimoCicloVigente.nm_ciclo }}, <span class="font-weight-bold">o que impedirá sua avaliação de desempenho</span>. Entre em contato com sua chefia solicitando sua inclusão ao plano de trabalho da unidade.
            </span>
            <span v-else>
                Você não está vinculado à um plano de trabalho para o {{ ultimoCicloVigente.nm_ciclo }}. Entre em contato com sua chefia solicitando sua inclusão ao plano de trabalho da unidade.
            </span>
        </v-alert>

        <v-alert v-else-if="!servidor.equipe_trabalho_externo[0] && (servidor.equipes_trabalho[0] && !servidor.equipes_trabalho[0].ds_meta_individual && !(this.servidor.funcao && !this.servidor.funcao.st_avaliado))" type="warning" prominent>
            <div class="text-h6">
                <strong>Meta individual não pactuada</strong>
            </div>
            <span v-if="servidorAvaliado()">
                Você não possui meta individual pactuada para o {{ ultimoCicloVigente.nm_ciclo }}, o que <span class="font-weight-bold">impedirá sua avaliação de desempenho</span>. Entre em contato com sua chefia solicitando o cadastro da meta individual.
            </span>
            <span v-else>
                Você não possui meta individual pactuada para o {{ ultimoCicloVigente.nm_ciclo }}. Entre em contato com sua chefia solicitando o cadastro da meta individual.
            </span>
        </v-alert>

        <v-alert v-if="!servidor.equipe_trabalho_externo[0] && (servidor.equipes_trabalho[0] && servidor.equipes_trabalho[0].ds_meta_individual && !servidor.equipes_trabalho[0].st_acordado && !(this.servidor.funcao && !this.servidor.funcao.st_avaliado))" type="warning" prominent>
            <div class="text-h6">
                <strong>Meta individual não acordada</strong>
            </div>
            <span>
                Você não deu o de acordo com a sua meta individial para o {{ ultimoCicloVigente.nm_ciclo }}. <span class="font-weight-bold" style="cursor: pointer;" @click.stop="openMetaIndividualDialog()">Clique aqui</span> para dar o ciente.
            </span>
        </v-alert>

        <v-card class="my-7 mx-2 pa-3" color="grey lighten-4">
            <v-row>
                <v-col cols="12" sm="4">
                    <v-card class="mb-2 mx-2 pa-2">
                        <v-card-title class="text-h5">
                            Dados cadastrais
                        </v-card-title>
                        <v-card-text>
                            <v-alert
                                dense
                                type="info"
                                :icon="false"
                                outlined
                            >
                              Mantenha seus contatos atualizados.
                            </v-alert>
                            <v-tabs
                                v-model="tab"
                                color="deep-purple"
                                dense
                            >
                                <v-tabs-slider></v-tabs-slider>
                                <v-tab :key="1" >
                                    Funcionais
                                </v-tab>
                                <v-tab :key="2" >
                                    Contatos
                                </v-tab>
                                <v-tabs-items class="mt-4" v-model="tab">
                                    <v-tab-item :key="1">
                                        <p>
                                            <span class="font-weight-bold">Nome:</span>
                                            {{servidor.nm_servidor}}
                                        </p>
                                        <p>
                                            <span class="font-weight-bold">SIAPE:</span>
                                            {{servidor.nr_siape}}
                                        </p>
                                        <p>
                                            <span class="font-weight-bold">CPF:</span>
                                            {{ servidor.nr_cpf | VMask('###.###.###-##') }}
                                        </p>
                                        <p>
                                            <span class="font-weight-bold">Gratificação:</span>
                                            <span v-if="servidor.id_gratificacao">{{servidor.gratificacao.sg_gratificacao}}</span>
                                            <span v-else>-</span>
                                        </p>
                                        <p>
                                            <span class="font-weight-bold">Função:</span>
                                            <span v-if="servidor.id_funcao">{{servidor.funcao.cd_funcao}}</span>
                                            <span v-else>-</span>
                                        </p>
                                        <p>
                                            <span class="font-weight-bold">Órgão:</span>
                                            <span v-if="servidor.cd_orgao && servidor.orgao.deleted_at" >
                                                <font color="red">{{servidor.orgao.sg_sigla}} - {{servidor.orgao.nm_orgao}} (Órgão excluído)</font>
                                            </span>
                                            <span v-else-if="servidor.cd_orgao" >
                                                {{servidor.orgao.sg_sigla}} - {{servidor.orgao.nm_orgao}}
                                            </span>
                                            <span v-else>-</span>
                                        </p>
                                        <p>
                                            <span class="font-weight-bold">Unidade de exercício:</span>
                                            <span v-if="servidor.unidade_exercicio">                                               
                                                {{servidor.unidade_exercicio.sg_completa}} - {{servidor.unidade_exercicio.nm_unidade}}
                                            </span>
                                            <span v-else>                                               
                                                {{servidor.sg_unidade_exercicio_externo}} - {{servidor.nm_unidade_exercicio_externo}}
                                            </span>
                                        </p>

                                        <small>Dados funcionais obtidos do SIAPE. Caso encontre uma divergência, a alteração deve ser realizada diretamente no SIGEPE.</small>
                                    </v-tab-item>
                                    <v-tab-item :key="2">
                                        <p>
                                            <span class="font-weight-bold">Telefone:</span>
                                            <span>
                                                {{servidor.nr_telefone}}
                                            </span>
                                        </p>
                                        <p>
                                            <span class="font-weight-bold">E-mail institucional:</span>
                                            <span v-if="servidor.ds_email_institucional">
                                                {{servidor.ds_email_institucional}}
                                            </span>
                                        </p>
                                        <p>
                                            <span class="font-weight-bold">E-mail pessoal:</span>
                                            <span v-if="servidor.ds_email_pessoal">
                                                {{servidor.ds_email_pessoal}}
                                            </span>
                                        </p>
                                        <p>
                                            <v-btn color="primary" small @click="mostrarDialogDetalhesServidor()">
                                                Alterar dados de contato
                                            </v-btn>
                                        </p>
                                    </v-tab-item>
                                </v-tabs-items>  
                            </v-tabs>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" sm="8" v-if="ultimoCicloVigente.nm_ciclo">
                    <v-card class="mb-2 mx-2 pa-2">
                        <v-card-title class="text-h5">
                            {{ ultimoCicloVigente.nm_ciclo }}
                        </v-card-title>
                        <v-card-text>
                            <v-row>
                                <v-col cols="12" sm="6">
                                    <v-timeline dense clipped>
                                        <v-timeline-item 
                                            small 
                                            v-for="fase in orderedCicloFases" 
                                            :key="fase.id_fase" 
                                            :color="faseVigente(fase) ? 'primary':'grey'"
                                            v-show="fase.id_fase != 7 && fase.id_fase != 8 && fase.id_fase != 9"
                                            >
                                                <span class="font-weight-bold">{{fase.nm_fase}}</span>
                                                <br>{{ fase.dt_inicio }} a {{ fase.dt_encerramento }}
                                        </v-timeline-item>
                                        </v-timeline>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-card
                                        v-if="servidor.equipes_trabalho[0]"
                                        color="success"
                                        dark
                                        class="mb-3"
                                    >
                                        <v-card-title class="text-h5">
                                            Seu plano de trabalho
                                        </v-card-title>
                                        <v-card-subtitle>
                                            {{ servidor.equipes_trabalho[0].plano_trabalho.nm_unidade }}
                                        </v-card-subtitle>
                                        <v-card-actions>
                                            <router-link :to="{ name: 'planos-trabalho-detalhar', params: { id: servidor.equipes_trabalho[0].id_plano_trabalho } }">
                                                <v-btn outlined rounded small>
                                                    Visualizar plano
                                                </v-btn>
                                            </router-link>
                                        </v-card-actions>
                                    </v-card>
                                    <v-card
                                        v-if="avaliacaoAberta"
                                        color="primary"
                                        dark
                                        class="mb-3"
                                    >
                                        <v-card-title class="text-h5">
                                            Avaliação
                                        </v-card-title>
                                        <v-card-subtitle>
                                            Está aberto o período de avalição de desempenho.
                                        </v-card-subtitle>
                                        <v-card-actions>
                                            <router-link :to="{ name: 'Realizar Avaliação' }">
                                                <v-btn outlined rounded small>
                                                    Realizar avaliação
                                                </v-btn>
                                            </router-link>
                                        </v-card-actions>
                                    </v-card>
                                    <v-card
                                        color="primary lighten-2"
                                        dark
                                        class="mb-3"
                                    >
                                        <v-card-title class="text-h5">
                                            Resultado da Avaliação
                                        </v-card-title>
                                        <v-card-subtitle>
                                            Está disponível o resultado da avaliação de desempenho.
                                        </v-card-subtitle>
                                        <v-card-actions>
                                            <router-link :to="{ name: 'Resultados das Avaliações' }">
                                                <v-btn outlined rounded small>
                                                    Visualizar Resultado
                                                </v-btn>
                                            </router-link>
                                        </v-card-actions>
                                    </v-card>
                                    
                                    <v-card
                                        color="blue-grey"
                                        dark
                                        class="mb-3"
                                    >
                                        <v-card-title class="text-h5">
                                            Legislação Aplicada
                                        </v-card-title>
                                        <v-card-subtitle>
                                            Para mais informações sobre legislação aplicada clique em 'informações'.
                                        </v-card-subtitle>
                                        <v-card-actions>
                                            <v-btn href="/informacoes-legislacao-aplicada" target="_blank" outlined rounded small>
                                                Informações
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>

                <v-col cols="12" sm="8" v-else>
                    <v-card class="mb-2 mx-2 pa-2">
                        <v-card-title class="text-h5">
                            {{ ultimoCicloNaoVigente.nm_ciclo }}
                        </v-card-title>
                        <v-card-text>
                            <v-row>
                                <v-col cols="12" sm="6">
                                    <v-timeline dense clipped>
                                        <v-timeline-item 
                                            small 
                                            v-for="fase in orderedCicloAnteriorFases" 
                                            :key="fase.id_fase" 
                                            :color="faseVigente(fase) ? 'primary':'grey'"
                                            v-show="fase.id_fase != 7 && fase.id_fase != 8 && fase.id_fase != 9"
                                            >
                                                <span class="font-weight-bold">{{fase.nm_fase}}</span>
                                                <br>{{ fase.dt_inicio }} a {{ fase.dt_encerramento }}
                                        </v-timeline-item>
                                        </v-timeline>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-card
                                        v-if="avaliacaoAberta"
                                        color="primary"
                                        dark
                                        class="mb-3"
                                    >
                                        <v-card-title class="text-h5">
                                            Avaliação
                                        </v-card-title>
                                        <v-card-subtitle>
                                            Está aberto o período de avalição de desempenho.
                                        </v-card-subtitle>
                                        <v-card-actions>
                                            <router-link :to="{ name: 'Realizar Avaliação' }">
                                                <v-btn outlined rounded small>
                                                    Realizar avaliação
                                                </v-btn>
                                            </router-link>
                                        </v-card-actions>
                                    </v-card>
                                    <v-card
                                        color="primary lighten-2"
                                        dark
                                        class="mb-3"
                                    >
                                        <v-card-title class="text-h5">
                                            Resultado da Avaliação
                                        </v-card-title>
                                        <v-card-subtitle>
                                            Está disponível o resultado da avaliação de desempenho.
                                        </v-card-subtitle>
                                        <v-card-actions>
                                            <router-link :to="{ name: 'Resultados das Avaliações' }">
                                                <v-btn outlined rounded small>
                                                    Visualizar Resultado
                                                </v-btn>
                                            </router-link>
                                        </v-card-actions>
                                    </v-card>
                                    <v-card
                                        v-if="servidor.equipes_trabalho[0]"
                                        color="success"
                                        dark
                                        class="mb-3"
                                    >
                                        <v-card-title class="text-h5">
                                            Seu plano de trabalho
                                        </v-card-title>
                                        <v-card-subtitle>
                                            {{ servidor.equipes_trabalho[0].plano_trabalho.nm_unidade }}
                                        </v-card-subtitle>
                                        <v-card-actions>
                                            <router-link :to="{ name: 'planos-trabalho-detalhar', params: { id: servidor.equipes_trabalho[0].id_plano_trabalho } }">
                                                <v-btn outlined rounded small>
                                                    Visualizar plano
                                                </v-btn>
                                            </router-link>
                                        </v-card-actions>
                                    </v-card>
                                    <v-card
                                        color="blue-grey"
                                        dark
                                        class="mb-3"
                                    >
                                        <v-card-title class="text-h5">
                                            Legislação Aplicada
                                        </v-card-title>
                                        <v-card-subtitle>
                                            Para mais informações sobre legislação aplicada clique em 'informações'.
                                        </v-card-subtitle>
                                        <v-card-actions>
                                            <v-btn href="/informacoes-legislacao-aplicada" target="_blank" outlined rounded small>
                                                Informações
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-card>
        <v-dialog v-model="dialogDetalharServidor" width="60%" :fullscreen="$vuetify.breakpoint.xsOnly">
            <v-card>
                <v-card color="primary" dark class="mx-auto">
                    <v-card-title>
                        <v-icon large left> mdi-account-box-multiple </v-icon>
                        <span class="text-h6 font-weight-light"><b>Alteração de email/telefone de contato do sistema AvaliaMGI</b></span>
                    </v-card-title>
                </v-card>
                <br>
                <v-container class="pa-5">
                    <v-row>
                        <v-col cols="3">
                            <v-textarea rows=1 auto-grow label="SIAPE" dense hide-details="auto" :value="servidor.nr_siape" readonly> </v-textarea>
                        </v-col>
                        <v-col cols="4">
                            <v-textarea rows=1 auto-grow label="CPF" dense hide-details="auto" :value="servidor.nr_cpf" readonly> </v-textarea>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="7">
                            <v-textarea rows=1 auto-grow label="Nome" dense hide-details="auto" :value="servidor.nm_servidor" readonly></v-textarea>
                        </v-col>
                    </v-row>
                    <v-form class="mt-5" ref="form_emailTelefone">
                        <v-row>
                            <v-col cols="4">
                                <v-text-field v-if="dialogDetalharServidor" dense hide-details="auto" label="Telefone" auto-grow v-model="novoTelefoneAvalia" v-mask="'(##) ####-#####'" required outlined :rules="[ 
            v => !v || v.replaceAll('(','').replaceAll(')','').replaceAll(' ','').replaceAll('-','').length>9 || 'Insira um telefone válido.'
          ]">
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="7">
                                <v-textarea rows=1 auto-grow label="E-mail Institucional" dense hide-details="auto" v-model="novoEmailInstitucionalAvalia" outlined :rules="[ 
            v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Insira um e-mail válido.'
          ]"></v-textarea>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="7">
                                <v-textarea rows=1 auto-grow label="E-mail Pessoal" dense hide-details="auto" v-model="novoEmailPessoalAvalia" outlined :rules="[ 
            v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Insira um e-mail válido.'
          ]"></v-textarea>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-container>
                <v-divider></v-divider>
                <v-row dense class="mx-5 mt-3 elevation-0">
                    <v-col cols="12">
                        <v-alert dense outlined type="info" class="mb-5">
                            <span v-html="alertInfoMessage_alteracaoEmailTeleone_ambitoAvalia"></span>
                        </v-alert>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="" @click="dialogDetalharServidor = false"> Cancelar</v-btn>
                    <v-btn color="green" @click="salvarNovosDadosServidor()" dark> Salvar </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogMetaIndividual" width="50%" :fullscreen="$vuetify.breakpoint.xsOnly">
            <v-card>
                <v-card-title>
                    <span class="headline">Meta individual</span>
                    <v-card-subtitle>
                        <v-icon color="primary">mdi-chevron-double-right</v-icon>
                        {{ servidor.nm_servidor }}
                    </v-card-subtitle>
                </v-card-title>
                <v-card-text>
                    <v-textarea solo rows="7" v-model="dsMetaIndividual" disabled></v-textarea>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <span>
                        <v-btn color="success" @click="editarDeAcordoMetaIndividual" width="95%">
                            Ciente
                        </v-btn>
                    </span>
                    <v-btn color="default" @click="dialogMetaIndividual = false">
                        Cancelar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import { updateServidor } from '../api/servidor';
    import { getUsuarioAutenticado } from '../api/auth';
    import { getCiclo, getCiclosVigentes, getCiclosNaoVigentes } from '../api/ciclo';
    import { getEquipeTrabalhoByIdCiclo, updateEquipeTrabalho } from '../api/equipe_trabalho';
    import { getUltimoInformativo } from '../api/informativo';

    import AlterarEmailTelefoneDialog from '../components/AlterarEmailTelefoneDialog.vue';
    import moment from 'moment';

    export default {
        name: 'TelaInicial',
        components: {
            AlterarEmailTelefoneDialog
        },
        data() {
            return {
                usuarioAutenticado: [],
                servidor: {},
                ciclosVigentes: [],
                ultimoCicloVigente: {
                    fases: []
                },
                ciclosNaoVigentes: [],
                ultimoCicloNaoVigente: {
                    fases: []
                },
                tab: null,
                items: [],
                ciclosVigentes_tab: null,
                ciclosVigentes_tabsItems: [],
                dialogDetalharServidor: false,
                novoEmailInstitucionalAvalia: '',
                novoEmailPessoalAvalia: '',
                novoTelefoneAvalia: '',
                avaliacaoAberta: false,
                alertInfoMessage_alteracaoEmailTeleone_ambitoAvalia: '<strong>IMPORTANTE!</strong><br> Alterações das informações de telefone e e-mails são válidas somente no âmbito do sistema AvaliaMGI. Não serão refletidas no cadastro do SIAPE.',
                dialogMetaIndividual: false,
                editaEquipe: {},
                dsMetaIndividual: '',
                showInformativo: false,
                informativo: []
            }
        },
        created() {
            getUltimoInformativo()
            .then(response => {
                var res = response.data.data;
                
                if (res.id_informativo) {
                    var dt_agora = moment().format('YYYY-MM-DD');
                    if (res.dt_inicio <= dt_agora && res.dt_fim >= dt_agora) {
                        this.informativo = res;
                        this.showInformativo = true;
                    }else {
                        this.showInformativo = false;
                        this.informativo = [];
                    } 
                } else {
                    this.showInformativo = false;
                    this.informativo = [];
                } 
            });

            getUsuarioAutenticado(this.$store.getters['auth/usuarioAutenticadoToken']).then(response => {
                this.usuarioAutenticado = response.data.data;
                
                getCiclosVigentes(this.$store.getters['auth/usuarioAutenticado']).then(response => {

                    if (response.data.data.length > 0) {
                        this.ciclosVigentes = response.data.data;
                        let ultimoCicloVigente = this.ciclosVigentes[Object.keys(this.ciclosVigentes).pop()];
                        
                        getCiclo(ultimoCicloVigente.id_ciclo).then(response => {
                            this.ultimoCicloVigente = response.data.data;
                        });
                        
                        getEquipeTrabalhoByIdCiclo(this.usuarioAutenticado.id_servidor, ultimoCicloVigente.id_ciclo).then(response => {
                            this.servidor = response.data.data;
                            
                            if ( this.servidor.equipes_trabalho[0].ds_meta_individual ) {
                                this.dsMetaIndividual = this.servidor.equipes_trabalho[0].ds_meta_individual
                            }
                        }).catch(erro => {
                            console.log('Não foi possível recuperar dados do servidor');
                        });
                    } else {
                        getCiclosNaoVigentes(this.$store.getters['auth/usuarioAutenticado']).then(response => {
                            this.ciclosNaoVigentes = response.data.data;
                            let ultimoCicloNaoVigente = this.ciclosNaoVigentes[Object.keys(this.ciclosNaoVigentes).pop()];
                            
                            getCiclo(ultimoCicloNaoVigente.id_ciclo).then(response => {
                                this.ultimoCicloNaoVigente = response.data.data;
                            });
                            
                            getEquipeTrabalhoByIdCiclo(this.usuarioAutenticado.id_servidor, ultimoCicloNaoVigente.id_ciclo).then(response => {
                                this.servidor = response.data.data;
                                
                                if ( this.servidor.equipes_trabalho[0].ds_meta_individual ) {
                                    this.dsMetaIndividual = this.servidor.equipes_trabalho[0].ds_meta_individual
                                }
                            }).catch(erro => {
                                console.log('Não foi possível recuperar dados do servidor');
                            });
                        });
                    }
                });
            })
        },
        computed: {
            orderedCicloFases: function () {
                return this.ultimoCicloVigente.fases.sort((a, b) => new Date(moment(a.dt_inicio, "DD/MM/YYYY").format("YYYY-MM-DD")) - new Date(moment(b.dt_inicio, "DD/MM/YYYY").format("YYYY-MM-DD")));
            },
            orderedCicloAnteriorFases: function () {
                return this.ultimoCicloNaoVigente.fases.sort((a, b) => new Date(moment(a.dt_inicio, "DD/MM/YYYY").format("YYYY-MM-DD")) - new Date(moment(b.dt_inicio, "DD/MM/YYYY").format("YYYY-MM-DD")));
            }
        },
        methods: {
            formataData(data) {
                return moment(data, "YYYY-MM-DD").format("DD/MM/YYYY");
            }, 
            faseVigente(fase) {
                let current = new Date();
                current.setHours(0,0,0,0);

                let partsInicio = fase.dt_inicio.split("/");
                let dtInicio = new Date(partsInicio[2], partsInicio[1] - 1, partsInicio[0]);
                let partsEncerramento = fase.dt_encerramento.split("/");
                let dtEncerramento = new Date(partsEncerramento[2], partsEncerramento[1] - 1, partsEncerramento[0]);

                if((fase.nm_fase === 'Avaliação dos Servidores') && ((dtInicio <= current) && (dtEncerramento >= current))) {
                    this.avaliacaoAberta = true;
                }
                                
                return ((dtInicio <= current) && (dtEncerramento >= current));
            },
            servidorAvaliado() {
                return (this.servidor.id_gratificacao && (!this.servidor.funcao || this.servidor.funcao.st_avaliado));
            },
            mostrarDialogDetalhesServidor() {
                this.dialogDetalharServidor = true
                this.novoEmailInstitucionalAvalia = this.servidor.ds_email_institucional;
                this.novoEmailPessoalAvalia = this.servidor.ds_email_pessoal;
                this.novoTelefoneAvalia = this.servidor.nr_telefone;
            },
            salvarNovosDadosServidor() {
                if (this.$refs['form_emailTelefone'].validate()) {
                    this.servidor.nr_telefone = this.novoTelefoneAvalia;
                    this.servidor.ds_email_institucional = this.novoEmailInstitucionalAvalia;
                    this.servidor.ds_email_pessoal = this.novoEmailPessoalAvalia;
                    this.dialogDetalharServidor = false;
                    this.postServidor();
                    this.$refs.form_emailTelefone.reset();
                }
            },
            postServidor() {
                this.$forceUpdate();
                updateServidor(this.servidor.id_servidor, JSON.stringify(this.servidor))
                    .then(response => {
                        if (response.data.success === true) {
                            this.servidor.ds_email_institucional = response.data.data.ds_email_institucional;
                            this.servidor.ds_email_pessoal = response.data.data.ds_email_pessoal;
                            this.servidor.ds_email_pessoal = response.data.data.ds_email_pessoal;
                            this.servidor.nr_telefone = response.data.data.nr_telefone;
                            this.$store.dispatch('alertas/show', {
                                tipo: 'Sucesso',
                                titulo: response.data.message,
                            });
                            this.$vuetify.goTo(0);
                        } else {
                            this.$store.dispatch('alertas/show', {
                                tipo: 'Erro',
                                titulo: response.data.message,
                            });
                            this.$vuetify.goTo(0);
                        }
                    },
                    error => {
                        this.$store.dispatch('alertas/show', {
                            tipo: 'Erro',
                            titulo: 'Não foi possível atualizar os dados',
                            mensagem: error.message,
                        });
                        this.$vuetify.goTo(0);
                    }
                );
            },

            openMetaIndividualDialog(equipe) {
                this.dialogMetaIndividual = true;
            },

            editarDeAcordoMetaIndividual() {
                this.servidor.equipes_trabalho[0].st_acordado = true;
                this.servidor.equipes_trabalho[0].dt_acordo = new Date().toISOString().slice(0, 10);
                updateEquipeTrabalho(this.servidor.equipes_trabalho[0].id_equipe_trabalho, this.servidor.equipes_trabalho[0])
                    .then(response => {
                        this.$store.dispatch('alertas/show', {
                            tipo: 'Sucesso',
                            titulo: 'Meta acordada com sucesso!',
                        });
                        this.$router.go(0);
                        this.dialogMetaIndividual = false;
                    });
            },
        }
    }
</script>
import api from './index';

const getServidorExternoInAvaliaInSiapeByCpf = (nr_cpf) => api.get('servidorExternoAvaliaSiape/cpf/' + nr_cpf.replaceAll('.','').replaceAll('-',''));

const getServidorExternoById = (id) => api.get('getServidorExternoById/'+id);


export {
    getServidorExternoInAvaliaInSiapeByCpf,
    getServidorExternoById
}
<template>
<div>
    <h1 class="ml-2">Detalhes do RDI</h1>
    <v-card class="my-7 mx-2 pa-3" color="grey lighten-4">
        <v-row>
            <v-col cols="12" sm="12">
                <v-card class="mb-2 mx-2 pa-2">
                    <v-card-title class="text-h5">
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12">
                                <v-form class="pa-0" ref="form_step1">
                                    <v-row dense class="ma-2">
                                        <v-col cols="12" sm="12">
                                            <v-text-field placeholder="Título" label="Nome do RDI" v-model="rdi.nm_rdi" outlined hide-details="auto" background-color="white" required :rules="[() => !!rdi.nm_rdi || 'Campo obrigatório.']"></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row dense class="ma-2">
                                        <v-col cols="12" sm="12">
                                            <v-text-field placeholder="Descrição" label="Descrição do RDI" v-model="rdi.ds_rdi" outlined hide-details="auto" background-color="white" required :rules="[() => !!rdi.ds_rdi || 'Campo obrigatório.']"></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-form>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" sm="6">
                <v-card class="mb-2 mx-2 pa-2">
                    <v-card-title class="text-h5">
                        Gratificações <span class="ml-5 body-1"> ({{rdi.gratificacoes.length}})</span>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-divider class="ma-0"></v-divider>
                            <div v-for="(gratificacao,i) in rdi.gratificacoes" :key="gratificacao.sg_gratificacao" class="ma-0">
                                <v-list-item>
                                    <template>
                                        <v-list-item-content>
                                            <v-list-item-title>{{gratificacao.sg_gratificacao + ' - ' +gratificacao.nm_gratificacao}}</v-list-item-title>
                                        </v-list-item-content>
                                    </template>
                                </v-list-item>
                                <v-divider class="ma-0"></v-divider>
                            </div>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" sm="6">
                <v-card class="mb-2 mx-2 pa-2">
                    <v-card-title class="text-h5">
                        Fatores
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="12">
                                    <v-form class="pa-0" ref="form_step3">
                                        <v-card v-for="(fator, i) in rdi.fatores" :key="fator.id_fase" class="pa-4 mx-2 mb-5 lighten-4">
                                            <v-row class="px-3 pb-4" dense>
                                                <h4 class="mx-1 mt-3 font-weight-medium">{{ fator.nm_fator}}</h4>
                                            </v-row>
                                            <v-row dense class="ma-2">
                                                <v-col cols="12" sm="12">
                                                    <v-text-field placeholder="Peso" label="Peso do Fator" v-model="fator.nr_peso" outlined hide-details="auto" background-color="white" required :rules="[() => !!fator.nr_peso || 'Campo obrigatório.']"></v-text-field>
                                                </v-col>
                                                <v-col cols="12" sm="12">
                                                    <v-text-field placeholder="Descrição" label="Descrição do Fator" v-model="fator.ds_fator" outlined hide-details="auto" background-color="white" required :rules="[() => !!fator.ds_fator || 'Campo obrigatório.']"></v-text-field>
                                                </v-col>
                                            </v-row>
                                        </v-card>
                                    </v-form>
                                </v-col>
                            </v-row>

                        </v-container>
                    </v-card-text>
                </v-card>
            </v-col>

        </v-row>
    </v-card>
    <v-sheet class="d-flex flex-row justify-center elevation-0">
        <v-sheet class="align-self-center" min-width="800" max-width="1200">
            <div>
                <template>
                    <router-link :to="{ name: 'rdi-listar'}" style="text-decoration: none">
                        <v-card class="text-center" elevation="0" cols="12" sm="6" md="1">
                            <v-btn class="ma-3" color="primary">
                                <v-icon color="white" style="text-decoration: none">mdi-keyboard-return</v-icon>
                                Voltar para a listagem
                            </v-btn>
                        </v-card>
                    </router-link>
                </template>
            </div>
        </v-sheet>

    </v-sheet>
</div>
</template>

<script>
import {
    getRDI
} from '../api/rdi';

export default {
    name: 'rdi-detalhar',
    data() {
        return {
            rdi: {
                nm_rdi: '',
                ds_rdi: '',
                chefiaOuAvaliado: null,
                gratificacoes: [],
                fatores: [],
                ciclo: {},
            },

        };
    },
    created() {
        let id_rdi = this.$route.params.id;
        getRDI(id_rdi)
            .then(response => {
                this.rdi = response.data.data;
            });
    },
    methods: {

    }
}
</script>

<template>
    <div>
        <h1>Informativo para tela inicial</h1>

        <div class="mt-7" v-if="informativo.ds_titulo || informativo.ds_mensagem">
            <h4 class="text-center grey--text text--darken-1  font-weight-light">
                Pré-visualização
            </h4>
            <v-alert  :type="(informativo.ds_tipo) ? informativo.ds_tipo : 'warning'" prominent>
                <div class="text-h6">
                    <strong>{{informativo.ds_titulo}}</strong>
                </div>
                <span>
                    {{informativo.ds_mensagem}}
                </span>
            </v-alert> 
        </div>

        <v-row>
            <v-col cols="12">
                <v-card>
                    <v-row dense class="ma-0 pa-2">
                        <v-col cols="12" md="6" class="mx-0 px-3">
                            <v-text-field 
                                id="titulo" 
                                ref="titulo" 
                                dense 
                                hide-details="auto" 
                                label="Título" 
                                v-model="informativo.ds_titulo" 
                                outlined 
                                clearable 
                                clear-icon="mdi-close-circle-outline"
                                :rules="[v => (v || '' ).length <= 300 || 'Título deve conter no máximo 300 caracteres']">
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" class="mx-0 px-3">
                            <v-select
                                id="tipo" 
                                ref="tipo" 
                                :items="tipos"
                                dense 
                                hide-details="auto" 
                                label="Tipo" 
                                v-model="informativo.ds_tipo" 
                                outlined 
                                clearable 
                                clear-icon="mdi-close-circle-outline"
                            ></v-select>
                        </v-col>
                    </v-row>

                    <v-row dense class="ma-0 pa-2">
                        <v-col cols="12" md="6" class="mx-0 px-3">
                            <v-menu
                                v-model="dtInicio"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y 
                                max-width="290px"
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                        v-mask="'####-##-##'"
                                        type="date"
                                        label="Data inicial"
                                        readonly
                                        hide-details="auto" 
                                        v-model="informativo.dt_inicio"
                                        v-on="on"
                                        clearable
                                        outlined
                                        dense
                                        :rules="[v => !!v || 'Campo obrigatório.']" 
                                        required
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    locale="pt-br"
                                    v-model="informativo.dt_inicio"
                                    no-title
                                    @input="dtInicio = false"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols="12" md="6" class="mx-0 px-3">
                            <v-menu
                                v-model="dtFim"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y 
                                max-width="290px"
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                        v-mask="'####-##-##'"
                                        type="date"
                                        label="Data final"
                                        readonly
                                        hide-details="auto" 
                                        v-model="informativo.dt_fim"
                                        v-on="on"
                                        clearable
                                        outlined
                                        dense
                                        :rules="[v => !!v || 'Campo obrigatório.']" 
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    locale="pt-br"
                                    v-model="informativo.dt_fim"
                                    no-title
                                    @input="dtFim = false"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                       
                        <v-col cols="12" md="12" class="mt-3 px-3">
                            <v-textarea
                                id="msg" 
                                ref="msg" 
                                dense 
                                label="Mensagem" 
                                v-model="informativo.ds_mensagem" 
                                outlined
                                counter="800"
                                :rules="[v => (v || '' ).length <= 800 || 'Mensagem deve conter no máximo 800 caracteres']"> 
                            ></v-textarea>
                        </v-col>
                        <v-col class="d-flex flex-row justify-end align-end text-right">
                            <v-btn class="success mr-3" @click="save">
                                Salvar
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card>                
            </v-col>
        </v-row> 

        <v-dialog v-model="dialogWarning" width="570" >
            <v-card>
                <v-card-title class="text-h5 text-center">
                    <v-alert prominent type="warning" outlined width="100%">
                        <v-row align="center">
                            <v-col class="grow">
                                {{ dialogMsg }}
                            </v-col>
                            <v-col class="shrink">
                            <v-btn color="warning"  @click="dialogWarning = false">Ok</v-btn>
                            </v-col>
                        </v-row>
                    </v-alert>
                </v-card-title> 
            </v-card>
        </v-dialog>  

        <v-dialog v-model="dialogSuccess" width="570" >
            <v-card>
                <v-card-title class="text-h5 text-center">
                    <v-alert prominent type="success" width="100%">
                        <v-row align="center">
                            <v-col class="grow">
                                {{dialogMsg}}
                            </v-col>
                            <v-col class="shrink">
                            <v-btn outlined  @click="dialogSuccess = false">Ok</v-btn>
                            </v-col>
                        </v-row>
                    </v-alert>
                </v-card-title> 
            </v-card>
        </v-dialog>    
    </div>
</template>

<script>
import { salvarInformativo, getUltimoInformativo } from '../api/informativo';

export default {
    name: 'InformativoTelaInicialCadastrar',
    data() {
        return {
            informativo: [],
            dtInicio: false,
            dtFim: false,
            tipos: [
                { text: 'Aviso', value: 'warning'},
                { text: 'Informação', value: 'info'},
                { text: 'Sucesso', value: 'success'},
                { text: 'Erro', value: 'error'}
            ],
            dialogWarning: false,
            dialogSuccess: false,
            dialogMsg:''
        };
    },
    
    mounted() {
    },
    created() {
        this.getInformativo();
    },
    methods: {
        save () {
            if (!this.informativo.ds_titulo && !this.informativo.ds_mensagem) {
                this.dialogMsg = "Necessário ter um título ou uma mensagem";
                return this.dialogWarning = true;
            }

            if (!this.informativo.dt_inicio || !this.informativo.dt_fim) {
                this.dialogMsg = "Necessário preencher as datas";
                return this.dialogWarning = true;
            } else {
                if (this.informativo.dt_inicio > this.informativo.dt_fim) {
                    this.dialogMsg = "Data final deve ser posterior à data início";
                    return this.dialogWarning = true;
                }
            }
            
            if (this.informativo.ds_titulo && this.informativo.ds_titulo.length > 300) {
                this.dialogMsg = "Título deve ter até 300 caracteres";
                return this.dialogWarning = true;
            }

            if (this.informativo.ds_mensagem && this.informativo.ds_mensagem.length > 800) {
                this.dialogMsg = "Mensagem deve ter até 800 caracteres";
                return this.dialogWarning = true;
            }

            let data = new FormData();
            if (this.informativo.id_informativo) {
                data.append('id_informativo', this.informativo.id_informativo);
            }
            data.append('ds_titulo', this.informativo.ds_titulo ? this.informativo.ds_titulo : '');                
            data.append('ds_mensagem', this.informativo.ds_mensagem ? this.informativo.ds_mensagem : '');
            data.append('ds_tipo', this.informativo.ds_tipo ? this.informativo.ds_tipo : 'warning');
            data.append('dt_inicio', this.informativo.dt_inicio);
            data.append('dt_fim', this.informativo.dt_fim);

            salvarInformativo(data).then(response => {
                this.dialogMsg = "Feito com sucesso";
                this.dialogSuccess = true;
                this.getInformativo();
            }).catch(erro => {
                this.dialogMsg = "Erro ao salvar";
                this.dialogWarning = true;
                console.log(erro);
            });
        },

        getInformativo () {
            getUltimoInformativo()
            .then(response => {
                var res = response.data.data;
                
                if (res.id_informativo) {
                    this.informativo = res;
                } else {
                    this.informativo = [];
                } 
            });
        }
    }
}
</script>
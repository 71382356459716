<template>
<div>
    <v-dialog v-model="dialog" width="50%" :fullscreen="$vuetify.breakpoint.xsOnly">
        <v-card>
            <v-card color="primary" dark class="mx-auto">
                <v-card-title>
                    <v-icon large left> {{dialogIcon}} </v-icon>
                    <span class="text-h6 font-weight-light">{{dialogTitle}}</span>
                </v-card-title>
            </v-card>
            <br>
            <v-container>
                <h3 class="text-center font-weight-light primary--text">{{dialogTitle}}</h3>
                <p class="text-center" >
                    Por favor, insira uma justificativa explicando o motivo desta ação.
                </p>
                <v-row dense class="ma-0">
                    <v-col cols="12" class="px-3">
                        <v-textarea
                        v-model="justificativa"
                        id="justificativa"
                        outlined
                        rows="2"
                        name="justificativa"
                        label="Justificativa"
                        ></v-textarea>
                    </v-col>      
                </v-row>

            </v-container>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="grey darken-1" text @click="fecharDialog()">Fechar</v-btn>
                <v-btn  color="primary" v-if="st_bloqueado"  text @click="liberar()">Liberar</v-btn>
                <v-btn  color="red darken-1" v-else text @click="bloquear()">Bloquear</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <v-dialog v-model="dialogAlert" width="580">
        <v-card>
            <v-card-title class="text-h5">
                <v-alert prominent type="warning" outlined width="100%">
                    <v-row align="center">
                        <v-col class="grow">
                            {{dialogMsg}}
                        </v-col>
                        <v-col class="shrink">
                        <v-btn color="warning"  @click="dialogAlert = false">Ok</v-btn>
                        </v-col>
                    </v-row>
                </v-alert>
            </v-card-title> 
        </v-card>
    </v-dialog>
    <v-dialog v-model="dialogSuccess" width="570" >
        <v-card>
            <v-card-title class="text-h5 text-center">
                <v-alert prominent type="success" width="100%">
                    <v-row align="center">
                        <v-col class="grow">
                            {{dialogMsg}}
                        </v-col>
                        <v-col class="shrink">
                        <v-btn outlined  @click="redirectSuccess()">Ok</v-btn>
                        </v-col>
                    </v-row>
                </v-alert>
            </v-card-title> 
        </v-card>
    </v-dialog>
</div>
    
</template>
<script>
import { bloquearAvaliacao, liberarAvaliacao } from '../api/avaliacao';

export default {
    data() {
        return {
            dialog: false,
            dialogTitle:'',
            dialogIcon:'',
            justificativa:'',
            st_bloqueado:null,
            dialogAlert: false,
            id_servidor: null,
            dialogSuccess: false,
            dialogMsg:''
        }
    },
    methods: {
    	showDialog(st_bloqueado, id_servidor) {
            if (st_bloqueado) {
               this.st_bloqueado = true; 
               this.dialogTitle = "Liberar Avaliação";
               this.dialogIcon = "mdi mdi-lock-open";
            } else{
                this.st_bloqueado = false; 
                this.dialogTitle = "Bloquear Avaliação";
                this.dialogIcon = "mdi mdi-lock";
            }
            this.id_servidor = id_servidor;
            this.dialog = true;
        },

        fecharDialog() {
            this.dialog = false;
            this.justificativa = '';
        },

        redirectSuccess() {
            this.dialogSuccess = false;
            window.location.href = window.location.href;
        },

        liberar() {
            if(this.justificativa == '') {
                this.dialogMsg = "Por favor, insira uma justificativa explicando o motivo desta ação.";
                this.dialogAlert = true;
            } else {
                var dados={
                    tx_observacao_avaliado : this.justificativa,
                    st_bloqueado : false,
                }; 
                liberarAvaliacao(this.id_servidor,JSON.stringify(dados)).then(response => {
                    if (!response.data.data) {
                        this.dialogMsg = response.data.message;
                        return this.dialogAlert = true;     
                    }else {
                        this.justificativa = '';
                        this.dialog = false;
                        this.dialogMsg = response.data.message;
                        return this.dialogSuccess = true;
                    }
                })
                .catch(erro => {
                    console.log('Erro ao liberar avaliação. ' + erro);
                });
            }
        },

        bloquear() {
            if(this.justificativa == '') {
                this.dialogMsg = "Por favor, insira uma justificativa explicando o motivo desta ação.";
                this.dialogAlert = true;                
            } else {
                let data = new FormData();

                data.append('tx_observacao_avaliado', this.justificativa);
                data.append('st_bloqueado', true);
                data.append('id_servidor', this.id_servidor);

                bloquearAvaliacao(data).then(response => {
                    if (!response.data.data) {
                        this.dialogMsg = response.data.message;
                        return this.dialogAlert = true;
                    } else {
                        this.justificativa = '';
                        this.dialog = false;
                        this.dialogMsg = response.data.message;
                        return this.dialogSuccess = true;
                    }
                })
                .catch(erro => {
                    console.log('Erro ao bloquear avaliação. ' + erro);
                });
            }
        }
    }
}

</script>

import api from './index';

const getAvaliacoes = () => api.get('avaliacoes');

const getAvaliacao = (id_avaliacao) => api.get('avaliacoes/' + id_avaliacao);
const getAvaliacaoCadastradaConsolidada = (id_avaliacao) => api.get('getAvaliacaoCadastradaConsolidada/' + id_avaliacao);

const updateAvaliacao = (id_avaliacao, avaliacao) => api.put('avaliacoes/' + id_avaliacao, avaliacao);

const gerarRelatorioAvaliacaoFiltrados = (page, itemsPerPage, filtrosAplicados = []) => api.get('relatorioAvaliacao', {params: {current_page: page, limit: itemsPerPage, filtros: filtrosAplicados}});

const gerarExtracaoRelatorioAvaliacao = (filtrosAplicados = []) => api.get('gerarExtracaoRelatorioAvaliacao', { params: {filtros: filtrosAplicados}, responseType: 'blob' } );

const createAvaliacao = (avaliacao) => api.post('avaliacoes', avaliacao);

const bloquearAvaliacao = (dados) => api.post('bloquearAvaliacao', dados);

const liberarAvaliacao = (id, dados) => api.put('liberarAvaliacao/'+id, dados);

const createAvaliacaoCadastrada = (dados) => api.post('createAvaliacaoCadastrada', dados);

const getRelatorioAvaliacoesCadastradas = (page, itemsPerPage, filtrosAplicados = []) => api.get('getRelatorioAvaliacoesCadastradas', {params: {current_page: page, limit: itemsPerPage, filtros: filtrosAplicados}});

const deleteAvaliacaoCadastrada = (dados) => api.post('deleteAvaliacaoCadastrada', dados);

const getAvaliacaoByAvaliado = (id_servidor) => api.get('getAvaliacaoByAvaliado/' + id_servidor);

const getGerenciarAvaliacao = (page, itemsPerPage, filtrosAplicados = []) => api.get('getGerenciarAvaliacao', {params: {current_page: page, limit: itemsPerPage, filtros: filtrosAplicados}});

const getTotalGerenciarAvaliacao = (filtrosAplicados = []) => api.get('getTotalGerenciarAvaliacao', {params: {filtros: filtrosAplicados}});

const getAvaliacoesByServidorByCiclo = (id_servidor,id_ciclo) => api.get('getAvaliacoesByServidorByCiclo', { params: {id_servidor: id_servidor, id_ciclo: id_ciclo} } );

const excluirAvaliacoesByGerenciarAvaliacao = (dados) => api.post('excluirAvaliacoesByGerenciarAvaliacao', dados);

const excluirTodasAvaliacoesByGerenciarAvaliacao = (dados) => api.post('excluirTodasAvaliacoesByGerenciarAvaliacao', dados);

const bloquearAvaliacaoExterna = (dados) => api.post('bloquearAvaliacaoExterna', dados);

const extracaoGerenciarAvaliacao = (filtrosAplicados = []) => api.get('extracaoGerenciarAvaliacao', { params: {filtros: filtrosAplicados}, responseType: 'blob' } );

const getTotalRelatorioAvaliacoesCadastradas = (filtrosAplicados = []) => api.get('getTotalRelatorioAvaliacoesCadastradas', {params: {filtros: filtrosAplicados}});

export {
    getAvaliacoes,
    getAvaliacao,
    updateAvaliacao,
    gerarRelatorioAvaliacaoFiltrados,
    gerarExtracaoRelatorioAvaliacao,
    createAvaliacao,
    bloquearAvaliacao,
    liberarAvaliacao,
    createAvaliacaoCadastrada,
    getRelatorioAvaliacoesCadastradas,
    deleteAvaliacaoCadastrada,
    getAvaliacaoByAvaliado,
    getGerenciarAvaliacao,
    getTotalGerenciarAvaliacao,
    getAvaliacoesByServidorByCiclo,
    excluirAvaliacoesByGerenciarAvaliacao,
    excluirTodasAvaliacoesByGerenciarAvaliacao,
    bloquearAvaliacaoExterna,
    extracaoGerenciarAvaliacao,
    getTotalRelatorioAvaliacoesCadastradas,
    getAvaliacaoCadastradaConsolidada
}
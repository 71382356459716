<template>
<div>
    <v-sheet class="d-flex flex-row justify-center elevation-0">
        <v-sheet class="align-self-center" min-width="800" max-width="1200">
            <h1>Ciclos</h1>
            <v-card class="mb-5 elevation-0">
                <router-link :to="{ name: 'ciclos-adicionar'}" style="text-decoration: none">
                    <v-btn class="primary">
                        <v-icon>mdi-plus</v-icon> Adicionar ciclo
                    </v-btn>
                </router-link>
            </v-card>
            <v-card>
                <v-simple-table>
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-left">Nome</th>
                                <th class="text-left">Início</th>
                                <th class="text-left">Encerramento</th>
                                <th class="text-left">Ação</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="ciclo in ciclos" :key="ciclo.id_ciclo">
                                <td>{{ ciclo.nm_ciclo }}</td>
                                <td>{{ ciclo.dt_inicio }}</td>
                                <td>{{ ciclo.dt_encerramento }}</td>
                                <td>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs}">
                                            <v-btn icon color="primary" small v-bind="attrs" v-on="on">
                                                <router-link :to="{ name: 'ciclos-detalhar', params: { id: ciclo.id_ciclo } }" style="text-decoration: none">
                                                    <v-icon color="primary" style="text-decoration: none">mdi-eye</v-icon>
                                                </router-link>
                                            </v-btn>
                                        </template>
                                        <span>Ver detalhes do ciclo</span>
                                    </v-tooltip>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-card>
        </v-sheet>
    </v-sheet>
</div>
</template>

<script>
import {
    getCiclos
} from '../api/ciclo';

export default {
    name: 'CiclosListar',
    data() {
        return {
            ciclos: [],
        };
    },
    mounted() {
        getCiclos()
            .then(response => {
                this.ciclos = response.data.data;
            });
    },
} 
</script>

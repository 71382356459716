import api from './index';

const getCodigosAfastamentos = () => api.get(
    'getCodigosAfastamentos'
);

const getCodigosAfastamentosAtivosEmOutroOrgao = () => api.get(
    'getCodigosAfastamentosAtivosEmOutroOrgao'
);

export {
    getCodigosAfastamentos,
    getCodigosAfastamentosAtivosEmOutroOrgao
}
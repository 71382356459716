<template>
    <div v-if="plano_trabalho.id_plano_trabalho">
        <servidorDialog ref="servidorDialog" />
        <pontosFocaisDialog ref="pontosFocaisDialog" :plano_trabalho="plano_trabalho" />
        <BuscaServidorSiapeCPFdialog ref="buscaServidorSiapeCPFdialog" :title="buscaServidorSiapeCPFdialog_title" :tipo="buscaServidorSiapeCPFdialog_tipo" @result:servidor="recebeuServidorFromBuscaSiapeCPF" />
        <BuscaServidorSiapeCPFdialogAdicionarMembro ref="buscaServidorSiapeCPFdialogAdicionarMembro" :plano_trabalho="plano_trabalho" @result:servidor="recebeuServidorFromBuscaSiapeCPFadicionarMembro" />
        <BuscaServidorSiapeCPFdialogDefinirChefiaImediata ref="buscaServidorSiapeCPFdialogDefinirChefiaImediata" :plano_trabalho="plano_trabalho" @result:servidor="recebeuServidorFromBuscaSiapeCPFdefinirChefiaImediata" />
        <BuscaServidorSiapeCPFdialogDefinirChefiaImediataSubst ref="buscaServidorSiapeCPFdialogDefinirChefiaImediataSubst" :plano_trabalho="plano_trabalho" @result:servidor="recebeuServidorFromBuscaSiapeCPFdefinirChefiaImediataSubst" />
        <BuscaServidorSiapeCPFdialogAdicionarPontoFocal ref="buscaServidorSiapeCPFdialogDefinirPontoFocal" :plano_trabalho="plano_trabalho" @result:servidor="recebeuServidorFromBuscaSiapeCPFdefinirPontoFocal" />
        <BuscaServidorSiapeCPFdialogAdicionarPontoFocalSubst ref="buscaServidorSiapeCPFdialogDefinirPontoFocalSubst" :plano_trabalho="plano_trabalho" @result:servidor="recebeuServidorFromBuscaSiapeCPFdefinirPontoFocalSubst" />
        <h1>{{plano_trabalho.nm_unidade}} 
            <v-tooltip top>
                <template v-slot:activator="{ on, attrs}">
                    <v-btn class="ml-4" id="btnVisualizarPlano" icon x-large :color="'primary'" v-bind="attrs" v-on="on" :to="{ name: 'planos-trabalho-detalhar', params: {id: plano_trabalho.id_plano_trabalho} }">
                        <v-icon> mdi-magnify </v-icon>
                    </v-btn>
                </template>
                <span>Visualizar Plano de Trabalho</span>
            </v-tooltip>
        </h1> 
        <v-card class="px-2" color="grey lighten-4">
                       
            <v-row>
               
                <v-col cols="12">
                    <v-card class="ma-2 scroll" id="equipe">
                        <v-card-title class="text-h5">
                            Equipe de trabalho
                        </v-card-title>
                        <v-card-subtitle v-if="canEditarEquipeTrabalho()" class="mt-1">
                            <v-tooltip top>
                                <template v-slot:activator="{ on: tooltip}">
                                    <v-btn color="success" small v-on="{ ...tooltip}" @click="toggleBuscaServidorSiapeCPFdialogAdicionarMembroVisible();">
                                        Adicionar membro
                                    </v-btn>
                                </template>
                                <span>Adicionar um novo membro à equipe de trabalho</span>
                            </v-tooltip>
                        </v-card-subtitle>
                        <v-card-text>

                            <v-simple-table>
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th class="text-left">Nome</th>
                                            <th class="text-left">SIAPE</th>
                                            <th class="text-left">Ações</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="equipe in orderedEquipe"  v-if="equipe.id_servidor === plano_trabalho.id_chefia" class="light-green lighten-4">
                                            <td>{{ equipe.servidor.nome }}</td>
                                            <td>{{ equipe.servidor.siape }}</td>
                                            <td>
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on: tooltip}">
                                                        <v-btn icon color="primary" small v-on="{ ...tooltip}" @click="() => modalServidor(equipe.id_servidor,'Membro')">
                                                            <v-icon> mdi-file-search-outline </v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>Visualizar dados do membro</span>
                                                </v-tooltip>
                                                <v-tooltip v-if="equipe.st_avaliado" top>
                                                    <template v-slot:activator="{ on: tooltip}">
                                                        <v-btn icon color="warning" v-on="{ ...tooltip}" small @click.stop="openMetaIndividualDialog(equipe)">
                                                            <v-icon v-if="equipe.ds_meta_individual" color="warning" dense>mdi-clipboard-text-outline</v-icon>
                                                            <v-icon v-else color="warning" dense>mdi-clipboard-alert-outline</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>Visualizar meta individual</span>
                                                </v-tooltip>

        
                                            </td>
                                        </tr>
                                        <tr v-for="plano_filho in orderedPlanosFilhosChefia" class="yellow lighten-4">
                                            <td>{{ plano_filho.chefia.nome }}</td>
                                            <td>{{ plano_filho.chefia.siape }}</td>
                                            <td>
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on: tooltip}">
                                                        <v-spacer></v-spacer>
                                                        <v-tooltip v-if="plano_filho.equipe_chefia.st_avaliado" top>
                                                            <template v-slot:activator="{ on: tooltip}">
                                                                <v-btn v-if="plano_filho.equipe_chefia != null" v-on="{ ...tooltip}" icon color="warning" small @click.stop="openMetaIndividualDialog(plano_filho.equipe_chefia)">
                                                                    <v-icon v-if="plano_filho.equipe_chefia != null && plano_filho.equipe_chefia.ds_meta_individual" color="warning" dense>mdi-clipboard-text-outline</v-icon>
                                                                    <v-icon v-else color="warning" dense>mdi-clipboard-alert-outline</v-icon>
                                                                </v-btn>
                                                            </template>
                                                            <span>Visualizar meta individual</span>
                                                        </v-tooltip>
                                                        <v-btn icon color="primary" small v-on="{ ...tooltip}" @click="() => modalServidor(plano_filho.chefia.id,'Servidor(a)')">
                                                            <v-icon> mdi-file-search-outline </v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>Ver detalhes do servidor</span>
                                                </v-tooltip>
                                            </td>
                                        </tr>
                                        <tr v-for="equipe in orderedEquipe" v-if="equipe.id_servidor !== plano_trabalho.id_chefia" :class="{'grey lighten-2':equipe.id_servidor== id_servidor}">
                                            <td>{{ equipe.servidor.nome }}</td>
                                            <td>{{ equipe.servidor.siape }}</td>
                                            <td>
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on: tooltip}">
                                                        <v-btn icon color="primary" small v-on="{ ...tooltip}" @click="() => modalServidor(equipe.id_servidor,'Membro')">
                                                            <v-icon> mdi-file-search-outline </v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>Visualizar dados do membro</span>
                                                </v-tooltip>
                                                <v-tooltip v-if="equipe.st_avaliado" top>
                                                    <template v-slot:activator="{ on: tooltip}">
                                                        <v-btn icon color="warning" small v-on="{ ...tooltip}" @click.stop="openMetaIndividualDialog(equipe)">
                                                            <v-icon v-if="equipe.ds_meta_individual" color="warning" dense>mdi-clipboard-text-outline</v-icon>
                                                            <v-icon v-else color="warning" dense>mdi-clipboard-alert-outline</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>Visualizar meta individual</span>
                                                </v-tooltip>
                                                
                                            </td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            
        </v-card>
           <v-dialog v-model="dialogMetaIndividual" width="50%" :fullscreen="$vuetify.breakpoint.xsOnly">
            <v-card>
                <v-card-title>
                    <span class="headline">Editar meta individual</span>
                    <v-card-subtitle>
                        <v-icon color="primary">mdi-chevron-double-right</v-icon>
                        {{ editaEquipe.servidor.nome }}
                    </v-card-subtitle>
                </v-card-title>
                <v-card-text>
                    <v-textarea solo rows="7" v-model="editaEquipe.ds_meta_individual" :readonly="!canEditarMetaIndividual(editaEquipe.servidor.id)" :rules="[v => (v || '' ).length <= 500 || 'A Meta Individual deve conter no máximo 500 caracteres']">
                    </v-textarea>
                    <div class="v-messages__message">{{ informarDeAcordo() }}</div>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <span v-if="!validarEdicaoDeAcordo(editaEquipe.servidor.id) || usuarioAutenticado.id_servidor == editaEquipe.servidor.id">
                        <v-btn color="success" @click="editarDeAcordoMetaIndividual" :disabled="validarEdicaoDeAcordo(editaEquipe.servidor.id)" width="95%">
                            Ciente
                        </v-btn>
                    </span>
                    <v-btn color="default" @click="closeDialogMetaIndividual">
                        Fechar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        
    </div>
</template>

<script>
    import {
        getPlanoTrabalho,
        updatePlanoTrabalho
    } from '../api/plano_trabalho';
    import {
        getEquipeTrabalhoBySiapeCiclo,
        createEquipeTrabalho,
        deleteEquipeTrabalho,
        updateEquipeTrabalho
    } from '../api/equipe_trabalho';
    import {
        getMetaIntermediariaByPlanoTrabalho,
        getMetaIntermediariaByIdMeta,
        deleteMetaIntermediaria,
        createMetaIntermediaria,
        updateMetaIntermediaria
    } from '../api/meta_intermediaria';
    import {
        getUnidadesMedidas
    } from '../api/unidades_medidas';
    import {
        getDetalheServidor
    } from '../api/servidor';
    
    import {
        getFuncoes
    } from '../api/funcao';

    import {
        getUsuarioAutenticado
    } from '../api/auth';
    import servidorDialog from '../components/ServidorDialog.vue';
    import pontosFocaisDialog from '../components/PontosFocaisDialog.vue';
    import BuscaServidorSiapeCPFdialog from '../components/BuscaServidorSiapeCPFdialog.vue';
    import BuscaServidorSiapeCPFdialogAdicionarMembro from '../components/BuscaServidorSiapeCPFdialogAdicionarMembro.vue';
    import BuscaServidorSiapeCPFdialogDefinirChefiaImediata from '../components/BuscaServidorSiapeCPFdialogDefinirChefiaImediata.vue';
    import BuscaServidorSiapeCPFdialogDefinirChefiaImediataSubst from '../components/BuscaServidorSiapeCPFdialogDefinirChefiaImediataSubst.vue';
    import BuscaServidorSiapeCPFdialogAdicionarPontoFocal from '../components/BuscaServidorSiapeCPFdialogAdicionarPontoFocal.vue';
    import BuscaServidorSiapeCPFdialogAdicionarPontoFocalSubst from '../components/BuscaServidorSiapeCPFdialogAdicionarPontoFocalSubst.vue';

    export function formataNumeroComPontosEVirgulasMask(value) {
        if (!value) return []
        let retorno = value.replace(/[^0-9]/g, '');
        if (retorno.length > 17) {
            retorno = retorno.substring(0, 17)
        }
        const formatPrice = (numberStringed) => { 
            let val = (Number(numberStringed) / 100).toFixed(2).replace('.', ',') 
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        }
        return [...formatPrice(retorno)]
    }

    export default {
        name: 'EquipeTrabalhoModal',
        components: {
            servidorDialog,
            BuscaServidorSiapeCPFdialog,
            BuscaServidorSiapeCPFdialogAdicionarMembro,
            BuscaServidorSiapeCPFdialogDefinirChefiaImediata,
            pontosFocaisDialog,
            BuscaServidorSiapeCPFdialogDefinirChefiaImediataSubst,
            BuscaServidorSiapeCPFdialogAdicionarPontoFocal,
            BuscaServidorSiapeCPFdialogAdicionarPontoFocalSubst,
        },
        data() {
            return {
                usuarioAutenticado: [],
                plano_trabalho: {
                    ciclo: [],
                    chefia: [],
                    substituto: [],
                    pontoFocal: [],
                    pontoFocalSubstituto: [],
                    unidade_avaliacao: {
                        gestor_setorial: [],
                        gestor_substituto: [],
                    },
                    equipe_trabalho: [],
                    planos_filhos: [],
                    metas_intermediarias: []
                },
                cicloVigente: false,
                fasePermiteEdicao: false,
                dialogCronograma: false,
                dialogEscopo: false,
                escopoAlterado: null,
                dialogMetaIndividual: false,
                dialogAdicionarMetaIntermediaria: false,
                editaEquipe: {
                    ds_meta_individual: '',
                    st_acordado: false,
                    dt_acordo: '',
                    servidor: []
                },
                siapeBusca: null,
                siapeBuscaResultado: [],
                unidadeMedida: [],
                metaIntermediariaDialog: {
                    id_meta_intermediaria: null,
                    ds_meta_intermediaria: '',
                    nm_indicador: '',
                    nm_formula: '',
                    nm_fonte_info: '',
                    id_unidade_medida: 4,
                    nr_previsto: null,
                    nr_realizado: null,
                    id_plano_trabalho: null,
                },
                metaIntermediariaNr_mask: formataNumeroComPontosEVirgulasMask,
                disableMetaIntermediariaEdit: false,
                btSalvaMeta: false,
                btEditaMeta: false,

                buscaServidorSiapeCPFdialog_title: '',
                buscaServidorSiapeCPFdialog_tipo: '',
                funcoes: [],
                id_servidor: location.href.split("/").pop(),
            };
        },
        created() {
            getUsuarioAutenticado(this.$store.getters['auth/usuarioAutenticadoToken'])
                .then(response => {
                    this.usuarioAutenticado = response.data.data;

                    var idPlano = this.$route.params.id_plano_trabalho ? this.$route.params.id_plano_trabalho : this.$route.params.id;
                    getPlanoTrabalho(idPlano)
                        .then(response => {
                            getFuncoes()
                                .then(response2 => {
                                    this.funcoes = response2.data.data
                                    this.plano_trabalho = response.data.data;
                                    this.loadAll_st_avaliado();
                                    this.escopoAlterado = this.plano_trabalho.ds_escopo;
                                    const current = new Date();
                                    if ((this.parseToDateInicio(this.plano_trabalho.ciclo.dt_inicio) <= current) 
                                    && (this.parseToDateEncerramento(this.plano_trabalho.ciclo.dt_encerramento) >= current)) {
                                        this.cicloVigente = true;
                                        let faseElaboracao = this.plano_trabalho.ciclo.fases.find(item => item.nm_fase == 'Elaboração do Plano de Trabalho');
                                        if ((this.parseToDateInicio(faseElaboracao.dt_inicio) <= current) 
                                            && (this.parseToDateEncerramento(faseElaboracao.dt_encerramento) >= current)) {
                                            this.fasePermiteEdicao = true;
                                        } else {
                                            let faseRevisao = this.plano_trabalho.ciclo.fases.find(item => item.nm_fase == 'Revisão do Plano de Trabalho');
                                        if ((this.parseToDateInicio(faseRevisao.dt_inicio) <= current) 
                                            && (this.parseToDateEncerramento(faseRevisao.dt_encerramento) >= current)) {
                                                this.fasePermiteEdicao = true;
                                            }
                                        }
                                    }
                                    
                                });
                        });
                });

            getUnidadesMedidas()
                .then(response => {
                    this.unidadeMedida = response.data.data;
                });
        },    
        computed: {
            orderedEquipe: function () {
                var listaOrdenada;
                let chefes = this.plano_trabalho.equipe_trabalho.filter(item => item.id_servidor == this.plano_trabalho.id_chefia);
                let listaEquipe = this.plano_trabalho.equipe_trabalho.filter(item => item.id_servidor != this.plano_trabalho.id_chefia);
                listaEquipe = _.orderBy(listaEquipe, 'servidor.nome');
                listaOrdenada = chefes.concat(listaEquipe);

                return listaOrdenada;
            },
            orderedPlanosFilhos: function () {
                return _.orderBy(this.plano_trabalho.planos_filhos, 'name');
            },
            orderedPlanosFilhosChefia: function () {
                let planos_filhos_nao_nulos = this.plano_trabalho.planos_filhos.filter(item => item.chefia !== null);
                return _.orderBy(planos_filhos_nao_nulos, 'chefia.name');
            },
            validarTamanhoMetaIndividual() {
                if (this.editaEquipe.ds_meta_individual == null)
                    return true;
                else
                    return this.editaEquipe.ds_meta_individual.length > 500;
            },
            orderedMetasIntermediarias: function () {
                return _.orderBy(this.plano_trabalho.metas_intermediarias, 'id_meta_intermediaria');
            }            
        },
        methods: {
            parseToDateInicio(dateString_YYYY_MM_DD){ // criar uma funcao que recebe a data string conforme o banco "2022-10-02" e retorna objeto tipo Date javascript referente ao dia
                let pieces = dateString_YYYY_MM_DD.split("/");
                return new Date(Number(pieces[2]), Number(pieces[1]) - 1, Number(pieces[0]));        
            },                         
            parseToDateEncerramento(dateString_YYYY_MM_DD){ // criar uma funcao que recebe a data string conforme o banco "2022-10-02" e retorna objeto tipo Date javascript referente ao dia
                let pieces = dateString_YYYY_MM_DD.split("/");
                return new Date(new Date(Number(pieces[2]), Number(pieces[1]) - 1, Number(pieces[0])).getTime() + (1000 * 60 * 60 * 24 - 1));        
            },                         
            loadAll_st_avaliado(){
                this.plano_trabalho.equipe_trabalho.map(eq => {
                    if (eq.id_funcao){
                        eq.st_avaliado = this.funcoes.filter(func => func.id_funcao === eq.id_funcao)[0].st_avaliado
                    } else {
                        eq.st_avaliado = true;
                    }
                });
                this.plano_trabalho.planos_filhos.map(plano_filho => {
                    if (plano_filho.equipe_chefia){
                        if (plano_filho.equipe_chefia.id_funcao){
                            plano_filho.equipe_chefia.st_avaliado = this.funcoes.filter(func => func.id_funcao === plano_filho.equipe_chefia.id_funcao)[0].st_avaliado
                        } else {
                            plano_filho.equipe_chefia.st_avaliado = true;
                        }
                    }
                });
            },
            adicionarChefiaSubstituta(servidor) {
                let plano_trabalho = {
                    id_ciclo: this.plano_trabalho.id_ciclo,
                    id_unidade_avaliacao: this.plano_trabalho.id_unidade_avaliacao,
                    id_unidade: this.plano_trabalho.id_unidade,
                    nm_unidade: this.plano_trabalho.nm_unidade,
                    id_substituto: servidor.id_servidor
                };
                updatePlanoTrabalho(this.plano_trabalho.id_plano_trabalho, JSON.stringify(plano_trabalho))
                    .then(response => {
                        this.plano_trabalho = response.data.data;
                        this.$store.dispatch('alertas/show', {
                            tipo: 'Sucesso',
                            titulo: 'Chefia substituta cadastrada com sucesso!',
                        });
                        this.$vuetify.goTo(0);
                    });
            },
            deletarChefiaSubstituta() {
                let plano_trabalho = {
                    id_ciclo: this.plano_trabalho.id_ciclo,
                    id_unidade_avaliacao: this.plano_trabalho.id_unidade_avaliacao,
                    id_unidade: this.plano_trabalho.id_unidade,
                    nm_unidade: this.plano_trabalho.nm_unidade,
                    id_substituto: null
                };
                updatePlanoTrabalho(this.plano_trabalho.id_plano_trabalho, JSON.stringify(plano_trabalho))
                    .then(response => {
                        this.plano_trabalho = response.data.data;
                        this.$store.dispatch('alertas/show', {
                            tipo: 'Sucesso',
                            titulo: 'Chefia substituta excluída com sucesso!',
                        });
                        this.$vuetify.goTo(0);
                    });
            },
            adicionarChefiaTitular(servidor) {
                let plano_trabalho = {
                    id_ciclo: this.plano_trabalho.id_ciclo,
                    id_unidade_avaliacao: this.plano_trabalho.id_unidade_avaliacao,
                    id_unidade: this.plano_trabalho.id_unidade,
                    nm_unidade: this.plano_trabalho.nm_unidade,
                    id_chefia: servidor.id_servidor
                };
                updatePlanoTrabalho(this.plano_trabalho.id_plano_trabalho, JSON.stringify(plano_trabalho))
                    .then(response => {
                        this.plano_trabalho = response.data.data;
                        this.$store.dispatch('alertas/show', {
                            tipo: 'Sucesso',
                            titulo: 'Chefia imediata cadastrada com sucesso!',
                        });
                        this.$vuetify.goTo(0);
                    });
            },

            deletarChefiaTitular() {
                let plano_trabalho = {
                    id_ciclo: this.plano_trabalho.id_ciclo,
                    id_unidade_avaliacao: this.plano_trabalho.id_unidade_avaliacao,
                    id_unidade: this.plano_trabalho.id_unidade,
                    nm_unidade: this.plano_trabalho.nm_unidade,
                    id_chefia: null
                };
                updatePlanoTrabalho(this.plano_trabalho.id_plano_trabalho, JSON.stringify(plano_trabalho))
                    .then(response => {
                        this.plano_trabalho = response.data.data;
                        this.$store.dispatch('alertas/show', {
                            tipo: 'Sucesso',
                            titulo: 'Chefia imediata excluída com sucesso!',
                        });
                        this.$vuetify.goTo(0);
                    });
            },
            adicionarMembro(servidor) {
                if (servidor.equipes_trabalho.length) {
                    deleteEquipeTrabalho(servidor.equipes_trabalho[0].id_equipe_trabalho)
                        .then(response => {
                            this.plano_trabalho.equipe_trabalho = this.plano_trabalho.equipe_trabalho.filter(item => item.id_equipe_trabalho !== servidor.equipes_trabalho[0].id_equipe_trabalho);
                            this.salvarEquipe(servidor);
                            this.$vuetify.goTo(0);
                            this.closeDialogAdicionarMembro();
                        });
                } else {
                    this.salvarEquipe(servidor);
                    this.$vuetify.goTo(0);
                    this.closeDialogAdicionarMembro();
                }
            },

            salvarEquipe(servidor) {
                let equipe_trabalho = {
                    id_plano_trabalho: this.plano_trabalho.id_plano_trabalho,
                    id_servidor: servidor.id_servidor,
                    id_gratificacao: servidor.id_gratificacao,
                    id_funcao: servidor.id_funcao,
                };
                createEquipeTrabalho(JSON.stringify(equipe_trabalho))
                    .then(
                        response => {
                            this.plano_trabalho.equipe_trabalho.push(response.data.data);
                            this.$store.dispatch('alertas/show', {
                                tipo: 'Sucesso',
                                titulo: 'Servidor adicionado na equipe de trabalho com sucesso!',
                            });
                        },
                        error => {
                            this.$store.dispatch('alertas/show', {
                                tipo: 'Erro',
                                titulo: 'Ocorreu um erro ao adicionar o servidor à equipe de trabalho. Tente novamente.',
                            });
                        }
                    );
            },
            excluirMembro(id) {
                deleteEquipeTrabalho(id)
                    .then(response => {
                        this.plano_trabalho.equipe_trabalho = this.plano_trabalho.equipe_trabalho.filter(item => item.id_equipe_trabalho !== id);

                        this.$store.dispatch('alertas/show', {
                            tipo: 'Sucesso',
                            titulo: 'Servidor excluído equipe de trabalho com sucesso!',
                        });
                        this.$vuetify.goTo(0);
                        this.closeDialogAdicionarMembro();
                    });
            },
            removerChefiaDePlanoDeServidor(servidor) {
                let plano_trabalho = {
                    id_ciclo: servidor.equipes_trabalho[0].plano_trabalho.id_ciclo,
                    id_unidade_avaliacao: servidor.equipes_trabalho[0].plano_trabalho.id_unidade_avaliacao,
                    id_unidade: servidor.equipes_trabalho[0].plano_trabalho.id_unidade,
                    nm_unidade: servidor.equipes_trabalho[0].plano_trabalho.nm_unidade,
                    id_chefia: null
                };
                updatePlanoTrabalho(servidor.equipes_trabalho[0].plano_trabalho.id_plano_trabalho, JSON.stringify(plano_trabalho))
                    .then(response => {
                        this.$store.dispatch('alertas/show', {
                            tipo: 'Sucesso',
                            titulo: 'Chefia imediata excluída com sucesso!',
                        });
                        this.$vuetify.goTo(0);
                    });
            },
            removerChefiaSubstDePlanoDeServidor(servidor) {
                let plano_trabalho = {
                    id_ciclo: servidor.equipes_trabalho[0].plano_trabalho.id_ciclo,
                    id_unidade_avaliacao: servidor.equipes_trabalho[0].plano_trabalho.id_unidade_avaliacao,
                    id_unidade: servidor.equipes_trabalho[0].plano_trabalho.id_unidade,
                    nm_unidade: servidor.equipes_trabalho[0].plano_trabalho.nm_unidade,
                    id_substituto: null
                };
                updatePlanoTrabalho(servidor.equipes_trabalho[0].plano_trabalho.id_plano_trabalho, JSON.stringify(plano_trabalho))
                    .then(response => {
                        this.$store.dispatch('alertas/show', {
                            tipo: 'Sucesso',
                            titulo: 'Chefia imediata substituta excluída com sucesso!',
                        });
                        this.$vuetify.goTo(0);
                    });
            },
            closeDialogAdicionarMembro() {
                this.dialogAdicionarMembro = false;
                this.siapeBusca = null;
                this.siapeBuscaResultado = [];
            },
            openMetaIndividualDialog(equipe) {
                this.editaEquipe = JSON.parse(JSON.stringify(equipe));
                this.dialogMetaIndividual = true;
            },
            editarMetaIndividual() {
                this.editaEquipe.st_acordado = false;
                this.editaEquipe.dt_acordo = '';
                updateEquipeTrabalho(this.editaEquipe.id_equipe_trabalho, this.editaEquipe)
                    .then(response => {
                        if (this.plano_trabalho.id_plano_trabalho === this.editaEquipe.id_plano_trabalho) {
                            this.plano_trabalho.equipe_trabalho = this.plano_trabalho.equipe_trabalho.filter(item => item.id_equipe_trabalho !== response.data.data.id_equipe_trabalho);
                            this.plano_trabalho.equipe_trabalho.push(response.data.data);
                        } else {
                            let plano_trabalho_filho = this.plano_trabalho.planos_filhos.filter(item => item.id === response.data.data.id_plano_trabalho)[0];
                            plano_trabalho_filho.equipe_chefia = response.data.data;
                        }

                        this.$store.dispatch('alertas/show', {
                            tipo: 'Sucesso',
                            titulo: 'Meta individual alterada com sucesso!',
                        });
                        this.$vuetify.goTo(0);
                        this.closeDialogMetaIndividual();
                        this.loadAll_st_avaliado();
                    });
            },
            editarDeAcordoMetaIndividual() {
                this.editaEquipe.st_acordado = true;
                this.editaEquipe.dt_acordo = new Date().toISOString().slice(0, 10);
                updateEquipeTrabalho(this.editaEquipe.id_equipe_trabalho, this.editaEquipe)
                    .then(response => {
                        this.plano_trabalho.equipe_trabalho = this.plano_trabalho.equipe_trabalho.filter(item => item.id_equipe_trabalho !== response.data.data.id_equipe_trabalho);
                        this.plano_trabalho.equipe_trabalho.push(response.data.data);
                        this.loadAll_st_avaliado()
                        this.$store.dispatch('alertas/show', {
                            tipo: 'Sucesso',
                            titulo: 'Meta acordada com sucesso!',
                        });
                        this.$vuetify.goTo(0);
                        this.closeDialogMetaIndividual();
                    });
            },
            closeDialogMetaIndividual() {
                this.dialogMetaIndividual = false;
                this.editaEquipe = {
                    ds_meta_individual: '',
                    st_acordado: false,
                    dt_acordo: '',
                    servidor: []
                };
            },
            editarEscopo() {
                let plano_trabalho = {
                    id_ciclo: this.plano_trabalho.id_ciclo,
                    id_unidade_avaliacao: this.plano_trabalho.id_unidade_avaliacao,
                    id_unidade: this.plano_trabalho.id_unidade,
                    nm_unidade: this.plano_trabalho.nm_unidade,
                    ds_escopo: this.escopoAlterado
                };
                updatePlanoTrabalho(this.plano_trabalho.id_plano_trabalho, JSON.stringify(plano_trabalho))
                    .then(response => {
                        this.plano_trabalho = response.data.data;
                        this.$store.dispatch('alertas/show', {
                            tipo: 'Sucesso',
                            titulo: 'Escopo alterado com sucesso!',
                        });
                        this.$vuetify.goTo(0);
                        this.dialogEscopo = false;
                    });
            },
            adicionarPontoFocal(servidor) {
                let plano_trabalho = {
                    id_ciclo: this.plano_trabalho.id_ciclo,
                    id_unidade_avaliacao: this.plano_trabalho.id_unidade_avaliacao,
                    id_unidade: this.plano_trabalho.id_unidade,
                    nm_unidade: this.plano_trabalho.nm_unidade,
                    id_ponto_focal: servidor.id_servidor
                };
                updatePlanoTrabalho(this.plano_trabalho.id_plano_trabalho, JSON.stringify(plano_trabalho))
                    .then(response => {
                        this.plano_trabalho = response.data.data;
                        this.$store.dispatch('alertas/show', {
                            tipo: 'Sucesso',
                            titulo: 'Ponto focal cadastrado com sucesso!',
                        });
                        this.$vuetify.goTo(0);
                    });
            },
            deletarPontoFocal() {
                let plano_trabalho = {
                    id_ciclo: this.plano_trabalho.id_ciclo,
                    id_unidade_avaliacao: this.plano_trabalho.id_unidade_avaliacao,
                    id_unidade: this.plano_trabalho.id_unidade,
                    nm_unidade: this.plano_trabalho.nm_unidade,
                    id_ponto_focal: null
                };
                updatePlanoTrabalho(this.plano_trabalho.id_plano_trabalho, JSON.stringify(plano_trabalho))
                    .then(response => {
                        this.plano_trabalho = response.data.data;
                        this.$store.dispatch('alertas/show', {
                            tipo: 'Sucesso',
                            titulo: 'Ponto focal excluído com sucesso!',
                        });
                        this.$vuetify.goTo(0);
                    });
            },
            adicionarPontoFocalSubstituto(servidor) {
                let plano_trabalho = {
                    id_ciclo: this.plano_trabalho.id_ciclo,
                    id_unidade_avaliacao: this.plano_trabalho.id_unidade_avaliacao,
                    id_unidade: this.plano_trabalho.id_unidade,
                    nm_unidade: this.plano_trabalho.nm_unidade,
                    id_ponto_focal_substituto: servidor.id_servidor
                };
                updatePlanoTrabalho(this.plano_trabalho.id_plano_trabalho, JSON.stringify(plano_trabalho))
                    .then(response => {
                        this.plano_trabalho = response.data.data;
                        this.$store.dispatch('alertas/show', {
                            tipo: 'Sucesso',
                            titulo: 'Ponto focal substituto cadastrado com sucesso!',
                        });
                        this.$vuetify.goTo(0);
                    });
            },
            deletarPontoFocalSubstituto() {
                let plano_trabalho = {
                    id_ciclo: this.plano_trabalho.id_ciclo,
                    id_unidade_avaliacao: this.plano_trabalho.id_unidade_avaliacao,
                    id_unidade: this.plano_trabalho.id_unidade,
                    nm_unidade: this.plano_trabalho.nm_unidade,
                    id_ponto_focal_substituto: null
                };
                updatePlanoTrabalho(this.plano_trabalho.id_plano_trabalho, JSON.stringify(plano_trabalho))
                    .then(response => {
                        this.plano_trabalho = response.data.data;
                        this.$store.dispatch('alertas/show', {
                            tipo: 'Sucesso',
                            titulo: 'Ponto focal substituto excluído com sucesso!',
                        });
                        this.$vuetify.goTo(0);
                    });
            },
            modalServidor(id_servidor, tipoServidor) {
                var servidor;
                getDetalheServidor(id_servidor)
                    .then(response => {
                        servidor = response.data.data;
                        this.$refs.servidorDialog.mostraServidorDialog(servidor, tipoServidor);
                    });
            },
            openDialogMetaIntermediaria() {
                this.btSalvaMeta = true;
                this.btEditaMeta = false;
                this.metaIntermediariaDialog.id_meta_intermediaria = null;
                this.metaIntermediariaDialog.ds_meta_intermediaria = '';
                this.metaIntermediariaDialog.nm_indicador = '';
                this.metaIntermediariaDialog.nm_formula = '';
                this.metaIntermediariaDialog.nm_fonte_info = '';
                this.metaIntermediariaDialog.id_unidade_medida = 4;
                this.metaIntermediariaDialog.nr_previsto = null;
                this.metaIntermediariaDialog.nr_realizado = null;
                this.metaIntermediariaDialog.id_plano_trabalho = null;
                this.dialogAdicionarMetaIntermediaria = true;
                //this.$refs.form_metaIntermediaria.reset(); // NAO fazer reset pois ele mexe no formulário ao abrir e fechar a dialog. Solução aqui = criar o componente para CRUD meta intermediária e comunicação via eventos.
            },
            openDialogEditarMetaIntermediaria(meta_intermediaria) {
                this.disableMetaIntermediariaEdit = (this.cicloVigente) ? false : true;
                this.metaIntermediariaDialog = JSON.parse(JSON.stringify(meta_intermediaria));
                this.btSalvaMeta = false;
                this.btEditaMeta = true;
                this.dialogAdicionarMetaIntermediaria = true;
            },
            salvarMetaIntermediaria() {
                if (this.$refs['form_metaIntermediaria'].validate()) {
                    this.metaIntermediariaDialog.id_plano_trabalho = this.plano_trabalho.id_plano_trabalho;
                    createMetaIntermediaria(JSON.stringify(this.metaIntermediariaDialog)).then(
                        response => {
                            let newMetaIntermediariaFromResponse = response.data.data
                            newMetaIntermediariaFromResponse.unidade_medida = this.unidadeMedida.find(uM => uM.id_unidade_medida === newMetaIntermediariaFromResponse.id_unidade_medida)
                            this.plano_trabalho.metas_intermediarias.push(newMetaIntermediariaFromResponse);
                            this.$store.dispatch('alertas/show', {
                                tipo: 'Sucesso',
                                titulo: 'Meta Intermediaria adicionada no plano de trabalho com sucesso'
                            });
                        },
                        error => {
                            this.$store.dispatch('alertas/show', {
                                tipo: 'Erro',
                                titulo: 'Ocorreu um erro ao adicionar a meta intermediária. Tente novamente.',
                            });
                        });
                    this.dialogAdicionarMetaIntermediaria = false;
                    this.$vuetify.goTo(0);
                } else {}
            },
            atualizaMetaIntermediaria() {
                if (this.$refs['form_metaIntermediaria'].validate()) {
                    updateMetaIntermediaria(this.metaIntermediariaDialog.id_meta_intermediaria, this.metaIntermediariaDialog)
                        .then(response => {
                            this.plano_trabalho.metas_intermediarias = this.plano_trabalho.metas_intermediarias.filter(item => item.id_meta_intermediaria !== response.data.data.id_meta_intermediaria);
                            this.plano_trabalho.metas_intermediarias.push(response.data.data);
                            this.$store.dispatch('alertas/show', {
                                    tipo: 'Sucesso',
                                    titulo: 'Meta Intermediaria alterada com sucesso!',
                                },
                                error => {
                                    this.$store.dispatch('alertas/show', {
                                        tipo: 'Erro',
                                        titulo: 'Ocorreu um erro ao alterar meta intermediária. Tente novamente.',
                                    });
                                });
                        });
                    this.dialogAdicionarMetaIntermediaria = false;
                    this.$vuetify.goTo(0);
                } else {}
            },
            excluirMetaIntermediaria(id_meta_intermediaria) {
                deleteMetaIntermediaria(id_meta_intermediaria)
                    .then(response => {
                        this.plano_trabalho.metas_intermediarias = this.plano_trabalho.metas_intermediarias.filter(item => item.id_meta_intermediaria !== id_meta_intermediaria);
                        this.$store.dispatch('alertas/show', {
                            tipo: 'Sucesso',
                            titulo: 'Meta Intermediaria excluída com sucesso!',
                        });
                    });
                this.$vuetify.goTo(0);

            },
            validarMostrarMetaIndividual(id_usuario) {
                if (this.plano_trabalho.chefia !== null) {
                    if (this.usuarioAutenticado.id_servidor == this.plano_trabalho.chefia.id)
                        return true;
                }

                if (this.plano_trabalho.unidade_avaliacao.gestor_setorial !== null) {
                    if (this.usuarioAutenticado.id_servidor == this.plano_trabalho.unidade_avaliacao.gestor_setorial.id)
                        return true;
                }

                if (this.plano_trabalho.pontoFocal !== null) {
                    if (this.usuarioAutenticado.id_servidor == this.plano_trabalho.pontoFocal.id)
                        return true;
                }

                return (this.usuarioAutenticado.id_servidor == id_usuario);
            },
            validarEdicaoMetaIndividual(id_servidor) {
                if (this.usuarioAutenticado.id_servidor == id_servidor)
                    return true;

                if (this.usuarioAutenticado.id_servidor == this.plano_trabalho.id_chefia)
                    return false;

                if (this.plano_trabalho.unidade_avaliacao.gestor_setorial !== null) {
                    if (this.usuarioAutenticado.id_servidor == this.plano_trabalho.unidade_avaliacao.gestor_setorial.id)
                        return false;
                }

                if (this.plano_trabalho.pontoFocal !== null) {
                    if (this.usuarioAutenticado.id_servidor == this.plano_trabalho.pontoFocal.id)
                        return false;
                }

                return true;
            },
            validarEdicaoDeAcordo(id_servidor) {
                return !(this.usuarioAutenticado.id_servidor == id_servidor) || this.editaEquipe.st_acordado || this.editaEquipe.ds_meta_individual === null;
            },
            informarDeAcordo() {
                let DataFormatada = ''
                if (this.editaEquipe.st_acordado) {
                    DataFormatada = this.editaEquipe.dt_acordo.slice(8, 10) + "/" + this.editaEquipe.dt_acordo.slice(5, 7) + "/" + this.editaEquipe.dt_acordo.slice(0, 4);
                    return "Meta individual acordada por " + this.editaEquipe.servidor.nome + " em " + DataFormatada;
                }
                return '';
            },
            toggleBuscaServidorSiapeCPFdialogVisible() {
                this.$refs.buscaServidorSiapeCPFdialog.toggleVisible();
            },
            toggleBuscaServidorSiapeCPFdialogAdicionarMembroVisible() {
                this.$refs.buscaServidorSiapeCPFdialogAdicionarMembro.toggleVisible();
            },
            toggleBuscaServidorSiapeCPFdialogDefinirChefiaImediataVisible() {
                this.$refs.buscaServidorSiapeCPFdialogDefinirChefiaImediata.toggleVisible();
            },
            togglePontosFocaisDialog() {
                this.$refs.pontosFocaisDialog.toggleVisible();
                this.$refs.pontosFocaisDialog.mostrarPontosFocais();
            },
            toggleBuscaServidorSiapeCPFdialogDefinirChefiaImediataSubstVisible() {
                this.$refs.buscaServidorSiapeCPFdialogDefinirChefiaImediataSubst.toggleVisible();
            },
            toggleBuscaServidorSiapeCPFdialogDefinirPontoFocalVisible() {
                this.$refs.buscaServidorSiapeCPFdialogDefinirPontoFocal.toggleVisible();
            },
            toggleBuscaServidorSiapeCPFdialogDefinirPontoFocalSubstVisible() {
                this.$refs.buscaServidorSiapeCPFdialogDefinirPontoFocalSubst.toggleVisible();
            },
            recebeuServidorFromBuscaSiapeCPF(servidor, tipo) {
                switch (tipo) {
                    case "PONTO_FOCAL":
                        this.adicionarPontoFocal(servidor);
                        break;
                    case "PONTO_FOCAL_SUBSTITUTO":
                        this.adicionarPontoFocalSubstituto(servidor);
                        break;
                    case "CHEFIA_IMEDIATA":
                        this.adicionarChefiaTitular(servidor);
                        break;
                    case "SUBSTITUTO_LEGAL":
                        this.adicionarChefiaSubstituta(servidor);
                        break;
                }
            },
            recebeuServidorFromBuscaSiapeCPFadicionarMembro(servidor) {
                this.adicionarMembro(servidor);
            },
            recebeuServidorFromBuscaSiapeCPFdefinirChefiaImediata(servidor) {
                this.servidor_buscado = servidor;

                if (servidor.equipes_trabalho && servidor.equipes_trabalho.length > 0 && servidor.equipes_trabalho[0].plano_trabalho && servidor.equipes_trabalho[0].plano_trabalho.id_chefia === servidor.id_servidor) {
                    this.removerChefiaDePlanoDeServidor(servidor)
                }
                this.adicionarMembro(servidor);
                this.adicionarChefiaTitular(servidor);
            },
            recebeuServidorFromBuscaSiapeCPFdefinirChefiaImediataSubst(servidor) {
                this.servidor_buscado = servidor;

                if (servidor.equipes_trabalho && servidor.equipes_trabalho.length > 0 &&
                    servidor.equipes_trabalho[0].plano_trabalho &&
                    servidor.equipes_trabalho[0].plano_trabalho.id_substituto === servidor.id_servidor) {
                    this.removerChefiaSubstDePlanoDeServidor(servidor)
                }

                //this.adicionarMembro(servidor);
                this.adicionarChefiaSubstituta(servidor);
            },
            recebeuServidorFromBuscaSiapeCPFdefinirPontoFocal(servidor) {
                this.adicionarPontoFocal(servidor);
            },
            recebeuServidorFromBuscaSiapeCPFdefinirPontoFocalSubst(servidor) {
                this.adicionarPontoFocalSubstituto(servidor);
            },
            hasPerfilAdmin() {
                if (this.usuarioAutenticado.perfis.find(item => item == 'administrador')) {
                    return true;
                }
                return false;
            },
            isGestorPlano() {
                if (this.usuarioAutenticado.id_servidor == this.plano_trabalho.unidade_avaliacao.id_gestor_setorial || this.usuarioAutenticado.id_servidor == this.plano_trabalho.unidade_avaliacao.id_gestor_substituto) {
                    return true;
                }
                return false;
            },
            isPontoFocalPlano() {
                if (this.usuarioAutenticado.id_servidor == this.plano_trabalho.id_ponto_focal ||
                    this.usuarioAutenticado.id_servidor == this.plano_trabalho.id_ponto_focal_substituto ||
                    this.plano_trabalho.planospais.find(item => item.id_ponto_focal == this.usuarioAutenticado.id_servidor || item.id_ponto_focal_substituto == this.usuarioAutenticado.id_servidor)
                ) {
                    return true;
                }
                return false;
            },
            isChefiaPlano() {
                if (this.usuarioAutenticado.id_servidor == this.plano_trabalho.id_chefia) {
                    return true;
                }
                return false;
            },
            isAdminOrGestorANDbeforeFaseAvaliacaoServidores(){
                return (this.hasPerfilAdmin() || this.isGestorPlano()) && this.plano_trabalho && 
                this.parseToDateInicio(this.plano_trabalho.ciclo.fases.filter(f => f.nm_fase === 'Avaliação dos Servidores')[0].dt_inicio) >= new Date()
            },
            canEditarEscopo() {
                //this.isChefiaPlano();
                if (this.cicloVigente && this.fasePermiteEdicao) {
                    if (this.hasPerfilAdmin() || this.isGestorPlano() || this.isPontoFocalPlano() || this.isChefiaPlano()) {
                        return true;
                    }
                } else if (this.isAdminOrGestorANDbeforeFaseAvaliacaoServidores()) {
                    return true
                }
                return false;
            },
            canEditarMetaIntermediaria() {
                if (this.cicloVigente && this.fasePermiteEdicao) {
                    if (this.hasPerfilAdmin() || this.isGestorPlano() || this.isPontoFocalPlano() || this.isChefiaPlano()) {
                        return true;
                    }
                } else if (this.isAdminOrGestorANDbeforeFaseAvaliacaoServidores()) {
                    return true
                }
                return false;
            },
            canEditarChefia() {
                if (this.cicloVigente && this.fasePermiteEdicao) {
                    if (this.hasPerfilAdmin() || this.isGestorPlano() || this.isPontoFocalPlano()) {
                        return true;
                    }
                } else if (this.isAdminOrGestorANDbeforeFaseAvaliacaoServidores()) {
                    return true
                }
                return false;
            },
            canEditarPontoFocal() {
                if (this.cicloVigente && this.fasePermiteEdicao) {
                    if (this.hasPerfilAdmin() || this.isGestorPlano() || this.isPontoFocalPlano()) {
                        return true;
                    }
                } else if (this.isAdminOrGestorANDbeforeFaseAvaliacaoServidores()) {
                    return true
                }
                return false;
            },
            canEditarEquipeTrabalho() {
                if (this.cicloVigente && this.fasePermiteEdicao) {
                    if (this.hasPerfilAdmin() || this.isGestorPlano() || this.isPontoFocalPlano() || this.isChefiaPlano()) {
                        return true;
                    }
                } else if (this.isAdminOrGestorANDbeforeFaseAvaliacaoServidores()) {
                    return true
                }
                return false;
            },
            canEditarMetaIndividual(id_servidor) {
                if (this.cicloVigente) {
                    if (this.usuarioAutenticado.id_servidor == id_servidor) {
                        return false;
                    } else if (this.hasPerfilAdmin() || this.isGestorPlano() || this.isPontoFocalPlano() || this.isChefiaPlano()) {
                        return true;
                    }
                } else if (this.isAdminOrGestorANDbeforeFaseAvaliacaoServidores()) {
                    return true
                }
                return false;
            },
            canVisualizarSubplano() {
                if (this.hasPerfilAdmin() || this.isGestorPlano() || this.isPontoFocalPlano() || this.isChefiaPlano()) {
                    return true;
                }
                return false;
            },
        }
    }
</script>

<style scoped>
    html {
        overflow: hidden !important;
    }

    #equipe.v-card {
        display: flex !important;
        flex-direction: column;
        max-height: 1000px;
    }

    .v-card__text {
        flex-grow: 1;
        overflow: auto;
    }
</style>
<template>
<div>
    <v-dialog v-model="dialog" width="70%" hide-overlay
      transition="dialog-bottom-transition">
        <v-card>
            <v-toolbar dark color="primary">
            <v-btn icon dark>
                <v-icon>mdi-magnify</v-icon>
            </v-btn>
            <v-toolbar-title>Avaliações cadastradas</v-toolbar-title>            
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-btn dark text @click="closeDialog()">
                <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar-items>
            </v-toolbar>

            <v-container>
                <v-row v-if="autoAvaliacao">
                    <h3 class="pa-5 primary--text">Auto avaliação</h3>
                    <v-col cols="12">
                        <v-data-table
                            :headers="headers"
                            :items="autoAvaliacao"
                            class="elevation-1"
                            :hide-default-footer="true"
                        >
                            <template v-slot:[`item.acao`]="{ item }">
                                <v-row justify="center">
                                    <v-tooltip top>
                                        <template v-slot:activator="{on, attrs}">
                                            <v-btn id="btnExcluir" icon small :color="'red'" v-bind="attrs" v-on="on" @click="showDialogDelete(item)">
                                                <v-icon> mdi-delete-forever </v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Excluir</span>
                                    </v-tooltip>
                                </v-row>
                            </template> 
                        </v-data-table>
                    </v-col>
                </v-row>
                <v-row v-if="chefiaAvaliacao">
                    <h3 class="pa-5 primary--text">Avaliação da chefia</h3>
                    <v-col cols="12">
                        <v-data-table
                            :headers="headers"
                            :items="chefiaAvaliacao"
                            class="elevation-1"
                            :hide-default-footer="true"
                        >
                            <template v-slot:[`item.acao`]="{ item }">
                                <v-row justify="center">
                                    <v-tooltip top>
                                        <template v-slot:activator="{on, attrs}">
                                            <v-btn id="btnExcluir" icon small :color="'red'" v-bind="attrs" v-on="on" @click="showDialogDelete()">
                                                <v-icon> mdi-delete-forever </v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Excluir</span>
                                    </v-tooltip>
                                </v-row>
                            </template> 
                        </v-data-table>
                    </v-col>
                </v-row>
                <v-row v-if="equipeAvaliacao">
                    <h3 class="pa-5 primary--text">Avaliação de equipe</h3>
                    <v-col cols="12">
                        <v-data-table
                            :headers="headers"
                            :items="equipeAvaliacao"
                            class="elevation-1"
                            :hide-default-footer="true"
                        >
                            <template v-slot:[`item.acao`]="{ item }">
                                <v-row justify="center">
                                    <v-tooltip top>
                                        <template v-slot:activator="{on, attrs}">
                                            <v-btn id="btnExcluir" icon small :color="'red'" v-bind="attrs" v-on="on" @click="showDialogDelete(item)">
                                                <v-icon> mdi-delete-forever </v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Excluir</span>
                                    </v-tooltip>
                                </v-row>
                            </template> 
                        </v-data-table>
                    </v-col>
                </v-row>
                <v-row v-if="pontuacaoCadastrada">
                    <h3 v-if="pontuacaoCadastrada[0] && pontuacaoCadastrada[0].perfil" class="pa-5 primary--text">
                        Pontuação cadastrada pelo {{ pontuacaoCadastrada[0].perfil }}
                    </h3>
                    <v-col cols="12">
                        <v-data-table
                            :headers="headers"
                            :items="pontuacaoCadastrada"
                            class="elevation-1"
                            :hide-default-footer="true"
                        >
                        </v-data-table>
                    </v-col>
                </v-row>
                <v-row v-if="avaliacaoRepetida">
                    <h3 class="pa-5 primary--text">Avaliação repetida de ciclo anterior</h3>
                    <v-col cols="12">
                        <v-data-table
                            :headers="headers"
                            :items="avaliacaoRepetida"
                            class="elevation-1"
                            :hide-default-footer="true"
                        > 
                        </v-data-table>
                    </v-col>
                </v-row>
            </v-container>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="grey darken-1" text @click="closeDialog()">Fechar</v-btn>
                <v-btn v-if="pontuacaoCadastrada" color="primary" text @click="showDialogDelete()">Excluir pontuação</v-btn>
                <v-btn v-else color="primary" text @click="showDialogDelete()">Excluir todas avaliações</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <v-dialog v-model="dialogAlert" width="570">
        <v-card>
            <v-card-title class="text-h5">
                <v-alert prominent type="warning" outlined width="100%">
                    <v-row align="center">
                        <v-col class="grow">
                            {{dialogMsg}}
                        </v-col>
                        <v-col class="shrink">
                        <v-btn color="warning"  @click="dialogAlert = false">Ok</v-btn>
                        </v-col>
                    </v-row>
                </v-alert>
            </v-card-title> 
        </v-card>
    </v-dialog>
    <v-dialog v-model="dialogSuccess" width="570" >
        <v-card>
            <v-card-title class="text-h5 text-center">
                <v-alert prominent type="success" width="100%">
                    <v-row align="center">
                        <v-col class="grow">
                            {{dialogMsg}}
                        </v-col>
                        <v-col class="shrink">
                        <v-btn outlined  @click="dialogSuccess = false">Ok</v-btn>
                        </v-col>
                    </v-row>
                </v-alert>
            </v-card-title> 
        </v-card>
    </v-dialog>
    <v-dialog v-model="dialogExcluirAvaliacao" max-width="500px">
        <v-card>
            <p class="text-center warning--text mt-2 pt-6" v-if="booTodas && pontuacaoCadastrada">
                <v-icon color="warning" class="mr-1">mdi-alert-circle-outline</v-icon>
                A pontuação cadastrada pelo administrador será excluída, favor informar justificativa.
            </p>
            <p class="text-center warning--text mt-2 pt-6" v-else-if="booTodas">
                <v-icon color="warning" class="mr-1">mdi-alert-circle-outline</v-icon>
                Todas as avaliações serão excluidas, favor informar justificativa.
            </p>
            <p class="text-center warning--text mt-2 pt-6" v-else>
                <v-icon color="warning" class="mr-1">mdi-alert-circle-outline</v-icon>
                A avaliação selecionada será excluida, favor informar justificativa.
            </p>
            <v-card-text>
                <v-container>
                    <v-row dense class="ma-0">
                        <v-col cols="12" class="mx-0 px-3">
                            <v-textarea
                            id="justificativa_exclusao"
                            v-model="justificativa"
                            outlined
                            rows="2"
                            name="justificativa"
                            label="Justificativa para exclusão"
                            ></v-textarea>
                        </v-col>      
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="warning darken-1" text @click="dialogExcluirAvaliacao = false">Cancelar</v-btn>
            <v-btn v-if="booTodas" color="black" text @click="excluirTodas()">Prosseguir</v-btn>
            <v-btn v-else color="black" text @click="excluir()">Prosseguir</v-btn>
            <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>
</div>
    
</template>
<script>
import { excluirAvaliacoesByGerenciarAvaliacao, excluirTodasAvaliacoesByGerenciarAvaliacao } from '../api/avaliacao';

export default {
    data() {
        return {
            dialog: false,
            justificativa:'',
            validaCampo: [v => !!v || 'Campo obrigatório'],
            dialogAlert:false,
            dialogMsg: '',
            dialogSuccess: false,
            dialogExcluirAvaliacao: false,

            idServidor: null,
            idCiclo: null,
            autoAvaliacao: [],
            chefiaAvaliacao: [],
            equipeAvaliacao: [],
            pontuacaoCadastrada: [],
            avaliacaoRepetida: [],
            headers: [
                { text: 'Nome', value: 'nm_servidor', sortable: false},
                { text: 'Nota', value: 'va_nota', sortable: false, width: '80px'},
                { text: 'Ações', value: 'acao', align: 'center', sortable: false, width: '10px'}
            ],
            booTodas: false
        }
    },
    methods: {
    	showDialog(avaliacoes = null) {
            if (avaliacoes) {
                this.idServidor = avaliacoes.idServidor;
                this.idCiclo = avaliacoes.idCiclo;
                this.autoAvaliacao = avaliacoes.autoAvaliacao;
                this.chefiaAvaliacao = avaliacoes.chefiaAvaliacao;
                this.equipeAvaliacao = avaliacoes.equipeAvaliacao;
                this.pontuacaoCadastrada = avaliacoes.pontuacaoCadastrada;
                this.avaliacaoRepetida = avaliacoes.avaliacaoRepetida;
                this.dialog = true;
            } else {
                this.dialog = true;
            }
        },

        closeDialog() {
            this.dialog = false;
            this.justificativa = '';
            this.id_avaliacao = null;
        },

        showDialogDelete(item) {
            this.justificativa = '';
            this.dialogExcluirAvaliacao = true;
            if ( item ) {
                this.id_avaliacao = item.id_avaliacao;
                this.booTodas = false;
            } else {
                this.id_avaliacao = null;
                this.booTodas = true;
            }
        },

        excluir() {
            if(this.justificativa == '') {
                this.justificativa = '';
                this.dialogMsg = "Por favor, insira uma justificativa para a exclusão.";
                return this.dialogAlert = true;                
            } 
            
            let data = new FormData();
            data.append('tx_justificativa', this.justificativa);
            data.append('id_avaliacao', this.id_avaliacao);

            excluirAvaliacoesByGerenciarAvaliacao(data).then(response => {
                document.getElementById("btnFiltrar").click();
                
                this.justificativa = '';
                this.id_avaliacao = null;
                this.dialog = false;
                this.dialogExcluirAvaliacao = false;
                this.dialogMsg = response.data.message;
                return this.dialogSuccess = true;
            }).catch(erro => {
                this.dialogMsg = "Erro ao cadastrar.";
                this.dialogAlert = true;
                this.avaliador = [];
            }); 
        },

        excluirTodas() {
            if(this.justificativa == '') {
                this.justificativa = '';
                this.dialogMsg = "Por favor, insira uma justificativa para a exclusão.";
                return this.dialogAlert = true;                
            } 
            
            let data = new FormData();
            data.append('tx_justificativa', this.justificativa);
            data.append('idServidor', this.idServidor);
            data.append('idCiclo', this.idCiclo);
            if ( this.pontuacaoCadastrada ) {
                data.append('booPontuacao', true);
            }
            if ( this.avaliacaoRepetida ) {
                data.append('booRepetida', true);
            }

            excluirTodasAvaliacoesByGerenciarAvaliacao(data).then(response => {
                document.getElementById("btnFiltrar").click();
                
                this.justificativa = '';
                this.id_avaliacao = null;
                this.dialog = false;
                this.dialogExcluirAvaliacao = false;
                this.dialogMsg = response.data.message;
                return this.dialogSuccess = true;
            }).catch(erro => {
                this.dialogMsg = "Erro ao cadastrar.";
                this.dialogAlert = true;
                this.avaliador = [];
            });
        }
    }
}

</script>

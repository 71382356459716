<template>
    <v-app>
        <Loader v-if="loading" />
        <Header :loggedIn="loggedIn" :loggedExterno="loggedExterno"/>
        <v-main id="main">
            <v-row>
                <Menu/>              
                <v-sheet id="container-content" class="col-12">
                    <Alerta class="px-md-10 mt-md-5"/>
                    <Breadcrumb class="px-md-10 mt-md-3"/>
                    <router-view :key="$route.path" class="col-12 px-md-10 pt-0"></router-view>
                </v-sheet>
            </v-row>
        </v-main>
        <v-footer
            color="#071d41"
            class="font-weight-medium"
            height="100">
            <v-col
                class="text-center"
                cols="12">
            </v-col>
        </v-footer>
    </v-app>
</template>


<script>
    import Header from './Header.vue';
    import Menu from './Menu.vue';
    import Alerta from './Alerta.vue';
    import Breadcrumb from './Breadcrumb.vue';
    import {mapState,mapMutations,mapAction } from 'vuex';
    import Loader from './Loader.vue';

    export default {
        name: 'main-app',
        components: {
            Header,
            Menu,
            Alerta,
            Breadcrumb,
            Loader
        },
        computed: {
            ...mapState('loader',['loading']),
            loggedIn() {
                return this.$store.getters['auth/isLoggedIn'];
            },
            loggedExterno() {
                return this.$store.getters['auth/isExternoLoggedIn'];
            },
        }
    }
</script>

<style scoped>
    @media print{
        .v-content {
            padding: 0 !important;
        }
    }   
</style>

<style>
    .v-application--wrap {
        min-height: auto !important;
    }

    html, body {
        height: 100%;
    }

    h1 {
        font-weight: normal;
        margin-bottom: 30px;
    }

    #app {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    a { text-decoration: none; }
</style>

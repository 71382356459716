<template>
    <div>
        <h1>Extração de dados</h1>
        <v-row>
            <v-col cols="12">
                <v-card>
                    <v-row dense class="ma-2 pa-0">
                        <v-col cols="12">
                            <v-row dense class="ma-2 pa-0">
                                <v-col cols="12" md="6" class="mx-0">
                                    <v-select
                                        v-model="filtros.id_ciclo"
                                        :items="ciclos"
                                        item-text="nm_ciclo"
                                        item-value="id_ciclo"
                                        label="Selecione o ciclo"
                                        outlined
                                        dense
                                        hide-details="auto"
                                    ></v-select>
                                </v-col>
                                <v-col cols="12" md="6" class="mx-0 px-3">
                                    <v-select
                                        id="orgao"
                                        :items="orgaos"
                                        v-model="filtros.cd_orgao"
                                        item-value="cd_orgao"
                                        item-text="nm_orgao"
                                        label="Órgão"
                                        outlined
                                        dense
                                        hide-details="auto"
                                    ></v-select>
                                </v-col>
                            </v-row>
                            <v-row dense class="ma-2 pa-0">
                                <v-col cols="12">
                                    <v-btn color="primary" @click="extrairDados()" :disabled="!filtros.id_ciclo">
                                        <v-icon>mdi-download</v-icon> Extrair dados
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
        <v-dialog v-model="dialogAlert" width="580">
            <v-card>
                <v-card-title class="text-h5">
                    <v-alert prominent type="warning" outlined width="100%">
                        <v-row align="center">
                            <v-col class="grow">
                                {{ dialogMsg }}
                            </v-col>
                            <v-col class="shrink">
                            <v-btn color="warning"  @click="dialogAlert = false">Ok</v-btn>
                            </v-col>
                        </v-row>
                    </v-alert>
                </v-card-title> 
            </v-card>
        </v-dialog>
    </div>
</template>


<script>
    import { getServidores } from '../api/servidor';
    import { getCiclos } from '../api/ciclo';
    import { gerarExtracao } from '../api/extracao';
    import { getOrgaos } from '../api/orgao';
    import { getOrgaoGestorOrgao } from '../api/orgao';

    export default {
        name: 'ServidoresListar',
        data() {
            return {
                ciclos: [],
                orgaos: [],
                filtros: [],
                isGestorOrgao: false,
                dialogMsg:'',
                dialogAlert: false
            };
        },
        created() {
            getCiclos().then(response => {
                this.ciclos = response.data.data;
            });

            if (
                this.$store.getters['auth/usuarioAutenticadoRoles'].indexOf("gestorOrgao") >= 0
                && this.$store.getters['auth/usuarioAutenticadoRoles'].indexOf("administrador") < 0
            ) {
                this.isGestorOrgao = true;
                getOrgaoGestorOrgao().then(response => { 
                    this.orgaos = response.data.data;
                    if ( response.data.data.length === 1 ) {
                        this.filtros.cd_orgao = this.orgaos.find(org => org.cd_orgao == response.data.data[0].cd_orgao);
                    } 
                });
            } else {
                getOrgaos().then(response => {
                    this.orgaos = response.data.data; 
                    this.orgaos.map(org =>{
                        org.text = org.sg_sigla + ' - ' + org.nm_orgao
                        org.value = org.cd_orgao
                    })
                    this.orgaos.unshift({cd_orgao: -1, nm_orgao: 'Todos'});
                });
            }
        },
        methods: {
            extrairDados() {
                if (this.isGestorOrgao && !this.filtros.cd_orgao) {
                    this.dialogMsg = "Campo órgão obrigatório";
                    return this.dialogAlert = true;
                }

                var filtros = [];
                filtros = this.getFiltros();

                gerarExtracao(filtros).then(response => {
                    var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                    var fileLink = document.createElement('a');
                    fileLink.href = fileURL;
                    fileLink.setAttribute('download', 'extracao-avalia.xlsx');
                    document.body.appendChild(fileLink);
                    fileLink.click();
                });
            },

            getFiltros(){
                var filtros = [];
                var obj = {};

                if ( this.filtros.id_ciclo ) {
                    obj['id_ciclo'] = this.filtros.id_ciclo;
                }

                if (this.filtros.cd_orgao && this.filtros.cd_orgao !== -1) {
                    if ( typeof this.filtros.cd_orgao === 'object' ) {
                        obj['cd_orgao'] = this.filtros.cd_orgao.cd_orgao;
                    } else {
                        obj['cd_orgao'] = this.filtros.cd_orgao;
                    }
                } 

                filtros.push(obj);
                return filtros;
            },
        }
    }
</script>
<template>
    <div class="pt-8">
        <p>
            O sistema eletrônico AvaliaMGI é o conjunto de funcionalidades eletrônicas, 
            desenvolvido para gerir a avaliação de desempenho individual e institucional e 
            responsável por ofertar suporte à elaboração de planos de trabalho, ao 
            estabelecimento de metas individuais e de metas intermediárias, ao 
            procedimento avaliativo e aos pedidos de reconsideração e de recurso, 
            de modo a dar ciência e transparência dos atos praticados para os servidores 
            públicos do quadro de pessoal do Ministério da Gestão e Inovação em Serviços 
            Públicos e para os órgãos que compõem o <strong>COLABORAGOV</strong>.
		</p>
        <p>
            Para acessá-lo, é necessário pertencer ao MGI e ao <strong>COLABORAGOV</strong> e ter uma conta no  
            <a href="https://contas.acesso.gov.br/" target="_blank">gov.br</a>.
        </p>
    </div>
</template>


<script>
    export default {
        name: 'logout',
        created() {
		},
    }
</script>
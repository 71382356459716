<template>
<div>
    <v-dialog v-model="dialog" max-width="800" persistent>
        <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark v-bind="attrs" v-on="on">
                Novo fator
                <v-icon right> mdi-plus</v-icon>
            </v-btn>
        </template>
        <v-card class="pa-5 rounded-lg">
            <v-card-title class="text-h5">
                {{tituloForm}}
            </v-card-title>
            <v-form class="pa-5" ref="form">
                <v-row>
                    <v-col cols="12">
                        <v-text-field placeholder="Nome do Fator (Título)" label="Fator" v-model.trim="fatorEditavel.nm_fator" outlined hide-details="auto" required maxlength="500" :rules="[() => !!fatorEditavel.nm_fator || 'Campo obrigatório.']">
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-textarea placeholder="Descrição da fator" label="Descrição do fator" v-model.trim="fatorEditavel.ds_fator" outlined required no-resize rows="5" maxlength="5000" :rules="[() => !!fatorEditavel.ds_fator || 'Campo obrigatório.']">
                        </v-textarea>
                    </v-col>
                </v-row>
            </v-form>
            <v-card-actions class="justify-end">
                <v-btn text @click="limparForm();">Cancelar</v-btn>
                <v-btn color="primary" @click="salvarFator()">Salvar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <v-row>
        <v-col cols="12" class="mt-3">
            <v-footer padless class="primary" dark>
                <v-col class="text-center" cols="12">
                    <strong>LISTA DE FATORES</strong>
                </v-col>
            </v-footer>
            <v-data-table disable-pagination :headers="headers" :items="fatores" class="elevation-1" hide-default-footer>
                <template v-slot:item.acao="{ item }">
                    <v-row>
                        <v-tooltip left>
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on" color="green" @click="mostrarModal(item)">mdi-pencil</v-icon>
                            </template>
                            <span>Editar</span>
                        </v-tooltip>
                        <!--<v-menu v-if="canEditarChefia()" :close-on-content-click="true">-->
                        <v-menu v-if="true" :close-on-content-click="true">
                            <template v-slot:activator="{ on: dialog, attrs }">
                                <v-tooltip left>
                                    <template v-slot:activator="{ on: tooltip}">
                                        <v-btn icon class="mx-2" color="red" small v-bind="attrs" v-on="{ ...tooltip, ...dialog }">
                                            <v-icon>
                                                mdi-delete-outline
                                            </v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Excluir fator</span>
                                </v-tooltip>
                            </template>
                            <v-card>
                                <v-card-text>
                                    Tem certeza que deseja excluir este fator?
                                </v-card-text>
                                <v-card-actions class="justify-center">
                                    <v-btn color="default">
                                        Cancelar
                                    </v-btn>
                                    <v-btn color="error" @click="deletarFator(item)">
                                        Confirmar
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-menu>
                    </v-row>
                </template>
            </v-data-table>
        </v-col>
    </v-row>
</div>
</template>

<script>
import {
    createFator,
    getFator,
    getFatores,
    updateFator,
    deleteFator
} from '../api/fator';

export default {
    name: 'FatoresListar',
    data() {
        return {
            fatores: [],
            dialog: false,
            indexFator: -1,
            fator: {
                nm_fator: '',
                ds_fator: '',
            },
            fatorEditavel: {
                nm_fator: '',
                ds_fator: '',
            },
            headers: [{
                    text: 'Fator',
                    value: 'nm_fator'
                },
                {
                    text: 'Descrição',
                    value: 'ds_fator'
                },
                {
                    text: 'Ação',
                    value: 'acao',
                    sortable: false
                },
            ],
        };
    },
    created() {
        this.carregarFatores();
    },
    computed: {
        tituloForm() {
            return this.indexFator === -1 ? 'Cadastrar Fator' : 'Editar Fator'
        },
    },
    methods: {
        mostrarModal(fator) {
            this.indexFator = this.fatores.indexOf(fator);
            this.fatorEditavel = Object.assign({}, fator);
            this.dialog = true;
            event.currentTarget.blur();
        },
        carregarFatores() {
            getFatores().then(response => {
                this.fatores = response.data.data;
            });
        },
        limparForm() {
            this.dialog = false;
            this.fatorEditavel = Object.assign({}, this.fator);
            this.indexFator = -1;
            this.$nextTick(() => {
                this.$refs.form.resetValidation();
            });
        },
        salvarFator() {
            if (this.$refs.form.validate()) {
                if (this.indexFator > -1) {
                    this.editarFator(this.fatorEditavel);
                } else {
                    createFator(this.fatorEditavel).then(response => {
                        if (response.data.success) {
                            this.carregarFatores();
                            this.$store.dispatch('alertas/show', {
                                tipo: 'Sucesso',
                                titulo: 'Fator "' + this.fatorEditavel.nm_fator + '" cadastrado com sucesso!',
                            });
                            this.limparForm();
                        }
                    });
                }
            }
        },
        editarFator(fator) {
            updateFator(fator.id_fator, fator)
                .then(response => {
                    this.$store.dispatch('alertas/show', {
                        tipo: 'Sucesso',
                        titulo: 'Fator "' + this.fatorEditavel.nm_fator + '" alterado com sucesso!',
                    });
                    this.limparForm();
                    this.carregarFatores();
                });
        },
        deletarFator(fator) {
            deleteFator(fator.id_fator)
                .then(response => {
                    this.response = response.data;
                    this.$store.dispatch('alertas/show', {
                        tipo: 'Sucesso',
                        titulo: 'Fator "' + fator.nm_fator + '" removido com sucesso!',
                    });
                    this.carregarFatores();
                });
        },
    }
}
</script>

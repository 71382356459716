import api from './index';

const getMetaIntermediariaByPlanoTrabalho = (id_plano_trabalho) => api.get('plano_trabalho/' + id_plano_trabalho + '/metas');

const getMetaIntermediariaByIdMeta = (id_meta_intermediaria) => api.get('metas_intermediarias/' + id_meta_intermediaria);

const createMetaIntermediaria = (meta_intermediaria) => api.post('metas_intermediarias', meta_intermediaria);

const deleteMetaIntermediaria = (id_meta_intermediaria) => api.delete('metas_intermediarias/' + id_meta_intermediaria);

const updateMetaIntermediaria = (id_meta_intermediaria, meta_intermediaria) => api.put('metas_intermediarias/' + id_meta_intermediaria, meta_intermediaria);

export {

    getMetaIntermediariaByPlanoTrabalho,
    getMetaIntermediariaByIdMeta,
    createMetaIntermediaria,
    updateMetaIntermediaria,
    deleteMetaIntermediaria
}
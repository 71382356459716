import api from './index';

const salvarGestorOrgao = (data) => api.post(
    'salvarGestorOrgao', 
    data
);

const excluirGestorOrgao = (id) => api.post(
    'excluirGestorOrgao', 
    id
);

const getGestores = (id_orgao) => api.get(
    'getGestores/' + id_orgao
);

const getGestorByCPF = (data) => api.post(
    'getGestorByCPF',
    data
);

export {
    salvarGestorOrgao,
    getGestores,
    excluirGestorOrgao,
    getGestorByCPF
}
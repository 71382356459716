<template>
<div>
    <h1>Emitir portaria</h1>
    <v-row>
        <v-col cols="12">
            <v-card>
                <v-form class="mt-5" ref="form_portaria">
                    <v-row dense class="ma-0 pa-2">
                        <v-col cols="6" class="mx-0 px-3">
                            <v-select
                                id="ciclo"
                                :items="ciclos"
                                v-model="filtros.id_ciclo"
                                item-text="nm_ciclo"
                                item-value="id_ciclo"
                                label="Ciclo"
                                outlined
                                dense
                                hide-details="auto"
                                required 
                                :rules="[() => !!filtros.id_ciclo || 'Campo obrigatório.']"
                            ></v-select>
                        </v-col>
                        <v-col cols="6" class="mx-0 px-3">
                            <v-select
                                id="gratificacao"
                                :items="gratificacoes"
                                v-model="filtros.id_gratificacao"
                                item-value="id_gratificacao"
                                item-text="sg_gratificacao"
                                label="Gratificação "
                                outlined
                                dense
                                hide-details="auto"
                            ></v-select>
                        </v-col>
                    </v-row>
                    <v-row dense class="ma-0 pa-2">
                        <v-col cols="12" class="mx-0 px-3">
                            <v-text-field id="tituloPortaria" ref="tituloPortaria" v-model="titulo_portaria" dense hide-details="auto" label="Título" outlined clearable clear-icon="mdi-close-circle-outline" required :rules="validaCampo"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row dense class="ma-0 pa-2">
                        <v-col cols="12" class="mx-0 px-3">
                            Corpo da portaria
                        </v-col>
                        <v-col cols="12" class="mx-0 px-3">
                            <ckeditor :editor="editor" v-model="corpo_portaria" :config="editorConfig" required :rules="validaCampo" ref="corpo_portaria"></ckeditor>
                        </v-col>
                    </v-row>
                </v-form>
                <v-row>
                    <v-col cols="10" class="text-right"></v-col>
                    <v-col cols="2" class="ma-0 mb-4 pa-2 px-8 d-flex flex-row justify-end align-end text-right elevation-0">
                        <v-btn color="success" @click="gerarPortariaPdf()">
                            <v-icon>mdi-download</v-icon> Emitir
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card>
            <br />
        </v-col>
    </v-row>
</div>
</template>

<script>
    import { getAllCiclos } from '../api/ciclo';

    import { getGratificacoes } from '../api/gratificacao';

    import { gerarPortariaPdf } from '../api/portaria';

    import Vue from 'vue';
    import CKEditor from '@ckeditor/ckeditor5-vue2';

    import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

    Vue.use( CKEditor );

    export default {
        name: 'PortariaEmitir',
        components: {
            ckeditor: CKEditor.component
        },
        data() {
            return {
                editor: ClassicEditor,
                editorConfig: {
                    language: 'pt-br'
                },
                validaCampo: [
                    v => !!v || 'Campo obrigatório',
                ],

                ciclos: [],
                filtros: {},
                gratificacoes: [],
                editor: ClassicEditor,

                titulo_portaria: 'Minuta de Portaria de resultado das avaliações',

                corpo_portaria: 
                    '<p><b>A DIRETORA DE GESTÃO DE PESSOAS DO MINISTÉRIO DA GESTÃO E DA INOVAÇÃO EM SERVIÇOS PÚBLICOS</b>, no uso da competência que lhe confere o inciso I do art. 20 do Anexo I do Decreto nº 9.745, de 8 de abril de 2019, publicado no Diário Oficial da União de 9 de abril de 2019, com suas alterações, e considerando o disposto no art. 65 da Medida Provisória nº 1.154, de 1º de janeiro de 2023, a ainda no art. 51 da Portaria ME nº 9.346 de 26 de outubro de 2022 e no Processo nº XXXXX.XXXX/20XX-XX,</p>'+
                    '<p><b>RESOLVE:</b></p>'+
                    '<p>Art. 1º Publicar, na forma do Anexo, o resultado da avaliação de desempenho individual referente ao Ciclo 202X-202X, que compreende o período de 1º de outubro de 20XX a 30 de setembro de 20XX, dos servidores do quadro de pessoal do Ministério da Gestão e Inovação de Serviços Públicos que fazem jus às gratificações de desempenho abrangidas pela Portaria GM-ME nº 528, de 26 de setembro de 2019 e que pertencem à Unidade Pagadora da Diretoria de Gestão de Pessoas, da Secretaria de Gestão Corporativa XXXXX.</p>'+
                    '<p>Art. 2º O resultado está disponibilizado de acordo com a situação funcional, contendo matrícula SIAPE, nome do servidor, gratificação e pontuação obtida.</p>'+
                    '<p>Art. 3º Esta Portaria entra em vigor na data de sua publicação.</p>'+
                    '<center>LUCÍOLA MAURÍCIO DE ARRUDA</center>'
            };
        },

        mounted() {
            getAllCiclos().then(response => { 
                this.ciclos = response.data.data; 
            });

            getGratificacoes().then(response => {
                this.gratificacoes = response.data.data;
                this.gratificacoes = _.orderBy(this.gratificacoes, 'sg_gratificacao');
                this.gratificacoes.unshift({id_gratificacao: -1, sg_gratificacao: 'Todas'});
            });
        },

        methods: {
            gerarPortariaPdf() {
                if ( this.$refs['form_portaria'].validate() ) {
                    var filtros = [];
                    var obj = {};

                    if ( this.filtros.id_ciclo ) {
                        obj['id_ciclo'] = this.filtros.id_ciclo;
                    }

                    if ( this.filtros.id_gratificacao ) {
                        obj['id_gratificacao'] = this.filtros.id_gratificacao;
                    }

                    if ( this.titulo_portaria ) {
                        obj['titulo_portaria'] = this.titulo_portaria;
                    }

                    if ( this.corpo_portaria ) {
                        obj['corpo_portaria'] = this.corpo_portaria;
                    }

                    filtros.push(obj);

                    gerarPortariaPdf(filtros).then(response => {
                        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                        var fileLink = document.createElement('a');
                        fileLink.href = fileURL;
                        fileLink.setAttribute('download', 'Portaria.pdf');
                        document.body.appendChild(fileLink);
                        fileLink.click();
                    });
                }
            }
        }
    }
</script>

<template>
<div>
    <template>
        <h1>Novo Ciclo: {{ciclo.nome}}</h1>
        <v-stepper v-model="e1" class="mb-8">
            <v-stepper-header>
                <v-stepper-step :complete="e1 > 1" step="1">
                    Informações básicas
                </v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step :complete="e1 > 2" step="2">
                    Gratificações
                </v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step :complete="e1 > 3" step="3">
                    Fases
                </v-stepper-step>
                <v-divider></v-divider>
                <!-- <v-stepper-step :complete="e1 > 4" step="4">
                    Unidades de Avaliação
                </v-stepper-step> -->
            </v-stepper-header>

            <v-stepper-items>
                <v-stepper-content step="1">
                    <v-card class="mb-2 mx-2 pa-4" color="grey lighten-4">
                        <v-form class="pa-0" ref="form_step1">
                            <v-row dense class="ma-2">
                                <v-col cols="12" sm="12">
                                    <v-text-field placeholder="Nome do ciclo" label="Nome do ciclo" v-model="ciclo.nome" outlined hide-details="auto" background-color="white" required :rules="[() => !!ciclo.nome || 'Campo obrigatório.']"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row dense class="ma-2">
                                <v-col cols="12" sm="6">
                                    <v-text-field v-mask="'####-##-##'" placeholder="Data início" label="Data de início" type="date" v-model="ciclo.dataInicio" outlined hide-details background-color="white" required :rules="rules.dataInicioRules"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-text-field v-mask="'####-##-##'" placeholder="Data fim" label="Data de encerramento" type="date" v-model="ciclo.dataFim" outlined hide-details background-color="white" required :rules="rules.dataFimRules"></v-text-field>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-card>
                    <v-card class="text-right" elevation="0" cols="12" sm="6" md="1">
                        <v-btn class="ma-3" color="primary" @click="goToStep2()">
                            Avançar
                        </v-btn>
                    </v-card>
                </v-stepper-content>

                <v-stepper-content step="2">
                    <v-card class="pa-6" color="grey lighten-4">
                        <v-card-title>
                            <span class="headline">Selecionar gratificações</span>
                        </v-card-title>
                        <v-card-text>
                            <v-container>
                                <v-row>
                                    <v-col cols="12">
                                        <v-checkbox v-model="allGratificacoesSelected" :label="`Selecionar todas`" @click="selectAllGratificacoes()"></v-checkbox>
                                    </v-col>
                                </v-row>
                                <v-divider class="ma-0"></v-divider>
                                <div v-for="(gratificacao,i) in gratificacoes" :key="gratificacao.id_gratificacao" class="ma-0">
                                    <v-list-item @click="gratificacaoSelected(i)">
                                        <template v-slot:default>
                                            <v-list-item-action>
                                                <v-checkbox :input-value="gratificacao.selected" color="primary"></v-checkbox>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>{{gratificacao.sg_gratificacao + ' - ' +gratificacao.nm_gratificacao}}</v-list-item-title>
                                            </v-list-item-content>
                                        </template>
                                    </v-list-item>
                                    <v-divider class="ma-0"></v-divider>
                                </div>

                                <v-sheet v-if="selecionarGratificacoesRequiredFieldsAlertShow" class="pa-0 ma-0 mt-5 d-flex grey lighten-3 elevation-0 justify-end align-end" width="100%">
                                    <v-alert class="mx-10 mt-3 mb-0 lighten-5" border="right" colored-border type="error" elevation="5">
                                        Escolha ao menos uma gratificação.
                                    </v-alert>
                                </v-sheet>
                            </v-container>
                        </v-card-text>
                    </v-card>

                    <v-card class="text-right" elevation="0" cols="12" sm="6" md="1">
                        <v-btn class="ma-3" text @click="e1 = 1">
                            Voltar
                        </v-btn>
                        <v-btn class="ma-3" color="primary" @click="goToStep3()">
                            Avançar
                        </v-btn>
                    </v-card>
                </v-stepper-content>

                <v-stepper-content step="3">
                    <v-card class="pa-6 grey lighten-4">
                        <v-card-title>
                            <span class="headline">Fases do ciclo</span>
                        </v-card-title>
                        <v-card-text>
                            <v-container>
                                <v-row>
                                    <v-col cols="12">
                                        <v-form class="pa-0" ref="form_step3">
                                            <v-card v-for="(fase) in fases" :key="fase.id_fase" v-show="fase.id_fase != 7 && fase.id_fase != 8 && fase.id_fase != 9" class="pa-4 mx-2 mb-5  lighten-4">
                                                <v-row class="px-3 pb-4" dense>
                                                    <h4 class="mx-1 mt-3 font-weight-medium">{{ fase.nm_fase }}</h4>
                                                </v-row>
                                                <v-row dense class="ma-2">
                                                    <v-col cols="12" sm="6">
                                                        <v-text-field ref="fase_dataInicio" placeholder="Data de início" label="Data de início" type="date" v-model="fase.dt_inicio" outlined hide-details background-color="white" required :rules="
                                                            [   () => !!fase.dt_inicio || 'Campo obrigatório.',
                                                                () => fase.dt_inicio >= ciclo.dataInicio || 'Data início deve ser posterior/igual à data de início do ciclo.',
                                                                () => fase.dt_inicio < ciclo.dataFim || 'Data início deve anterior à data final do ciclo.',
                                                                () => Number(fase.dt_inicio.substring(0,4)) <= ciclo.anoInicio+1 || 'Data início posterior ao limite de 31/dez/'+(ciclo.anoInicio+1)+'.',
                                                            ]"></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" sm="6">
                                                        <v-text-field ref="fase_dataFim" placeholder="Data de encerramento" label="Data de encerramento" type="date" v-model="fase.dt_encerramento" outlined hide-details background-color="white" required :rules="
                                                            [   () => !!fase.dt_encerramento || 'Campo obrigatório.', 
                                                                () => fase.dt_inicio <= fase.dt_encerramento || 'Data final deve ser menor ou igual à data início da fase.',
                                                                () => fase.dt_encerramento <= ciclo.dataFim || 'Data final deve ser anterior/igual à data final do ciclo.',
                                                                () => Number(fase.dt_encerramento.substring(0,4)) <= ciclo.anoInicio+1 || 'Data final posterior ao limite de 31/dez/'+(ciclo.anoInicio+1)+'.',
                                                            ]"></v-text-field>
                                                    </v-col>
                                                </v-row>
                                            </v-card>
                                        </v-form>
                                    </v-col>
                                    <v-sheet v-if="validaFormFasesAlertShow" class="pa-0 ma-0 mt-5 d-flex grey lighten-3 elevation-0 justify-end align-end" width="100%">
                                        <v-alert class="mx-10 mt-3 mb-0 lighten-5" border="right" colored-border type="error" elevation="5">
                                            Confira as datas.
                                        </v-alert>
                                    </v-sheet>
                                </v-row>
                            </v-container>
                        </v-card-text>
                    </v-card>
                    <v-card class="text-right" elevation="0" cols="12" sm="6" md="1">
                        <v-btn class="ma-3" text @click="e1 = 2">
                            Voltar
                        </v-btn>
                        <!-- <v-btn class="ma-3" color="primary" @click="goToStep4()">
                            Avançar
                        </v-btn> -->
                        <v-btn class="ma-3" color="primary" @click="postCiclo">
                            Concluir
                        </v-btn>
                    </v-card>
                </v-stepper-content>

                <!-- <v-stepper-content step="4">
                     <v-card class="mb-5 mx-2 pa-4" color="lighten-4 grey">
                        <v-card-title>
                            <span class="headline">Unidades de Avaliação</span>
                        </v-card-title>
                        <v-card-text>
                            <v-container>
                                <v-alert cols="12" type="info" prominent>
                                    <span>
                                        As unidades de avaliação e planos de trabalho serão carregadas de acordo com último ciclo.
                                    </span>
                                </v-alert>
                            </v-container>
                        </v-card-text>
                    </v-card> 
                    <v-card class="pa-6" color="grey lighten-4">
                        <v-card-title>
                            <span class="headline">Selecionar órgão que as unidades serão reaproveitadas</span>
                        </v-card-title>
                        <v-card-text>
                            <v-container>
                                <v-row>
                                    <v-col cols="12">
                                        <v-checkbox v-model="allOrgaosSelected" :label="`Selecionar todas`" @click="selectAllOrgaos()"></v-checkbox>
                                    </v-col>
                                </v-row>
                                <v-divider class="ma-0"></v-divider>
                                <div v-for="(orgao,i) in orgaos" :key="orgao.cd_orgao" class="ma-0">
                                    <v-list-item @click="orgaoSelected(i)">
                                        <template v-slot:default>
                                            <v-list-item-action>
                                                <v-checkbox :input-value="orgao.selected" color="primary"></v-checkbox>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>{{orgao.nm_orgao}}</v-list-item-title>
                                            </v-list-item-content>
                                        </template>
                                    </v-list-item>
                                    <v-divider class="ma-0"></v-divider>
                                </div>
                            </v-container>
                        </v-card-text>
                    </v-card>

                    <v-card class="text-right" elevation="0" cols="12" sm="6" md="1">
                        <v-btn class="ma-3" text @click="e1 = 3">
                            Voltar
                        </v-btn>
                        <v-btn class="ma-3" color="primary" @click="postCiclo">
                            Concluir
                        </v-btn>
                    </v-card>
                </v-stepper-content> -->
            </v-stepper-items>
        </v-stepper>
    </template>
</div>
</template>

<script>
import { getGratificacoes } from '../api/gratificacao';
import { getFases } from '../api/fase';
import { createCiclo } from '../api/ciclo';
import { getOrgaoByReaproveitarUnidade } from '../api/orgao';

import TreeView2 from '../components/other/TreeView2';

export default {
    name: 'CiclosAdicionar',
    components: {
        TreeView2,
    },
    data() {
        return {
            e1: 1,
            fases: [],
            gratificacoes: [],
            allGratificacoesSelected: true,
            validaInformacoesIniciaisAlertShow: false,
            selecionarGratificacoesRequiredFieldsAlertShow: false,
            validaFormFasesAlertShow: false,
            orgaos: [],
            allOrgaosSelected: true,
            ciclo: {
                nome: '',
                dataInicio: '',
                dataFim: '',
                anoInicio: 0,
            },
            novaUnidadeAvaliacao: {
                nome: '',
                gestorSetorial: {},
                gestorSetorial_campo_siape_OU_cpf: '',
                gestorSetorialSubstituto: {},
                gestorSetorialSubstituto_campo_siape_OU_cpf: '',
                uorgs: [],
            },

            rules: {
                dataInicioRules: [
                    v => !!v || 'Campo obrigatório',
                ],
                dataFimRules: [
                    v => !!v || 'Campo obrigatório',
                    v => this.ciclo.dataInicio < this.ciclo.dataFim || 'Data final deve ser posterior à data início',
                ],
            },
        }
    },
    computed: {

    },
    created() {
        getGratificacoes()
            .then(response => {
                response.data.data.forEach((gratificacao) => {
                    gratificacao.selected = true;
                    this.gratificacoes.push(gratificacao);
                })
            });

        getFases()
            .then(response => {
                response.data.data.forEach((fase) => {
                    fase.dt_inicio = '';
                    fase.dt_encerramento = '';
                    this.fases.push(fase);
                })
            });

        const ordenaPorName = (noArray) => {
            let retorno = _.orderBy(noArray, 'name');
            for (let node of noArray) {
                if (node.children.length > 0) {
                    node.children = ordenaPorName(node.children);
                }
            }
            return retorno;
        }

        const addSelectedBolToNode = (no) => {
            no.selected = false;
            if (no.children.length > 0) {
                for (let i = 0; i < no.children.length; i++) {
                    addSelectedBolToNode(no.children[i])
                }
            }
        };

        getOrgaoByReaproveitarUnidade()
            .then(response => {
                response.data.data.forEach((orgao) => {
                    orgao.selected = true;
                    this.orgaos.push(orgao);
                })
            });
    },
    mounted() {
        this.selectAllGratificacoes(false);
        this.selectAllOrgaos(false);
    },

    methods: {
        soNumero(e) {
            let keyCode = (e.keyCode ? e.keyCode : e.which);
            if (keyCode > 31 && (keyCode < 48 || keyCode > 57)) {
                e.preventDefault();
            }
        },
        goToStep2() {
            this.$refs['form_step1'].validate();
            if (this.$refs['form_step1'].validate()) {
                this.e1 = 2;
                this.$vuetify.goTo(0)
                this.validaInformacoesIniciaisAlertShow = false;
                this.ciclo.anoInicio = Number(this.ciclo.dataInicio.substring(0, 4))
            } else {
                this.validaInformacoesIniciaisAlertShow = true;
            }
        },
        goToStep3() {
            if (this.gratificacoes.filter(grat => grat.selected === true).length > 0) {
                this.e1 = 3;
                this.$vuetify.goTo(0)
                this.selecionarGratificacoesRequiredFieldsAlertShow = false;
            } else {
                this.selecionarGratificacoesRequiredFieldsAlertShow = true;
            }
        },
        goToStep4() {
            this.$refs['form_step3'].validate();
            if (this.$refs['form_step3'].validate()) {
                this.e1 = 4;
                this.$vuetify.goTo(0)
                this.validaFormFasesAlertShow = false;
            } else {
                this.validaFormFasesAlertShow = true;
            }
        },
        postCiclo() {
            createCiclo(this.gerarJson())
                .then(response => {
                    this.$router.push(
                        {
                            name: 'ciclos-listar',
                        },
                        () => this.$store.dispatch('alertas/show', {
                            tipo: 'Sucesso',
                            titulo: 'Ciclo salvo com suceso',
                        })
                    );
                })
                .catch(erro => {
                    this.$router.push(
                        {
                            name: 'ciclos-listar',
                        },
                        () => this.$store.dispatch('alertas/show', {
                            tipo: 'Erro',
                            titulo: 'Erro ao salvar o ciclo.',
                        })
                    );
                });
        },
        gerarJson() {
            let fases = this.fases.map(function (fase, i) {
                return {
                    id_fase: fase.id_fase,
                    dt_inicio: fase.dt_inicio,
                    dt_encerramento: fase.dt_encerramento,
                }
            });
            let gratificacoes = this.gratificacoes.filter(gratificacao => (gratificacao.selected == true)).map(function (gratificacao, i) {
                return {
                    id_gratificacao: gratificacao.id_gratificacao,
                }
            });
            let orgaos = this.orgaos.filter(orgao => (orgao.selected == true)).map(function (orgao, i) {
                return {
                    cd_orgao: orgao.cd_orgao,
                }
            });

            let json = {
                nm_ciclo: this.ciclo.nome,
                dt_inicio: this.ciclo.dataInicio,
                dt_encerramento: this.ciclo.dataFim,
                fases: fases,
                gratificacoes: gratificacoes,
                orgaos: orgaos,
            }
            return JSON.stringify(json);
        },
        gratificacaoSelected(index) {
            this.gratificacoes[index].selected = !this.gratificacoes[index].selected;
            if (this.selecionarGratificacoesRequiredFieldsAlertShow && this.gratificacoes.length > 0) {
                this.selecionarGratificacoesRequiredFieldsAlertShow = false
            }
            this.allGratificacoesSelected = (this.gratificacoes.filter(grat => grat.selected === true).length === this.gratificacoes.length) ?
                true :
                false;
        },
        selectAllGratificacoes(valueToSet) {
            let newValueToSet = (valueToSet) ? valueToSet : (this.allGratificacoesSelected) ? true : false;
            if (this.selecionarGratificacoesRequiredFieldsAlertShow && this.gratificacoes.length > 0) {
                this.selecionarGratificacoesRequiredFieldsAlertShow = false
            }
            for (let gratificacao of this.gratificacoes) {
                gratificacao.selected = newValueToSet;
            }
        },

        orgaoSelected(index) {
            this.orgaos[index].selected = !this.orgaos[index].selected;
            this.allOrgaosSelected = (this.orgaos.filter(grat => grat.selected === true).length === this.orgaos.length) ?
                true :
                false;
        },
        selectAllOrgaos(valueToSetOrgao) {
            let newValueToSetOrgao = (valueToSetOrgao) ? valueToSetOrgao : (this.allOrgaosSelected) ? true : false;
            if (this.selecionarGratificacoesRequiredFieldsAlertShow && this.orgaos.length > 0) {
                this.selecionarGratificacoesRequiredFieldsAlertShow = false
            }
            for (let orgao of this.orgaos) {
                orgao.selected = newValueToSetOrgao;
            }
        },
    },
}
</script>

<template>
    <v-breadcrumbs
        v-if="crumbs !== null && !this.$store.getters['auth/isExternoLoggedIn']"
        :items="crumbs">
        <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
        </template>
        <template v-slot:item="{ item }">
            <v-breadcrumbs-item :href="item.href" :disabled="item.disabled">
                <v-icon v-if="item.text == 'home'" color="primary">mdi-home</v-icon>
                <span v-else>{{ item.text }}</span>
            </v-breadcrumbs-item>
        </template>
    </v-breadcrumbs>
</template>

<script>
    export default {
        name: 'Breadcrumb',
        computed: {
            crumbs: function() {
                if(this.$route.meta.breadcrumb.show) {
                    let pathArray = this.$route.path.split("/");
                    pathArray.shift();
                    let breadcrumbs = pathArray.reduce((breadcrumbArray, path, idx) => {
                        if(idx == 0) {
                            breadcrumbArray.push({href: '/', text: 'home', disabled: false});
                        }
    
                        if ( this.$route.matched[idx] ) {
                            var textCrumb = '';
                            if ( this.$route.matched[idx].meta.breadcrumb.titulo ) {
                                textCrumb = this.$route.matched[idx].meta.breadcrumb.titulo;
                            } else {
                                this.$router.options.routes.forEach(function(item) {
                                    if ( item.children ) {
                                        Object.entries(item.children).forEach(function(chi) {
                                            if (chi[1].path === path || chi[1].path === '/'+path) {
                                                textCrumb = chi[1].name;
                                            }
                                        });
                                    }
                                });
                            }

                            breadcrumbArray.push({
                                href: breadcrumbArray[idx - 1]
                                    ? "/" + breadcrumbArray[idx - 1].path + "/" + path
                                    : "/" + path,
                                text: textCrumb,
                            });
                        }

                        return breadcrumbArray;
                    }, []);

                    breadcrumbs[(breadcrumbs.length)-1].disabled = true;

                    return breadcrumbs;
                }
                return null;
            }
        }
    }
</script>
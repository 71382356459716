<template>
    <div>
        <h1>Recursos dos servidores avaliados</h1>
        <v-row>
            <v-col cols="12">
                <v-card class="mb-2">
                    <v-row dense class="ma-0 pa-2"> 
                        
                        <v-col cols="12" md="6" class="mx-0 px-3">
                            <v-form  ref="form_ciclo">
                            <v-select
                                id="ciclo"
                                :items="ciclos"
                                v-model="filtros.id_ciclo"
                                item-text="nm_ciclo"
                                item-value="id_ciclo"
                                label="Ciclo"
                                outlined
                                dense
                                hide-details="auto"
                                required 
                                :rules="[() => !!filtros.id_ciclo || 'Campo obrigatório.']"
                            ></v-select>
                            </v-form>
                        </v-col>
                    </v-row>
                    <v-row dense class="ma-0 pa-2">
                        <v-col cols="12" md="6" class="mx-0 px-3">
                            <v-select
                                id="orgao"
                                :items="orgaos"
                                v-model="filtros.cd_orgao"
                                item-value="cd_orgao"
                                item-text="nm_orgao"
                                label="Órgão"
                                outlined
                                dense
                                hide-details="auto"
                            ></v-select>
                        </v-col>
                        <v-col cols="12" md="6" class="mx-0 px-3">
                            <v-text-field 
                                id="cpf" 
                                v-model="filtros.nr_cpf" 
                                ref="cpf" 
                                dense 
                                hide-details="auto" 
                                label="CPF" 
                                v-mask="'###.###.###-##'" 
                                outlined 
                                clearable 
                                clear-icon="mdi-close-circle-outline"
                            ></v-text-field>
                        </v-col>                   
                    </v-row>
                    <v-row dense class="ma-0 pa-2">
                        <v-col cols="12" md="6" class="mx-0 px-3">
                           <v-text-field 
                                id="nome" 
                                v-model="filtros.nm_servidor" 
                                ref="nome" 
                                dense 
                                hide-details="auto" 
                                label="Nome" 
                                outlined 
                                clearable 
                                clear-icon="mdi-close-circle-outline"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" class="mx-0 px-3">
                            <v-select
                                id="gratificacao"
                                :items="gratificacoes"
                                v-model="filtros.id_gratificacao"
                                item-value="id_gratificacao"
                                item-text="sg_gratificacao"
                                label="Gratificação"
                                outlined
                                dense
                                hide-details="auto"
                            ></v-select>
                        </v-col>
                    </v-row>
                    <v-row dense class="ma-0 pa-2">
                        <v-col cols="12" md="6" class="mx-0 px-3">
                            <v-menu
                                v-model="dtCadastro"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y 
                                max-width="290px"
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                        v-mask="'####-##-##'"
                                        type="date"
                                        label="Data de interposição"
                                        readonly
                                        v-model="filtros.dtCadastro"
                                        v-on="on"
                                        clearable
                                        outlined
                                        dense
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    locale="pt-br"
                                    v-model="filtros.dtCadastro"
                                    no-title
                                    @input="dtCadastro = false"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols="12" md="6" class="mx-0 px-3">
                            <v-select
                                id="cadsad"
                                :items="cadsad"
                                v-model="filtros.cadsad"
                                label="CAD/SAD cadastrada?"
                                outlined
                                dense
                                hide-details="auto"
                            ></v-select> 
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="10" class="text-right"></v-col>
                        <v-col 
                            cols="2" 
                            class="pr-8 pb-8 d-flex flex-row justify-end align-end text-right">
                            <v-btn 
                                id="btnFiltrar" 
                                color="primary" 
                                @click="listarRecursos()"
                            >
                                <v-icon>mdi-magnify</v-icon> Filtrar
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card>
                
                <br>
                <v-card>
                    <template>
                        <v-data-table
                            :headers="headers"
                            :items="recursos"
                            :items-per-page="5"
                            class="elevation-1"
                        >
                            <template v-slot:[`item.created_at`]="{ item }">
                                <span>
                                    {{ item.created_at | moment("DD/MM/YYYY HH:mm") }}
                                </span>
                            </template> 
                            <template v-slot:[`item.membro_recurso`]="{ item }">
                                <span v-if="item.membro_recurso == null">
                                    NÃO
                                </span>
                                <span v-else>
                                    SIM
                                </span>
                            </template> 
                            <template v-slot:[`item.acao`]="{ item }">
                                <v-row>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs}">
                                            <v-btn 
                                                id="btnCadSad" 
                                                icon 
                                                class="mr-2"
                                                small 
                                                :color="'primary'" 
                                                v-bind="attrs" 
                                                v-on="on" 
                                                @click="showDialogCadSad(item)"
                                            >
                                                <v-icon> mdi-account-multiple-plus </v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Cadastrar CAD ou SAD</span>
                                    </v-tooltip>
                                    <v-tooltip top v-if="isAdmin">
                                        <template v-slot:activator="{on, attrs}">
                                            <v-btn id="btnDelete" icon small v-bind="attrs" :color="'error'" v-on="on" @click="showModalExcluir(item)">
                                                <v-icon> mdi-delete-forever </v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Excluir recurso</span>
                                    </v-tooltip>
                                    <div v-if="showInserirNota">
                                        <v-tooltip top v-if="item.va_nota == null">
                                            <template v-slot:activator="{ on, attrs}">
                                                <v-btn 
                                                    id="btnInserirNota" 
                                                    icon 
                                                    small 
                                                    :color="'primary'" 
                                                    v-bind="attrs" 
                                                    v-on="on" 
                                                    @click="showDialogInserirNota(item)"
                                                >
                                                    <v-icon> mdi-numeric-5-box-multiple-outline </v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Inserir nota após decisão</span>
                                        </v-tooltip>
                                        <v-tooltip top v-else>
                                            <template v-slot:activator="{ on, attrs}">
                                                <v-btn 
                                                    id="btnInserirNota" 
                                                    icon 
                                                    small 
                                                    :color="'primary'" 
                                                    v-bind="attrs" 
                                                    v-on="on" 
                                                    @click="showDialogEditarNota(item)"
                                                >
                                                    <v-icon> mdi-numeric-5-box-multiple-outline </v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Editar nota após decisão</span>
                                        </v-tooltip>
                                    </div>
                                </v-row>
                            </template>    
                        </v-data-table>
                    </template>
                </v-card>
            </v-col>
        </v-row>
        <v-dialog 
            v-model="dialogMembros" 
            width="100%" 
            fullscreen 
            hide-overlay
            transition="dialog-bottom-transition"
        >
            <v-card>
                <v-card color="primary" dark class="mx-auto">
                    <v-toolbar dark color="primary">
                        <v-btn icon dark>
                            <v-icon>mdi-account-multiple-plus</v-icon>
                        </v-btn>
                        <v-toolbar-title v-if="radioGroup == 2">
                            Cadastrar membros da Subcomissão de Acompanhamento de Avaliação de Desempenho
                        </v-toolbar-title>
                        <v-toolbar-title v-else>
                            Cadastrar membros da Comissão de Acompanhamento de Avaliação de Desempenho
                        </v-toolbar-title>             
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                            <v-btn dark text @click="closeDialogMembros()">
                            <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-toolbar-items>
                    </v-toolbar>
                </v-card>
                <br>
                <v-container>
                    <div>
                        <v-col cols="12">
                            <v-card class="pb-3">
                                <h3 v-if="radioGroup == 2" class="pa-5 primary--text text-center">
                                    Cadastrar membros da Subcomissão de Acompanhamento de Avaliação de Desempenho
                                </h3>
                                <h3 v-else class="pa-5 primary--text text-center">
                                    Cadastrar membros da Comissão de Acompanhamento de Avaliação de Desempenho
                                </h3>
                                <v-row dense class="ma-0 pa-2">
                                    <v-col cols="12" md="12" class="mx-0 px-3">
                                        <v-checkbox
                                            v-model="checkbox"
                                            value= "1"
                                            label="Membro não pertence ao quadro do ME"
                                        ></v-checkbox>
                                    </v-col>
                                </v-row>
                                <div v-if="!checkbox">
                                    <v-row dense class="ma-0 pa-2">
                                        <v-col cols="12" md="6" class="mx-0 px-3">
                                            <v-text-field
                                                id="interno_cpf_siape" 
                                                ref="interno_cpf_siape" 
                                                v-model="nr_siape_nr_cpf"
                                                append-icon="mdi-magnify"
                                                label="Digite o CPF ou SIAPE"
                                                single-line
                                                v-mask="'###########'"
                                                hide-details="auto"
                                                v-on:change="getServidor(nr_siape_nr_cpf)"
                                                :rules="[() => !!nr_siape_nr_cpf || 'Campo obrigatório.']"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row dense class="ma-0 pa-2">
                                        <v-col cols="12" md="6" class="mx-0 px-3">
                                            <v-text-field 
                                                id="interno_siape" 
                                                ref="interno_siape" 
                                                dense 
                                                hide-details="auto" 
                                                label="SIAPE" 
                                                v-model="interno.nr_siape" 
                                                outlined 
                                                clearable 
                                                disabled
                                                clear-icon="mdi-close-circle-outline" 
                                                :rules="[() => !!interno.nr_siape || 'Campo obrigatório.']"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="6" class="mx-0 px-3">
                                            <v-text-field 
                                                id="interno_nome"  
                                                ref="interno_nome" 
                                                dense 
                                                hide-details="auto" 
                                                label="Nome" 
                                                outlined 
                                                clearable clear-icon="mdi-close-circle-outline" 
                                                v-model="interno.nm_servidor" 
                                                disabled
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row  dense class="ma-0 pa-2">
                                        <v-col cols="12" md="6" class="mx-0 px-3">
                                            <v-text-field 
                                                id="interno_cargo" 
                                                v-model="interno.nm_cargo" 
                                                ref="interno_cargo" 
                                                dense 
                                                hide-details="auto" 
                                                label="Cargo" 
                                                outlined 
                                                clearable 
                                                clear-icon="mdi-close-circle-outline" 
                                                disabled
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="6" class="mx-0 px-3">
                                            <v-text-field 
                                                id="interno_funcao" 
                                                v-model="funcao" 
                                                ref="interno_funcao" 
                                                dense 
                                                hide-details="auto" 
                                                label="Função" 
                                                outlined 
                                                clearable 
                                                clear-icon="mdi-close-circle-outline" 
                                                disabled
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row dense class="ma-0 pa-2">
                                        <v-col cols="12" md="12" class="mx-0 px-3">
                                            <v-textarea
                                                v-model="condicao"
                                                id="interno_condicao"
                                                outlined
                                                rows="2"
                                                name="interno_condicao"
                                                label="Informações da condição do membro"
                                                :rules="[() => !!condicao || 'Campo obrigatório.']"
                                            ></v-textarea>
                                        </v-col>
                                    </v-row>
                                </div>
                                <div v-else>
                                    <v-row dense class="ma-0 pa-2">
                                        <v-col cols="12" md="6" class="mx-0 px-3">
                                            <v-text-field 
                                                id="externo_cpf" 
                                                ref="externo_cpf" 
                                                dense 
                                                hide-details="auto" 
                                                label="CPF " 
                                                v-model="externo.nr_cpf" 
                                                v-mask="'###.###.###-##'" 
                                                outlined 
                                                clearable 
                                                clear-icon="mdi-close-circle-outline" 
                                                v-on:change="validaCPF(externo.nr_cpf)"
                                                :rules="[() => !!externo.nr_cpf || 'Campo obrigatório.']"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="6" class="mx-0 px-3">
                                            <v-text-field 
                                                id="externo_nome"  
                                                ref="externo_nome" 
                                                dense 
                                                hide-details="auto" 
                                                label="Nome" 
                                                outlined 
                                                clearable clear-icon="mdi-close-circle-outline" 
                                                v-model="externo.nm_servidor"
                                                :rules="[() => !!externo.nm_servidor || 'Campo obrigatório.']"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row dense class="ma-0 pa-2">
                                        <v-col cols="12" md="12" class="mx-0 px-3">
                                            <v-textarea
                                                v-model="externo.origem"
                                                id="externo_origem"
                                                outlined
                                                rows="2"
                                                name="externo_origem"
                                                label="Origem"
                                                :rules="[() => !!externo.origem || 'Campo obrigatório.']"
                                            ></v-textarea>
                                        </v-col>
                                    </v-row>
                                </div>
                                <v-row>
                                    <v-col cols="10" class="text-right"></v-col>
                                    <v-col 
                                        cols="2" 
                                        class="ma-0 mb-4 pa-2 px-8 d-flex flex-row justify-end align-end text-right elevation-0">
                                        <v-btn 
                                            id="btnSalvarMembro" 
                                            color="primary" 
                                            @click="salvarMembro()"
                                        >
                                            Salvar Membro
                                        </v-btn>
                                    </v-col>
                                </v-row>
                                <template>
                                    <v-data-table
                                        :headers="headersMembros"
                                        :items="membros"
                                        :items-per-page="5"
                                        class="elevation-1"
                                    >
                                        <template v-slot:[`item.st_servidor_externo`]="{ item }">
                                            <span v-if="item.st_servidor_externo">
                                                Não
                                            </span>
                                            <span v-else>
                                                Sim
                                            </span>
                                        </template> 
                                        <template v-slot:[`item.acao`]="{ item }">
                                            <v-row>
                                                <v-tooltip top >
                                                    <template v-slot:activator="{on, attrs}">
                                                        <v-btn 
                                                            rounded 
                                                            icon  
                                                            class="mr-2" 
                                                            id="btnremoverMembro" 
                                                            @click="removerMembro(item.id_membros_recurso)"
                                                            small
                                                            :color="'error'" 
                                                            v-bind="attrs" 
                                                            v-on="on"
                                                        >
                                                            <v-icon> mdi-delete-forever </v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>REMOVER</span>
                                                </v-tooltip>
                                            </v-row>
                                        </template>
                                    </v-data-table>
                                </template>
                            </v-card>
                        </v-col>
                    </div>
                    <template>
                        <v-card class="mx-3">
                            <v-card-title>
                                <v-row class="mt-3 mx-3">
                                    Anexos
                                    <v-spacer></v-spacer>
                                    <v-file-input 
                                        id="anexo" 
                                        ref="anexo" 
                                        :rules="rulesAnexo"
                                        accept=".png, .jpeg, .pdf, .doc, .xlsx, .xls"
                                        v-model="anexo" 
                                        label="Anexo" 
                                        outlined
                                        height="40px"
                                        dense
                                    ></v-file-input>
                                    <v-btn 
                                        color="primary" 
                                        height="40px" 
                                        @click="addAnexo()"
                                    >
                                        Adicionar
                                    </v-btn>
                                </v-row>
                            </v-card-title>
                            <v-data-table
                                :headers="headersAnexo"
                                :items="anexosRecurso"
                                :items-per-page="5"
                            >
                                <template v-slot:[`item.acao`]="{ item }">
                                    <v-row>
                                        <v-tooltip top >
                                            <template v-slot:activator="{on, attrs}">
                                                <v-btn 
                                                    rounded 
                                                    icon  
                                                    class="mr-2" 
                                                    id="btnDownload" 
                                                    @click="download(item.arquivo.id_arquivo, item.arquivo.nm_arquivo)"
                                                    small 
                                                    :color="'primary'" 
                                                    v-bind="attrs" 
                                                    v-on="on"
                                                >
                                                    <v-icon> mdi-download </v-icon>
                                                </v-btn>
                                            </template>
                                            <span>DOWNLOAD</span>
                                        </v-tooltip>

                                        <v-tooltip top >
                                            <template v-slot:activator="{on, attrs}">
                                                <v-btn 
                                                    rounded 
                                                    icon  
                                                    class="mr-2" 
                                                    id="btnremover" 
                                                    @click="removerAnexo(item.arquivo.id_arquivo)"
                                                    small
                                                    :color="'error'" 
                                                    v-bind="attrs" 
                                                    v-on="on"
                                                >
                                                    <v-icon> mdi-delete-forever </v-icon>
                                                </v-btn>
                                            </template>
                                            <span>REMOVER</span>
                                        </v-tooltip>
                                    </v-row>
                                </template>    
                            </v-data-table>
                        </v-card>
                    </template>
                    <br>
                    <template>
                        <v-card class="mx-3">
                            <v-card-title>
                                <v-row class="mt-3 mx-3">
                                    ATA
                                    <v-spacer></v-spacer>
                                    <v-file-input 
                                        id="ata" 
                                        v-if="showAta"
                                        ref="ata" 
                                        :rules="rulesAnexo"
                                        accept=".png, .jpeg, .pdf, .doc, .xlsx, .xls"
                                        v-model="ata" 
                                        label="Campo destinado à ATA" 
                                        outlined
                                        height="40px"
                                        dense
                                    ></v-file-input>
                                    <v-btn 
                                        v-if="showAta"
                                        color="primary" 
                                        height="40px" 
                                        @click="addAta()"
                                    >
                                        Adicionar
                                    </v-btn>
                                </v-row>
                            </v-card-title>
                            <v-data-table
                                :headers="headersAta"
                                :items="ataRecurso"
                                :items-per-page="5"
                            >
                                <template v-slot:[`item.acao`]="{ item }">
                                    <v-row>
                                        <v-tooltip top >
                                            <template v-slot:activator="{on, attrs}">
                                                <v-btn 
                                                    rounded 
                                                    icon  
                                                    class="mr-2" 
                                                    id="btnDownload" 
                                                    @click="download(item.arquivo.id_arquivo, item.arquivo.nm_arquivo)"
                                                    small 
                                                    :color="'primary'" 
                                                    v-bind="attrs" 
                                                    v-on="on"
                                                >
                                                    <v-icon> mdi-download </v-icon>
                                                </v-btn>
                                            </template>
                                            <span>DOWNLOAD</span>
                                        </v-tooltip>

                                        <v-tooltip top v-if="showAta">
                                            <template v-slot:activator="{on, attrs}">
                                                <v-btn 
                                                    rounded 
                                                    icon  
                                                    class="mr-2" 
                                                    id="btnremover" 
                                                    @click="removerAnexo(item.arquivo.id_arquivo)"
                                                    small
                                                    :color="'error'" 
                                                    v-bind="attrs" 
                                                    v-on="on"
                                                >
                                                    <v-icon> mdi-delete-forever </v-icon>
                                                </v-btn>
                                            </template>
                                            <span>REMOVER</span>
                                        </v-tooltip>
                                    </v-row>
                                </template>    
                            </v-data-table>
                        </v-card>
                    </template>
                </v-container>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="grey darken-1" text @click="closeDialogMembros()">Fechar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogCadSad" width="50%" :fullscreen="$vuetify.breakpoint.xsOnly">
            <v-card>
                <v-card color="primary" dark class="mx-auto">
                    <v-card-title>
                        <v-icon large left> mdi-account-multiple-plus </v-icon>
                        <span class="text-h6 font-weight-light">
                            Cadastrar CAD ou SAD
                        </span>
                    </v-card-title>
                </v-card>
                <br>
                <v-container>
                    <h3 class="text-center font-weight-light primary--text">
                        Cadastrar CAD ou SAD
                    </h3>
                    <br>
                    <v-radio-group v-model="radioGroup" class="mt-3">
                        <v-row>
                            <v-col cols="3"></v-col>
                            <v-col cols="3" class="ml-3">
                                <v-radio
                                    label="CAD"
                                    color="primary"
                                    value="1"
                                    required
                                    :rules="[() => !!radioGroup|| 'Campo obrigatório.']"
                                ></v-radio>
                            </v-col>
                            <v-col cols="3" class="ml-15">
                                <v-radio
                                    label="SAD"
                                    color="primary"
                                    value="2"
                                    :rules="[() => !!radioGroup || 'Campo obrigatório.']"
                                ></v-radio>
                            </v-col>
                            <v-col cols="3"></v-col>
                        </v-row>
                    </v-radio-group>
                </v-container>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="grey darken-1" text @click="closeDialogCadSad()">Fechar</v-btn>
                    <v-btn  color="primary" text @click="showDialogMembros(radioGroup)">Prosseguir</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
         <v-dialog v-model="dialogAlert" width="580">
            <v-card>
                <v-card-title class="text-h5">
                    <v-alert prominent type="warning" outlined width="100%">
                        <v-row align="center">
                            <v-col class="grow">
                                {{ dialogMsg }}
                            </v-col>
                            <v-col class="shrink">
                            <v-btn color="warning"  @click="dialogAlert = false">Ok</v-btn>
                            </v-col>
                        </v-row>
                    </v-alert>
                </v-card-title> 
            </v-card>
        </v-dialog>
         <v-dialog v-model="dialogSuccess" width="570" >
            <v-card>
                <v-card-title class="text-h5 text-center">
                    <v-alert prominent type="success" width="100%">
                        <v-row align="center">
                            <v-col class="grow">
                                Feito com sucesso.
                            </v-col>
                            <v-col class="shrink">
                            <v-btn outlined  @click="dialogSuccess = false">Ok</v-btn>
                            </v-col>
                        </v-row>
                    </v-alert>
                </v-card-title> 
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogNota" width="50%" :fullscreen="$vuetify.breakpoint.xsOnly">
            <v-card>
                <v-card color="primary" dark class="mx-auto">
                    <v-card-title>
                        <v-icon large left> mdi-numeric-5-box-multiple-outline </v-icon>
                        <span class="text-h6 font-weight-light" v-if="inserir">
                            Inserir nota após decisão
                        </span>
                        <span class="text-h6 font-weight-light" v-else>
                            Editar nota após decisão
                        </span>
                    </v-card-title>
                </v-card>
                <br>
                <v-container>
                    <h3 class="text-center font-weight-light primary--text" v-if="inserir">
                        Inserir nota após decisão
                    </h3>
                    <h3 class="text-center font-weight-light primary--text" v-else>
                        Editar nota após decisão
                    </h3>
                    <br>
                        <v-row class="">
                            <v-col 
                                cols="12" 
                                class="text-center"
                            >
                                <span><b>Insira aqui o novo Resultado da Avaliação Individual</b></span>
                                <br><small>(Considere apenas uma casa decimal. Notas permitidas é de <strong>0</strong> à <strong>5</strong>)</small>
                            </v-col>
                            <v-col 
                                cols="12" 
                                class="text-center px-15"
                            >
                                <v-text-field 
                                    class="pa-2 mx-15" 
                                    v-mask="'#.#'"
                                    v-model="va_nota_final_nova" 
                                    label="Nota"
                                    :rules="[
                                        () => (
                                            va_nota_final_nova 
                                            && Number(va_nota_final_nova) <= 5 
                                        ) || 'Insira uma nota válida.'
                                    ]" 
                                    hide-details="auto" 
                                    required 
                                />
                            </v-col>
                        </v-row>
                        <v-row class="my-1">
                            <v-col cols="12" class="d-flex flex-column justify-end text-center">
                                <span class="text-h6"><b>Conversão da nota em pontos</b></span>
                            </v-col>
                            <v-col cols="12" class="d-flex flex-column align-center justify-center text-center">
                                <span>
                                    <v-chip large label class="px-2 mx-1 green" text-color="white">
                                        <span class="text-h4 font-weight-medium">

                                            {{nr_pontos_novo ? nr_pontos_novo : 0}}
                                        </span>
                                    </v-chip>
                                </span>
                            </v-col>
                        </v-row>
                </v-container>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="grey darken-1" text @click="dialogNota =false" >Fechar</v-btn>
                    <v-btn  color="primary" text @click="inserirNota()">Salvar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
                <h4 class="primary--text text-center py-6">
                    Por favor, insira uma justificativa explicando<br>
                    porque deseja excluir
                 </h4>
                
                <v-card-text>
                    <v-container>
                        <v-row dense class="ma-0">
                            <v-col cols="12" class="mx-0 px-3">
                                <v-textarea
                                v-model="justificativa"
                                id="justificativa"
                                outlined
                                rows="2"
                                name="justificativa"
                                label="Justificativa"
                                ></v-textarea>
                            </v-col>      
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="grey darken-1" text @click="dialogDelete = false">Fechar</v-btn>
                <v-btn color="red darken-1"  text @click="excluir()">Excluir</v-btn>
                <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>


<script>
import ComumService from '../services/comum.service'
import { getCiclos } from '../api/ciclo';

import { getFuncao } from '../api/funcao';

import { getRecursos } from '../api/recurso';
import { getAnexosCadSad } from '../api/recurso';
import { adicionarAnexoCadSad } from '../api/recurso';
import { downloadAnexoRecurso } from '../api/recurso';
import { removerAnexoRecurso } from '../api/recurso';
import { salvarMembroCadSad } from '../api/recurso';
import { getMembros } from '../api/recurso';
import { removerMembroCadSad } from '../api/recurso';
import { getAtaCadSad } from '../api/recurso';
import { adicionarAtaCadSad } from '../api/recurso';
import { inserirNotaAposDecisaoRecurso } from '../api/recurso';
import { excluirRecurso } from '../api/recurso';
import { getOrgaos } from '../api/orgao';
import { getOrgaoGestorOrgao } from '../api/orgao';

import { getServidorByCPF } from '../api/servidor';
import { getServidorBySiape } from '../api/servidor';

import {getGratificacoes} from '../api/gratificacao';


export default {
    name: 'RecursoListar',
    data() {
        return {
            ciclos: [],
            filtros: [],
            headers: [
                { text: 'CPF', value: 'avaliado.nr_cpf', sortable: false },
                { text: 'Nome', value: 'avaliado.nm_servidor', sortable: false },
                { text: 'Gratificação', value: 'avaliado.gratificacao.sg_gratificacao', sortable: false },
                { text: 'Data de interposição', value: 'created_at', sortable: false },
                { text: 'CAD/SAD cadastrada', value: 'membro_recurso', sortable: false },
                { text: 'Órgão', value: 'avaliado.orgao.sg_sigla', sortable: false },
                { text: 'Ação', value: 'acao', sortable: false, width: '100px' },
            ],
            recursos:[],
            radioGroup: null,
            dialogCadSad: false,
            interno: [],
            checkbox: null,
            externo: [],
            dialogMembros: false,
            dialogAlert: false,
            dialogMsg: '',
            headersAnexo: [
                { text: 'Nome do anexo', value: 'arquivo.nm_arquivo' ,sortable: false},
                { text: 'Ação', value: 'acao' ,sortable: false},
            ],
            anexosRecurso: [],
            anexo:null,
            id_reconsideracao: null,
            headersMembros: [
                { text: 'CPF', value: 'nr_cpf' ,sortable: false},
                { text: 'Nome', value: 'nm_nome' ,sortable: false},
                { text: 'Pertence ao quadro do ME', value: 'st_servidor_externo' ,sortable: false},
                { text: 'Ação', value: 'acao' ,sortable: false},
            ],
            membros: [],
            nr_siape_nr_cpf: null,
            condicao: null,
            funcao:null,
            id_recurso: null,
            dialogSuccess: false,
            rulesAnexo: [
                value => !value || value.size < 2000000 || 'Tamanho deve ser menor que 2 MB!',
            ],
            headersAta: [
                { text: 'Ata', value: 'arquivo.nm_arquivo' ,sortable: false},
                { text: 'Ação', value: 'acao' ,sortable: false},
            ],
            ataRecurso: [],
            ata:null,
            showAta: false,
            dialogNota: false,
            va_nota_final_nova: null,
            nr_pontos_novo: null,
            showInserirNota: false,
            inserir: false,
            gratificacoes: [],
            cadsad: [
                { text: 'Selecione', value: null},
                { text: 'Sim', value: 1},
                { text: 'Não', value: 2}
            ],
            dtCadastro: false,
            dialogDelete: false,
            justificativa: '',
            isAdmin: false,
            orgaos:[],
            isGestorOrgao: false
        };
    },
    watch: {
        va_nota_final_nova: function (value) {
            if (Number(value) <= 5 && Number(value) >= 4){
                this.nr_pontos_novo = 20
            } else if (Number(value) < 4 && Number(value) >= 3.5){
                this.nr_pontos_novo = 17
            } else if (Number(value) < 3.5 && Number(value) >= 3){
                this.nr_pontos_novo = 14
            } else if (Number(value) < 3 && Number(value) >= 2){
                this.nr_pontos_novo = 10
            } else if (Number(value) < 2 && Number(value) >= 1){
                this.nr_pontos_novo = 5
            } else {
                this.nr_pontos_novo = 0
            }
        }
    },
    mounted() {
        getGratificacoes()
            .then(response => {
                this.gratificacoes = response.data.data;
                this.gratificacoes = _.orderBy(this.gratificacoes, 'sg_gratificacao');
                this.gratificacoes.unshift({id_gratificacao: -1, sg_gratificacao: 'Todas'});
        });
    },
    created() {
        getCiclos().then(response => {
            this.ciclos = response.data.data;
        });

        if (this.$store.getters['auth/usuarioAutenticadoRoles'].indexOf("administrador") >= 0) {
            this.showInserirNota = true;
            this.isAdmin = true;
        }

        if (
            this.$store.getters['auth/usuarioAutenticadoRoles'].indexOf("gestorOrgao") >= 0
            && this.$store.getters['auth/usuarioAutenticadoRoles'].indexOf("administrador") < 0
        ) {
            this.isGestorOrgao = true;
            getOrgaoGestorOrgao().then(response => { 
                this.orgaos = response.data.data;
                if ( response.data.data.length === 1 ) {
                    this.filtros.cd_orgao = this.orgaos.find(org => org.cd_orgao == response.data.data[0].cd_orgao);
                } 
            });
        } else {
            getOrgaos().then(response => {
                this.orgaos = response.data.data; 
                this.orgaos.map(org =>{
                    org.text = org.sg_sigla + ' - ' + org.nm_orgao
                    org.value = org.cd_orgao
                })
                this.orgaos.unshift({cd_orgao: -1, nm_orgao: 'Todos'});
            });
        }
    },
    methods: {
        getFiltros(){
            var filtros = [];
            var obj = {};

            if ( this.filtros.id_ciclo ) {
                obj['id_ciclo'] = this.filtros.id_ciclo;
            }

            if (this.filtros.nr_cpf) {
                obj['nr_cpf'] = this.filtros.nr_cpf.replace(/[-.]/g,'');
            }

            if( this.filtros.nm_servidor ) {
                obj['nm_servidor'] = this.filtros.nm_servidor.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase();
            }

            if ( this.filtros.id_gratificacao ) {
                obj['id_gratificacao'] = this.filtros.id_gratificacao;
            }

            if ( this.filtros.dtCadastro ) {
                obj['created_at'] = this.filtros.dtCadastro;
            }

            if ( this.filtros.cadsad ) {
                obj['st_cad_sad'] = this.filtros.cadsad;
            }

            if (this.filtros.cd_orgao && this.filtros.cd_orgao !== -1) {
                if ( typeof this.filtros.cd_orgao === 'object' ) {
                    obj['cd_orgao'] = this.filtros.cd_orgao.cd_orgao;
                } else {
                    obj['cd_orgao'] = this.filtros.cd_orgao;
                }
            } 

            filtros.push(obj);
            return filtros;
        },

        listarRecursos() {
            if (this.isGestorOrgao && !this.filtros.cd_orgao) {
                this.dialogMsg = "Campo órgão obrigatório";
                return this.dialogAlert = true;
            }
            
            var filtros = [];
            filtros = this.getFiltros();
            if ( this.$refs['form_ciclo'].validate() ) {
                getRecursos(filtros).then(response => {
                    this.recursos = response.data.data;
                });
            }
        },

        listarMembros(tipo){
            var filtros = [];
            var obj = {};

            if (tipo ==1) {
                //CAD
                obj['st_subcomissao'] = 0;
            } else {
                //SAD
                obj['st_subcomissao'] = 1;
            }

            filtros.push(obj);

            getMembros(filtros).then(response => {
                this.membros = response.data.data;
            });
        },

        showDialogCadSad(recurso) {
            this.id_reconsideracao = recurso.id_reconsideracao;
            this.id_recurso = recurso.id_recurso;
            this.radioGroup = null;
            this.dialogCadSad = true;
        },

        closeDialogCadSad() {
            this.radioGroup = null;
            this.dialogCadSad = false;
        },

        showDialogMembros(tipo) {
            if (tipo == null) {
                this.dialogMsg = "Selecione uma opção"
                return this.dialogAlert = true;
            }
            this.listarMembros(tipo);
            this.listarAnexos();
            if (this.$store.getters['auth/usuarioAutenticadoRoles'].indexOf("administrador") >= 0) {
                this.showAta = true;
            }

            if (this.$store.getters['auth/usuarioAutenticadoRoles'].indexOf("gestorSetorial") >= 0) {
                this.showAta = true;
            }
            this.dialogMembros = true;
        },

        closeDialogMembros() {
            this.listarRecursos();
            this.radioGroup = null;
            this.dialogMembros = false;
        },

        salvarMembro() {
            if (!this.checkbox) {
                if (this.nr_siape_nr_cpf == null || this.nr_siape_nr_cpf == '') {
                    this.nr_siape_nr_cpf = '';
                    this.dialogMsg = "Campo obrigatório";
                    return this.dialogAlert = true;
                }

                if (this.condicao == null || this.condicao == '') {
                    this.condicao = '';
                    this.dialogMsg = "Campo obrigatório";
                    return this.dialogAlert = true;
                }

                var cpfExistente = false;
                var cpf = this.interno.nr_cpf.replaceAll('.','').replaceAll('-','')
                this.membros.map(function(v) {
                   if (v.nr_cpf == cpf) {
                       cpfExistente= true;
                   } 
                });

                if (cpfExistente) {
                    this.nr_siape_nr_cpf = '';
                    this.dialogMsg = "Este CPF já é membro";
                    return this.dialogAlert = true;
                }

                let data = new FormData();
                data.append('id_recurso', this.id_recurso);
                data.append('nr_cpf', this.interno.nr_cpf.replaceAll('.','').replaceAll('-',''));
                data.append('nm_nome', this.interno.nm_servidor.toUpperCase());
                data.append('id_servidor', this.interno.id_servidor);
                data.append('ds_condicao', this.condicao);

                if (this.radioGroup ==1) {
                    //CAD
                    data.append('st_subcomissao', 0);
                } else {
                    //SAD
                    data.append('st_subcomissao', 1);
                }

                salvarMembroCadSad(data).then(response => {
                    this.dialogSuccess =true;
                    this.listarMembros(this.radioGroup);
                }).catch(erro => {
                    this.dialogMsg = "Erro ao salvar.";
                    this.dialogAlert = true;
                });

            } else {
                if (this.externo.nr_cpf == '' || this.externo.nr_cpf == null) {
                    this.externo.nr_cpf = '';
                    this.dialogMsg = "Campo obrigatório";
                    return this.dialogAlert = true;
                }

                if (this.externo.nm_servidor == '' || this.externo.nm_servidor == null) {
                    this.externo.nm_servidor = '';
                    this.dialogMsg = "Campo obrigatório";
                    return this.dialogAlert = true;
                }

                if (this.externo.origem == '' || this.externo.origem == null) {
                    this.externo.origem = '';
                    this.dialogMsg = "Campo obrigatório";
                    return this.dialogAlert = true;
                }

                var cpfExistente = false;
                var cpf = this.externo.nr_cpf.replaceAll('.','').replaceAll('-','')
                this.membros.map(function(v) {
                   if (v.nr_cpf == cpf) {
                       cpfExistente= true;
                   } 
                });

                if (cpfExistente) {
                    this.externo.nr_cpf = '';
                    this.dialogMsg = "Este CPF já é membro";
                    return this.dialogAlert = true;
                }

                let data = new FormData();
                data.append('id_recurso', this.id_recurso);
                data.append('nr_cpf', this.externo.nr_cpf.replaceAll('.','').replaceAll('-',''));
                data.append('nm_nome', this.externo.nm_servidor.toUpperCase());
                data.append('st_servidor_externo', 1);
                data.append('ds_servidor_externo_origem', this.externo.origem);

                if (this.radioGroup ==1) {
                    //CAD
                    data.append('st_subcomissao', 0);
                } else {
                    //SAD
                    data.append('st_subcomissao', 1);
                }
                
                salvarMembroCadSad(data).then(response => {
                    this.dialogSuccess =true;
                    this.listarMembros(this.radioGroup);
                }).catch(erro => {
                    this.dialogMsg = "Erro ao salvar.";
                    this.dialogAlert = true;
                });
            }
        },

        listarAnexos() {
            getAnexosCadSad(JSON.stringify(this.id_reconsideracao)).then(response => {
                this.anexosRecurso = response.data.data;
            }).catch(erro => {
                this.dialogMsg = "Erro ao listar anexos.";
                this.dialogAlert = true;
            });

            getAtaCadSad(JSON.stringify(this.id_reconsideracao)).then(response => {
                this.ataRecurso = response.data.data;
            }).catch(erro => {
                this.dialogMsg = "Erro ao listar ATA.";
                this.dialogAlert = true;
            });
        },

        addAnexo() {
            if (this.anexo == null) {
                this.dialogMsg = 'Primeiro escolha um anexo' 
                return this.dialogAlert = true;
            }

            let data = new FormData();
            data.append('file', this.anexo);
            data.append('id_reconsideracao', this.id_reconsideracao);
            data.append('id_servidor', this.$store.getters['auth/usuarioAutenticado']);
            adicionarAnexoCadSad(data).then(response => {
                this.dialogSuccess =true;
                this.anexo = null;
                this.listarAnexos();
            }).catch(erro => {
                this.dialogMsg = "Erro ao adicionar.";
                this.dialogAlert = true;
            });
        },

        download(id_arquivo, nm_arquivo) {
            downloadAnexoRecurso(id_arquivo).then(response => {
                let blob = new Blob([response.data], { type: 'application/pdf' });
                let link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = nm_arquivo;                
                link.click();
            }).catch(erro => {
                this.dialogMsg = "Erro ao realizar download.";
                this.dialogAlert = true;
            });
        },

        removerAnexo(id_arquivo) {
            let data = new FormData();
            data.append('id_anexo', id_arquivo);

            removerAnexoRecurso(data).then(response => {
                this.dialogSuccess =true;
                this.listarAnexos();
            }).catch(erro => {
                this.dialogMsg = "Erro ao remover.";
                this.dialogAlert = true;
            });
        },

        getServidor(numero) {
           
            if (numero.length > 8) {
                getServidorByCPF(numero).then(response => {
                    this.interno = response.data.data;
                    if (this.interno.id_funcao != null) {
                        getFuncao(this.interno.id_funcao).then(res =>{                            
                            var funcao = res.data.data;
                            this.funcao = funcao.cd_funcao +' - '+funcao.nm_funcao;
                        })
                    }
                }).catch(e => {
                    this.interno = [];
                    this.nr_siape_nr_cpf = null;
                    this.funcao = null;
                    this.dialogMsg = "Não encontrado.";
                    this.dialogAlert = true;
                });
            } else {
                getServidorBySiape(numero).then(response => {
                    this.interno = response.data.data;
                    if (this.interno.id_funcao != null) {
                        getFuncao(this.interno.id_funcao).then(res =>{
                            var funcao = res.data.data;
                            this.funcao = funcao.cd_funcao +' - '+funcao.nm_funcao;
                        })
                    }
                }).catch(e => {
                    this.interno = [];
                    this.nr_siape_nr_cpf = null;
                    this.funcao = null;
                    this.dialogMsg = "Não encontrado.";
                    this.dialogAlert = true;
                });
            }                       
        },

        removerMembro(id_membro) {
            let data = new FormData();
            data.append('id_membros_recurso', id_membro);

            removerMembroCadSad(data).then(response => {
                this.dialogSuccess =true;
                this.listarMembros(this.radioGroup);
            }).catch(erro => {
                this.dialogMsg = "Erro ao remover.";
                this.dialogAlert = true;
            });
        },

        validaCPF(cpf){
            var cpfValido = ComumService.validaCPF(cpf);
            if (!cpfValido) {
                this.externo.nr_cpf = null;
                this.dialogMsg = "CPF inválido.";
                this.dialogAlert = true;
            }
        },

        addAta() {
            if (this.ata == null) {
                this.dialogMsg = 'Primeiro escolha à ATA' 
                return this.dialogAlert = true;
            }

            let data = new FormData();
            data.append('file', this.ata);
            data.append('id_reconsideracao', this.id_reconsideracao);
            data.append('id_servidor', this.$store.getters['auth/usuarioAutenticado']);
            adicionarAtaCadSad(data).then(response => {
                this.dialogSuccess =true;
                this.ata = null;
                this.listarAnexos();
            }).catch(erro => {
                this.dialogMsg = "Erro ao adicionar.";
                this.dialogAlert = true;
            });
        },

        showDialogInserirNota(recurso) {
            this.nr_pontos_novo = null;
            this.va_nota_final_nova = null;
            this.id_recurso = recurso.id_recurso;
            this.id_reconsideracao = recurso.id_reconsideracao;

            getMembros([{}]).then(response => {
                this.membros = response.data.data;
                if (response.data.data.length == 0) {
                    this.dialogMsg = "Recurso não possui membro";
                    return this.dialogAlert = true;
                } else {
                    this.inserir = true;
                    this.dialogNota = true;
                }
            });
        },

        showDialogEditarNota(recurso) {
            this.va_nota_final_nova = recurso.va_nota;
            this.id_recurso = recurso.id_recurso;
            this.inserir = false;
            this.dialogNota = true;
        },

        inserirNota() {
            if (
                this.va_nota_final_nova == null
                || this.va_nota_final_nova == ''
                || this.va_nota_final_nova > 5
            ) {
                this.dialogMsg = "Insira uma nota válida.";
                return this.dialogAlert = true;
            }

            getAtaCadSad(JSON.stringify(this.id_reconsideracao)).then(response => {
                if (response.data.data.length == 0) {
                    this.dialogMsg = "Recurso não possui ATA";
                    return this.dialogAlert = true;
                } else {
                    let data = new FormData();
                    data.append('id_recurso', this.id_recurso);
                    data.append('va_nota', this.va_nota_final_nova);
                    data.append('nr_pontos', this.nr_pontos_novo);

                    inserirNotaAposDecisaoRecurso(data).then(response => {
                        this.dialogNota = false;
                        this.listarRecursos();
                        this.dialogSuccess = true;
                    }).catch(erro => {
                        this.dialogMsg = "Erro ao salvar.";
                        this.dialogAlert = true;
                    });
                }
            });
        },

        showModalExcluir(recurso) {
            this.id_recurso = recurso.id_recurso;
            this.justificativa = '';
            this.dialogDelete = true;
        },

        excluir() {
            if (this.justificativa == '' || this.justificativa.length < 3) {
                this.dialogMsg = 'Justificativa inválida.';
                return this.dialogAlert = true;
            }

            let data = new FormData();
            data.append('ds_justificativa_delete', this.justificativa);
            data.append('id_admin_excludente', this.$store.getters['auth/usuarioAutenticado']);

            excluirRecurso(this.id_recurso, data).then(response => {
                this.listarRecursos();
                this.dialogSuccess =true;
                this.dialogDelete = false;
            }).catch(erro => {
                this.dialogMsg = "Erro ao deletar.";
                this.dialogAlert = true;
            });
        }
    }
}
</script>
<template>
<div>
    <v-dialog v-model="dialog" width="60%"
      transition="dialog-bottom-transition">
        <v-form class="mt-5" ref="form_cadastrar_nota">
            <v-card>
                <v-toolbar dark color="primary">
                <v-btn icon dark>
                    <v-icon>mdi mdi-account</v-icon>
                </v-btn>
                <v-toolbar-title>Cadastrar nota de avaliação de servidor</v-toolbar-title>            
                <v-spacer></v-spacer>
                <v-toolbar-items>
                    <v-btn dark text @click="closeDialog()">
                    <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar-items>
                </v-toolbar>

                <v-container>
                    <v-col id="alertServidorAvaliacao" cols="12" md="12" class="mx-0 px-3" style="display: none;">
                        <v-alert prominent type="warning" outlined width="100%">
                            <v-row align="center">
                                <v-col cols="12" class="grow">
                                    Existe avaliações cadastradas para esse servidor, ao salvar a pontuação elas serão excluidas.
                                </v-col>
                            </v-row>
                        </v-alert>
                    </v-col>
                    <v-row dense class="ma-0">
                        <v-col cols="12" md="4" class="mx-0 px-3">
                            <v-text-field id="cpf_avaliado" ref="cpfavaliado" dense hide-details="auto" label="CPF servidor" v-model="servidor.nr_cpf" v-mask="'###.###.###-##'" outlined clearable clear-icon="mdi-close-circle-outline" :rules="validaCampo" v-on:change="getServidor(`${servidor.nr_cpf}`)">
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" md="8" class="mx-0 px-3">
                            <v-text-field id="nm_servidor" ref="nmServidor" dense hide-details="auto" label="Nome" outlined clearable clear-icon="mdi-close-circle-outline" v-model="servidor.nm_servidor" disabled>
                            </v-text-field>
                        </v-col>
                    </v-row>

                    <v-row dense class="ma-0">
                        <v-col cols="12" md="4" class="mx-0 px-3">
                            <v-select
                                id="ciclo"
                                :items="ciclos"
                                v-model="id_ciclo"
                                item-text="nm_ciclo"
                                item-value="id_ciclo"
                                label="Ciclo"
                                outlined
                                dense
                                hide-details="auto"
                                required 
                                :rules="validaCampo"
                                v-on:change="validarAvaliacaoServidor()"
                            ></v-select>
                        </v-col>
                        <v-col cols="12" md="8" class="mx-0 px-3">
                            <v-text-field id="pontuacao" ref="pontuacao" dense hide-details="auto" label="Pontuação" v-model="nr_pontos" v-mask="'##'" outlined clearable clear-icon="mdi-close-circle-outline" :rules="validaCampo">
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <p class="text-center mt-2" >
                        <span class="red--text">* </span>Por favor, insira uma justificativa explicando o motivo desta ação.
                    </p>
                    <v-row dense class="ma-0">
                        <v-col cols="12" class="px-3">
                            <v-textarea
                            v-model="justificativa"
                            id="justificativa"
                            outlined
                            rows="2"
                            name="justificativa"
                            label="Justificativa"
                            :rules="validaCampo"
                            ></v-textarea>
                        </v-col>      
                    </v-row>
                </v-container>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="grey darken-1" text @click="closeDialog()">Fechar</v-btn>
                    <v-btn  color="primary" text @click="create()">Salvar</v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
    </v-dialog>
    <v-dialog v-model="dialogAlert" width="570">
        <v-card>
            <v-card-title class="text-h5">
                <v-alert prominent type="warning" outlined width="100%">
                    <v-row align="center">
                        <v-col cols="10" class="grow">
                            {{dialogMsg}}
                        </v-col>
                        <v-col cols="2" class="shrink">
                        <v-btn color="warning"  @click="dialogAlert = false">Ok</v-btn>
                        </v-col>
                    </v-row>
                </v-alert>
            </v-card-title> 
        </v-card>
    </v-dialog>
    <v-dialog v-model="dialogSuccess" width="570" >
        <v-card>
            <v-card-title class="text-h5 text-center">
                <v-alert prominent type="success" width="100%">
                    <v-row align="center">
                        <v-col class="grow">
                            {{dialogMsg}}
                        </v-col>
                        <v-col class="shrink">
                        <v-btn outlined  @click="dialogSuccess = false">Ok</v-btn>
                        </v-col>
                    </v-row>
                </v-alert>
            </v-card-title> 
        </v-card>
    </v-dialog>
</div>
    
</template>
<script>
import ComumService from '../services/comum.service';
import { getServidorByCPF, getServidorByCpfForGestor } from '../api/servidor';
import { createPontuacaoServidor, getPontuacaoSalvaServidor, getValidarAvaliacoesServidorPontuacao } from '../api/pontuacao_cadastrada_servidor';
import { getAllCiclos } from '../api/ciclo';

export default {
    data() {
        return {
            dialog: false,
            servidor: [],
            id_ciclo:'',
            nr_pontos:'',
            justificativa:'',
            validaCampo: [v => !!v || 'Campo obrigatório'],
            dialogAlert:false,
            dialogMsg: '',
            dialogSuccess: false,
            ciclos: []
        }
    },
    methods: {
    	showDialog() {
            this.dialog = true;
            getAllCiclos().then(response => { 
                this.ciclos = response.data.data; 
            });
        },

        closeDialog() {
            this.dialog = false;
            this.clearInputs();
        },

        clearInputs() {
            this.servidor.nr_cpf = '';
            this.servidor.nm_servidor = '';
            this.id_ciclo = null;
            this.nr_pontos = '';
            this.justificativa = '';
            document.getElementById("alertServidorAvaliacao").style.display = "none";
        },

        getServidor(cpf) {
            var cpfValido = ComumService.validaCPF(cpf);
            
            if (!cpfValido) {
                this.clearInputs();
                this.dialogMsg = "CPF informado está inválido!";
                return this.dialogAlert = true;
            }

            if (
                this.$store.getters['auth/usuarioAutenticadoRoles'].indexOf("gestorOrgao") >= 0
                && this.$store.getters['auth/usuarioAutenticadoRoles'].indexOf("administrador") < 0
            ) {
                getServidorByCpfForGestor(cpf).then(response => {
                    document.getElementById("alertServidorAvaliacao").style.display = "none";
                    
                    if (response.data.message == 'outroOrgao') {
                        this.dialogMsg = "Servidor de outro órgão!";
                        this.servidor = [];
                        return this.dialogAlert = true;
                    }else {
                        this.servidor = response.data.data;
                        this.servidor.nr_cpf = response.data.data.nr_cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
                        
                        if ( this.servidor.nr_cpf && this.id_ciclo ) {
                            this.validarAvaliacaoServidor();
                        }
                    }
                }).catch(erro => {
                    this.dialogMsg = "CPF não encontrado.";
                    this.dialogAlert = true;
                    this.servidor = [];
                });
            } else {
                getServidorByCPF(cpf).then(response => {
                    document.getElementById("alertServidorAvaliacao").style.display = "none";
                    this.servidor = response.data.data;
                    this.servidor.nr_cpf = response.data.data.nr_cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
                    
                    if ( this.servidor.nr_cpf && this.id_ciclo ) {
                        this.validarAvaliacaoServidor();
                    }

                }).catch(erro => {
                    this.dialogMsg = "CPF não encontrado.";
                    this.dialogAlert = true;
                    this.servidor = [];
                });
            }
        },

        create() {
            if ( this.$refs['form_cadastrar_nota'].validate() ) {
                if (this.nr_pontos > 20) {
                    this.nr_pontos = '';
                    this.dialogMsg = "Pontuação não pode ser maior que 20";
                    return this.dialogAlert = true;
                }

                let data = new FormData();
                data.append('id_servidor', this.servidor.id_servidor);
                data.append('id_ciclo', this.id_ciclo);
                data.append('nr_pontos', this.nr_pontos);
                data.append('tx_justificativa_inclusao', this.justificativa);
                data.append('nr_cpf_servidor', this.servidor.nr_cpf.replace(/[-.]/g,''));

                getPontuacaoSalvaServidor(data).then(response => {
                    if (response.data) {
                        this.dialogMsg = "Já existe pontuação cadastrada para o servidor nesse cíclo";
                        return this.dialogAlert = true;
                    } else {
                        createPontuacaoServidor(data).then(response => {
                            this.clearInputs();
                            document.getElementById("btnFiltrar").click();

                            this.dialog = false;
                            this.dialogMsg = response.data.message;
                            return this.dialogSuccess = true;
                        }).catch(erro => {
                            this.dialog = false;
                            this.clearInputs();
                            this.dialogMsg = "Erro ao cadastrar pontuação.";
                            this.dialogAlert = true;
                        }); 
                    }
                });
            }
        },

        validarAvaliacaoServidor() {
            let dataValida = new FormData();
            dataValida.append('nr_cpf_servidor', this.servidor.nr_cpf.replace(/[-.]/g,''));
            dataValida.append('id_ciclo', this.id_ciclo);

            getValidarAvaliacoesServidorPontuacao(dataValida).then(response => {
                if (response.data) {
                    document.getElementById("alertServidorAvaliacao").style.display = "block";
                } else {
                    document.getElementById("alertServidorAvaliacao").style.display = "none";
                }
            });
        }
    }
}

</script>

<template>
    <div>
        <br/>
        <p class="h2">Página não encontrada</p>
        <div class="row">
            <p>Não foi possível localizar o caminho informado. Favor verificar se digitou corretamente e tentar novamente.</p>

        </div>
    </div>
</template>


<script>

    export default {
        name: 'Erro404',
        components: {
        },
        data() {
            return {
          };
        },
    }
</script>
import api from './index';

const getAgendamentos = () => api.get('getAgendamentos');

const createAgendamento = (data) => api.post('createAgendamento', data);

const editarAgendamento = (data) => api.post('editarAgendamento', data);

const visualizarAgendamento = (id) => api.get('visualizarAgendamento/'+ id);

const downloadAnexo = (id_arquivo) => api.get(
    'downloadAnexo/'+id_arquivo,
    { responseType: 'arraybuffer' }
);

const addAnexoEmailAgendado = (anexo) => api.post(
    'addAnexoEmailAgendado', 
    anexo
);

const getAnexosEmailAgendado = (idEmailAgendado) => api.get(
    'getAnexosEmailAgendado/'+ idEmailAgendado
);

const removerAnexoEmailAgendado = (id) => api.post(
    'removerAnexoEmailAgendado', 
    id
);

const getEmailAgendado = (id_email_agendado) => api.get(
    'getEmailAgendado/' + id_email_agendado
);

const excluirEmailAgendado = (id) => api.post(
    'excluirEmailAgendado', 
    id
);

export {
    getAgendamentos,
    createAgendamento,
    visualizarAgendamento,
    downloadAnexo,
    addAnexoEmailAgendado,
    getAnexosEmailAgendado,
    removerAnexoEmailAgendado,
    getEmailAgendado,
    editarAgendamento,
    excluirEmailAgendado
}
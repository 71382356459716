import api from './index';

const getEquipeTrabalhoByIdCiclo = (id_servidor, id_ciclo) => api.get('equipes_trabalho/id-ciclo/' + id_servidor + '/' + id_ciclo);

const getEquipeTrabalhoBySiapeCiclo = (nr_siape, id_ciclo) => api.get('equipes_trabalho/siape-ciclo/' + nr_siape + '/' + id_ciclo);

const getEquipeTrabalhoByCPFCiclo = (nr_cpf, id_ciclo) => api.get('equipes_trabalho/cpf-ciclo/' + nr_cpf + '/' + id_ciclo);

const deleteEquipeTrabalho = (id_equipe_trabalho) => api.delete('equipes_trabalho/' + id_equipe_trabalho);

const createEquipeTrabalho = (equipe_trabalho) => api.post('equipes_trabalho', equipe_trabalho);

const updateEquipeTrabalho = (id_equipe_trabalho, equipe_trabalho) => api.put('equipes_trabalho/' + id_equipe_trabalho, equipe_trabalho);

const gerarRelatorioEquipeTrabalhoFiltrados = (page, itemsPerPage, filtrosAplicados = []) => api.get('relatorioEquipeTrabalho', {params: {current_page: page, limit: itemsPerPage, filtros: filtrosAplicados}});

const getTotalRelatorioEquipeTrabalho =  (filtrosAplicados = []) => api.get('totalRelatorioEquipeTrabalho', {params: {filtros: filtrosAplicados}});

const gerarExtracaoRelatorioEquipeTrabalho = (filtrosAplicados = []) => api.get('gerarExtracaoRelatorioEquipeTrabalho', { params: {filtros: filtrosAplicados}, responseType: 'blob' } );

const copiarEquipeUnidade = (idUnidade) => api.get('copiarEquipeUnidade/' + idUnidade);

const copiarEquipePlano = (idPlano) => api.get('copiarEquipePlano/' + idPlano);

export {
	deleteEquipeTrabalho,
	createEquipeTrabalho,
	updateEquipeTrabalho,
    getEquipeTrabalhoByIdCiclo,
    getEquipeTrabalhoBySiapeCiclo,
    getEquipeTrabalhoByCPFCiclo,
    gerarRelatorioEquipeTrabalhoFiltrados,
    getTotalRelatorioEquipeTrabalho,
    gerarExtracaoRelatorioEquipeTrabalho,
    copiarEquipeUnidade,
    copiarEquipePlano
}
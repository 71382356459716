import api from './index';

const gerarRelatorioAvaliadoresAvaliadosFiltrados = (page, itemsPerPage, filtrosAplicados = []) => api.get('relatorioAvaliadorAvaliado', {params: {current_page: page, limit: itemsPerPage, filtros: filtrosAplicados}});

const getTotalRelatorioAvaliadoresAvaliados =  (filtrosAplicados = []) => api.get('totalRelatorioAvaliadorAvaliado', {params: {filtros: filtrosAplicados}});

const getAvaliadorAvaliado = (idEquipeTrabalhoExterno) => api.get('avaliadorAvaliado/' + idEquipeTrabalhoExterno);

const deleteAvaliados = (idEquipeTrabalhoExterno) => api.delete('EquipeTrabalhoExterno/' + idEquipeTrabalhoExterno);

const deleteAvaliador = (idAvaliador) => api.delete('ServidorExterno/' + idAvaliador);

const createJustificativa = (id, justificativa) => api.put('createJustificativa/'+id, justificativa);


export {
    gerarRelatorioAvaliadoresAvaliadosFiltrados,
    getTotalRelatorioAvaliadoresAvaliados,
    getAvaliadorAvaliado,
    deleteAvaliados,
    deleteAvaliador,
    createJustificativa
}
import api from './index';

const getGratificacoes = () => api.get(
    'gratificacoes'
);

const getGratificacao = (id_gratificacao) => api.get(
    'gratificacoes/' + id_gratificacao
);

const salvarGratificacao = (data) => api.post(
    'salvarGratificacao', 
    data
);

const editarGratificacao = (data) => api.post(
    'editarGratificacao', 
    data
);

const excluirGratificacao = (id) => api.post(
    'excluirGratificacao', 
    id
);

export {
    getGratificacoes,
    getGratificacao,
    salvarGratificacao,
    editarGratificacao,
    excluirGratificacao
}
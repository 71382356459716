import api from './index';

const getRDIs = () => api.get('rdis');
const getRDI = (id_rdi) => api.get('rdis/' + id_rdi);

const createRDI = (rdi) => api.post('rdis', rdi);

const updateRDI = (id_rdi, rdi) => api.put('rdis/'+id_rdi, rdi);

export {
    getRDIs,
    getRDI,
    createRDI,
    updateRDI
}
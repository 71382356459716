<template>
    <div>
        <h1>Relatório de plano de trabalho</h1>
        <v-row>
            <v-col cols="12">
                <v-card>
                   <v-form class="mt-5" ref="form_ciclo">
                        <v-row dense class="ma-0 pa-2">
                            <v-col cols="6" class="mx-0 px-3">
                                <v-select
                                    id="ciclo"
                                    :items="ciclos"
                                    v-model="filtros.id_ciclo"
                                    item-text="nm_ciclo"
                                    item-value="id_ciclo"
                                    label="Ciclo"
                                    outlined
                                    dense
                                    hide-details="auto"
                                    required 
                                    :rules="[() => !!filtros.id_ciclo || 'Campo obrigatório.']"
                                    v-on:change="
                                        carregarUnidade(`${filtros.id_ciclo}`,`${filtros.cd_orgao}`),
                                        carregarChefia(`${filtros.id_ciclo}`),
                                        carregarChefiaSubstituta(`${filtros.id_ciclo}`),
                                        carregarPontoFocal(`${filtros.id_ciclo}`),
                                        carregarPontoFocalSubstituto(`${filtros.id_ciclo}`)
                                    "
                                ></v-select>
                            </v-col>
                            <v-col cols="6" class="mx-0 px-3">
                                <v-select
                                    id="orgao"
                                    :items="orgaos"
                                    v-model="filtros.cd_orgao"
                                    item-value="cd_orgao"
                                    item-text="nm_orgao"
                                    label="Órgão"
                                    outlined
                                    dense
                                    hide-details="auto"
                                    v-on:change="
                                        carregarUnidade(`${filtros.id_ciclo}`,`${filtros.cd_orgao}`)
                                    "
                                ></v-select>
                            </v-col>
                        </v-row>
                    </v-form>
                    <v-row dense class="ma-0 pa-2">
                        <v-col cols="6" class="mx-0 px-3">
                            <v-select
                                id="unidade"
                                :items="unidades"
                                v-model="filtros.id_unidade_avaliacao"
                                item-value="id_unidade_avaliacao"
                                item-text="nm_unidade_avaliacao"
                                label="Unidade de Avaliação"
                                outlined
                                dense
                                hide-details="auto"
                                v-on:change="carregarPlanosTrabalho(`${filtros.id_unidade_avaliacao}`)"
                            ></v-select>
                        </v-col>
                        <v-col cols="6" class="mx-0 px-3">
                            <v-combobox
                                id="planoTrabalho"
                                ref="planoTrabalho"
                                :items="planosTrabalho"
                                v-model="filtros.id_plano_trabalho"
                                hide-details
                                outlined 
                                dense
                                label="Plano de trabalho"
                                ></v-combobox>
                        </v-col>
                    </v-row>
                    <v-row dense class="ma-0 pa-2">
                        <v-col cols="6" class="mx-0 px-3">
                            <v-select
                                id="escopo"
                                :items="comboSimNaoTodos"
                                v-model="filtros.in_escopo"
                                label="Escopo"
                                outlined
                                dense
                                hide-details="auto"
                            ></v-select>
                        </v-col>
                        <v-col cols="6" class="mx-0 px-3">
                            <v-select
                                id="metaIntermediaria"
                                :items="comboSimNaoTodos"
                                v-model="filtros.in_meta_intermediaria"
                                label="Meta intermediária"
                                outlined
                                dense
                                hide-details
                            ></v-select>
                        </v-col>
                    </v-row>
                    <v-row dense class="ma-0 pa-2">
                        <v-col cols="6" class="mx-0 px-3">
                            <v-combobox
                                id="nomeChefia"
                                ref="chefia"
                                :items="chefias"
                                v-model="filtros.id_chefia"
                                hide-details
                                outlined 
                                dense
                                label="Nome da chefia"
                                ></v-combobox>
                        </v-col>
                        <v-col cols="6" class="mx-0 px-3">
                            <v-combobox
                                id="nomeChefiaSubstituta"
                                ref="chefiaSubstituta"
                                :items="chefiasSubstitutas"
                                v-model="filtros.id_substituto"
                                hide-details
                                outlined 
                                dense
                                label="Nome da chefia substituta"
                                ></v-combobox>
                        </v-col>
                    </v-row>
                    <v-row dense class="ma-0 pa-2">
                        <v-col cols="6" class="mx-0 px-3">
                            <v-combobox
                                id="nomePonto"
                                ref="pontoFocal"
                                :items="pontosFocais"
                                v-model="filtros.id_ponto_focal"
                                hide-details
                                outlined 
                                dense
                                label="Nome do ponto focal"
                                ></v-combobox>
                        </v-col>
                        <v-col cols="6" class="mx-0 px-3">
                            <v-combobox
                                id="nomePontoSubstituto"
                                ref="pontoFocalSubstituto"
                                :items="pontosFocaisSubstituto"
                                v-model="filtros.id_ponto_focal_substituto"
                                hide-details
                                outlined 
                                dense
                                label="Nome do ponto focal substituto"
                                ></v-combobox>
                        </v-col>
                    </v-row>
                    <v-row dense class="ma-0 pa-2">
                        <v-col cols="3" class="mx-0 px-3">
                            <v-select
                                id="comChefia"
                                :items="comboSimNaoTodos"
                                v-model="filtros.in_chefia"
                                label="Chefia"
                                outlined
                                dense
                                hide-details="auto"
                            ></v-select>
                        </v-col>
                        <v-col cols="3" class="mx-0 px-3">
                            <v-select
                                id="comChefiaSubstituto"
                                :items="comboSimNaoTodos"
                                v-model="filtros.in_chefia_substituta"
                                label="Chefia substituta"
                                outlined
                                dense
                                hide-details
                            ></v-select>
                        </v-col>
                        <v-col cols="2" class="mx-0 px-3">
                            <v-select
                                id="comPonto"
                                :items="comboSimNaoTodos"
                                v-model="filtros.in_ponto_focal"
                                label="Ponto focal"
                                outlined
                                dense
                                hide-details="auto"
                            ></v-select>
                        </v-col>
                        <v-col cols="2" class="mx-0 px-3">
                            <v-select
                                id="comPontoSubstituto"
                                :items="comboSimNaoTodos"
                                v-model="filtros.in_ponto_focal_substituto"
                                label="Ponto focal substituto"
                                outlined
                                dense
                                hide-details="auto"
                            ></v-select>
                        </v-col>
                        <v-col cols="2" class="mx-0 px-3">
                            <v-select
                                id="uf"
                                :items="ufs"
                                v-model="filtros.id_uf"
                                item-value="id_uf"
                                item-text="sg_uf"
                                label="UF"
                                outlined
                                dense
                                hide-details="auto"
                            ></v-select>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="10" class="text-right"></v-col>
                        <v-col cols="2" class="ma-0 mb-4 pa-2 px-8 d-flex flex-row justify-end align-end text-right elevation-0">
                            <v-btn color="primary" @click="gerarRelatorioPlanoTrabalho()">
                                <v-icon>mdi-magnify</v-icon> Filtrar
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
        <br />
        <v-row v-show="tableResult">
            <v-col cols="12">
                <v-data-table
                    :headers="headers"
                    :items="relatorioPlanosTrabalho"
                    :items-per-page="pagination.itemsPerPage"
                    :footer-props="{'items-per-page-options':[30, 50, 100]}"
                    :options.sync="pagination" 
                    @update:options="gerarRelatorioPlanoTrabalho"
                    :server-items-length="pagination.total"
                    class="elevation-1"
                >

                    <template v-slot:[`item.acao`]="{ item }">
                        <v-row justify="center">
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs}">
                                    <v-btn id="btnVisualizar" icon small :color="'primary'" v-bind="attrs" v-on="on" :to="{ name: 'detalhePlanoTrabalho', params: { id_plano_trabalho: item.id_plano_trabalho } }">
                                        <v-icon> mdi-eye </v-icon>
                                    </v-btn>
                                </template>
                                <span>Visualizar plano de trabalho</span>
                            </v-tooltip>
                        </v-row>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
        <v-row v-if="pagination.total">
            <v-col cols="10" class="text-right"></v-col>
            <v-col cols="2" class="ma-0 mb-6 pa-2 px-4 d-flex flex-row justify-end align-end text-right elevation-0">
                <v-btn color="success" @click="exportarRelatorioPlanoTrabalho()">
                    <v-icon>mdi-download</v-icon> Exportar para excel
                </v-btn>
            </v-col>
        </v-row>
        <v-dialog v-model="dialogAlert" width="570">
            <v-card>
                <v-card-title class="text-h5">
                    <v-alert prominent type="warning" outlined width="100%">
                        <v-row align="center">
                            <v-col class="grow">
                                {{dialogMsg}}
                            </v-col>
                            <v-col class="shrink">
                            <v-btn color="warning"  @click="dialogAlert = false">Ok</v-btn>
                            </v-col>
                        </v-row>
                    </v-alert>
                </v-card-title> 
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    // Lista de ciclos
    import { getAllCiclos } from '../api/ciclo';

    // Lista UF
    import { getUFs } from '../api/uf';

    // Lista unidade avaliacao
    import { getUnidadeAvaliacaoByCicloOrgao } from '../api/unidade_avaliacao';

    // Lista plano de trabalho
    import { getPlanoTrabalhoByUnidadeAvaliacao } from '../api/plano_trabalho';

    import { 
        getPlanoTrabalhoFiltrados, 
        getTotalRelatorioPlanoTrabalho,
        getChefiaByCicloAndPlano,
        getChefiaSubstitutaByCicloAndPlano,
        getPontoFocalByCicloAndPlano,
        getPontoFocalSubstitutoByCicloAndPlano,
        gerarExtracaoRelatorioPlanoTrabalho
    } from '../api/plano_trabalho';

    // Lista orgao
    import { getOrgaoGestorOrgao, getOrgaos } from '../api/orgao';

    export default {
        name: 'RelatorioPlanoTrabalhoListar',
        data() {
            return {
                ciclos: [],
                unidades: [],
                relatorioPlanosTrabalho: [],
                planosTrabalho: [],
                chefias: [],
                chefiasSubstitutas: [],
                pontosFocais: [],
                pontosFocaisSubstituto: [],
                ufs: [],
                orgaos: [],

                comboSimNaoTodos: [
                    { text: 'Todos', value: -1},
                    { text: 'Sim', value: 1},
                    { text: 'Não', value: 2}
                ],

                tableResult: false,
                headers: [
                    { text: 'Nome do plano de trabalho', value: 'plano_trabalho', sortable: false },
                    { text: 'Unidade de Avaliação', value: 'nm_unidade_avaliacao', sortable: false },
                    { text: 'Nome da chefia', value: 'nm_servidor_chefia', sortable: false },
                    { text: 'Nome da chefia substituta', value: 'nm_servidor_substituto', sortable: false },
                    { text: 'Órgão', value: 'sg_sigla', sortable: false },
                    { text: 'UF da unidade', value: 'sg_uf', sortable: false },
                    { text: 'Meta intermediária', value: 'meta_intermediaria', sortable: false },
                    { text: 'Ações', value: 'acao', align: 'center', sortable: false},
                ],
                pagination: {
                    current: 1,
                    total: 0,
                    page: 1,
                    itemsPerPage: 30
                },
                filtros: {

                },
                dialogMsg:'',
                dialogAlert: false,
                isGestorOrgao: false
            };
        },
        mounted() {
            getAllCiclos()
                .then(response => {
                    this.ciclos = response.data.data;
                });

            getUFs()
                .then(response => { 
                    this.ufs = response.data.data; 
                    this.ufs.unshift({id_uf: -1, sg_uf: 'Todas'});
            });

            if (
                (this.$store.getters['auth/usuarioAutenticadoRoles'].indexOf("gestorOrgao") >= 0
                || this.$store.getters['auth/usuarioAutenticadoRoles'].indexOf("gestorSetorial") >= 0
                || this.$store.getters['auth/usuarioAutenticadoRoles'].indexOf("pontoFocal") >= 0)
                && this.$store.getters['auth/usuarioAutenticadoRoles'].indexOf("administrador") < 0
                
            )  {
                this.isGestorOrgao = true;
                getOrgaoGestorOrgao().then(response => { 
                    this.orgaos = response.data.data;
                    if ( response.data.data.length === 1 ) {
                        this.filtros.cd_orgao = this.orgaos.find(org => org.cd_orgao == response.data.data[0].cd_orgao);
                    } 
                });
            } else {
                getOrgaos().then(response => {
                    this.orgaos = response.data.data; 
                    this.orgaos.map(org =>{
                        org.text = org.sg_sigla + ' - ' + org.nm_orgao
                        org.value = org.cd_orgao
                    })
                    this.orgaos.unshift({cd_orgao: -1, nm_orgao: 'Todos'});

                });
            }
        },
        methods: { 
            carregarUnidade(id_ciclo,cd_orgao) {
                if ( id_ciclo !== undefined && cd_orgao !== 'undefined' ) {
                    getUnidadeAvaliacaoByCicloOrgao(id_ciclo,cd_orgao)
                        .then(response => { 
                            this.unidades = response.data.data; 
                            this.unidades = _.orderBy(this.unidades, 'nm_unidade_avaliacao');
                            this.unidades.unshift({id_unidade_avaliacao: -1, nm_unidade_avaliacao: 'Todas'});
                    });
                }
            },

            carregarPlanosTrabalho(id_unidade_avaliacao) {
                getPlanoTrabalhoByUnidadeAvaliacao(id_unidade_avaliacao)
                    .then(response => { 
                        this.$refs.planoTrabalho.lazyValue = undefined; 
                        this.planosTrabalho = response.data.data; 
                        this.planosTrabalho.map(plano =>{
                            plano.text = plano.nm_unidade
                            plano.value = plano.id_plano_trabalho
                        })
                        this.planosTrabalho.unshift({text: 'Todos', value: -1, id_funcao: -1})                   
                });
            },

            carregarChefia(id_ciclo) {
                getChefiaByCicloAndPlano(id_ciclo)
                    .then(response => { 
                        this.$refs.chefia.lazyValue = undefined; 
                        this.chefias = response.data.data; 
                        this.chefias.map(item =>{
                            item.text = item.chefia.nm_servidor
                            item.value = item.chefia.id_servidor
                        })
                        this.chefias = _.orderBy(this.chefias, 'text');
                        this.chefias.unshift({text: 'Todas', value: -1, id_funcao: -1})                   
                });
            },

            carregarChefiaSubstituta(id_ciclo) {
                getChefiaSubstitutaByCicloAndPlano(id_ciclo)
                    .then(response => { 
                        this.$refs.chefiaSubstituta.lazyValue = undefined; 
                        this.chefiasSubstitutas = response.data.data; 
                        this.chefiasSubstitutas.map(item =>{
                            item.text = item.substituto.nm_servidor
                            item.value = item.substituto.id_servidor
                        })
                        this.chefiasSubstitutas = _.orderBy(this.chefiasSubstitutas, 'text');
                        this.chefiasSubstitutas.unshift({text: 'Todas', value: -1, id_funcao: -1})                   
                });
            },

            carregarPontoFocal(id_ciclo) {
                getPontoFocalByCicloAndPlano(id_ciclo)
                    .then(response => { 
                        this.$refs.pontoFocal.lazyValue = undefined; 
                        this.pontosFocais = response.data.data; 
                        this.pontosFocais.map(item =>{
                            item.text = item.ponto_focal.nm_servidor
                            item.value = item.ponto_focal.id_servidor
                        })
                        this.pontosFocais = _.orderBy(this.pontosFocais, 'text');
                        this.pontosFocais.unshift({text: 'Todas', value: -1, id_funcao: -1})                   
                });
            },

            carregarPontoFocalSubstituto(id_ciclo) {
                getPontoFocalSubstitutoByCicloAndPlano(id_ciclo)
                    .then(response => { 
                        this.$refs.pontoFocalSubstituto.lazyValue = undefined; 
                        this.pontosFocaisSubstituto = response.data.data; 
                        this.pontosFocaisSubstituto.map(item =>{
                            item.text = item.ponto_focal_substituto.nm_servidor
                            item.value = item.ponto_focal_substituto.id_servidor
                        })
                        this.pontosFocaisSubstituto = _.orderBy(this.pontosFocaisSubstituto, 'text');
                        this.pontosFocaisSubstituto.unshift({text: 'Todas', value: -1, id_funcao: -1})                   
                });
            },

            getFiltros() {
                var filtros = [];
                var obj = {};

                // Filtro ciclo
                if ( this.filtros.id_ciclo ) {
                    obj['id_ciclo'] = this.filtros.id_ciclo;
                }

                // Filtro unidade de avaliacao
                if ( this.filtros.id_unidade_avaliacao ) {
                    obj['id_unidade_avaliacao'] = this.filtros.id_unidade_avaliacao;
                }

                // Filtro plano de trabalho
                if ( typeof this.filtros.id_plano_trabalho === 'object' ) {
                    obj['id_plano_trabalho'] = this.filtros.id_plano_trabalho;
                } else {
                    this.$refs.planoTrabalho.lazyValue = undefined;
                }

                // Filtro escopo
                if ( this.filtros.in_escopo ) {
                    obj['in_escopo'] = this.filtros.in_escopo;
                }

                // Filtro meta intermediaria
                if ( this.filtros.in_meta_intermediaria ) {
                    obj['in_meta_intermediaria'] = this.filtros.in_meta_intermediaria;
                }

                // Filtro UF
                if ( this.filtros.id_uf ) {
                    obj['id_uf'] = this.filtros.id_uf;
                }

                // Filtro chefia
                if ( typeof this.filtros.id_chefia === 'object' ) {
                    obj['id_chefia'] = this.filtros.id_chefia;
                } else {
                    this.$refs.chefia.lazyValue = undefined;
                }

                // Filtro chefia substituta
                if ( typeof this.filtros.id_substituto === 'object' ) {
                    obj['id_substituto'] = this.filtros.id_substituto;
                } else {
                    this.$refs.chefiaSubstituta.lazyValue = undefined;
                }

                // Filtro ponto focal
                if ( typeof this.filtros.id_ponto_focal === 'object' ) {
                    obj['id_ponto_focal'] = this.filtros.id_ponto_focal;
                } else {
                    this.$refs.pontoFocal.lazyValue = undefined;
                }

                // Filtro ponto focal substituto
                if ( typeof this.filtros.id_ponto_focal_substituto === 'object' ) {
                    obj['id_ponto_focal_substituto'] = this.filtros.id_ponto_focal_substituto;
                } else {
                    this.$refs.pontoFocalSubstituto.lazyValue = undefined;
                }

                // Filtro chefia
                if ( this.filtros.in_chefia ) {
                    obj['in_chefia'] = this.filtros.in_chefia;
                }

                // Filtro chefia substituta
                if ( this.filtros.in_chefia_substituta ) {
                    obj['in_chefia_substituta'] = this.filtros.in_chefia_substituta;
                }

                // Filtro ponto focal
                if ( this.filtros.in_ponto_focal ) {
                    obj['in_ponto_focal'] = this.filtros.in_ponto_focal;
                }

                // Filtro ponto focal substituto
                if ( this.filtros.in_ponto_focal_substituto ) {
                    obj['in_ponto_focal_substituto'] = this.filtros.in_ponto_focal_substituto;
                }

                // Filtro orgao
                if (this.filtros.cd_orgao && this.filtros.cd_orgao !== -1) {
                    if ( typeof this.filtros.cd_orgao === 'object' ) {
                        obj['cd_orgao'] = this.filtros.cd_orgao.cd_orgao;
                    } else {
                        obj['cd_orgao'] = this.filtros.cd_orgao;
                    }
                } 

                filtros.push(obj);

                return filtros;
            },

            gerarRelatorioPlanoTrabalho() {
                if ( this.$refs['form_ciclo'].validate() ) {
                    if (this.isGestorOrgao && this.filtros.cd_orgao === undefined) {
                        this.dialogMsg = "Campo órgão obrigatório";
                        return this.dialogAlert = true;
                    }
                    this.tableResult = true;

                    var filtros = [];
                    filtros = this.getFiltros();

                    getPlanoTrabalhoFiltrados(this.pagination.page, this.pagination.itemsPerPage, filtros)
                        .then(response => { 
                            this.relatorioPlanosTrabalho = response.data.data;
                        });

                    getTotalRelatorioPlanoTrabalho(filtros)
                        .then(response => {
                            this.pagination.total = response.data;
                        }); 
                }
            },

            exportarRelatorioPlanoTrabalho() {
                var filtros = [];
                filtros = this.getFiltros();

                gerarExtracaoRelatorioPlanoTrabalho(filtros)
                    .then(response => {
                        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                        var fileLink = document.createElement('a');
                        fileLink.href = fileURL;
                        fileLink.setAttribute('download', 'Relatório de plano de trabalho.xlsx');
                        document.body.appendChild(fileLink);
                        fileLink.click();
                    });
            },
        }
    }
</script>

<template>
    <div>
        <servidorDialog ref="servidorDialog" />
        <v-row>
            <v-col cols="12">
                <v-card>
                    <v-row dense class="ma-2 pa-0">
                        <v-col cols="12">
                            <v-row dense class="ma-2 pa-0">
                                <v-col md="2" cols="12" class="mx-0 px-3">
                                    <v-text-field 
                                        ref="siape" 
                                        dense 
                                        hide-details="auto" 
                                        label="SIAPE" 
                                        v-model="filtros.siape" 
                                        v-mask="'#######'" 
                                        outlined 
                                        clearable 
                                        clear-icon="mdi-close-circle-outline" 
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="3"  class="mx-0 px-3">
                                    <v-text-field 
                                        ref="cpf" 
                                        dense 
                                        hide-details="auto" 
                                        label="CPF" 
                                        v-model="filtros.cpf" 
                                        v-mask="'###.###.###-##'" 
                                        outlined 
                                        clearable 
                                        clear-icon="mdi-close-circle-outline" 
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="4" class="mx-0 px-3">
                                    <v-text-field 
                                        ref="nome" 
                                        dense 
                                        hide-details="auto" 
                                        label="Nome" 
                                        v-model="filtros.nome" 
                                        outlined 
                                        clearable 
                                        clear-icon="mdi-close-circle-outline" 
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="3" class="mx-0 px-3">
                                    <v-combobox
                                        id="orgao"
                                        :items="orgaos"
                                        v-model="filtros.cd_orgao"
                                        item-value="cd_orgao"
                                        item-text="nm_orgao"
                                        label="Órgão"
                                        outlined
                                        dense
                                        hide-details="auto"
                                    ></v-combobox>
                                </v-col>
                            </v-row>
                            <v-row dense class="ma-2 pa-0">
                                <v-col cols="12" md="2" class="mx-0 px-3">
                                    <v-select
                                        id="sg_uf"
                                        :items="ufs"
                                        v-model="filtros.id_uf"
                                        item-value="id_uf"
                                        item-text="sg_uf"
                                        label="UF"
                                        outlined
                                        dense
                                        hide-details="auto"
                                    ></v-select>
                                </v-col>
                                <v-col cols="12" md="3" class="mx-0 px-3">
                                    <v-select
                                        id="gratificacao"
                                        v-model="filtros.id_gratificacao"
                                        :items="gratificacoes"
                                        item-value="id_gratificacao"
                                        item-text="sg_gratificacao"
                                        label="Gratificação"
                                        outlined
                                        dense
                                        hide-details="auto"
                                    ></v-select>
                                </v-col>
                                <v-col cols="12" md="4" class="mx-0 px-3">
                                    <v-combobox
                                        id="funcao"
                                        v-model="filtros.funcao"
                                        :items="funcoes"
                                        hide-details="auto" 
                                        dense
                                        label="Função"
                                        outlined
                                    ></v-combobox>
                                </v-col>
                                <v-col cols="12" md="3" class="mx-0 px-3">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                             <v-select      
                                                hide-details="auto"     
                                                :items="['Todos', 'Ativos', 'Inabilitados']"   
                                                v-model="filtros.tipo_pesquisa"        
                                                label="Tipo de pesquisa"           
                                                dense      
                                                outlined  
                                                v-bind="attrs" 
                                                v-on="on"   
                                            ></v-select>
                                        </template>
                                        <span>Inabilitados = vacância, exoneração ou aposentadoria.</span>
                                    </v-tooltip>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col md="10" class="text-right"></v-col>
                                <v-col cols="12" md="2" class="ma-0 mb-4 pa-2 px-8 d-flex flex-row justify-end align-end text-right elevation-0">
                                    <v-btn color="primary" @click="getServidoresFiltrados()">
                                        <v-icon>mdi-magnify</v-icon> Filtrar
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-footer padless class="primary" dark>
                    <v-col class="text-center" cols="12">
                        <strong>LISTA DE SERVIDORES</strong>
                    </v-col>
                </v-footer>
                <v-data-table
                    :headers="headers"
                    :items="servidores"
                    :items-per-page="pagination.itemsPerPage"
                    :footer-props="{'items-per-page-options':[30]}"
                    :options.sync="pagination" 
                    :server-items-length="pagination.total"
                    class="elevation-1"
                    :hide-default-footer = true 
                >
                    <template v-slot:footer >
                        <v-divider></v-divider><br>
                        <v-pagination
                            v-model="pagination.current"
                            :length="last_page"
                            :total-visible="5"
                            circle
                            small
                            @input="getServidoresFiltrados"
                        ></v-pagination>
                        <h6 class="text-center grey--text">Total: {{pagination.total}}</h6>
                    </template>
                    <template v-slot:item.acao="{ item }">
                        <v-row justify="center">
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs}">
                                    <v-btn 
                                        icon 
                                        :color="item.deleted_at ? 'error' : 'primary'" 
                                        v-bind="attrs" 
                                        v-on="on" 
                                        small 
                                        @click="() => modalServidor(item.id,'Detalhes de Servidor',item.deleted_at)"
                                    >
                                        <v-icon> mdi-eye </v-icon>
                                    </v-btn>
                                </template>
                                <span>Detalhes de Servidor</span>
                            </v-tooltip>
                        </v-row>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
        <v-row v-if="pagination.total">
            <v-col cols="10" class="text-right"></v-col>
            <v-col cols="2" class="ma-0 mb-6 pa-2 px-4 d-flex flex-row justify-end align-end text-right elevation-0">
                <v-btn color="success" @click="exportarRelatorio()">
                    <v-icon>mdi-download</v-icon> Exportar para excel
                </v-btn>
            </v-col>
        </v-row>
        <v-dialog v-model="dialogAlert" width="570">
            <v-card>
                <v-card-title class="text-h5">
                    <v-alert prominent type="warning" outlined width="100%">
                        <v-row align="center">
                            <v-col class="grow">
                                {{dialogMsg}}
                            </v-col>
                            <v-col class="shrink">
                            <v-btn color="warning"  @click="dialogAlert = false">Ok</v-btn>
                            </v-col>
                        </v-row>
                    </v-alert>
                </v-card-title> 
            </v-card>
        </v-dialog>
    </div>
</template>


<script>
    import { getDetalheServidor } from '../api/servidor';
    import { getServidores } from '../api/servidor';
    import { getTotalServidores } from '../api/servidor';
    import { gerarExtracaoRelatorioServidores } from '../api/servidor';
    import { getServidorWithOrgao } from '../api/servidor';
    import { getGratificacoes } from '../api/gratificacao';
    import { getUFs } from '../api/uf';
    import { getOrgaos } from '../api/orgao';
    import { getFuncoes } from '../api/funcao';
    import ServidorDialog from '../components/ServidorDialog.vue';
    import { getOrgaoGestorOrgao } from '../api/orgao';


    export default {
        name: 'ServidoresListar',
        components: {
            ServidorDialog        
        },
        data() {
            return {
                servidores: [],
                gratificacoes: [],
                ufs: [],
                funcoes: [],
                orgaos: [],
                headers: [
                    { text: 'SIAPE', sortable: false, value: 'siape'},
                    { text: 'CPF', value: 'cpf', sortable: false, width: '130px'},
                    { text: 'Nome', value: 'nome', sortable: false},
                    { text: 'Unidade de Exercício', value: 'unidade_exercicio', sortable: false},
                    { text: 'UF', value: 'uf', sortable: false},
                    { text: 'Gratificação', value: 'gratificacao', sortable: false},
                    { text: 'Órgão', value: 'orgao', sortable: false},
                    { text: 'Ações', value: 'acao', align: 'center', sortable: false},
                ],
                pagination: {
                    current: 1,
                    total: 0,
                    page: 1,
                    itemsPerPage: 30
                },
                filtros: {
                    siape: '',
                    cpf: '',
                    nome: '',
                    tipo_pesquisa: 'Todos',
                    id_gratificacao: -1,
                    id_uf: -1,
                    funcao: {text: 'Qualquer', value: -1, id_funcao: -1},
                },
                dialogAlert: false,
                dialogMsg:'',
                isGestorOrgao: false,
                last_page: 0,
            };
        },
        mounted() {

            if (
                (this.$store.getters['auth/usuarioAutenticadoRoles'].indexOf("gestorOrgao") >= 0
                || this.$store.getters['auth/usuarioAutenticadoRoles'].indexOf("gestorSetorial") >= 0
                || this.$store.getters['auth/usuarioAutenticadoRoles'].indexOf("pontoFocal") >= 0)
                && this.$store.getters['auth/usuarioAutenticadoRoles'].indexOf("administrador") < 0
                
            ) {
                this.isGestorOrgao = true;
                getOrgaoGestorOrgao().then(response => { 
                    this.orgaos = response.data.data;
                    if ( response.data.data.length === 1 ) {
                        this.filtros.cd_orgao = this.orgaos.find(org => org.cd_orgao == response.data.data[0].cd_orgao);
                    } else {
                        this.orgaos.unshift({cd_orgao: -1, nm_orgao: 'Todos'});
                    } 
                });
            } else {
                getOrgaos().then(response => {
                    this.orgaos = response.data.data; 
                    this.orgaos.map(org =>{
                        org.text = org.sg_sigla + ' - ' + org.nm_orgao
                        org.value = org.cd_orgao
                    })
                    this.orgaos.unshift({cd_orgao: -1, nm_orgao: 'Todos'});
                });
            }

            getGratificacoes().then(response => {
                this.gratificacoes = response.data.data;
                this.gratificacoes.unshift({id_gratificacao: -1, sg_gratificacao: 'Qualquer'});

                    getUFs().then(response => { 
                        this.ufs = response.data.data; 
                        this.ufs.unshift({id_uf: -1, sg_uf: 'Todas'});

                    getFuncoes().then(response => { 
                        this.funcoes = response.data.data; 
                        this.funcoes.sort((a,b) => {   return a.nm_funcao.localeCompare(b.nm_funcao);});
                        this.funcoes.map(func =>{
                            func.text = func.cd_funcao + ' - ' + func.nm_funcao;
                            func.value = func.id_funcao;
                            func.nm_funcao_com_sigla = func.cd_funcao + ' - ' + func.nm_funcao;
                        });
                        this.funcoes.unshift({text: 'Qualquer', value: -1, id_funcao: -1});
                    });
                });
            });
        },
        methods: { 
            modalServidor(id_servidor, tipoServidor, deleted_at) {
                var servidor;
                getDetalheServidor(id_servidor)
                    .then(response => {
                        servidor = response.data.data;
                        this.$refs.servidorDialog.mostraServidorDialog(servidor, tipoServidor, deleted_at);
                    });
            },
            getServidoresFiltrados() {
                var filtros = [];
                var obj = {};

                if(this.filtros.siape) {
                    obj['nr_siape'] = this.filtros.siape;
                }
                if(this.filtros.cpf) {
                    obj['nr_cpf'] = this.filtros.cpf.replace(/[-.]/g,'');
                }
                if(this.filtros.nome) {
                    obj['nm_servidor'] = this.filtros.nome.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase();
                }
                if(this.filtros.id_gratificacao !== -1) {
                    obj['id_gratificacao'] = this.filtros.id_gratificacao;
                }
                if(this.filtros.id_uf !== -1) {
                    obj['id_uf'] = this.filtros.id_uf;
                }
                if(this.filtros.funcao.id_funcao !== -1) {
                    obj['id_funcao'] = this.filtros.funcao.id_funcao;
                }
                if ( 
                    typeof this.filtros.cd_orgao === 'object' 
                    && this.filtros.cd_orgao.cd_orgao !== -1
                ) {
                    obj['cd_orgao'] = this.filtros.cd_orgao.cd_orgao;
                } 
                
                filtros.push(obj);
                
                getServidores(this.pagination.current, this.pagination.itemsPerPage, filtros, this.filtros.tipo_pesquisa)
                    .then(response => { 
                        this.servidores = response.data.data;
                        this.pagination.current = response.data.data[0].currentPage;
                        this.last_page = response.data.data[0].lastPage;
                        this.total = response.data.data[0].total;
                    });

                getTotalServidores(filtros, this.filtros.tipo_pesquisa)
                    .then(response => {
                        this.pagination.total = response.data;
                    });
            },

            getFiltros() {
                var filtros = [];
                var obj = {};

                if(this.filtros.siape) {
                    obj['nr_siape'] = this.filtros.siape;
                }
                if(this.filtros.cpf) {
                    obj['nr_cpf'] = this.filtros.cpf.replace(/[-.]/g,'');
                }
                if(this.filtros.nome) {
                    obj['nm_servidor'] = this.filtros.nome.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase();
                }
                if(this.filtros.id_gratificacao !== -1) {
                    obj['id_gratificacao'] = this.filtros.id_gratificacao;
                }
                if(this.filtros.id_uf !== -1) {
                    obj['id_uf'] = this.filtros.id_uf;
                }
                if(this.filtros.funcao.id_funcao !== -1) {
                    obj['id_funcao'] = this.filtros.funcao.id_funcao;
                }
                 if ( 
                    typeof this.filtros.cd_orgao === 'object' 
                    && this.filtros.cd_orgao.cd_orgao !== -1
                ) {
                    obj['cd_orgao'] = this.filtros.cd_orgao.cd_orgao;
                } 
                
                filtros.push(obj);
                return filtros;
            },

            exportarRelatorio() {
                var filtros = [];
                filtros = this.getFiltros();

                gerarExtracaoRelatorioServidores(this.pagination.page, this.pagination.itemsPerPage, filtros, this.filtros.tipo_pesquisa)
                    .then(response => {
                        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                        var fileLink = document.createElement('a');
                        fileLink.href = fileURL;
                        fileLink.setAttribute('download', 'RelatorioDeServidores.xlsx');
                        document.body.appendChild(fileLink);
                        fileLink.click();
                    });
            },
        }
    }
</script>
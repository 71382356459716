<template>
<div>
    <v-sheet class="d-flex flex-row justify-center elevation-0">
        <v-sheet class="align-self-center" min-width="800" max-width="1200">
            <h1>Relatórios de Desempenho Individual</h1>
            <v-card class="mb-5 elevation-0">
                <router-link :to="{ name: 'rdi-adicionar'}" style="text-decoration: none">
                    <v-btn class="primary">
                        <v-icon>mdi-plus</v-icon> Adicionar RDI
                    </v-btn>
                </router-link>
            </v-card>
            <v-card>
                <v-simple-table>
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-left">id</th>
                                <th class="text-left">Título</th>
                                <th class="text-left">Descrição</th>
                                <th class="text-left">Chefia/Avaliado</th>
                                <th class="text-left">Ação</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="rdi in rdis" :key="rdi.id_rdi">
                                <td>{{ rdi.id_rdi }}</td>
                                <td>{{ rdi.nm_rdi }}</td>
                                <td>{{ rdi.ds_rdi }}</td>
                                <td>{{ rdi.st_chefia ? 'Chefia' : 'Avaliado'}}</td>
                                <td>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs}">
                                            <v-btn icon color="primary" small v-bind="attrs" v-on="on">
                                                <router-link :to="{ name: 'rdi-detalhar', params: { id: rdi.id_rdi } }" style="text-decoration: none">
                                                    <v-icon color="primary" style="text-decoration: none">mdi-eye</v-icon>
                                                </router-link>
                                            </v-btn>
                                        </template>
                                        <span>Ver detalhes do rdi</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs}">
                                            <v-btn icon color="primary" small v-bind="attrs" v-on="on">
                                                <router-link :to="{ name: 'rdi-editar', params: { id: rdi.id_rdi } }" style="text-decoration: none">
                                                    <v-icon color="primary" style="text-decoration: none">mdi-pencil</v-icon>
                                                </router-link>
                                            </v-btn>
                                        </template>
                                        <span>Editar RDI</span>
                                    </v-tooltip>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-card>
        </v-sheet>
    </v-sheet>
</div>
</template>

<script>
import {
    getRDIs
} from '../api/rdi';

export default {
    name: 'rdi-listar',
    data() {
        return {
            rdis: [],
        };
    },
    created() {
        getRDIs()
            .then(response => {
                this.rdis = response.data.data;
            });
    },
} 
</script>

import api from './index';

const getAdministradores = () => api.get('administradores');

const getAdministrador = (id_administrador) => api.get('administradores/' + id_administrador);

const deleteAdministrador = (id_administrador) => api.delete('administradores/' + id_administrador);

const createAdministrador = (administrador) => api.post('administradores', administrador);

export {
    getAdministradores,
    getAdministrador,
    createAdministrador,
    deleteAdministrador
}
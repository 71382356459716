<template>
	<v-sheet v-show="showSideBar">
        <v-navigation-drawer  id="navigation" permanent width="100%">
            <v-list nav dense>
                <v-list-item-group v-for="(route,menu) in routes" :key="menu" active-class="white--text text--accent-4">
                    <v-list-group :value="false" v-if="route.submenu" v-bind:id="route.titulo">
                        <template v-slot:activator>
                            <v-list-item-title class="font-weight-light" color="#1351b4">{{ route.titulo }}</v-list-item-title>
                        </template>

                        <v-list-item v-for="(route,submenu) in route.submenu" :key="submenu" :to="route.pathsub" v-bind:id="route.titulosub">
                            <v-list-item-title class="font-weight-light submenu" color="#1351b4">{{ route.titulosub }}</v-list-item-title>
                        </v-list-item>
                    </v-list-group>
                    <v-list-item :to="route.path" v-else v-bind:id="route.titulo">
                        <v-list-item-title class="font-weight-light" color="#1351b4">{{ route.titulo }}</v-list-item-title>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </v-navigation-drawer>
    </v-sheet>
</template>

<script>
    export default {
        name: 'Menu',
        computed: {
        	routes() {
        		var routes = [];
                let roles = this.$store.getters['auth/usuarioAutenticadoRoles'] ? this.$store.getters['auth/usuarioAutenticadoRoles'] : null;

                if(roles) {
            		this.$router.options.routes.forEach(function(item, index, object) {
            			if(item.meta.menu.show && roles.some(r=> item.meta.roles.indexOf(r) >= 0)) {
            				var route = {
            					'name': item.name,
            					'titulo' : item.meta.menu.titulo,
                                'path': item.path
            				};

                            if ( item.children ) {
                                var routeSub = {};
                                Object.entries(item.children).forEach(function(chi, index) {
                                    if( chi[1].submenu && roles.some(r=> chi[1].meta.roles.indexOf(r) >= 0) ) {
                                        routeSub[index] = {
                                            'titulosub' :chi[1].meta.breadcrumb.titulo,
                                            'pathsub' : chi[1].path
                                        }

                                        route.submenu = routeSub;
                                    }
                                })
                            }

                            routes.push(route);
            			}
    				});
                }

				return routes;
		    },
            showSideBar() {
                const menu = document.querySelector('#navigation');
                const btnMenuIcon = document.querySelector('#btnMenu > span > i');
                const container = document.querySelector('#container-content');

                if(menu) {
                    if(this.$store.getters['menu/g_sideBarOpen']) {
                        btnMenuIcon.classList.add('mdi-close');
                        btnMenuIcon.classList.remove('mdi-menu');
                        container.classList.add('col-lg-10', 'col-md-10', 'col-sm-9', 'col-xs-9');
                        container.classList.remove('col-12');
                    }
                    else {
                    	if(btnMenuIcon) {
                        	btnMenuIcon.classList.add('mdi-menu');
                        	btnMenuIcon.classList.remove('mdi-close');
                        }
                        container.classList.remove('col-lg-10', 'col-md-10', 'col-sm-9', 'col-xs-9');
                        container.classList.add('col-12');
                    }
                }

                return this.$store.getters['menu/g_sideBarOpen'];
            }
        },
    }
</script>

<style scope>
    #navigation .v-list-item-group .v-list-item {
        color: #1351b4 !important;
    }

    #navigation .v-list-item-group .v-list-item--active {
        background-color: #0c326f;
        color: #FFF !important;
    }

    #navigation .v-list--nav .v-list-item, .v-list--nav .v-list-item::before {
        border-radius: 0;
        min-height: 50px;
    }

    #navigation .v-list--nav {
        padding-right: 0;
    }

    #navigation .v-list--dense .v-list-item .v-list-item__title {
        font-size: 0.92rem;
        padding-left: 15px;
    }

    #navigation .submenu {
        margin-left: 8px;
        max-width: 130px;
        white-space: pre-line;
        height: auto;
    }
</style>
<!--
STACKOVERFLOW https://jareklipski.medium.com/interactive-ordered-animated-list-in-vue-js-with-vuetify-js-and-nuxt-js-ccf71548f8bf 
-->
<template>
<transition-group name="list" tag="div">
    <v-sheet v-for="(item, index) in value" :key="item[itemId]" class="rounded-lg">
        <v-hover v-slot="{ hover }">
            <v-card outlined class="mt-3 rounded-lg" :elevation="hover ?  10 : 2" >
                <v-card-title class="justify-space-between pb-0">
                    <v-sheet class="mb-5 mx-5">
                        {{item.cardTitle}} {{index+1}}
                    </v-sheet>
                    <v-sheet class="mb-5 mx-5">
                        <v-btn :disabled="index + 1 >= value.length" @click="down(index)" icon>
                            <v-icon>
                                mdi-arrow-down
                            </v-icon>
                        </v-btn>
                        <v-btn :disabled="index === 0" @click="up(index)" icon>
                            <v-icon>
                                mdi-arrow-up
                            </v-icon>
                        </v-btn>
                        <!--
            <v-btn @click="remove(index)" icon>
                <v-icon>
                    mdi-close
                </v-icon>
            </v-btn>
            -->
                    </v-sheet>
                </v-card-title>
                <v-card-text>
                    <slot :item="item" :index="index" />
                </v-card-text>
            </v-card>
        </v-hover>
    </v-sheet>
</transition-group>
</template>

<script>
export default {
    props: {
        value: {
            type: Array,
            default: () => []
        },

        itemId: {
            type: String,
            default: 'id'
        },

        cardTitle: {
            type: String,
            default: 'cardTitle'
        }
    },

    methods: {
        remove(index) {
            const newValue = [...this.value.slice(0, index), ...this.value.slice(index + 1)]
            this.$emit('input', newValue)
        },

        up(index) {
            const newValue = [...this.value]
            newValue[index] = this.value[index - 1]
            newValue[index - 1] = this.value[index]
            this.$emit('input', newValue)
        },

        down(index) {
            const newValue = [...this.value]
            newValue[index] = this.value[index + 1]
            newValue[index + 1] = this.value[index]
            this.$emit('input', newValue)
        }
    }
}
</script>

<style scoped>
.list-enter,
.list-leave-to {
    opacity: 0;
}

.list-enter-active,
.list-leave-active {
    transition: opacity 0.5s ease;
}

.list-move {
    transition: transform 0.5s ease-out;
}
</style>

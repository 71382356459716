<template>
	<div>
		<servidorDialog ref="servidorDialog" />
	    <v-dialog v-model="isVisible" width="80%" :fullscreen="$vuetify.breakpoint.xsOnly">
	    	<v-card>
	            <v-row>
	                <v-col cols="12">
	                    <v-footer padless class="primary" dark>
	                        <v-col class="text-center">
	                        <strong>LISTA DE PONTOS FOCAIS</strong>
	                        </v-col>
	                    </v-footer>

	                    <v-data-table
	                        :headers="headers"
	                        :items="pontosFocais"
	                        class="elevation-1"
	                        hide-default-footer>
	                    </v-data-table>
	                </v-col>
	            </v-row>
	    		<v-card-actions>
	                <v-spacer></v-spacer>
	                <v-btn color="primary" @click="toggleVisible()"> Fechar </v-btn>
	            </v-card-actions>
	    	</v-card>
	    </v-dialog>
	</div>
</template>
<script>
	import { getPontosFocais } from '../api/plano_trabalho';
	import servidorDialog from '../components/ServidorDialog.vue';
	export default {
		name: 'pontosFocaisDialog',
        props: ['plano_trabalho'],
		components: {
        	servidorDialog,
        },
	    data() {
	        return {
	        	pontosFocais: [],
	            isVisible: false,
	            headers: [
	                { text: 'SIAPE', sortable: false, value: 'servidor.nr_siape'},
	                { text: 'Nome', value: 'servidor.nm_servidor'},
	                { text: 'E-mail institucional', value: 'servidor.ds_email_institucional'},
	                { text: 'Plano de Trabalho', value: 'nm_unidade'},
	            ],
	        }
	    },
	    methods: {
	        toggleVisible() {
            	this.isVisible = !this.isVisible;
        	},
        	mostrarPontosFocais() {
        		getPontosFocais(this.plano_trabalho.id_plano_trabalho)
        			.then(response => {
                	this.pontosFocais = response.data.data;
            	});
        	}
	    }
}

</script>
<template>
<div>
    <h1>{{ ciclo.nm_ciclo }}</h1>
    <v-card class="my-7 mx-2 pa-3" color="grey lighten-4">
        <v-row>
            <v-col cols="12" sm="12">
                <v-card class="mb-2 mx-2 pa-2">
                    <v-card-title class="text-h5">
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12">
                                <v-form class="pa-0" ref="form_step1">
                                    <v-row dense class="ma-2">
                                        <v-col cols="12" sm="12">
                                            <v-text-field placeholder="Nome do ciclo" label="Nome do ciclo" v-model="ciclo.nm_ciclo" readonly outlined hide-details="auto" background-color="white" required :rules="[() => !!ciclo.nm_ciclo || 'Campo obrigatório.']"></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row dense class="ma-2">
                                        <v-col cols="12" sm="6">
                                            <v-text-field v-mask="'####-##-##'" placeholder="Data início" label="Data de início" type="date"  readonly
                                            v-model="ciclo.dt_inicio_string" outlined hide-details background-color="white" required  :rules="rules.dataInicioRules"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <v-text-field 
                                                v-mask="'####-##-##'" 
                                                placeholder="Data fim" 
                                                label="Data de encerramento" 
                                                type="date"  
                                                readonly
                                                v-model="ciclo.dt_encerramento_string" 
                                                outlined 
                                                hide-details 
                                                background-color="white" 
                                                required 
                                                :rules="rules.dataFimRules">
                                                <template v-slot:append>  
                                                    <v-icon color="primary" @click="showEditarEncerramentoCiclo(ciclo)">
                                                         mdi-pencil 
                                                    </v-icon> 
                                                </template>
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-form>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" sm="6">
                <v-card class="mb-2 mx-2 pa-2">
                    <v-card-title class="text-h5">
                        Gratificações <span class="ml-5 body-1"> ({{gratificacoes.length}})</span>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-divider class="ma-0"></v-divider>
                            <div v-for="(gratificacao,i) in gratificacoes" :key="i" class="ma-0">
                                <v-list-item>
                                    <template>
                                        <v-list-item-content>
                                            <v-list-item-title>{{gratificacao.sg_gratificacao + ' - ' +gratificacao.nm_gratificacao}}</v-list-item-title>
                                        </v-list-item-content>
                                    </template>
                                </v-list-item>
                                <v-divider class="ma-0"></v-divider>
                            </div>
                        </v-container>
                    </v-card-text>
                </v-card>
                <br>
                <v-card v-if="isCicloVigente" class="mb-2 mx-2 pa-2 text-center">
                    <h5 class="text-h5 mt-4">
                         Repetir Pontuação
                    </h5>
                    <v-card-text>
                        O sistema irá buscar a última nota registrada de todos dos servidores.
                        A pontuação encontrada será atribuída aos servidores, e na consulta do 
                        resultado detalhado constará a mensagem:
                        <br> 
                        <span style="background:#FFCD07">
                            “Por determinação superior não houve avaliação neste ano, e os servidores
                            tiveram a nota da última avaliação repetida”.
                        </span>
                        <br>
                        Ao clicar no botão, será solicitado uma justificativa para confirmação. 
                        Esta funcionalidade é para atender situações excepcionais em que não 
                        haverá avaliação de desempenho no ciclo.
                        <br>
                        <strong>Esta ação não pode ser desfeita após confirmação.</strong>
                        <v-container>
                            <v-btn 
                                v-if="justificativa_repeticao==''"
                                color="error"
                                @click="showDialogRepetirNota()"
                            >
                                REPETIR PONTUAÇÃO DE CICLO ANTERIOR
                            </v-btn>
                            <div 
                                v-else
                                style="background:#3333"
                            >
                                <strong>Data/hora repetição das avaliações</strong>
                                <br> {{justificativa_horario}} às 24h00<br>
                                <strong>Justificativa</strong>
                                <br> {{justificativa_repeticao}}
                            </div>
                        </v-container>
                    </v-card-text>
                </v-card>
                <!--
                <v-card class="pa-6" color="grey lighten-4">
                    <v-card-title>
                        <span class="headline">Selecionar gratificações</span>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="12">
                                    <v-checkbox v-model="allGratificacoesSelected" :label="`Selecionar todas`" @click="selectAllGratificacoes()"></v-checkbox>
                                </v-col>
                            </v-row>
                            <v-divider class="ma-0"></v-divider>
                            <div v-for="(gratificacao,i) in gratificacoes" :key="gratificacao.sg_gratificacao" class="ma-0">
                                <v-list-item @click="gratificacaoSelected(i)">
                                    <template v-slot:default>
                                        <v-list-item-action>
                                            <v-checkbox :input-value="gratificacao.selected" color="primary"></v-checkbox>
                                        </v-list-item-action>
                                        <v-list-item-content>
                                            <v-list-item-title>{{gratificacao.sg_gratificacao + ' - ' +gratificacao.nm_gratificacao}}</v-list-item-title>
                                        </v-list-item-content>
                                    </template>
                                </v-list-item>
                                <v-divider class="ma-0"></v-divider>
                            </div>

                            <v-sheet v-if="selecionarGratificacoesRequiredFieldsAlertShow" class="pa-0 ma-0 mt-5 d-flex grey lighten-3 elevation-0 justify-end align-end" width="100%">
                                <v-alert class="mx-10 mt-3 mb-0 lighten-5" border="right" colored-border type="error" elevation="5">
                                    Escolha ao menos uma gratificação.
                                </v-alert>
                            </v-sheet>
                        </v-container>
                    </v-card-text>
                </v-card>
            -->
            </v-col>
            <v-col cols="12" sm="6">
                <v-card class="mb-2 mx-2 pa-2">
                    <v-card-title class="text-h5">
                        Fases
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="12">
                                    <v-form class="pa-0">
                                        <v-card v-for="(fase,i) in ciclo.fases" :key="i" v-show="fase.id_fase != 7 && fase.id_fase != 8 && fase.id_fase != 9" class="pa-4 mx-2 mb-5 lighten-4">
                                            <v-row class="px-3 pb-4" dense>
                                                <v-sheet class="pa-0 d-flex flex-row elevation-0 align-center justify-space-between flex-wrap">
                                                    <h4 class="mx-1 mt-3 font-weight-medium">{{ fase.nm_fase}}</h4>
                                                    <v-tooltip top v-if="isCicloVigente">
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-btn icon color="primary" small v-bind="attrs" v-on="on" @click="openDialogEditarDatasFase(fase)">
                                                                <v-icon> mdi-pencil</v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <span>Editar datas</span>
                                                    </v-tooltip>
                                                </v-sheet>
                                            </v-row>
                                            <v-row dense class="ma-2">
                                                <v-col cols="12" sm="6">
                                                    <v-text-field ref="fase_dataInicio" placeholder="Data de início" label="Data de início" type="date" 
                                                    v-model="fase.dt_inicio.substring(6,11)+'-'+fase.dt_inicio.substring(3,5)+'-'+fase.dt_inicio.substring(0,2)" 
                                                    outlined hide-details background-color="white" required  readonly></v-text-field>
                                                </v-col>
                                                <v-col cols="12" sm="6">
                                                    <v-text-field ref="fase_dataFim" placeholder="Data de encerramento" label="Data de encerramento" type="date" v-model="fase.dt_encerramento.substring(6,11)+'-'+fase.dt_encerramento.substring(3,5)+'-'+fase.dt_encerramento.substring(0,2)" 
                                                    outlined hide-details background-color="white" required 
                                                             readonly></v-text-field>
                                                </v-col>
                                            </v-row>
                                        </v-card>
                                    </v-form>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" sm="12">
                <v-card class="mb-2 mx-2 pa-2">
                    <v-card-title class="text-h5">
                        Unidades de Avaliação
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-expansion-panels class="mb-10" focusable>
                                    <v-expansion-panel v-for="(org,index) in orgaos" :key="index" v-show="(isCicloVigente && org.cd_orgao != 17000) || !isCicloVigente" >
                                        <v-expansion-panel-header>
                                            {{org.nm_orgao}}
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                            <v-expansion-panels class="mb-10" focusable>
                                                <v-expansion-panel v-for="(unidade,i) in ciclo.unidades_avaliacao" :key="i" v-show="unidade.cd_orgao == org.cd_orgao">
                                                    <v-expansion-panel-header>
                                                        {{unidade.nm_unidade_avaliacao}}
                                                    </v-expansion-panel-header>
                                                    <v-expansion-panel-content>
                                                        <v-sheet class="pa-0 d-flex flex-row elevation-0 align-center justify-center flex-wrap">
                                                            <v-btn v-if="isCicloVigente" class="mt-2" color="primary" @click="openDialogAlteracaoGestoresDeUnidadeAvaliacao(unidade)">
                                                                Alterar gestores <v-icon color="white" style="text-decoration: none">mdi-pencil</v-icon>
                                                            </v-btn>
                                                        </v-sheet>
                                                        <v-sheet class="pa-2 d-flex flex-row elevation-0 align-center justify-center flex-wrap">
                                                            <v-sheet v-if="unidade.gestor_setorial" class="ma-1 pa-2 elevation-2 rounded-lg" min-width="300" max-width="600">
                                                                <h4> Gestor Setorial</h4>
                                                                CPF: {{unidade.gestor_setorial.cpf}}
                                                                <br>
                                                                Nome: {{unidade.gestor_setorial.nome}}
                                                                <br>
                                                                e-mail: {{unidade.gestor_setorial.email_pessoal}}
                                                            </v-sheet>
                                                            <v-sheet v-else class="ma-1 pa-2 elevation-2 rounded-lg" min-width="300" max-width="600">
                                                                <h4> Gestor Setorial</h4>
                                                                Não há gestor setorial cadastrado.
                                                            </v-sheet>
                                                            <v-sheet v-if="unidade.gestor_substituto" class="ma-1 pa-2 elevation-2 rounded-lg" min-width="300" max-width="600">
                                                                <h4> Gestor Setorial Substituto</h4>
                                                                CPF: {{unidade.gestor_substituto.cpf}}
                                                                <br>
                                                                Nome: {{unidade.gestor_substituto.nome}}
                                                                <br>
                                                                e-mail: {{unidade.gestor_substituto.email_pessoal}}
                                                            </v-sheet>
                                                            <v-sheet v-else class="ma-1 pa-2 elevation-2 rounded-lg" min-width="300" max-width="600">
                                                                <h4> Gestor Setorial Substituto</h4>
                                                                Não há gestor setorial substituto cadastrado.
                                                            </v-sheet>
                                                        </v-sheet>
                                                        <v-card class="mx-auto pa-2" max-width="800" tile>
                                                            <v-expansion-panels multiple class="mt-2 mb-5 elevation-2">
                                                                <v-expansion-panel :key="1">
                                                                    <v-expansion-panel-header>
                                                                        Unidades Subordinadas
                                                                    </v-expansion-panel-header>
                                                                    <v-expansion-panel-content>
                                                                        <v-list dense>
                                                                            <v-list-item v-for="(uorg, j) in unidade.planos_trabalho" :key="j">
                                                                                <v-list-item-content>
                                                                                    <v-list-item-title v-text="uorg.name"></v-list-item-title>
                                                                                </v-list-item-content>
                                                                            </v-list-item>
                                                                        </v-list>
                                                                    </v-expansion-panel-content>
                                                                </v-expansion-panel>
                                                            </v-expansion-panels>
                                                        </v-card>
                                                    </v-expansion-panel-content>
                                                </v-expansion-panel>
                                            </v-expansion-panels>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                </v-expansion-panels>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-card>
    <v-dialog v-model="editarFaseDialogShow" persistent max-width="1200">
        <v-card class="pa-4">
            <v-card-title>
                <span class="headline">Edição de datas de Fase</span>
            </v-card-title>
                    <v-container>
                        <v-row>
                            <v-col cols="12">
                                <v-form class="pa-0" ref="form_editarFase">
                                        <v-row class="px-3 pb-4" dense>
                                            <h4 class="mx-1 mt-3 font-weight-medium">{{ faseToEdit.nm_fase }}</h4>
                                        </v-row>
                                        <v-row dense class="ma-2">
                                            <v-col cols="12" sm="6">
                                                <v-text-field ref="fase_dataInicio" placeholder="Data de início" label="Data de início" type="date" v-model="faseToEdit.dt_inicio" outlined hide-details background-color="white" required  :rules="
                                                    [   () => !!faseToEdit.dt_inicio || 'Campo obrigatório.',
                                                        () => faseToEdit.dt_inicio >= ciclo.dt_inicio_string || 'Data início deve ser posterior/igual à data de início do ciclo: '+ ciclo.dt_inicio_string,
                                                        () => faseToEdit.dt_inicio < ciclo.dt_encerramento_string || 'Data início deve anterior à data final do ciclo: '+ciclo.dt_encerramento_string,
                                                    ]">
                                                    </v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="6">
                                                <v-text-field ref="fase_dataFim" placeholder="Data de encerramento" label="Data de encerramento" type="date" v-model="faseToEdit.dt_encerramento" outlined hide-details background-color="white" required  :rules="
                                                    [   () => !!faseToEdit.dt_encerramento|| 'Campo obrigatório.', 
                                                        () => faseToEdit.dt_inicio <= faseToEdit.dt_encerramento || 'Data final deve ser posterior/igual à data início da fase: '+formataData(faseToEdit.dt_inicio),
                                                        () => faseToEdit.dt_encerramento <= ciclo.dt_encerramento || 'Data final deve ser anterior/igual à data final do ciclo: '+ciclo.dt_encerramento_string,
                                                    ]">
                                                    </v-text-field>
                                            </v-col>
                                        </v-row>
                                </v-form>
                            </v-col>
                            <v-sheet v-if="validaFormFasesAlertShow" class="pa-0 ma-0 mt-0 d-flex white lighten-3 elevation-0 justify-end align-end" width="100%">
                                <v-alert class="mx-10 mt-3 mb-4 lighten-5" border="right" colored-border type="error" elevation="5">
                                    Confira as datas.
                                </v-alert>
                            </v-sheet>
                        </v-row>
                    </v-container>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="limparDialogEditarFase">
                    Cancelar
                </v-btn>
                <v-btn color="success" @click="salvarFase(faseToEdit)">
                    Salvar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
     <v-dialog v-model="unidadeAvaliacaoDialogShow" persistent max-width="1200">
        <v-card class="pa-4">
            <v-card-title>
                <span class="headline">Alteração de gestores de Unidade de Avaliação</span>
            </v-card-title>
            <v-card-text class="px-5 py-0 my-0 mb-6">
                <v-row dense class="ma-2 pa-0">
                    <v-col cols="12" sm="7">
                        <v-sheet>
                            <v-text-field v-if="unidadeAvaliacaoDialogShow" label="Nome da Unidade de Avaliação" v-model="unidadeAvaliacaoToEdit.nome" required dense outlined hide-details readonly></v-text-field>
                        </v-sheet>
                    </v-col>
                </v-row>
            </v-card-text>

            <v-row dense class="ma-0 mt-0 mb-1 elevation-0">
                <v-sheet width="300">
                    <h4 class="mx-5 px-3 mt-4 mb-0 font-weight-medium">Gestor setorial</h4>
                </v-sheet>
                <v-col cols="3" class="mx-0 mb-n4 mt-2">
                    <v-form class="pa-0" ref="form_gestorSetorial_siape_OU_cpf" v-on:submit.prevent>
                        <v-text-field v-if="unidadeAvaliacaoDialogShow" ref="siape" dense label="SIAPE/CPF" maxlength="11" v-model="unidadeAvaliacaoToEdit.gestorSetorial_campo_siape_OU_cpf" @click:append="buscaGestorSetorialPorSIAPEouCPF()" @keyup.enter="buscaGestorSetorialPorSIAPEouCPF()" append-icon="mdi-magnify" required @keypress.native="soNumero($event)" outlined :rules="rules.gestorSetorial_campo_siape_OU_cpf"></v-text-field>
                    </v-form>
                </v-col>
                <v-col cols="3" class="mx-0">
                    <h6 class="mx-2" v-if="!validations.gestorSetorial_campo_siape_OU_cpf"> Insira 7 dígitos para consultar por SIAPE ou 11 dígitos para buscar por CPF.</h6>
                </v-col>
            </v-row>

            <v-card-text class="px-5 py-0 my-0 mb-6">
                <v-row dense class="ma-2 pa-0">
                    <v-col cols="2" class="mx-0">
                        <v-form class="pa-0" ref="form_gestorSetorial_siape" v-on:submit.prevent>
                            <v-text-field v-if="unidadeAvaliacaoDialogShow" ref="siape" dense hide-details label="SIAPE" v-model="unidadeAvaliacaoToEdit.gestorSetorial.siape" v-mask="'#######'" required outlined readonly>
                            </v-text-field>
                        </v-form>
                    </v-col>
                    <v-col cols="2" class="mx-0">
                        <v-form class="pa-0" ref="form_gestorSetorial_cpf" v-on:submit.prevent>
                            <v-text-field v-if="unidadeAvaliacaoDialogShow" dense hide-details label="CPF" v-model="unidadeAvaliacaoToEdit.gestorSetorial.cpf" v-mask="'###.###.###-##'" required outlined readonly> </v-text-field>
                        </v-form>
                    </v-col>
                    <v-col cols="8" class="mx-0">
                        <v-text-field v-if="unidadeAvaliacaoDialogShow" dense hide-details label="Nome" v-model="unidadeAvaliacaoToEdit.gestorSetorial.nome" required readonly outlined></v-text-field>
                    </v-col>
                </v-row>
            </v-card-text>

            <v-row dense class="ma-0 mt-0 mb-1 elevation-0">
                <v-sheet width="300">
                    <h4 class="mx-5 px-3 mt-4 mb-0 font-weight-medium">Gestor setorial substituto</h4>
                </v-sheet>
                <v-col cols="3" class="mx-0 mb-n4 mt-2">
                    <v-form class="pa-0" ref="form_gestorSetorialSubstituto_siape_OU_cpf" v-on:submit.prevent>
                        <v-text-field v-if="unidadeAvaliacaoDialogShow" ref="siape" dense label="SIAPE/CPF" maxlength="11" v-model="unidadeAvaliacaoToEdit.gestorSetorialSubstituto_campo_siape_OU_cpf" @click:append="buscaGestorSetorialSubstitutoPorSIAPEouCPF()" @keyup.enter="buscaGestorSetorialSubstitutoPorSIAPEouCPF()" append-icon="mdi-magnify" required outlined
                            @keypress.native="soNumero($event)" :rules="rules.gestorSetorialSubstituto_campo_siape_OU_cpf"></v-text-field>
                    </v-form>
                </v-col>
                <v-col cols="3" class="mx-0">
                    <h6 class="mx-2" v-if="!validations.gestorSetorialSubstituto_campo_siape_OU_cpf"> Insira 7 dígitos para consultar por SIAPE ou 11 dígitos para buscar por CPF.</h6>
                </v-col>
            </v-row>

            <v-card-text class="px-5 py-0 my-0 mb-5">
                <v-row dense class="ma-2 pa-0">
                    <v-col cols="2">
                        <v-form class="pa-0" ref="form_gestorSetorialSubstituto_siape" v-on:submit.prevent>
                            <v-text-field v-if="unidadeAvaliacaoDialogShow" dense hide-details label="SIAPE" v-model="unidadeAvaliacaoToEdit.gestorSetorialSubstituto.siape" v-mask="'#######'" required outlined readonly> </v-text-field>
                        </v-form>
                    </v-col>
                    <v-col cols="2">
                        <v-form class="pa-0" ref="form_gestorSetorialSubstituto_cpf" v-on:submit.prevent>
                            <v-text-field v-if="unidadeAvaliacaoDialogShow" dense hide-details label="CPF" v-model="unidadeAvaliacaoToEdit.gestorSetorialSubstituto.cpf" v-mask="'###.###.###-##'" required outlined readonly> </v-text-field>
                        </v-form>
                    </v-col>
                    <v-col cols="8">
                        <v-text-field v-if="unidadeAvaliacaoDialogShow" dense hide-details label="Nome" v-model="unidadeAvaliacaoToEdit.gestorSetorialSubstituto.nome" required readonly outlined></v-text-field>
                    </v-col>
                </v-row>
            </v-card-text>
            
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="limparModalUnidadeDeAvaliacao">
                    Cancelar
                </v-btn>
                <v-btn color="success" @click="salvarUnidadeDeAvaliacao(unidadeAvaliacaoToEdit)">
                    Salvar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <v-dialog v-model="unidadeAvaliacaoErroBuscaSiapeDialogShow" persistent max-width="600">
        <v-card class="pa-4">
            <v-card-title>
                <span class="headline mb-5">Erro na busca de servidor</span>
            </v-card-title>
            <v-card-subtitle>
                <span class="subtitle-1 mt-5">{{'\n'+unidadeAvaliacaoErroBuscaSiapeMessage}}</span>
            </v-card-subtitle>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="unidadeAvaliacaoErroBuscaSiapeDialogShow = false">
                    OK
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <v-dialog v-model="dialogRepetirNota" width="50%" :fullscreen="$vuetify.breakpoint.xsOnly">
        <v-card>
            <v-card color="primary" dark class="mx-auto">
                <v-card-title>
                    <v-icon large left> mdi-content-duplicate </v-icon>
                    <span class="text-h6 font-weight-light">
                        REPETIR PONTUAÇÃO DE CICLO ANTERIOR
                    </span>
                </v-card-title>
            </v-card>
            <br>
            <v-container>
                <h3 class="text-center font-weight-light primary--text">
                    REPETIR PONTUAÇÃO DE CICLO ANTERIOR
                </h3> 
                <p class="text-center subtitle" >
                    Será agendada para executar a repetição de avaliações anteriores<br>
                     hoje às <strong>24h00</strong> após confirmação.
                </p>
                <p class="text-center" >
                    Por favor, insira uma justificativa explicando o motivo desta ação.
                </p>
                <v-row dense class="ma-0">
                    <v-col cols="12" class="px-3">
                        <v-textarea
                        v-model="justificativaRepetirNota"
                        id="justificativaRepetirNota"
                        outlined
                        rows="2"
                        required
                        :rules="[() => !!justificativaRepetirNota || 'Campo obrigatório.']"
                        name="justificativaRepetirNota"
                        label="Justificativa"
                        ></v-textarea>
                    </v-col>      
                </v-row>
            </v-container>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="grey darken-1" text @click="dialogRepetirNota = false">Cancelar</v-btn>
                <v-btn  color="error" text @click="showDialogConfirmarRepetirNota()">Prosseguir</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <v-dialog v-model="dialogConfirmarRepetirNota" width="50%" :fullscreen="$vuetify.breakpoint.xsOnly">
        <v-card>
            <v-container>
                <h3 class="text-center font-weight-light primary--text">
                        Tem certeza que deseja confirmar o agendamento?
                </h3> 
                <p class="text-center" >
                    Esta ação não pode ser desfeita após confirmação do agendamento
                </p>
            </v-container>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="grey darken-1" text @click="dialogConfirmarRepetirNota = false">Cancelar</v-btn>
                <v-btn  color="error" text @click="saveRepetirNotas()">Confirmar agendamento</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <v-dialog v-model="dialogWarning" width="570" >
        <v-card>
            <v-card-title class="text-h5 text-center">
                <v-alert prominent type="warning" outlined width="100%">
                    <v-row align="center">
                        <v-col class="grow">
                            {{ dialogMsg }}
                        </v-col>
                        <v-col class="shrink">
                        <v-btn color="warning"  @click="dialogWarning = false">Ok</v-btn>
                        </v-col>
                    </v-row>
                </v-alert>
            </v-card-title> 
        </v-card>
    </v-dialog>  

    <v-dialog v-model="dialogSuccess" width="570" >
        <v-card>
            <v-card-title class="text-h5 text-center">
                <v-alert prominent type="success" width="100%">
                    <v-row align="center">
                        <v-col class="grow">
                            {{dialogMsg}}
                        </v-col>
                        <v-col class="shrink">
                        <v-btn outlined  @click="dialogSuccess = false">Ok</v-btn>
                        </v-col>
                    </v-row>
                </v-alert>
            </v-card-title> 
        </v-card>
    </v-dialog> 

    <v-dialog v-model="dialogSuccessReload" width="570" >
        <v-card>
            <v-card-title class="text-h5 text-center">
                <v-alert prominent type="success" width="100%">
                    <v-row align="center">
                        <v-col class="grow">
                            {{dialogMsg}}
                        </v-col>
                        <v-col class="shrink">
                        <v-btn outlined  @click="recarregarAPagina()">Ok</v-btn>
                        </v-col>
                    </v-row>
                </v-alert>
            </v-card-title> 
        </v-card>
    </v-dialog> 

    <v-dialog v-model="dialogEditarEncerramentoCiclo" persistent max-width="500">
        <v-card class="pa-4">
            <v-card-title>
                <span class="headline text-center primary--text">Edição de data de encerramento</span>
            </v-card-title>
                    <v-container>
                        <v-row>
                            <v-col cols="12">
                                <v-form class="pa-0" ref="form_editarFase">
                                        <v-row class="px-3 pb-4" dense>
                                            <h4 class="mx-1 mt-3 font-weight-medium">Data de Encerramento</h4>
                                        </v-row>
                                        <v-row dense class="ma-2">
                                            <v-col cols="12" sm="12">
                                                <v-text-field 
                                                    ref="dataFimEditarCiclo" 
                                                    id="dataFimEditarCiclo" 
                                                    placeholder="Data de encerramento" 
                                                    label="Data de encerramento" 
                                                    type="date" 
                                                    v-model="dt_encerramento_ciclo_string" 
                                                    outlined 
                                                    background-color="white" 
                                                    hide-details="auto"  
                                                    required 
                                                    :rules="[v => !!v || 'Campo obrigatório.']" 
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                </v-form>
                            </v-col>
                            <v-sheet 
                                v-if="validaFormFasesAlertShow" 
                                class="pa-0 ma-0 mt-0 d-flex white lighten-3 elevation-0 justify-end align-end" 
                                width="100%"
                            >
                                <v-alert 
                                    class="mx-10 mt-3 mb-4 lighten-5" 
                                    border="right" 
                                    colored-border 
                                    type="error" 
                                    elevation="5"
                                >
                                    Confira as datas.
                                </v-alert>
                            </v-sheet>
                        </v-row>
                    </v-container>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialogEditarEncerramentoCiclo = false">
                    Cancelar
                </v-btn>
                <v-btn color="success" @click="editarEncerramentoCiclo(dt_encerramento_ciclo_string, ciclo)">
                    Salvar
                </v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>
</div>
</template>

<script>
import {
    getCiclo, 
    getCiclosVigentes, 
    updateCicloFases, 
    repetirNotasAnteriores, 
    getJustificativaRepetirAvaliacoes,
    editarDtEncerramentoCiclo
} from '../api/ciclo';
import {
    getGratificacoes
} from '../api/gratificacao';
import {
    getServidorBySiape
} from '../api/servidor';
import {
    getServidorByCPF
} from '../api/servidor';
import {
    updateUnidadeAvaliacao
} from '../api/unidade_avaliacao';
import moment from 'moment';

 import { getOrgaos } from '../api/orgao';

export default {
    name: 'CiclosDetalhar',
    data() {
        return {
            ciclosVigentes: [],
            ciclo: {gratificacoes:[]},
            isCicloVigente: false,
            gratificacoes: [],
            allGratificacoesSelected: true,
            selecionarGratificacoesRequiredFieldsAlertShow: false,
            fases: [],
            allGratificacoesSelected: false,
            gratificacoes: [],
            editarFaseDialogShow: false,
            validaFormFasesAlertShow: false,
            faseToEdit: {
                id_fase: '',
                nm_fase: '',
                ds_fase: "",
                dt_inicio: '',
                dt_encerramento: "",
            },
            unidadeAvaliacaoDialogShow: false,
            unidadeAvaliacaoErroBuscaSiapeDialogShow: false,
            unidadeAvaliacaoErroBuscaSiapeMessage: '',
            unidadeAvaliacaoToEdit: {
                id: '',
                nome: '',
                gestorSetorial: {},
                gestorSetorial_campo_siape_OU_cpf: '',
                gestorSetorialSubstituto: {},
                gestorSetorialSubstituto_campo_siape_OU_cpf: '',
                uorgs: [],
            },
            rules: {
                dataInicioRules: [
                    v => !!v || 'Campo obrigatório',
                ],
                dataFimRules: [
                    v => !!v || 'Campo obrigatório',
                    v => this.ciclo.dt_inicio < this.ciclo.dt_encerramento || 'Data final deve ser posterior à data início',
                ],
                gestorSetorial_campo_siape_OU_cpf: [
                    () => !!this.unidadeAvaliacaoToEdit.gestorSetorial_campo_siape_OU_cpf || 'Preencha com um SIAPE ou CPF.',
                ],
                gestorSetorialSubstituto_campo_siape_OU_cpf: [
                    () => !!this.unidadeAvaliacaoToEdit.gestorSetorialSubstituto_campo_siape_OU_cpf || 'Preencha com um SIAPE ou CPF.',
                ]

            },
            validations: {
                gestorSetorial_campo_siape_OU_cpf: true,
                gestorSetorialSubstituto_campo_siape_OU_cpf: true,
            },
            justificativaRepetirNota: '',
            dialogRepetirNota: false,
            dialogConfirmarRepetirNota: false,
            dialogWarning: false,
            dialogMsg:'',
            dialogSuccess:false,
            justificativa_repeticao:'',
            justificativa_horario:'',
            dialogEditarEncerramentoCiclo: false,
            dt_encerramento_ciclo_string:null,
            dt_encerramento_ciclo:null,
            dialogSuccessReload:false,
            orgaos:[]
        };
    },
    created() {
        getJustificativaRepetirAvaliacoes().then(response => {
            var res = response.data.data;
            if (res.ds_justificativa) {
                this.justificativa_repeticao = res.ds_justificativa;
                this.justificativa_horario = res.agendamento;
            }
        });

        getGratificacoes().then(response => {
            this.gratificacoes = response.data.data;
            this.gratificacoes = _.orderBy(this.gratificacoes, 'sg_gratificacao');
        });

        this.listarOrgaos();

        let id_ciclo = this.$route.params.id;
        getCiclo(id_ciclo)
            .then(response => {
                this.ciclo = response.data.data;
                this.adaptCicloDatesToShow();
                getCiclosVigentes(this.$store.getters['auth/usuarioAutenticado'])
                    .then(response => {
                        this.ciclosVigentes = response.data.data;
                        //[{"id_ciclo":2,"nm_ciclo":"Ciclo 2021 - 2022","dt_inicio":"01/10/2021","dt_encerramento":"01/02/2023","id_ciclo_pai":null,
                        //"created_at":"2022-02-10T13:48:50.000000Z","updated_at":"2022-02-10T13:48:50.000000Z","deleted_at":null}]
                        this.isCicloVigente = (this.ciclosVigentes.filter(ciclo => ciclo.id_ciclo === Number(id_ciclo))[0]) ? true: false;
                        if (!this.isCicloVigente ) {
                           

                            this.$store.dispatch('alertas/show', {
                                tipo: 'Warning',
                                titulo: 'Este ciclo não está vigente: não é possível editar os dados.',
                            })
                        }
                    }).catch(erro => {
                        console.error('erro na busca de ciclos vigentes');
                    });
            });
    },
    mounted(){
        this.checkAllGratificacoesSelected();
    },
    methods: {
        adaptCicloDatesToShow(){
            if (this.ciclo.dt_inicio.indexOf('/') > -1){
                this.ciclo.dt_inicio_string = new Date( // 01/03/2021 new  Date(yyyy,mm,dd,hh)
                        this.ciclo.dt_inicio.substring(6, 10),
                        Number(this.ciclo.dt_inicio.substring(3, 5))-1,
                        this.ciclo.dt_inicio.substring(0, 2))
                    .toISOString().substring(0, 10);
                this.ciclo.dt_encerramento_string = new Date(
                        this.ciclo.dt_encerramento.substring(6, 10),
                        Number(this.ciclo.dt_encerramento.substring(3, 5))-1,
                        this.ciclo.dt_encerramento.substring(0, 2))
                    .toISOString().substring(0, 10);
            } else {
                this.ciclo.dt_inicio_string = this.ciclo.dt_inicio.substring(0, 10)
                this.ciclo.dt_encerramento_string = this.ciclo.dt_encerramento.substring(0, 10)
            }
        },
        salvarFase(fase){
            this.$refs['form_editarFase'].validate();
            if (this.$refs['form_editarFase'].validate()) {
                this.validaFormFasesAlertShow = false;
                let faseEditada = this.ciclo.fases.filter(f => f.id_fase=== fase.id_fase)[0]
                faseEditada.dt_inicio = fase.dt_inicio.substring(8, 10)+ '/'+fase.dt_inicio.substring(5, 7)+'/'+fase.dt_inicio.substring(0, 4)
                faseEditada.dt_encerramento = fase.dt_encerramento.substring(8, 10)+ '/'+fase.dt_encerramento.substring(5, 7)+'/'+fase.dt_encerramento.substring(0, 4)
               
                let fases = this.ciclo.fases.map(function (fase, i) {
                    return {
                        id_fase: fase.id_fase,
                        //nm_fase: fase.nm_fase,
                        //ds_fase: fase.ds_fase,
                        dt_inicio: new Date( // 01/03/2021 new  Date(yyyy,mm,dd,hh)
                                fase.dt_inicio.substring(6, 10),
                                Number(fase.dt_inicio.substring(3, 5))-1,
                                fase.dt_inicio.substring(0, 2))
                            .toISOString(),
                        dt_encerramento: new Date(
                                fase.dt_encerramento.substring(6, 10),
                                Number(fase.dt_encerramento.substring(3, 5))-1,
                                fase.dt_encerramento.substring(0, 2))
                            .toISOString(),
                    }
                });
                let jsonToSend = {
                    id_ciclo: this.ciclo.id_ciclo,
                    fases: fases,
                }
                updateCicloFases(this.ciclo.id_ciclo,JSON.stringify(jsonToSend)).then(response => {
                            this.ciclo = response.data.data;
                            this.adaptCicloDatesToShow()
                            this.$store.dispatch('alertas/show', {
                                tipo: 'Sucesso',
                                titulo: 'Datas da fase '+fase.nm_fase + ' alteradas com sucesso.',
                            })
                            this.editarFaseDialogShow = false;
                            this.$vuetify.goTo(0)
                            
                        }).catch(erro => {
                            console.error('updateCicloFases error')
                        });
            } else {
                this.validaFormFasesAlertShow = true;
            }
            
        },
        salvarUnidadeDeAvaliacao(unidadeAvaliacao){
            if ( this.$refs['form_gestorSetorial_siape_OU_cpf'].validate() && this.$refs['form_gestorSetorialSubstituto_siape_OU_cpf'].validate() ) {
                const unidadeAvaliacaoToSend = {
                    id_unidade_avaliacao: unidadeAvaliacao.id,
                    nm_unidade_avaliacao: unidadeAvaliacao.nome,
                    id_gestor_setorial:unidadeAvaliacao.gestorSetorial.id,
                    id_gestor_substituto: unidadeAvaliacao.gestorSetorialSubstituto.id,
                    id_ciclo: this.ciclo.id_ciclo
                }
                updateUnidadeAvaliacao(unidadeAvaliacaoToSend.id_unidade_avaliacao,JSON.stringify(unidadeAvaliacaoToSend)).then(response => {
                            this.$store.dispatch('alertas/show', {
                                tipo: 'Sucesso',
                                titulo: 'Unidade de Avaliação '+unidadeAvaliacao.nome + ' alterada com sucesso.',
                            })
                            this.unidadeAvaliacaoDialogShow = false;
                            this.$vuetify.goTo(0)
                            getCiclo(this.$route.params.id) // getCiclo again to reload the page
                                .then(response => {
                                    this.ciclo = response.data.data;
                                    this.adaptCicloDatesToShow()
                                });
                        }).catch(erro => {
                            console.error('updateUnidadeAvaliacao error')
                        });
            }
        },
        openDialogAlteracaoGestoresDeUnidadeAvaliacao(unidade){
            var gestorSetorial = '';
            var cpfGestorSetorial = '';
            if ( unidade.gestor_setorial ) {
                gestorSetorial = unidade.gestor_setorial;
                cpfGestorSetorial = gestorSetorial.cpf.replaceAll('-','').replaceAll('.','');
            }

            var gestorSetorialSubs = '';
            var cpsGestorSetorialSubs = '';
            if ( unidade.gestor_substituto ) {
                gestorSetorialSubs = unidade.gestor_substituto;
                cpsGestorSetorialSubs = gestorSetorialSubs.cpf.replaceAll('-','').replaceAll('.','');
            }

            const new_unidadeAvaliacaoToEdit = {
                id: unidade.id_unidade_avaliacao,
                nome: unidade.nm_unidade_avaliacao,
                gestorSetorial_campo_siape_OU_cpf: cpfGestorSetorial,
                gestorSetorialSubstituto_campo_siape_OU_cpf: cpsGestorSetorialSubs,
                gestorSetorial: gestorSetorial,
                gestorSetorialSubstituto: gestorSetorialSubs,
                uorgs: []
            }
            this.unidadeAvaliacaoToEdit = new_unidadeAvaliacaoToEdit;
            this.unidadeAvaliacaoDialogShow = true; 
        },
        openDialogEditarDatasFase(fase){
            const new_faseToEdit = {
                id_fase: fase.id_fase,
                nm_fase: fase.nm_fase,
                ds_fase: fase.ds_fase,
                dt_inicio: fase.dt_inicio.substring(6,11)+'-'+fase.dt_inicio.substring(3,5)+'-'+fase.dt_inicio.substring(0,2),
                dt_encerramento: fase.dt_encerramento.substring(6,11)+'-'+fase.dt_encerramento.substring(3,5)+'-'+fase.dt_encerramento.substring(0,2),
            }
            this.faseToEdit = new_faseToEdit;
            this.editarFaseDialogShow = true; 
        },  
        buscaGestorSetorialPorSIAPEouCPF() {
            if (this.$refs['form_gestorSetorial_siape_OU_cpf'].validate() && this.unidadeAvaliacaoToEdit.gestorSetorial_campo_siape_OU_cpf) {
                this.validations.gestorSetorial_campo_siape_OU_cpf = true
                if (this.unidadeAvaliacaoToEdit.gestorSetorial_campo_siape_OU_cpf.length === 11) {
                    let cpf = this.unidadeAvaliacaoToEdit.gestorSetorial_campo_siape_OU_cpf;
                    getServidorByCPF(cpf).then(response => {
                        this.unidadeAvaliacaoToEdit.gestorSetorial = response.data.data;
                        this.unidadeAvaliacaoToEdit.gestorSetorial.id = this.unidadeAvaliacaoToEdit.gestorSetorial.id_servidor;
                        this.unidadeAvaliacaoToEdit.gestorSetorial.nome = this.unidadeAvaliacaoToEdit.gestorSetorial.nm_servidor;
                        this.unidadeAvaliacaoToEdit.gestorSetorial.siape = this.unidadeAvaliacaoToEdit.gestorSetorial.nr_siape;
                        this.unidadeAvaliacaoToEdit.gestorSetorial.cpf = this.unidadeAvaliacaoToEdit.gestorSetorial.nr_cpf;
                    }).catch(erro => {
                        this.unidadeAvaliacaoErroBuscaSiapeDialogShow = true
                        this.unidadeAvaliacaoErroBuscaSiapeMessage = (erro.toString().indexOf('404') > -1) ? 'Não foi encontrato servidor com CPF ' + this.unidadeAvaliacaoToEdit.gestorSetorial_campo_siape_OU_cpf + '.' : erro;
                        this.unidadeAvaliacaoToEdit.gestorSetorial = {}
                    });
                } else if (this.unidadeAvaliacaoToEdit.gestorSetorial_campo_siape_OU_cpf.length === 7) {
                    let siape = this.unidadeAvaliacaoToEdit.gestorSetorial_campo_siape_OU_cpf;
                    getServidorBySiape(siape).then(response => {
                        this.unidadeAvaliacaoToEdit.gestorSetorial = response.data.data;
                        this.unidadeAvaliacaoToEdit.gestorSetorial.id = this.unidadeAvaliacaoToEdit.gestorSetorial.id_servidor;
                        this.unidadeAvaliacaoToEdit.gestorSetorial.nome = this.unidadeAvaliacaoToEdit.gestorSetorial.nm_servidor;
                        this.unidadeAvaliacaoToEdit.gestorSetorial.siape = this.unidadeAvaliacaoToEdit.gestorSetorial.nr_siape;
                        this.unidadeAvaliacaoToEdit.gestorSetorial.cpf = this.unidadeAvaliacaoToEdit.gestorSetorial.nr_cpf;
                    }).catch(erro => {
                        this.unidadeAvaliacaoErroBuscaSiapeDialogShow = true
                        this.unidadeAvaliacaoErroBuscaSiapeMessage = (erro.toString().indexOf('404') > -1) ? 'Não foi encontrato servidor com SIAPE ' + this.unidadeAvaliacaoToEdit.gestorSetorial_campo_siape_OU_cpf + '.' : erro;
                        this.unidadeAvaliacaoToEdit.gestorSetorial = {}
                    });
                } else {
                    this.validations.gestorSetorial_campo_siape_OU_cpf = false
                    this.unidadeAvaliacaoToEdit.gestorSetorial = {}
                }
            }
        },
        
        buscaGestorSetorialSubstitutoPorSIAPEouCPF() {
            if (this.$refs['form_gestorSetorialSubstituto_siape_OU_cpf'].validate() && this.unidadeAvaliacaoToEdit.gestorSetorialSubstituto_campo_siape_OU_cpf) {
                this.validations.gestorSetorialSubstituto_campo_siape_OU_cpf = true
                if (this.unidadeAvaliacaoToEdit.gestorSetorialSubstituto_campo_siape_OU_cpf.length === 11) {
                    let cpf = this.unidadeAvaliacaoToEdit.gestorSetorialSubstituto_campo_siape_OU_cpf;
                    getServidorByCPF(cpf).then(response => {
                        this.unidadeAvaliacaoToEdit.gestorSetorialSubstituto = response.data.data;
                        this.unidadeAvaliacaoToEdit.gestorSetorialSubstituto.id = this.unidadeAvaliacaoToEdit.gestorSetorialSubstituto.id_servidor;
                        this.unidadeAvaliacaoToEdit.gestorSetorialSubstituto.nome = this.unidadeAvaliacaoToEdit.gestorSetorialSubstituto.nm_servidor;
                        this.unidadeAvaliacaoToEdit.gestorSetorialSubstituto.siape = this.unidadeAvaliacaoToEdit.gestorSetorialSubstituto.nr_siape;
                        this.unidadeAvaliacaoToEdit.gestorSetorialSubstituto.cpf = this.unidadeAvaliacaoToEdit.gestorSetorialSubstituto.nr_cpf;
                    }).catch(erro => {
                        this.unidadeAvaliacaoErroBuscaSiapeDialogShow = true
                        this.unidadeAvaliacaoErroBuscaSiapeMessage = (erro.toString().indexOf('404') > -1) ? 'Não foi encontrato servidor com CPF ' + this.unidadeAvaliacaoToEdit.gestorSetorialSubstituto_campo_siape_OU_cpf + '.' : erro;
                        this.unidadeAvaliacaoToEdit.gestorSetorialSubstituto = {}
                    });
                } else if (this.unidadeAvaliacaoToEdit.gestorSetorialSubstituto_campo_siape_OU_cpf.length === 7) {
                    let siape = this.unidadeAvaliacaoToEdit.gestorSetorialSubstituto_campo_siape_OU_cpf;
                    getServidorBySiape(siape).then(response => {
                        this.unidadeAvaliacaoToEdit.gestorSetorialSubstituto = response.data.data;
                        this.unidadeAvaliacaoToEdit.gestorSetorialSubstituto.id = this.unidadeAvaliacaoToEdit.gestorSetorialSubstituto.id_servidor;
                        this.unidadeAvaliacaoToEdit.gestorSetorialSubstituto.nome = this.unidadeAvaliacaoToEdit.gestorSetorialSubstituto.nm_servidor;
                        this.unidadeAvaliacaoToEdit.gestorSetorialSubstituto.siape = this.unidadeAvaliacaoToEdit.gestorSetorialSubstituto.nr_siape;
                        this.unidadeAvaliacaoToEdit.gestorSetorialSubstituto.cpf = this.unidadeAvaliacaoToEdit.gestorSetorialSubstituto.nr_cpf;
                    }).catch(erro => {
                        this.unidadeAvaliacaoErroBuscaSiapeDialogShow = true
                        this.unidadeAvaliacaoErroBuscaSiapeMessage = (erro.toString().indexOf('404') > -1) ? 'Não foi encontrato servidor com SIAPE ' + this.unidadeAvaliacaoToEdit.gestorSetorialSubstituto_campo_siape_OU_cpf + '.' : erro;
                        this.unidadeAvaliacaoToEdit.gestorSetorialSubstituto = {}
                    });
                } else {
                    this.validations.gestorSetorialSubstituto_campo_siape_OU_cpf = false
                    this.unidadeAvaliacaoToEdit.gestorSetorialSubstituto = {}
                }
            }
        },
        soNumero(e) {
            let keyCode = (e.keyCode ? e.keyCode : e.which);
            if (keyCode > 31 && (keyCode < 48 || keyCode > 57)) {
                e.preventDefault();
            }
        },
        limparModalUnidadeDeAvaliacao() {
            this.$refs['form_gestorSetorial_cpf'].resetValidation()
            this.$refs['form_gestorSetorial_siape'].resetValidation()
            this.$refs['form_gestorSetorialSubstituto_cpf'].resetValidation()
            this.$refs['form_gestorSetorialSubstituto_siape'].resetValidation()
            this.$refs['form_gestorSetorial_siape_OU_cpf'].resetValidation()
            this.$refs['form_gestorSetorialSubstituto_siape_OU_cpf'].resetValidation()
            this.unidadeAvaliacaoToEdit.nome = '';
            this.unidadeAvaliacaoToEdit.gestorSetorial = {}
            this.unidadeAvaliacaoToEdit.gestorSetorial_campo_siape_OU_cpf = ''
            this.unidadeAvaliacaoToEdit.gestorSetorialSubstituto = {};
            this.unidadeAvaliacaoToEdit.gestorSetorialSubstituto_campo_siape_OU_cpf = '';
            this.validations.gestorSetorial_campo_siape_OU_cpf = true;
            this.validations.gestorSetorialSubstituto_campo_siape_OU_cpf = true;
            this.unidadeAvaliacaoDialogShow = false;
        },
        limparDialogEditarFase() {  
            this.$refs['form_editarFase'].resetValidation();       
            this.faseToEdit = {
                id_fase: '',
                nm_fase: '',
                ds_fase: "",
                dt_inicio: '',
                dt_encerramento: "",
            };
            this.editarFaseDialogShow = false;
            this.validaFormFasesAlertShow = false;
        },
        checkAllGratificacoesSelected(){
            if (this.gratificacoes.filter(g => g.selected === true).length === this.gratificacoes.length){
                this.allGratificacoesSelected = true
            } else {
                this.allGratificacoesSelected = false
            }
        },
        gratificacaoSelected(index) {
            this.gratificacoes[index].selected = !this.gratificacoes[index].selected;
            if (this.selecionarGratificacoesRequiredFieldsAlertShow && this.gratificacoes.length > 0) {
                this.selecionarGratificacoesRequiredFieldsAlertShow = false
            }
            this.checkAllGratificacoesSelected();
        },
        selectAllGratificacoes(valueToSet) {
            let newValueToSet = (valueToSet) ? valueToSet : (this.allGratificacoesSelected) ? true : false;
            if (this.selecionarGratificacoesRequiredFieldsAlertShow && this.gratificacoes.length > 0) {
                this.selecionarGratificacoesRequiredFieldsAlertShow = false
            }
            for (let gratificacao of this.gratificacoes) {
                gratificacao.selected = newValueToSet;
            }
        },
        validaCiclo(){
            this.validaGratificacoes();
        },
        validaGratificacoes(){
            if (this.gratificacoes.filter(grat => grat.selected === true).length > 0) {
                this.selecionarGratificacoesRequiredFieldsAlertShow = false;
            } else {
                this.selecionarGratificacoesRequiredFieldsAlertShow = true;
            }
        },
        formataData(data) {
            return moment(data, "YYYY-MM-DD").format("DD/MM/YYYY");
        }, 
        showDialogRepetirNota() {
            this.dialogRepetirNota = true;
            this.justificativaRepetirNota = '';
        },
        showDialogConfirmarRepetirNota() {
            if (this.justificativaRepetirNota == null || this.justificativaRepetirNota == '') {
                this.justificativaRepetirNota = '';
                this.dialogMsg = 'Necessário ter justificativa';
                return this.dialogWarning = true;
            }
            this.dialogRepetirNota = false;
            this.dialogConfirmarRepetirNota = true;
        },
        saveRepetirNotas() {
            if (this.justificativaRepetirNota == null || this.justificativaRepetirNota == '') {
                this.justificativaRepetirNota = '';
                this.dialogMsg = 'Necessário ter justificativa';
                return this.dialogWarning = true;
            }
            
            let data = new FormData();

            data.append('ds_justificativa', this.justificativaRepetirNota);                
            data.append('id_servidor', this.$store.getters['auth/usuarioAutenticado']);

            repetirNotasAnteriores(data).then(response => {
                var res = response.data.data;
                this.dialogConfirmarRepetirNota = false;
                this.justificativa_repeticao = res.ds_justificativa;
                this.justificativa_horario = res.agendamento;
                this.dialogMsg= 'Agendamento realizado';
                this.dialogSuccess= true;
            }).catch(erro => {
                this.dialogMsg = "Erro ao salvar";
                this.dialogWarning = true;
            });
        },

        showEditarEncerramentoCiclo(ciclo) {
            this.dt_encerramento_ciclo_string = ciclo.dt_encerramento_string;
            this.dialogEditarEncerramentoCiclo = true;
        },

        editarEncerramentoCiclo(dt_encerramento, ciclo) {
            if (!dt_encerramento) {
                this.dialogMsg = "Campo obrigatório";
                return this.dialogWarning = true;
            } else {
                if (ciclo.dt_inicio_string > dt_encerramento) {
                    this.dialogMsg = "Encerramento deve ser posterior à data início";
                    return this.dialogWarning = true;
                }
            }

            let data = new FormData();
            data.append('id_ciclo', ciclo.id_ciclo);                
            data.append('dt_encerramento', dt_encerramento);

            editarDtEncerramentoCiclo(data).then(response => {
                this.ciclo.dt_encerramento_string = dt_encerramento;
                this.dialogEditarEncerramentoCiclo = false;
                this.dialogMsg = "Feito com sucesso";
                this.dialogSuccessReload = true;
            }).catch(erro => {
                this.dialogMsg = "Erro ao salvar";
                this.dialogWarning = true;
            });
        },

        recarregarAPagina(){
            window.location.reload();
        },

        listarOrgaos() {
            getOrgaos().then(response => {
                this.orgaos = response.data.data; 
            });
        }
    }
}
</script>

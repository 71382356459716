import api from './index';

const getNotificacoesUnidadeGestao = () => api.get('getNotificacaoUnidadeGestao');

const getNotificacoesUnidadeGestaoGestor = () => api.get('getNotificacoesUnidadeGestaoGestor');

const createNotificacaoUnidadeGestao = (notificacao) => api.post('createNotificacaoUnidadeGestao', notificacao);

const getTituloCorpoPadrao = (data) => api.post('getTituloCorpoPadrao', data);

const visualizarNotificacao = (id) => api.get('visualizarNotificacao/'+ id);

const downloadAnexo = (id_arquivo) => api.get('downloadAnexo/'+id_arquivo,{ responseType: 'arraybuffer' });

export {
    getNotificacoesUnidadeGestao,
    createNotificacaoUnidadeGestao,
    getTituloCorpoPadrao,
    visualizarNotificacao,
    downloadAnexo,
    getNotificacoesUnidadeGestaoGestor
}
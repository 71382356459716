import { login } from '../api/auth';

class AuthService {
    getLocalUser() {
        const user = localStorage.getItem("user");

        if(!user) {
            return null;
        }

        return JSON.parse(user);
    }

    getLocalUserExterno() {
        const user = localStorage.getItem("user_externo");

        if(!user || user === 'undefined') {
            return null;
        }

        return JSON.parse(user);
    }

    getLocalToken() {
        const token = localStorage.getItem("access_token");

        if(!token) {
            return null;
        }

        return JSON.parse(token);
    }

    getLocalRoles() {
        const roles = localStorage.getItem("roles");

        return JSON.parse(roles);
    }
    
    login(code) {
        return login(code)
            .then(response => {
                if(response.status == 200) {
                    if ( response.data.data.id_servidor_externo ) {
                        localStorage.setItem('user', JSON.stringify(response.data.data.id_servidor_externo));
                        localStorage.setItem('id_servidor_externo', JSON.stringify(response.data.data.id_servidor_externo));
                    }
                    if ( response.data.data.id_servidor ) {
                        localStorage.setItem('user', JSON.stringify(response.data.data.id_servidor));
                        localStorage.setItem('id_servidor', JSON.stringify(response.data.data.id_servidor));
                    } 
                    if ( !response.data.data.id_servidor_externo && !response.data.data.id_servidor ) {
                        localStorage.setItem('user_externo', JSON.stringify(response.data.data.sub));
                    }
                    
                    localStorage.setItem('access_token', JSON.stringify(response.data.data.token));
                    localStorage.setItem('roles', JSON.stringify(response.data.data.roles));
                    localStorage.setItem('hasCiclo', JSON.stringify(response.data.data.hasCiclo));
                }
            }).catch(error => {
                return Promise.reject(error);
            });
    }

    logout() {
        localStorage.removeItem('user');
        localStorage.removeItem('user_externo');
        localStorage.removeItem('access_token');
        localStorage.removeItem('roles');
        localStorage.removeItem('id_servidor');
        localStorage.removeItem('id_servidor_externo');
        localStorage.removeItem('hasCiclo');
    }
}

export default new AuthService();

<template>
<div>
    <br>
    <v-divider></v-divider>
    <h3 class="pa-5 my-2 text-h5">1ª avaliação semestral</h3>
    <div v-if="onlyNota">
        <p v-if="msgOnlyNota">
            <v-alert type="info" prominent>
                <span>
                    {{msgOnlyNota}}
                </span>
            </v-alert>
            <span class="font-weight-bold"></span>
        </p>
        <v-card>
            <v-container class="pa-1" width="800">
                <v-row class="my-1">
                    <v-col cols="10" class="d-flex flex-column align-end justify-end text-center">
                        <span class="text-h6"><b>Conversão da nota em pontos</b></span>
                        <v-chip small label class="mb-0 mx-0 blue lighten-5" text-color="white">
                            <span class="font-weight-medium">
                                <a 
                                    href="https://www.in.gov.br/en/web/dou/-/portaria-n-528-de-26-de-setembro-de-2019-218534988" 
                                    target="_blank"
                                >
                                    Anexo III - PORTARIA GM/ME Nº 528, DE 26/09/2019
                                </a>
                            </span>
                        </v-chip>
                    </v-col>
                    <v-col cols="2" class="d-flex flex-column align-center justify-center text-center">
                        <span>
                            <v-chip large label class="px-2 mx-1 green" text-color="white">
                                <span class="text-h4 font-weight-medium">
                                    {{avaliacao_consolidada.nr_pontos}}
                                </span>
                            </v-chip>
                        </span>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
        <br/>
    </div>
    <div v-else>
        <div v-if="avaliacaoColetada && avaliacao_consolidada">
        <v-card>
            <h3 class="pa-5 my-2 text-h5">Datas das ocorrências</h3>
            <div>
                <v-container class="pa-1">
                    <v-simple-table class="mb-3">
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th class="text-left">#</th>
                                        <th class="text-left">Ocorrência</th>
                                        <th class="text-left">Data</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>1</td>
                                        <td>Avaliação foi concluída</td>
                                        <td v-if="avaliacao_consolidada.created_at">
                                            {{avaliacao_consolidada.created_at | moment("DD/MM/YYYY HH:mm")}}
                                        </td>
                                        <td v-else>Sem registro</td>
                                    </tr>
                                    <tr>
                                        <td>2</td>
                                        <td>Servidor apresentou pedido de reconsideração</td>
                                        <td v-if="avaliacao_consolidada.reconsideracao && avaliacao_consolidada.reconsideracao.created_at">
                                            {{avaliacao_consolidada.reconsideracao.created_at | moment("DD/MM/YYYY HH:mm")}} 
                                        </td>
                                        <td v-else>Sem registro</td>
                                    </tr>
                                    <tr>
                                        <td>3</td>
                                        <td>Pedido de reconsideração foi analisado</td>
                                        <td v-if="avaliacao_consolidada.reconsideracao && avaliacao_consolidada.reconsideracao.dt_ciencia_chefia">
                                            {{avaliacao_consolidada.reconsideracao.dt_ciencia_chefia | moment("DD/MM/YYYY HH:mm")}} 
                                        </td>
                                        <td v-else>Sem registro</td>
                                    </tr>
                                    <tr>
                                        <td>4</td>
                                        <td>Servidor apresentou recurso</td>
                                        <td v-if="dt_recurso">
                                            {{dt_recurso | moment("DD/MM/YYYY HH:mm")}} 
                                        </td>
                                        <td v-else>Sem registro</td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                </v-container>
            </div>
        </v-card>
        <br>

        <v-card>
            <h3 class="pa-5 my-2 text-h5">Resultado da Autoavaliação</h3>
                <div v-if="avaliacao_consolidada.equipe_trabalho && avaliacao_consolidada.equipe_trabalho.avaliacao_auto">
                    <v-container class="pa-1">
                        <v-simple-table class="mb-3">
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th class="text-left">#</th>
                                        <th class="text-left">Fator</th>
                                        <th class="text-left">Descrição</th>
                                        <th class="text-left">Peso (P)</th>
                                        <th class="text-left">Conceito (C)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        v-for="fator,index in avaliacao_consolidada.equipe_trabalho.avaliacao_auto.fatores"
                                        :key="fator.id_fator"
                                    >
                                        <td>{{ index+1 }}</td>
                                        <td>{{ fator.nm_fator }}</td>
                                        <td>{{ fator.ds_fator }}</td>
                                        <td>{{ fator.pivot.nr_peso }}</td>
                                        <td>{{ fator.pivot.nr_nota }}</td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                        <v-sheet class="ma-3 d-flex align-end justify-end">
                            <v-sheet class="mx-3 px-3 d-flex flex-column pa-1 elevation-6 rounded-lg" style="background-color: white;">
                                <v-sheet class="pa-2 d-flex flex-row align-end justify-end">
                                    <h5 class="my-0 mx-0 font-weight-medium">Somatório dos pesos</h5>
                                    <v-chip small label class="mb-0 mx-2 blue" text-color="white">
                                        <span class="font-weight-medium">P</span>
                                    </v-chip>
                                    <v-sheet class="text-center" width="55">
                                        <h5 class="my-0">{{valoresAutoAvaliacao.somatorioDosPesos}}</h5>
                                    </v-sheet>
                                </v-sheet>
                                <v-divider></v-divider>
                                <v-sheet class="pa-2 d-flex flex-row align-end justify-end">
                                    <h5 class="my-0 mx-0 font-weight-medium">Somatório das notas obtidas</h5>
                                    <v-chip small label class="mb-0 mx-2 blue" text-color="white">
                                        <span class="font-weight-medium"> P x C = N</span>
                                    </v-chip>
                                    <v-sheet class="text-center" width="55">
                                        <h5 class="my-0">{{valoresAutoAvaliacao.somatorioNotasObtidas}}</h5>
                                    </v-sheet>
                                </v-sheet>
                                <v-divider></v-divider>
                                <v-sheet class="pa-2 d-flex flex-row align-end justify-end">
                                    <h5 class="my-0 mx-0 font-weight-medium">Resultado da Autoavaliação</h5>
                                    <v-chip small label class="mb-0 mx-2 blue" text-color="white">
                                        <span class="font-weight-medium"> N / P</span>
                                    </v-chip>
                                    <v-sheet class="text-center" width="55">
                                        <h5 class="my-0">
                                            {{avaliacao_consolidada.equipe_trabalho.avaliacao_auto.va_nota}}
                                        </h5>
                                    </v-sheet>
                                </v-sheet>
                            </v-sheet>
                        </v-sheet>
                    </v-container>
                </div>
                <div v-else>
                    <v-row dense class="mx-5 mt-3 elevation-0">
                        <v-col cols="12">
                            <v-alert dense outlined type="info" class="mb-5">
                                <span>Servidor sem autoavaliação.</span>
                            </v-alert>
                        </v-col>
                    </v-row>
                </div>
            </v-card>
        </div>
        <br />
        <v-card>
            <h3 class="pa-5 my-2 text-h5">Resultado da Avaliação da Chefia</h3>
            <v-row class="mx-0">
                <v-col cols="6" class="mx-0 ml-2">
                    <p v-if="avaliacao_consolidada && avaliacao_consolidada.equipe_trabalho.avaliacao_chefia.avaliador">
                        <strong>Avaliador:</strong> {{avaliacao_consolidada.equipe_trabalho.avaliacao_chefia.avaliador.nm_servidor}}
                    </p>
                    <p v-if="avaliacao_consolidada && avaliacao_consolidada.equipe_trabalho_externo">
                        <strong>Avaliador:</strong> {{avaliacao_consolidada.equipe_trabalho.avaliacao_chefia.equipe_trabalho_externo.servidor_externo.nm_servidor}}
                    </p>
                </v-col>
            </v-row>
            <br>
            <div v-if="avaliacao_consolidada && avaliacao_consolidada.equipe_trabalho.avaliacao_chefia">
                <v-container class="pa-1">
                    <v-simple-table class="mb-3">
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-left">#</th>
                                    <th class="text-left">Fator</th>
                                    <th class="text-left">Descrição</th>
                                    <th class="text-left">Peso (P)</th>
                                    <th class="text-left">Conceito (C)</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    v-for="fator,index in avaliacao_consolidada.equipe_trabalho.avaliacao_chefia.fatores"
                                    :key="fator.id_fator"
                                >
                                    <td>{{ index+1 }}</td>
                                    <td>{{ fator.nm_fator }}</td>
                                    <td>{{ fator.ds_fator }}</td>
                                    <td>{{ fator.pivot.nr_peso }}</td>
                                    <td>{{ fator.pivot.nr_nota }}</td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                    <v-sheet class="ma-3 d-flex align-end justify-end">
                        <v-sheet class="mx-3 px-3 d-flex flex-column pa-1 elevation-6 rounded-lg">
                            <v-sheet class="pa-2 d-flex flex-row align-end justify-end">
                                <h5 class="my-0 mx-0 font-weight-medium">Somatório dos pesos</h5>
                                <v-chip small label class="mb-0 mx-2 blue" text-color="white">
                                    <span class="font-weight-medium">P</span>
                                </v-chip>
                                <v-sheet class="text-center" width="55">
                                    <h5 class="my-0">{{valoresChefia.somatorioDosPesos}}</h5>
                                </v-sheet>
                            </v-sheet>
                            <v-divider></v-divider>
                            <v-sheet class="pa-2 d-flex flex-row align-end justify-end">
                                <h5 class="my-0 mx-0 font-weight-medium">Somatório das notas obtidas</h5>
                                <v-chip small label class="mb-0 mx-2 blue" text-color="white">
                                    <span class="font-weight-medium"> P x C = N</span>
                                </v-chip>
                                <v-sheet class="text-center" width="55">
                                    <h5 class="my-0">{{valoresChefia.somatorioNotasObtidas}}</h5>
                                </v-sheet>
                            </v-sheet>
                            <v-divider></v-divider>
                            <v-sheet class="pa-2 d-flex flex-row align-end justify-end">
                                <h5 class="my-0 mx-0 font-weight-medium">
                                    Resultado da Avaliação da Chefia
                                </h5>
                                <v-chip small label class="mb-0 mx-2 blue" text-color="white">
                                    <span class="font-weight-medium"> N / P</span>
                                </v-chip>
                                <v-sheet class="text-center" width="55">
                                    <h5 class="my-0">
                                        {{avaliacao_consolidada.equipe_trabalho.avaliacao_chefia.va_nota}}
                                    </h5>
                                </v-sheet>
                            </v-sheet>
                        </v-sheet>
                    </v-sheet>
                </v-container>
            </div>
            <div v-else>
                <v-row dense class="mx-5 mt-3 elevation-0">
                    <v-col cols="12">
                        <v-alert dense outlined type="info" class="mb-5">
                            <span>Servidor sem avaliação de chefia.</span>
                        </v-alert>
                    </v-col>
                </v-row>
            </div>
        </v-card>	

        <br>
        <v-divider/>
        <br>

        <v-sheet class="my-0 pa-5 px-3 d-flex flex-column pa-1 elevation-2 rounded-lg">
            <h3 class="my-2 text-h5">Consolidação da Avaliação</h3>
            <v-row class="my-0">
                <v-col 
                    cols="6" 
                    class="d-flex flex-column align-center justify-center text-center text-subtitle-1 font-weight-bold"
                >
                    <span>Tipo de avaliação</span>
                </v-col>
                <v-col 
                    cols="2" 
                    class="d-flex flex-column align-center justify-center text-center text-subtitle-1 font-weight-bold"
                >
                    <span>Nota</span>
                </v-col>
                <v-col 
                    cols="2" 
                    class="d-flex flex-column align-center justify-center text-center text-subtitle-1 font-weight-bold"
                >
                    <span>Percentual</span>
                </v-col>
                <v-col 
                    cols="2" 
                    class="d-flex flex-column align-center justify-center text-center text-subtitle-1 font-weight-bold"
                >
                    <span>Nota Final</span>
                </v-col>
            </v-row>
            <v-divider class="my-n2"/>
            <v-row class="my-0">
                <v-col 
                    cols="6" 
                    class="d-flex flex-column align-center justify-center text-center"
                >
                    <span><b>Autoavaliação</b></span>
                </v-col>
                <v-col cols="2" class="d-flex flex-column align-center justify-center text-center">
                    <span v-if="avaliacao_consolidada && avaliacao_consolidada.equipe_trabalho.avaliacao_auto">
                        {{avaliacao_consolidada.equipe_trabalho.avaliacao_auto.va_nota}}
                    </span>
                    <span v-else >-</span>
                </v-col>
                <v-col cols="2" class="d-flex flex-column align-center justify-center text-center">
                    <span v-if="avaliacao_consolidada && avaliacao_consolidada.equipe_trabalho.avaliacao_auto">
                        {{avaliacao_consolidada.va_proporcao_auto}}%
                    </span>
                    <span v-else >-</span>
                </v-col>
                <v-col cols="2" class="d-flex flex-column align-center justify-center text-center">
                    <span v-if="avaliacao_consolidada && avaliacao_consolidada.equipe_trabalho.avaliacao_auto">
                        {{avaliacao_consolidada.va_nota_auto}}
                    </span>
                    <span v-else >-</span>
                </v-col>
            </v-row>
            <v-divider class="my-n2"/>
            <v-row class="my-0">
                <v-col cols="6" class="d-flex flex-column align-center justify-center text-center">
                    <span><b>Avaliação da Equipe</b></span>
                </v-col>
                <v-col cols="2" class="d-flex flex-column align-center justify-center text-center">
                    <span v-if="avaliacao_consolidada && avaliacao_consolidada.fatores_equipe">
                        {{valoresEquipe.resultadoAvaliacao}}
                    </span>
                    <span v-else >-</span>
                </v-col>
                <v-col cols="2" class="d-flex flex-column align-center justify-center text-center">
                    <span v-if="avaliacao_consolidada && avaliacao_consolidada.fatores_equipe">
                        {{avaliacao_consolidada.va_proporcao_equipe}}%
                    </span>
                    <span v-else >-</span>
                </v-col>
                <v-col cols="2" class="d-flex flex-column align-center justify-center text-center">
                    <span v-if="avaliacao_consolidada && avaliacao_consolidada.fatores_equipe">
                        {{avaliacao_consolidada.va_nota_equipe}}
                    </span>
                    <span v-else >-</span>
                </v-col>
            </v-row>
            <v-divider class="my-n2"/>
            <v-row class="my-0">
                <v-col cols="6" class="d-flex flex-column align-center justify-center text-center">
                    <span><b>Avaliação da Chefia imediata</b></span>
                </v-col>
                <v-col cols="2" class="d-flex flex-column align-center justify-center text-center">
                    <span 
                        v-if="avaliacao_consolidada 
                            && avaliacao_consolidada.equipe_trabalho.avaliacao_chefia.fatores"
                    >
                        {{avaliacao_consolidada.equipe_trabalho.avaliacao_chefia.va_nota}}
                    </span>
                    <span v-else >-</span>
                </v-col>
                <v-col cols="2" class="d-flex flex-column align-center justify-center text-center">
                    <span 
                        v-if="avaliacao_consolidada 
                            && avaliacao_consolidada.equipe_trabalho.avaliacao_chefia.fatores"
                    >
                        {{avaliacao_consolidada.va_proporcao_chefia}}%
                    </span>
                    <span v-else >-</span>
                </v-col>
                <v-col cols="2" class="d-flex flex-column align-center justify-center text-center">
                    <span 
                        v-if="avaliacao_consolidada 
                            && avaliacao_consolidada.equipe_trabalho.avaliacao_chefia.fatores"
                    >
                        {{avaliacao_consolidada.va_nota_chefia}}
                    </span>
                    <span v-else >-</span>
                </v-col>
            </v-row>
            <v-sheet class="pa-3 ma-5 elevation-6 rounded-lg align-self-end  grey lighten-5" width="60%" style="background-color: white;">
                <v-row class="my-1">
                    <v-col cols="10" class="d-flex flex-column align-end textjustify-center text-center">
                        <span><b>Resultado da Avaliação Individual</b></span>
                        <small>(considera apenas uma casa decimal)</small>
                        <small v-if="reconsideracaoHasParecerChefia" class="red--text">
                            * Nota pode ter sofrido alteração após pedido de reconsideração
                        </small>
                    </v-col>
                    <v-col cols="2" class="d-flex flex-column align-center justify-center text-center">
                        <span>
                            <v-chip label class="pa-2 mx-1 green" text-color="white">
                                <span v-if="avaliacao_consolidada" class="text-h6 font-weight-medium">
                                    {{avaliacao_consolidada.va_nota_final}}
                                </span>
                            </v-chip>
                        </span>
                    </v-col>
                </v-row>

                <v-divider></v-divider>
                <v-row class="my-1">
                    <v-col cols="10" class="d-flex flex-column align-end justify-end text-center">
                        <span class="text-h6"><b>Conversão da nota em pontos</b></span>
                        <v-chip small label class="mb-0 mx-0 blue lighten-5" text-color="white">
                            <span class="font-weight-medium">
                                <a 
                                    href="https://www.in.gov.br/en/web/dou/-/portaria-n-528-de-26-de-setembro-de-2019-218534988"
                                    target="_blank"
                                >
                                    Anexo III - PORTARIA GM/ME Nº 528, DE 26/09/2019
                                </a>
                            </span>
                        </v-chip>
                    </v-col>
                    <v-col cols="2" class="d-flex flex-column align-center justify-center text-center">
                        <span>
                            <v-chip large label class="px-2 mx-1 green" text-color="white">
                                <span v-if="avaliacao_consolidada" class="text-h4 font-weight-medium">
                                    {{avaliacao_consolidada.nr_pontos}}
                                </span>
                            </v-chip>
                        </span>
                    </v-col>
                </v-row>
            </v-sheet>
        </v-sheet>
        <v-card color="grey lighten-4" v-if="showCiencia">
            <br/>
            <v-divider></v-divider>
            <br/>
            <v-container class="pa-5">
                <v-row>
                    <v-col cols="12" class="d-flex flex-column align-center justify-center text-center">
                        <h1>Ciência da Avaliação Individual</h1>
                        <p>
                            Em caso de não concordância com o resultado da avaliação individual, estou ciente 
                            de que disponho de 10 (dez) dias úteis, <strong>de {{inicio_reconsideracao_semestral}} 
                            à {{fim_reconsideracao_semestral}}</strong>, para apresentar pedido 
                            de reconsideação dirigido a minha chefia imediata, por meio do sistema AvaliaMGI, 
                            clicando na opção "Não concordo com a avaliação".
                        </p>
                        <p>
                            Para o servidor que obteve a pontuação máxima na avaliação individual (20 pontos), 
                            não estará disponível o pedido de reconsideração.
                        </p>
                    </v-col>
                </v-row>
            </v-container>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-col cols="12" class="d-flex flex-column align-center justify-center">
                    <div 
                        v-if="avaliacao_consolidada && avaliacao_consolidada.st_de_acordo === null" 
                        class="d-flex flex-row align-center justify-center"
                    >
                        <v-btn 
                            class="mx-3" 
                            color="primary lighten-1" 
                            @click="deAcordoAvaliacao()"
                        > 
                            Concordo com a avaliação
                        </v-btn>
                        <v-btn 
                            v-if="avaliacao_consolidada && avaliacao_consolidada.nr_pontos < 20" 
                            class="mx-3" 
                            color="grey darken-1" 
                            @click="discordanciaAvaliacao()" 
                            dark
                        > 
                            Não concordo com a avaliação 
                        </v-btn>
                    </div>
                    <div v-else>
                        <div v-if="avaliacao_consolidada && avaliacao_consolidada.st_de_acordo">
                            Servidor concordou com o resultado da Avaliação de Desempenho Individual em 
                            {{ avaliacao_consolidada.dt_ciencia | moment("DD/MM/YYYY HH:mm") }}.
                        </div>
                        <div 
                            v-if="avaliacao_consolidada && avaliacao_consolidada.st_de_acordo === false" 
                            class="d-flex flex-column align-center justify-center"
                        >
                            <p>
                                Servidor discordou do resultado da Avaliação de Desempenho Individual em 
                                {{ avaliacao_consolidada.dt_ciencia | moment("DD/MM/YYYY HH:mm") }}.
                            </p>
                            <v-dialog 
                                v-if="avaliacao_consolidada && !avaliacao_consolidada.reconsideracao" 
                                transition="dialog-top-transition" 
                                max-width="1000" 
                                scrollable 
                                persistent
                            >
                                <template v-slot:activator="{ on, attrs }">

                                    <v-sheet class="d-flex flex-column grey lighten-4">
                                        <v-btn 
                                            v-if="avaliacao_consolidada.nr_pontos < 20" 
                                            class="mx-3" 
                                            color="success darken-1" 
                                            @click="resetJustificativasReconsideracao()" 
                                            dark 
                                            v-bind="attrs" 
                                            v-on="on"
                                        > 
                                            Solicitar reconsideração
                                        </v-btn>
                                    </v-sheet>
                                    
                                </template>
                                <template v-slot:default="dialog">
                                    <v-card>
                                        <v-card-title class="primary">
                                            <span class="text-h6 white--text">Pedido de Reconsideração</span>
                                        </v-card-title>
                                        <v-card-text style="height: 80%">
                                        <br>
                                            <h3>
                                                Para cada fator que desejar reconsideração, clique no botão 
                                                "Solicitar Reconsideração do Fator" e insira a justificativa.
                                            </h3>
                                            <v-sheet 
                                                class="d-flex flex-column align-center white lighten-3 ml-n5 mt-5"
                                            >
                                                <v-sheet 
                                                    v-for="item in fatores_reconsideracao" 
                                                    :key="item.id_fator" 
                                                    width="100%"
                                                    max-width="900"
                                                >
                                                    <v-hover v-slot="{ hover }">
                                                        <v-card 
                                                            class="ma-3 pt-5 pr-4 mb-4 rounded-lg grey lighten-4"
                                                            width="100%" 
                                                            max-width="900" 
                                                            :elevation="hover ? 8 : 3"
                                                        >
                                                            <v-row dense class="px-4">
                                                                <v-col cols="3">
                                                                    <v-subheader 
                                                                        class="font-weight-medium"
                                                                    >
                                                                        Fator
                                                                    </v-subheader>
                                                                </v-col>
                                                                <v-col cols="9">
                                                                    <v-text-field 
                                                                        dense 
                                                                        v-model="item.nm_fator" 
                                                                        hide-details="auto" 
                                                                        outlined 
                                                                        readonly />
                                                                </v-col>
                                                            </v-row>
                                                            <v-row dense class="px-4">
                                                                <v-col cols="3">
                                                                    <v-subheader class="font-weight-medium">
                                                                        Descrição
                                                                    </v-subheader>
                                                                </v-col>
                                                                <v-col cols="9">
                                                                    <v-textarea 
                                                                        dense 
                                                                        v-model="item.ds_fator" 
                                                                        hide-details="auto" 
                                                                        outlined 
                                                                        readonly 
                                                                        rows="3" />
                                                                </v-col>
                                                            </v-row>
                                                            <v-row 
                                                                v-if="item.justificativaVisible" 
                                                                dense 
                                                                class="px-4"
                                                            >
                                                                <v-col cols="3">
                                                                    <v-subheader class="font-weight-medium">
                                                                        Justificativa
                                                                    </v-subheader>
                                                                </v-col>
                                                                <v-col cols="9">
                                                                    <v-textarea 
                                                                        class="white" 
                                                                        dense 
                                                                        v-model="item.justificativa" 
                                                                        hide-details="auto" 
                                                                        outlined 
                                                                        rows="3" />
                                                                </v-col>
                                                            </v-row>
                                                            <v-row dense class="px-5 mt-1 mb-3 grey-lighten-4">
                                                                <v-sheet 
                                                                    class="d-flex justify-space-between elevation-0 grey lighten-4" 
                                                                    width="100%"
                                                                >
                                                                    <v-sheet 
                                                                        class="d-flex flex-row grey lighten-4"
                                                                    ></v-sheet>
                                                                    <v-sheet 
                                                                        class="d-flex flex-row mb-5 grey lighten-4"
                                                                    >
                                                                        <v-btn 
                                                                            v-if="!item.justificativaVisible" 
                                                                            color="primary lighten-2 mx-5 mt-4 elevation-2 pa-2" 
                                                                            @click="inserirJustificativa(item.id_fator)" 
                                                                            :disabled="item.justificativaVisible"
                                                                        >
                                                                            Solicitar Reconsideração do Fator
                                                                        </v-btn>
                                                                        <v-btn 
                                                                            v-if="item.justificativaVisible" 
                                                                            dark 
                                                                            color="red lighten-2 mx-5 mt-4 elevation-2 pa-2" 
                                                                            @click="removerJustificativa(item.id_fator)" 
                                                                            :disabled="!item.justificativaVisible"
                                                                        >
                                                                            Cancelar Solicitação
                                                                        </v-btn>
                                                                    </v-sheet>
                                                                </v-sheet>
                                                            </v-row>
                                                        </v-card>
                                                    </v-hover>
                                                </v-sheet>
                                            </v-sheet>
                                        </v-card-text>
                                        <v-divider class="ma-0"></v-divider>

                                        <v-card-actions class="justify-end">
                                            <v-btn text @click="dialog.value = false">Cancelar</v-btn>
                                            <v-btn
                                                :class="(enviarPedidoReconsideracaoButtonEnabled) 
                                                    ? 'success darken-1' 
                                                    : 'grey lighten-2'" 
                                                text 
                                                @click="postReconsideracao();dialog.value = false;" 
                                                :disabled="!enviarPedidoReconsideracaoButtonEnabled"
                                            >
                                                Enviar pedido de reconsideração
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </template>
                            </v-dialog>
                        </div>
                    </div>
                </v-col>
            </v-card-actions>
        </v-card>
        <div v-if="avaliacao_consolidada && avaliacao_consolidada.reconsideracao">
            <br/>
            <v-divider />
            <br/>
            <v-card color="white lighten-4 elevation-6" >
                <v-container class="pa-5">
                    <v-row>
                        <v-col cols="12" class="d-flex flex-column align-center justify-center text-center">
                            <h1>Pedido de Reconsideração</h1>
                            <p>
                                Pedido de reconsideração enviado em 
                                {{avaliacao_consolidada.reconsideracao.created_at | moment("DD/MM/YYYY HH:mm")}} 
                                para a Chefia Imediata
                            </p>
                            <v-sheet 
                                class="d-flex flex-column align-center white lighten-3 ml-n5 mt-5" 
                                width="90%"
                            >
                                <v-sheet 
                                    v-for="item in avaliacao_consolidada.reconsideracao.fatores" 
                                    :key="item.id_fator" 
                                    width="100%" 
                                    max-width="900"
                                >
                                    <v-hover v-slot="{ hover }">
                                        <v-card 
                                            class="ma-3 pt-5 pr-4 mb-4 rounded-lg grey lighten-4" 
                                            width="100%" 
                                            max-width="900" 
                                            :elevation="hover ? 8 : 3"
                                        >
                                            <v-row dense class="px-4">
                                                <v-col cols="3">
                                                    <v-subheader class="font-weight-medium">
                                                        Fator
                                                    </v-subheader>
                                                </v-col>
                                                <v-col cols="9">
                                                    <v-text-field 
                                                        dense 
                                                        v-model="item.nm_fator" 
                                                        hide-details="auto" 
                                                        outlined 
                                                        readonly />
                                                </v-col>
                                            </v-row>
                                            <v-row dense class="px-4">
                                                <v-col cols="3">
                                                    <v-subheader class="font-weight-medium">
                                                        Descrição
                                                    </v-subheader>
                                                </v-col>
                                                <v-col cols="9">
                                                    <v-textarea 
                                                        dense 
                                                        v-model="item.ds_fator" 
                                                        hide-details="auto" 
                                                        outlined 
                                                        readonly 
                                                        rows="3" />
                                                </v-col>
                                            </v-row>
                                            <v-row dense class="px-4">
                                                <v-col cols="3">
                                                    <v-subheader class="font-weight-medium">
                                                        Justificativa
                                                    </v-subheader>
                                                </v-col>
                                                <v-col cols="9">
                                                    <v-textarea 
                                                        dense 
                                                        v-model="item.pivot.tx_justificativa" 
                                                        hide-details="auto" 
                                                        outlined 
                                                        readonly 
                                                        rows="3" />
                                                </v-col>
                                            </v-row>
                                            <br>
                                            <div v-if="reconsideracaoHasParecerChefia">
                                                <br>
                                                <v-divider />
                                                <br>
                                                <v-row dense class="px-4">
                                                    <v-col cols="3">
                                                        <v-subheader class="font-weight-medium">
                                                            Situação
                                                        </v-subheader>
                                                    </v-col>
                                                    <v-col cols="9">
                                                        <v-subheader class="font-weight-bold">
                                                            <h3>{{item.in_parecer_chefia_radio_value}}</h3>
                                                        </v-subheader>
                                                    </v-col>
                                                </v-row>
                                                <v-row dense class="px-4">
                                                    <v-col cols="3">
                                                        <v-subheader class="font-weight-medium">
                                                            Parecer da Chefia Imediata
                                                        </v-subheader>
                                                    </v-col>
                                                    <v-col cols="9">
                                                        <v-textarea 
                                                            readonly 
                                                            class="white" 
                                                            dense 
                                                            v-model="item.pivot.tx_parecer_chefia" 
                                                            hide-details="auto" 
                                                            outlined 
                                                            rows="3" />
                                                    </v-col>
                                                </v-row>
                                                <br>
                                            </div>
                                        </v-card>
                                    </v-hover>
                                </v-sheet>
                            </v-sheet>
                        </v-col>
                    </v-row>
                    <v-sheet 
                        v-if="reconsideracaoHasParecerChefia" 
                        class="pa-3 ma-5 d-flex flex-row justify-center align-center elevation-0" 
                        width="100%"
                    >
                        <v-sheet 
                            class="pa-3 ma-5 align-self-center elevation-6 rounded-lg grey lighten-5" 
                            width="70%"
                        >
                            <v-row class="my-1">
                                <v-col 
                                    cols="10" 
                                    class="d-flex flex-column align-end textjustify-center text-center"
                                >
                                    <span><b>Resultado da Avaliação Individual APÓS RECONSIDERAÇÃO</b></span>
                                    <small>(considera apenas uma casa decimal)</small>
                                </v-col>
                                <v-col 
                                    cols="2" 
                                    class="d-flex flex-column align-center justify-center text-center"
                                >
                                    <span>
                                        <v-chip label class="pa-2 mx-1 green" text-color="white">
                                            <span class="text-h6 font-weight-medium">
                                                {{avaliacao_consolidada.reconsideracao.va_nota}}
                                            </span>
                                        </v-chip>
                                    </span>
                                </v-col>
                            </v-row>

                            <v-divider></v-divider>
                            <v-row class="my-1">
                                <v-col cols="10" class="d-flex flex-column align-end justify-end text-center">
                                    <span class="text-h6">
                                        <b>Conversão da nota em pontos APÓS RECONSIDERAÇÃO</b>
                                    </span>
                                    <v-chip small label class="mb-0 mx-0 blue lighten-5" text-color="white">
                                        <span class="font-weight-medium">
                                            <a 
                                                href="https://www.in.gov.br/en/web/dou/-/portaria-n-528-de-26-de-setembro-de-2019-218534988" 
                                                target="_blank"
                                            >
                                                Anexo III - PORTARIA GM/ME Nº 528, DE 26/09/2019
                                            </a>
                                        </span>
                                    </v-chip>
                                </v-col>
                                <v-col 
                                    cols="2" 
                                    class="d-flex flex-column align-center justify-center text-center"
                                >
                                    <span>
                                        <v-chip large label class="px-2 mx-1 green" text-color="white">
                                            <span class="text-h4 font-weight-medium">
                                                {{avaliacao_consolidada.reconsideracao.nr_pontos}}
                                            </span>
                                        </v-chip>
                                    </span>
                                </v-col>
                            </v-row>
                        </v-sheet>
                    </v-sheet>
                    <div 
                        v-if="!reconsideracaoHasParecerChefia" 
                        class="d-flex flex-row align-center justify-center my-4"
                    >
                        <v-sheet 
                            cols="12" 
                            class="d-flex flex-column align-center justify-center text-center my-5"
                        >
                            <div>
                                <v-alert dense outlined type="info">
                                    <span>Pedido de reconsideração aguardando parecer da Chefia Imediata.</span>
                                </v-alert>
                            </div>
                        </v-sheet>
                    </div>
                    <div v-else>
                        <div v-if="!avaliacao_consolidada.reconsideracao.dt_ciencia_servidor">
                            <v-sheet 
                                cols="12" 
                                class="d-flex flex-row align-center justify-center text-center my-5"
                            >
                                <v-btn 
                                    v-if="usuario_avaliado"
                                    class="mx-3" 
                                    color="primary lighten-1" 
                                    @click="deAcordoReconsideracao()"
                                > 
                                    Concordo com a reconsideração
                                </v-btn>
                                <v-btn 
                                    v-if="avaliacao_consolidada.nr_pontos < 20 && usuario_avaliado"
                                    class="mx-3"
                                    color="grey darken-1"
                                    @click="discordanciaReconsideracao()"
                                    dark
                                > 
                                    Não concordo com a Reconsideração
                                </v-btn>
                            </v-sheet>
                        </div>
                        <div v-else>		
                            <v-sheet 
                                cols="12" 
                                class="d-flex flex-row align-center justify-center text-center my-5"
                            >
                                <div v-if="avaliacao_consolidada.reconsideracao.st_de_acordo_servidor">
                                    Servidor concordou com o resultado da Reconsideração Avaliação de 
                                    Desempenho Individual em 
                                    {{ 
                                        avaliacao_consolidada.reconsideracao.dt_ciencia_servidor 
                                        | moment("DD/MM/YYYY HH:mm") 
                                    }}.
                                </div>
                                <div v-else>
                                    Servidor não concordou com o resultado da Reconsideração Avaliação de 
                                    Desempenho Individual em <br>
                                    {{ 
                                        avaliacao_consolidada.reconsideracao.dt_ciencia_servidor 
                                        | moment("DD/MM/YYYY HH:mm") 
                                    }}.
                                    <br><br>
                                    Servidor dispõe de 10 (dez) dias úteis a partir da disponibilização do 
                                    resultado do pedido de reconsideração para apresentar pedido de recurso 
                                    dirigido à
                                    <strong>
                                        Comissão de Acompanhamento da Avaliação de Desempenho – CAD
                                    </strong>  
                                    no DF, ou à 
                                    <strong>
                                        Subcomissão de Acompanhamento da Avaliação de Desempenho – SAD
                                    </strong>
                                    , no respectivo estado.
                                    <br><br>
                                    <div v-if="hasRegistroRecurso">
                                        Servidor registrou recurso {{dt_recurso | moment("DD/MM/YYYY HH:mm")}}.
                                        <br><br>
                                        <v-btn
                                            class="mx-3" 
                                            color="primary"
                                            @click="showDialogRecurso()"
                                        > 
                                            Visualizar registro de Recurso
                                        </v-btn>
                                        <br>

                                        <v-sheet 
                                            v-if="has_nota_recurso" 
                                            class="pa-3 ma-5 d-flex flex-row justify-center align-center elevation-0" 
                                            width="100%"
                                        >
                                            <v-sheet 
                                                class="pa-3 ma-5 align-self-center elevation-6 rounded-lg grey lighten-5" 
                                                width="70%"
                                            >
                                                <v-row class="my-1">
                                                    <v-col 
                                                        cols="10" 
                                                        class="d-flex flex-column align-end textjustify-center text-center"
                                                    >
                                                        <span><b>Resultado da Avaliação Individual APÓS RECURSO</b></span>
                                                        <small>(considera apenas uma casa decimal)</small>
                                                    </v-col>
                                                    <v-col 
                                                        cols="2" 
                                                        class="d-flex flex-column align-center justify-center text-center"
                                                    >
                                                        <span>
                                                            <v-chip label class="pa-2 mx-1 green" text-color="white">
                                                                <span class="text-h6 font-weight-medium">
                                                                    {{recurso_va_nota}}
                                                                </span>
                                                            </v-chip>
                                                        </span>
                                                    </v-col>
                                                </v-row>

                                                <v-divider></v-divider>
                                                <v-row class="my-1">
                                                    <v-col cols="10" class="d-flex flex-column align-end justify-end text-center">
                                                        <span class="text-h6">
                                                            <b>Conversão da nota em pontos APÓS RECURSO</b>
                                                        </span>
                                                        <v-chip small label class="mb-0 mx-0 blue lighten-5" text-color="white">
                                                            <span class="font-weight-medium">
                                                                <a 
                                                                    href="https://www.in.gov.br/en/web/dou/-/portaria-n-528-de-26-de-setembro-de-2019-218534988" 
                                                                    target="_blank"
                                                                >
                                                                    Anexo III - PORTARIA GM/ME Nº 528, DE 26/09/2019
                                                                </a>
                                                            </span>
                                                        </v-chip>
                                                    </v-col>
                                                    <v-col 
                                                        cols="2" 
                                                        class="d-flex flex-column align-center justify-center text-center"
                                                    >
                                                        <span>
                                                            <v-chip large label class="px-2 mx-1 green" text-color="white">
                                                                <span class="text-h4 font-weight-medium">
                                                                    {{recurso_nr_pontos}}
                                                                </span>
                                                            </v-chip>
                                                        </span>
                                                    </v-col>
                                                </v-row>
                                            </v-sheet>
                                        </v-sheet>
                                        <br>
                                        <v-divider></v-divider>
                                        <br>
                                        <v-card color="grey lighten-4" v-if="has_nota_recurso">
                                            <v-container class="pa-5">
                                                <v-row>
                                                    <v-col cols="12" class="d-flex flex-column align-center justify-center text-center">
                                                        <h1>Ciência da Decisão do Recurso</h1>
                                                        <p>
                                                            Servidor dispõe de 10 (dez) dias úteis a partir da decisão do 
                                                            recurso para manifestar ciência.
                                                        </p>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                            <v-divider></v-divider>
                                            <v-card-actions>
                                                <v-spacer></v-spacer>
                                                <v-col cols="12" class="d-flex flex-column align-center justify-center">
                                                    <div 
                                                        v-if="has_nota_recurso" 
                                                        class="d-flex flex-row align-center justify-center"
                                                    >
                                                        <v-btn 
                                                            v-if="hasCienciaRecurso == false && cienciaRecursoExpirado== false"
                                                            class="mx-3" 
                                                            color="primary lighten-1" 
                                                            @click="manifestarCienciaRecurso()"
                                                        > 
                                                            Ciente
                                                        </v-btn>
                                                        <div v-else>
                                                            <div v-if="dt_ciencia_servidor_recurso">
                                                                Servidor manifestou ciência em 
                                                                {{ 
                                                                    dt_ciencia_servidor_recurso 
                                                                    | moment("DD/MM/YYYY HH:mm") 
                                                                }}.
                                                            </div>
                                                            <div v-else>
                                                                <div v-if="cienciaRecursoExpirado">
                                                                    Servidor não manifestou ciência no prazo regulamentar (10 dias úteis)
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </v-col>
                                            </v-card-actions>
                                        </v-card>
                                    </div>
                                    <div v-else>
                                        <v-btn
                                            v-if="showRecurso && usuario_avaliado"
                                            class="mx-3" 
                                            color="error"
                                            @click="showDialogRecurso()"
                                        > 
                                            Registrar Recurso
                                        </v-btn>
                                    </div>
                                </div>
                            </v-sheet>
                        </div>	
                    </div>	




                    <div v-if="!reconsideracaoHasParecerChefia && avaliacao_consolidada.reconsideracao.st_liberado_para_recurso">
                        <div>		
                            <v-sheet 
                                cols="12" 
                                class="d-flex flex-row align-center justify-center text-center my-5"
                            >
                                <div>
                                    Servidor liberado para solicitar recurso.<br>
                                    {{ 
                                        avaliacao_consolidada.reconsideracao.dt_ciencia_servidor 
                                        | moment("DD/MM/YYYY HH:mm") 
                                    }}.
                                    <br><br>
                                    Servidor dispõe de 10 (dez) dias úteis a partir da disponibilização do 
                                    resultado do pedido de reconsideração para apresentar pedido de recurso 
                                    dirigido à
                                    <strong>
                                        Comissão de Acompanhamento da Avaliação de Desempenho – CAD
                                    </strong>  
                                    no DF, ou à 
                                    <strong>
                                        Subcomissão de Acompanhamento da Avaliação de Desempenho – SAD
                                    </strong>
                                    , no respectivo estado.
                                    <br><br>
                                    <div v-if="hasRegistroRecurso">
                                        Servidor registrou recurso {{dt_recurso | moment("DD/MM/YYYY HH:mm")}}.
                                        <br><br>
                                        <v-btn
                                            class="mx-3" 
                                            color="primary"
                                            @click="showDialogRecurso()"
                                        > 
                                            Visualizar registro de Recurso
                                        </v-btn>
                                        <br>

                                        <v-sheet 
                                            v-if="has_nota_recurso" 
                                            class="pa-3 ma-5 d-flex flex-row justify-center align-center elevation-0" 
                                            width="100%"
                                        >
                                            <v-sheet 
                                                class="pa-3 ma-5 align-self-center elevation-6 rounded-lg grey lighten-5" 
                                                width="70%"
                                            >
                                                <v-row class="my-1">
                                                    <v-col 
                                                        cols="10" 
                                                        class="d-flex flex-column align-end textjustify-center text-center"
                                                    >
                                                        <span><b>Resultado da Avaliação Individual APÓS RECURSO</b></span>
                                                        <small>(considera apenas uma casa decimal)</small>
                                                    </v-col>
                                                    <v-col 
                                                        cols="2" 
                                                        class="d-flex flex-column align-center justify-center text-center"
                                                    >
                                                        <span>
                                                            <v-chip label class="pa-2 mx-1 green" text-color="white">
                                                                <span class="text-h6 font-weight-medium">
                                                                    {{recurso_va_nota}}
                                                                </span>
                                                            </v-chip>
                                                        </span>
                                                    </v-col>
                                                </v-row>

                                                <v-divider></v-divider>
                                                <v-row class="my-1">
                                                    <v-col cols="10" class="d-flex flex-column align-end justify-end text-center">
                                                        <span class="text-h6">
                                                            <b>Conversão da nota em pontos APÓS RECURSO</b>
                                                        </span>
                                                        <v-chip small label class="mb-0 mx-0 blue lighten-5" text-color="white">
                                                            <span class="font-weight-medium">
                                                                <a 
                                                                    href="https://www.in.gov.br/en/web/dou/-/portaria-n-528-de-26-de-setembro-de-2019-218534988" 
                                                                    target="_blank"
                                                                >
                                                                    Anexo III - PORTARIA GM/ME Nº 528, DE 26/09/2019
                                                                </a>
                                                            </span>
                                                        </v-chip>
                                                    </v-col>
                                                    <v-col 
                                                        cols="2" 
                                                        class="d-flex flex-column align-center justify-center text-center"
                                                    >
                                                        <span>
                                                            <v-chip large label class="px-2 mx-1 green" text-color="white">
                                                                <span class="text-h4 font-weight-medium">
                                                                    {{recurso_nr_pontos}}
                                                                </span>
                                                            </v-chip>
                                                        </span>
                                                    </v-col>
                                                </v-row>
                                            </v-sheet>
                                        </v-sheet>
                                        <br>
                                        <v-divider></v-divider>
                                        <br>
                                        <v-card color="grey lighten-4" v-if="has_nota_recurso">
                                            <v-container class="pa-5">
                                                <v-row>
                                                    <v-col cols="12" class="d-flex flex-column align-center justify-center text-center">
                                                        <h1>Ciência da Decisão do Recurso</h1>
                                                        <p>
                                                            Servidor dispõe de 10 (dez) dias úteis a partir da decisão do 
                                                            recurso para manifestar ciência.
                                                        </p>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                            <v-divider></v-divider>
                                            <v-card-actions>
                                                <v-spacer></v-spacer>
                                                <v-col cols="12" class="d-flex flex-column align-center justify-center">
                                                    <div 
                                                        v-if="has_nota_recurso" 
                                                        class="d-flex flex-row align-center justify-center"
                                                    >
                                                        <v-btn 
                                                            v-if="hasCienciaRecurso == false && cienciaRecursoExpirado== false"
                                                            class="mx-3" 
                                                            color="primary lighten-1" 
                                                            @click="manifestarCienciaRecurso()"
                                                        > 
                                                            Ciente
                                                        </v-btn>
                                                        <div v-else>
                                                            <div v-if="dt_ciencia_servidor_recurso">
                                                                Servidor manifestou ciência em 
                                                                {{ 
                                                                    dt_ciencia_servidor_recurso 
                                                                    | moment("DD/MM/YYYY HH:mm") 
                                                                }}.
                                                            </div>
                                                            <div v-else>
                                                                <div v-if="cienciaRecursoExpirado">
                                                                    Servidor não manifestou ciência no prazo regulamentar (10 dias úteis)
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </v-col>
                                            </v-card-actions>
                                        </v-card>
                                    </div>
                                    <div v-else>
                                        <v-btn
                                            v-if="showRecurso && usuario_avaliado"
                                            class="mx-3" 
                                            color="error"
                                            @click="showDialogRecurso()"
                                        > 
                                            Registrar Recurso
                                        </v-btn>
                                    </div>
                                </div>
                            </v-sheet>
                        </div>	
                    </div>	
                </v-container>
            </v-card>
        </div>
        <div v-else>
            <v-row v-if="avaliacaoColetada && avaliacao_consolidada === null" dense class="mx-5 mt-3 elevation-0">
                <v-col cols="12">
                    <v-alert dense outlined type="info" class="mb-5">
                        <span style="white-space: pre-line">
                            Não foi possível emitir o resultado de sua avaliação de desempenho individual. 
                            Possível ocorrência: 
                            - ausência da avaliação da chefia
                            - afastamento por mais de 2/3 do ciclo
                            - decisão judicial 
                            - servidor não faz jus à GD da Portaria n 528/2019
                        </span>
                    </v-alert>
                </v-col>
            </v-row>
        </div>
    </div>
    <v-dialog v-model="dialogReconsideracao" width="60%" :fullscreen="$vuetify.breakpoint.xsOnly">
        <v-card>
            <v-card color="primary" dark class="mx-auto">
                <v-card-title>
                    <span class="text-h6 font-weight-light"><b>Reconsideração</b></span>
                </v-card-title>
            </v-card>
            <br>
            <v-divider></v-divider>
            <v-row dense class="mx-5 mt-3 elevation-0">
                <v-col cols="12">
                    <v-alert dense outlined type="info" class="mb-5">
                        <span v-html="alertInfoMessage_alteracaoEmailTeleone_ambitoAvalia"></span>
                    </v-alert>
                </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn class="ma-2" color="" @click="dialogReconsideracao = false"> Fechar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <v-dialog v-model="dialogSuccess" width="570" >
        <v-card>
            <v-card-title class="text-h5 text-center">
                <v-alert prominent type="success" width="100%">
                    <v-row align="center">
                        <v-col class="grow">
                            Feito com sucesso.
                        </v-col>
                        <v-col class="shrink">
                        <v-btn outlined  @click="redirectSuccess()">Ok</v-btn>
                        </v-col>
                    </v-row>
                </v-alert>
            </v-card-title> 
        </v-card>
    </v-dialog>
    <v-dialog v-model="dialogRecurso" width="70%" :fullscreen="$vuetify.breakpoint.xsOnly">
        <v-card>
            <v-card color="primary" dark class="mx-auto">
                <v-card-title>
                    <v-icon large left> mdi-gavel </v-icon>
                    <span v-if="hasRegistroRecurso" class="text-h6 font-weight-light">
                        Visualizar Registro de Recurso
                    </span>
                    <span v-else class="text-h6 font-weight-light">
                        Registrar Recurso
                    </span>
                </v-card-title>
            </v-card>
            <br>
            <v-container>
                <h3 v-if="hasRegistroRecurso" class="text-center font-weight-light primary--text">
                    Visualizar Registro de Recurso
                </h3>
                <h3 v-else class="text-center font-weight-light primary--text">
                    Registrar Recurso
                </h3>
                
                <p class="text-center" v-if="hasRegistroRecurso == false">
                    Por favor, insira as razões explicando o motivo desta ação.<br>
                    Após escolha de cada anexo é preciso clicar em 'ADICIONAR' antes de salvar o registro de recurso.<br>
                </p>
                <v-row dense class="ma-0">
                    <v-col cols="12" class="px-3">
                        <v-textarea
                            v-model="justificativa"
                            id="justificativaRecurso"
                            outlined
                            counter
                            rows="2"
                            required
                            :disabled="hasRegistroRecurso"
                            :rules="[v => v.length <= 2000 || 'Maximo 2000 caracteres']"
                            name="justificativaRecurso"
                            label="Razão do recurso"
                        ></v-textarea>
                    </v-col>      
                </v-row>
                <template>
                    <v-card class="mx-3">
                        <v-card-title>
                            <v-row class="mt-3 mx-3">
                                Anexos
                                <v-spacer></v-spacer>
                                <v-file-input 
                                    v-if="hasRegistroRecurso == false"
                                    :rules="rulesAnexo"
                                    accept=".png, .jpeg, .pdf, .doc, .xlsx, .xls"
                                    id="anexo" 
                                    ref="anexo" 
                                    v-model="anexo" 
                                    label="Anexo" 
                                    outlined
                                    height="40px"
                                    dense
                                ></v-file-input>
                                <v-btn 
                                    v-if="hasRegistroRecurso == false"
                                    color="primary" 
                                    height="40px" 
                                    @click="addAnexo()"
                                >
                                    Adicionar
                                </v-btn>
                            </v-row>
                        </v-card-title>
                        <v-data-table
                            :headers="headers"
                            :items="anexosRecurso"
                        >
                            <template v-slot:[`item.acao`]="{ item }">
                                <v-row>
                                    <v-tooltip top >
                                        <template v-slot:activator="{on, attrs}">
                                            <v-btn 
                                                rounded 
                                                icon  
                                                class="mr-2" 
                                                id="btnDownload" 
                                                @click="download(item.arquivo.id_arquivo, item.arquivo.nm_arquivo)"
                                                small 
                                                :color="'primary'" 
                                                v-bind="attrs" 
                                                v-on="on"
                                            >
                                                <v-icon> mdi-download </v-icon>
                                            </v-btn>
                                        </template>
                                        <span>DOWNLOAD</span>
                                    </v-tooltip>

                                    <v-tooltip top >
                                        <template v-slot:activator="{on, attrs}">
                                            <v-btn 
                                                v-if="hasRegistroRecurso == false"
                                                rounded 
                                                icon  
                                                class="mr-2" 
                                                id="btnremover" 
                                                @click="removerAnexo(item.arquivo.id_arquivo)"
                                                small
                                                :color="'error'" 
                                                v-bind="attrs" 
                                                v-on="on"
                                            >
                                                <v-icon> mdi-delete-forever </v-icon>
                                            </v-btn>
                                        </template>
                                        <span>REMOVER</span>
                                    </v-tooltip>
                                </v-row>
                            </template>    
                        </v-data-table>
                    </v-card>
                </template>
                <br><br>
                <template>
                    <v-card class="mx-3">
                        <v-card-title>
                            <v-row class="mt-3 mx-3">
                                Documentos anexados após o registro de recurso
                                <v-spacer></v-spacer>
                            </v-row>
                        </v-card-title>
                        <v-data-table
                            :headers="headersAnexosAposRegistro"
                            :items="anexosRecursoAposRegistro"
                        >
                            <template v-slot:[`item.acao`]="{ item }">
                                <v-row>
                                    <v-tooltip top >
                                        <template v-slot:activator="{on, attrs}">
                                            <v-btn 
                                                rounded 
                                                icon  
                                                class="mr-2" 
                                                id="btnDownload" 
                                                @click="download(item.arquivo.id_arquivo, item.arquivo.nm_arquivo)"
                                                small 
                                                :color="'primary'" 
                                                v-bind="attrs" 
                                                v-on="on"
                                            >
                                                <v-icon> mdi-download </v-icon>
                                            </v-btn>
                                        </template>
                                        <span>DOWNLOAD</span>
                                    </v-tooltip>

                                    <v-tooltip top >
                                        <template v-slot:activator="{on, attrs}">
                                            <v-btn 
                                                v-if="hasRegistroRecurso == false"
                                                rounded 
                                                icon  
                                                class="mr-2" 
                                                id="btnremover" 
                                                @click="removerAnexo(item.arquivo.id_arquivo)"
                                                small
                                                :color="'error'" 
                                                v-bind="attrs" 
                                                v-on="on"
                                            >
                                                <v-icon> mdi-delete-forever </v-icon>
                                            </v-btn>
                                        </template>
                                        <span>REMOVER</span>
                                    </v-tooltip>
                                </v-row>
                            </template>    
                        </v-data-table>
                    </v-card>
                </template>
            </v-container>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn 
                    color="grey darken-1" 
                    text 
                    @click="dialogRecurso = false"
                >
                    Fechar
                </v-btn>
                <v-btn 
                    v-if="hasRegistroRecurso ==false" 
                    color="primary" 
                    text 
                    @click="salvarRecurso()"
                >
                    Salvar registro de recurso
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <v-dialog v-model="dialogError" width="570" >
        <v-card>
            <v-card-title class="text-h5 text-center">
                <v-alert prominent type="warning" outlined width="100%">
                    <v-row align="center">
                        <v-col class="grow">{{msgDialog}}</v-col>
                        <v-col class="shrink">
                        <v-btn color="warning"  @click="dialogError = false">Ok</v-btn>
                        </v-col>
                    </v-row>
                </v-alert>
            </v-card-title> 
        </v-card>
    </v-dialog>
</div>	
</template>

<script>
import { getAvaliacaoConsolidada, updateAvaliacaoConsolidada, getAvaliacaoConsolidadaResultado } from '../api/avaliacao_consolidada';
import { createReconsideracao, updateReconsideracaoFromVisualizacaoServidor } from '../api/reconsideracao';
import { getCiclosVigentes, getCiclo } from '../api/ciclo';
import { adicionarAnexo, getAnexos, downloadAnexoRecurso, removerAnexoRecurso, salvarRegistroRecurso, getRecursoByIdReconsideracao, getAnexosAposRecurso, cienciaRecurso } from '../api/recurso';

import moment from 'moment';

export default {
    name: 'AvaliacoesResultadoSemestral',
    data() {
        return {
            avaliacaoColetada: false,
            avaliacao_consolidada: null,
            fatores: [],
            fatores_equipe: [],
            fatores_reconsideracao: [],
            enviarPedidoReconsideracaoButtonEnabled: false,
            reconsideracaoHasParecerChefia: false,
            dialogReconsideracao: false,
            alertInfoMessage_alteracaoEmailTeleone_ambitoAvalia: '<strong>IMPORTANTE!</strong><br> O pedido de reconsideração estará disponível no período de 03/11/2021 à 17/11/2021. Acesse novamente essa tela no período informado, para iniciar o processo de reconsideração da avaliação de desempenho.',
            now: null,
            valoresAutoAvaliacao: {
                somatorioDosPesos : 0,
                somatorioNotasObtidas : 0
            },
            valoresEquipe: {
                somatorioDosPesos : 0,
                somatorioNotasObtidas : 0,
                resultadoAvaliacao: 0,
            },
            valoresChefia: {
                somatorioDosPesos : 0,
                somatorioNotasObtidas : 0
            },
            showCiencia: true,
            cicloVigente: [],
            onlyNota: false,
            msgOnlyNota: false,
            dt_inicio_reconsideracao:'',
            dt_encerramento_reconsideracao:'',
            dialogSuccess: false,
            dt_inicio_recurso:'',
            dt_encerramento_recurso:'',
            showRecurso: false,
            dialogRecurso: false,
            justificativa:'',
            headers: [
                { text: 'Nome do anexo', value: 'arquivo.nm_arquivo' ,sortable: false},
                { text: 'Ação', value: 'acao' ,sortable: false},
            ],
            anexosRecurso: [],
            anexo:null,
            dialogError: false,
            msgDialog:'',
            hasRegistroRecurso: false,
            dt_recurso: null,
            rulesAnexo: [
                value => !value || value.size < 2000000 || 'Tamanho deve ser menor que 2 MB!',
            ],
            recurso_va_nota: null,
            recurso_nr_pontos: null,
            has_nota_recurso: false,
            usuario_avaliado: false,
            anexosRecursoAposRegistro: [],
            headersAnexosAposRegistro: [
                { text: 'Nome do anexo', value: 'arquivo.nm_arquivo' ,sortable: false},
                { text: 'Usuário Responsável', value: 'servidor.nm_servidor' ,sortable: false},
                { text: 'Ação', value: 'acao' ,sortable: false},
            ],
            dt_decisao: null,
            id_recurso:null,
            hasCienciaRecurso:false,
            dt_ciencia_servidor_recurso: null,
            cienciaRecursoExpirado: false,
            inicio_reconsideracao_semestral: null,
            fim_reconsideracao_semestral: null
        };
    },
    created() {
        // if ( this.$route.params.id ) {
        //     var functionCreated = getAvaliacaoConsolidadaResultado(this.$route.params.id, this.$route.params.tipo);
        // } else if ( this.$route.params.id_equipe_trabalho ) {
        //     var functionCreated = getAvaliacaoConsolidadaResultado(this.$route.params.id_equipe_trabalho);
        // } else {
        //     var functionCreated = getAvaliacaoConsolidada();
        // }

        if ( this.$route.params.idCiclo && this.$route.params.idConsulta ) {
            var functionCreated = getAvaliacaoConsolidadaResultadoRelatorio(this.$route.params.idCiclo, this.$route.params.idConsulta, this.$route.params.sglTipo);
        } else {
            var functionCreated = getAvaliacaoConsolidadaResultado(this.$route.params.idCiclo);
        }

        getCiclosVigentes(this.$store.getters['auth/usuarioAutenticado']).then(response => {
            this.cicloVigente = response.data.data;

            let ultimoCicloVigente = this.cicloVigente[Object.keys(this.cicloVigente).pop()];

            getCiclo(ultimoCicloVigente.id_ciclo).then(response => {

                this.dt_inicio_reconsideracao = response.data.data.fases[4].dt_inicio;
                this.dt_encerramento_reconsideracao = response.data.data.fases[4].dt_encerramento;

                this.dt_inicio_recurso =response.data.data.fases[5].dt_inicio;
                this.dt_encerramento_recurso = response.data.data.fases[5].dt_encerramento;

                var dt_agora = moment().format('YYYY/MM/DD');

                var dt_inicio = moment(this.dt_inicio_reconsideracao, 'DD/MM/YYYY').format('YYYY/MM/DD');
                var dt_encerramento = moment(this.dt_encerramento_reconsideracao, 'DD/MM/YYYY').format('YYYY/MM/DD');

                var encerramento_recurso = moment(this.dt_encerramento_recurso, 'DD/MM/YYYY').format('YYYY/MM/DD');
                
                if (
                    dt_agora >= dt_inicio 
                    && this.$route.name !== 'detalheAvaliacaoServidor'
                ) {
                    this.showCiencia= true;
                } else {
                    this.showCiencia= false;
                }
                
                var fim_recurso_semestral = response.data.data.fases.find(({ id_fase }) => id_fase === 9).dt_encerramento;
                var dt_fim_recurso_semestral = moment(fim_recurso_semestral, 'DD/MM/YYYY').format('YYYY/MM/DD');

                if (
                    dt_agora <= dt_fim_recurso_semestral &&
                    this.$route.name !== 'detalheAvaliacaoServidor'
                ) {
                    this.showRecurso= true;
                } else {
                    this.showRecurso= false;
                }

                this.inicio_reconsideracao_semestral = response.data.data.fases.find(({ id_fase }) => id_fase === 8).dt_inicio;
                this.fim_reconsideracao_semestral = response.data.data.fases.find(({ id_fase }) => id_fase === 8).dt_encerramento;
                
                var dt_inicio_semestral = moment(this.inicio_reconsideracao_semestral, 'DD/MM/YYYY').format('YYYY/MM/DD');
                var dt_fim_semestral = moment(this.fim_reconsideracao_semestral, 'DD/MM/YYYY').format('YYYY/MM/DD');
                
                if (
                    dt_agora >= dt_inicio_semestral && dt_agora <= dt_fim_semestral
                    && this.$route.name !== 'detalheAvaliacaoServidor'
                ) {
                    this.showCiencia= true;
                } else {
                    this.showCiencia= false;
                }
                
            });
        });

        functionCreated.then(response => {
            this.avaliacao_consolidada = response.data.data.semestral;
            this.onlyNota = response.data.data.booOnlyNota;
            this.msgOnlyNota = response.data.data.msgOnlyNota;					
            this.avaliacaoColetada = true;

            if ( !this.avaliacao_consolidada.booOnlyNota ) {
                if (
                    this.avaliacao_consolidada 
                    && this.avaliacao_consolidada.reconsideracao 
                    && this.avaliacao_consolidada.reconsideracao.fatores[0].pivot.in_parecer_chefia
                ) {
                    this.reconsideracaoHasParecerChefia = true;
                    this.avaliacao_consolidada.reconsideracao.fatores.map(fator => {
                        fator.in_parecer_chefia_radio_items = ['Deferido', 'Parcialmente deferido','Indeferido'];
                        fator.in_parecer_chefia_radio_value = '';

                        if (fator.pivot.in_parecer_chefia) {
                            fator.in_parecer_chefia_radio_value = (fator.pivot.in_parecer_chefia === 'D') ?
                                'Deferido': (fator.pivot.in_parecer_chefia === 'P') ?
                                'Parcialmente deferido' : 'Indeferido';
                        } 
                    });
                }

                if (this.avaliacao_consolidada) {
                    this.fatores = this.avaliacao_consolidada.equipe_trabalho.avaliacao_chefia.rdi.fatores;
                    this.fatores_reconsideracao = JSON.parse(JSON.stringify(this.fatores))
                    this.fatores_reconsideracao.map(fator => {
                        fator.justificativa = '';
                        fator.justificativaVisible = false;
                    })
                    
                    if (this.avaliacao_consolidada.equipe_trabalho.avaliacao_auto) {
                        this.avaliacao_consolidada.equipe_trabalho.avaliacao_auto.fatores.map(fator =>{
                            const fatorBase = this.fatores.filter(f => f.id_fator === fator.id_fator)[0]
                            fator.pivot.nr_peso = fatorBase.pivot.nr_peso
                            this.valoresAutoAvaliacao.somatorioDosPesos += fator.pivot.nr_peso
                            this.valoresAutoAvaliacao.somatorioNotasObtidas += fator.pivot.nr_peso*fator.pivot.nr_nota
                            fator.pivot.nr_nota = this.round(fator.pivot.nr_nota,3)
                        })
                        this.valoresEquipe.somatorioNotasObtidas = this.round(
                            this.valoresEquipe.somatorioNotasObtidas,
                            3
                        );
                    }

                    if (this.avaliacao_consolidada.fatores_equipe) {
                        this.avaliacao_consolidada.fatores_equipe.map(fator =>{
                            const fatorBase = this.fatores.filter(f => f.id_fator === fator.id_fator)[0]
                            fator.pivot.nr_peso = fatorBase.pivot.nr_peso
                            this.valoresEquipe.somatorioDosPesos += fator.pivot.nr_peso
                            this.valoresEquipe.somatorioNotasObtidas += fator.pivot.nr_peso*fator.pivot.va_nota
                            fator.pivot.va_nota = this.round(fator.pivot.va_nota,3)
                        })
                        this.valoresEquipe.resultadoAvaliacao = this.valoresEquipe.somatorioNotasObtidas/this.valoresEquipe.somatorioDosPesos
                        this.valoresEquipe.resultadoAvaliacao = this.round(
                            this.valoresEquipe.resultadoAvaliacao,
                            3
                        )
                        this.valoresEquipe.somatorioNotasObtidas = this.round(
                            this.valoresEquipe.somatorioNotasObtidas,
                            3
                        )
                    }

                    if (this.avaliacao_consolidada.equipe_trabalho.avaliacao_chefia) {
                        this.avaliacao_consolidada.equipe_trabalho.avaliacao_chefia.fatores.map(fator =>{
                            const fatorBase = this.fatores.filter(f => f.id_fator === fator.id_fator)[0]
                            fator.pivot.nr_peso = fatorBase.pivot.nr_peso
                            this.valoresChefia.somatorioDosPesos += fator.pivot.nr_peso
                            this.valoresChefia.somatorioNotasObtidas += fator.pivot.nr_peso*fator.pivot.nr_nota
                            fator.pivot.nr_nota = this.round(fator.pivot.nr_nota,3)
                        })
                        this.valoresChefia.somatorioNotasObtidas = this.round(
                            this.valoresChefia.somatorioNotasObtidas,
                            3
                        )
                    }
                    if(!this.avaliacao_consolidada.dt_visualizacao) {
                        updateAvaliacaoConsolidada(
                            this.avaliacao_consolidada.id_avaliacao_consolidada, 
                            this.avaliacao_consolidada
                        );
                    }
                    this.roundEverything();
                }
            }

            var idServidorAvaliado = this.avaliacao_consolidada.equipe_trabalho.id_avaliado ? this.avaliacao_consolidada.equipe_trabalho.id_avaliado : this.avaliacao_consolidada.equipe_trabalho.id_servidor;

            if (this.$store.getters['auth/usuarioAutenticado'] == idServidorAvaliado) {
                this.usuario_avaliado = true;
            } else {
                this.usuario_avaliado = false;
            }

            if (
                this.avaliacao_consolidada.reconsideracao 
                && this.avaliacao_consolidada.reconsideracao.id_reconsideracao
            ) {
                getRecursoByIdReconsideracao(this.avaliacao_consolidada.reconsideracao.id_reconsideracao).then(res => {
                    if (res.data.data.length > 0) {
                        this.hasRegistroRecurso = true;
                        this.justificativa = res.data.data[0].ds_razao;
                        this.dt_recurso = res.data.data[0].created_at;
                        this.id_recurso = res.data.data[0].id_recurso;
                        if (res.data.data[0].va_nota != null) {
                            this.has_nota_recurso = true;
                            this.dt_decisao = res.data.data[0].updated_at
                            this.recurso_va_nota = res.data.data[0].va_nota;
                            this.recurso_nr_pontos = res.data.data[0].nr_pontos;
                        }
                        if (res.data.data[0].dt_ciencia_servidor != null) {
                            this.hasCienciaRecurso = true;
                            this.dt_ciencia_servidor_recurso = res.data.data[0].dt_ciencia_servidor;
                        } else{
                            var dataDecisao = new Date(this.dt_decisao);
                            var fimPeriodoCiencia = new Date();
                            var dataHoje = new Date();
                            fimPeriodoCiencia.setDate(dataDecisao.getDate() + 10);

                            if (dataHoje > fimPeriodoCiencia) {
                                this.cienciaRecursoExpirado = true;
                            }
                        }
                    } else {
                        this.hasRegistroRecurso = false;
                        this.justificativa = '';
                    }
                });
            }
            
        },
        error => {
            this.$router.push(
                {
                    name: 'tela-inicial',
                },
                () => this.$store.dispatch('alertas/show', {
                    tipo: 'Erro',
                    titulo: 'Acesso negado',
                    mensagem: 'Você não possui permissão necessária para acessar a página desejada.'
                })
            );
        });
    },
    methods: {
        resetJustificativasReconsideracao(){
            this.fatores_reconsideracao.map(f => {
                f.justificativa = '';
                f.justificativaVisible = false;
            })
            this.enviarPedidoReconsideracaoButtonEnabled = this.fatores_reconsideracao.filter(
                f => f.justificativaVisible
            ).length > 0
        },
        inserirJustificativa(id_fator){
            let fator = this.fatores_reconsideracao.find(f => f.id_fator === id_fator)
            fator.justificativa = '';
            fator.justificativaVisible = true;
            this.fatores_reconsideracao = JSON.parse(JSON.stringify(this.fatores_reconsideracao));
            this.enviarPedidoReconsideracaoButtonEnabled = this.fatores_reconsideracao.filter(
                f => f.justificativaVisible
            ).length > 0
        },
        removerJustificativa(id_fator){
            let fator = this.fatores_reconsideracao.find(f => f.id_fator === id_fator)
            fator.justificativa = '';
            fator.justificativaVisible = false;
            this.fatores_reconsideracao = JSON.parse(JSON.stringify(this.fatores_reconsideracao));
            this.enviarPedidoReconsideracaoButtonEnabled = this.fatores_reconsideracao.filter(
                f => f.justificativaVisible
            ).length > 0
        },
        roundEverything(){
            this.avaliacao_consolidada.equipe_trabalho.avaliacao_chefia.va_nota = this.round(
                this.avaliacao_consolidada.equipe_trabalho.avaliacao_chefia.va_nota,
                3
            )
            this.avaliacao_consolidada.va_nota_chefia = this.round(
                this.avaliacao_consolidada.va_nota_chefia,
                3
            )

            if ( this.avaliacao_consolidada.equipe_trabalho.avaliacao_auto ) {
                this.avaliacao_consolidada.equipe_trabalho.avaliacao_auto.va_nota = this.round(
                    this.avaliacao_consolidada.equipe_trabalho.avaliacao_auto.va_nota,
                    3
                )
            }
            this.avaliacao_consolidada.va_nota_auto = this.round(this.avaliacao_consolidada.va_nota_auto,3)
            this.valoresEquipe.resultadoAvaliacao = this.round(this.valoresEquipe.resultadoAvaliacao,3)
            this.avaliacao_consolidada.va_nota_equipe = this.round(this.avaliacao_consolidada.va_nota_equipe,3)
        },
        round(number, decimalPlaces) {
            if (number.toString().indexOf('.') > 0){
                return number.toString().substring(0,number.toString().indexOf('.')+decimalPlaces+1)
            } else {
                return number
            }
        },
        deAcordoAvaliacao() {
            this.avaliacao_consolidada.st_de_acordo = true;
            updateAvaliacaoConsolidada(
                this.avaliacao_consolidada.id_avaliacao_consolidada, 
                this.avaliacao_consolidada
            ).then(response => {
                this.dialogSuccess = true;
            });
        },
        discordanciaAvaliacao() {
            this.avaliacao_consolidada.st_de_acordo = false;
            updateAvaliacaoConsolidada(
                this.avaliacao_consolidada.id_avaliacao_consolidada, 
                this.avaliacao_consolidada
            ).then(response => {
                this.dialogSuccess = true;
            });
        },
        postReconsideracao() {
            createReconsideracao(this.gerarJson())
            .then(response => {
                this.dialogSuccess = true;
            });
            },
        gerarJson() {
            let json = {
                id_avaliacao_consolidada: this.avaliacao_consolidada.id_avaliacao_consolidada,
                servidor_avaliado: this.avaliacao_consolidada.equipe_trabalho && this.avaliacao_consolidada.equipe_trabalho.avaliado
                    ? this.avaliacao_consolidada.equipe_trabalho.avaliado.nm_servidor 
                    : this.avaliacao_consolidada.equipe_trabalho.servidor.nm_servidor,
                id_equipe_externa: this.avaliacao_consolidada.equipe_trabalho 
                    ? this.avaliacao_consolidada.equipe_trabalho.id_equipe_trabalho_externo 
                    : null,
                id_chefia: this.avaliacao_consolidada.equipe_trabalho.avaliacao_chefia.id_avaliador_substituto 
                    ? this.avaliacao_consolidada.equipe_trabalho.avaliacao_chefia.id_avaliador_substituto 
                    : this.avaliacao_consolidada.equipe_trabalho.avaliacao_chefia.id_avaliador ,
                fatores: [],
            }
            this.fatores_reconsideracao.map(fator => {
                let fatorToAdd = {
                    id_fator: fator.id_fator,
                    tx_justificativa: fator.justificativa
                }
                if (fator.justificativaVisible) json.fatores.push(fatorToAdd);
            })
            return JSON.stringify(json);
        },
        deAcordoReconsideracao() {		
            this.avaliacao_consolidada.reconsideracao.st_de_acordo_servidor = true;
            updateReconsideracaoFromVisualizacaoServidor(
                this.avaliacao_consolidada.reconsideracao.id_reconsideracao, 
                this.avaliacao_consolidada.reconsideracao
            ).then(response => {
                this.dialogSuccess = true;
            });
        },
        discordanciaReconsideracao() {
            this.avaliacao_consolidada.reconsideracao.st_de_acordo_servidor = false;
            updateReconsideracaoFromVisualizacaoServidor(
                this.avaliacao_consolidada.reconsideracao.id_reconsideracao, 
                this.avaliacao_consolidada.reconsideracao
            ).then(response => {
                this.dialogSuccess = true;
            });
        },

        redirectSuccess() {
            this.dialogSuccess = false;
            window.location.href = window.location.href;
        },

        showDialogRecurso() {
            this.listarAnexos();
            this.listarAnexosAposRegistroDeRecurso();
            this.dialogRecurso = true;
        },

        addAnexo() {
            if (this.anexo == null) {
                this.msgDialog = 'Primeiro escolha um anexo' 
                return this.dialogError = true;
            }

            let data = new FormData();
            data.append('file', this.anexo);
            data.append('id_reconsideracao', this.avaliacao_consolidada.reconsideracao.id_reconsideracao);
            adicionarAnexo(data).then(response => {
                this.anexo = null;
                this.listarAnexos();
                this.listarAnexosAposRegistroDeRecurso();
            }).catch(erro => {
                this.msgDialog = "Erro ao adicionar.";
                this.dialogError = true;
            });
        },

        listarAnexos() {
            getAnexos(JSON.stringify(this.avaliacao_consolidada.reconsideracao.id_reconsideracao)).then(response => {
                this.anexosRecurso = response.data.data;
            }).catch(erro => {
                this.msgDialog = "Erro ao listar anexos.";
                this.dialogError = true;
            });
        },

        download(id_arquivo, nm_arquivo) {
            downloadAnexoRecurso(id_arquivo).then(response => {
                let blob = new Blob([response.data], { type: 'application/pdf' });
                let link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = nm_arquivo;                
                link.click();
            }).catch(erro => {
                this.msgDialog = "Erro ao realizar download.";
                this.dialogError = true;
            });
        },

        removerAnexo(id_arquivo) {
            let data = new FormData();
            data.append('id_anexo', id_arquivo);

            removerAnexoRecurso(data).then(response => {
                this.listarAnexos();
                this.listarAnexosAposRegistroDeRecurso();
            }).catch(erro => {
                this.msgDialog = "Erro ao remover.";
                this.dialogError = true;
            });
        },

        salvarRecurso() {
            if (this.justificativa == '') {
                this.msgDialog = 'A razão do recurso é obrigatório' 
                return this.dialogError = true;
            }

            if (this.justificativa.length <= 5) {
                this.msgDialog = 'Razão inválida' 
                return this.dialogError = true;
            }

            if (this.justificativa.length > 2000) {
                this.msgDialog = 'Máximo 2000 caracteres' 
                return this.dialogError = true;
            }

            let ultimoCicloVigente = this.cicloVigente[Object.keys(this.cicloVigente).pop()];
            let data = new FormData();

            data.append('id_ciclo', ultimoCicloVigente.id_ciclo);
            data.append('id_reconsideracao', this.avaliacao_consolidada.reconsideracao.id_reconsideracao);
            if (this.avaliacao_consolidada.reconsideracao.id_chefia) {
                data.append('id_avaliador', this.avaliacao_consolidada.reconsideracao.id_chefia);
            } else {
                data.append('id_avaliador_externo', this.avaliacao_consolidada.reconsideracao.id_servidor_externo);
            }
            data.append('id_avaliado', this.$store.getters['auth/usuarioAutenticado']);
            data.append('ds_razao', this.justificativa);

            salvarRegistroRecurso(data).then(response => {
                this.dialogRecurso = false;
                this.hasRegistroRecurso = true;
                this.dialogSuccess = true;
            }).catch(erro => {
                this.msgDialog = "Erro ao salvar.";
                this.dialogError = true;
            });
        },

        listarAnexosAposRegistroDeRecurso() {
            getAnexosAposRecurso(JSON.stringify(this.avaliacao_consolidada.reconsideracao.id_reconsideracao)).then(response => {
                this.anexosRecursoAposRegistro = response.data.data;
            }).catch(erro => {
                this.msgDialog = "Erro ao listar anexos.";
                this.dialogError = true;
            });
        },

        manifestarCienciaRecurso() {
            cienciaRecurso(this.id_recurso).then(response => {
                this.dialogSuccess = true;
                this.hasCienciaRecurso = true;
            });
        }
    }, 
}        
</script>

<template>
    <div>
        <br/>
        <h1>Agendar envio de e-mail para servidores</h1>
        <p>
            Ao salvar agendamento será enviado às 24h00 de hoje e-mail para 
            todos os servidores que possuirem e-mail institucional atualizado.
        </p>  
        <div class="row">
            <v-col cols="12">
                <v-card>   
                    <v-row dense class="ma-0 pa-2">
                        <v-col cols="12" class="mx-0 px-3">
                            <v-text-field 
                                id="tituloNotificacao" 
                                ref="tituloNotificacao" 
                                v-model="tx_assunto" 
                                dense 
                                hide-details="auto" 
                                label="Assunto do e-mail" 
                                outlined 
                                clearable 
                                clear-icon="mdi-close-circle-outline" 
                                :rules="validaCampo"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row dense class="ma-0 pa-2">
                        <v-col cols="12" class="mx-0 px-3">
                            Corpo do email
                        </v-col>
                        <v-col cols="12" class="mx-0 px-3">
                            <ckeditor 
                                :editor="editor" 
                                v-model="tx_corpo" 
                                :config="editorConfig" 
                                :rules="validaCampo" 
                                ref="tx_corpo"
                            ></ckeditor>
                        </v-col>
                    </v-row>
                    <v-row>
                         <v-col class="d-flex flex-row justify-end align-end text-right">
                            <v-btn class="success mr-3" @click="agendar()">
                                Salvar agendamento
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </div>
        <br>
        <v-row dense class="ma-0 pa-2">
            <v-col cols="12" class="text-center text-muted mx-0 px-3 grey--text">
                Os anexos são inseridos na coluna 'Ações' clicando no icone 
                <v-icon color="grey"> mdi-attachment </v-icon> somente após salvar o agendamento.<br>
                Enquanto o status for
                <v-btn class="white--text" color="warning" rounded x-small>
                    Aguardando o envio
                </v-btn>
                o e-mail agendado pode ser editado e também excluído.
            </v-col>
        </v-row> 
        <br>
        <v-card>
            <template>
                <v-data-table
                    :headers="headersAgendamentos"
                    :items="emails"
                    :items-per-page="5"
                    class="elevation-1"
                >
                    <template v-slot:[`item.acao`]="{ item }">
                        <v-row>
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs}">
                                    <v-btn 
                                        id="detalhar" 
                                        icon 
                                        class="mr-2"
                                        small 
                                        :color="'primary'" 
                                        v-bind="attrs" 
                                        v-on="on" 
                                        @click="showDialogDetalhar(item.id_email_agendado)"
                                    >
                                        <v-icon> mdi-eye </v-icon>
                                    </v-btn>
                                </template>
                                <span>Detalhar</span>
                            </v-tooltip>
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs}">
                                    <v-btn 
                                        id="anexos" 
                                        icon 
                                        class="mr-2"
                                        small 
                                        :color="'grey'" 
                                        v-bind="attrs" 
                                        v-on="on" 
                                        @click="showDialogAnexos(item.id_email_agendado, item.st_enviado)"
                                    >
                                        <v-icon> mdi-attachment </v-icon>
                                    </v-btn>
                                </template>
                                <span>Anexos</span>
                            </v-tooltip>
                            <v-tooltip top v-if="!item.st_enviado">
                                <template v-slot:activator="{ on, attrs}">
                                    <v-btn 
                                        id="editar" 
                                        icon 
                                        class="mr-2"
                                        small 
                                        :color="'warning'" 
                                        v-bind="attrs" 
                                        v-on="on" 
                                        @click="showDialogEditar(item.id_email_agendado)"
                                    >
                                        <v-icon> mdi-pencil </v-icon>
                                    </v-btn>
                                </template>
                                <span>Editar</span>
                            </v-tooltip>
                            <v-tooltip top v-if="!item.st_enviado">
                                <template v-slot:activator="{ on, attrs}">
                                    <v-btn 
                                        id="deletarAgendamento" 
                                        icon 
                                        class="mr-2"
                                        small 
                                        :color="'error'" 
                                        v-bind="attrs" 
                                        v-on="on" 
                                        @click="showDialogExcluir(item.id_email_agendado)"
                                    >
                                        <v-icon> mdi-delete-forever </v-icon>
                                    </v-btn>
                                </template>
                                <span>Excluir agendamento</span>
                            </v-tooltip>
                        </v-row>
                    </template> 

                    <template v-slot:[`item.st_enviado`]="{ item }">
                        <v-row  v-if="item.st_enviado">
                            <v-btn class="white--text" color="success" rounded x-small>
                                Enviado com sucesso
                            </v-btn>
                        </v-row>
                        <v-row  v-else>
                            <v-btn class="white--text" color="warning" rounded x-small>
                                Aguardando o envio
                            </v-btn>
                        </v-row>
                    </template>   
                </v-data-table>
            </template>
        </v-card>

        <v-dialog v-model="dialogWarning" width="600" >
            <v-card>
                <v-card-title class="text-h5 text-center">
                    <v-alert prominent type="warning" outlined width="100%">
                        <v-row align="center">
                            <v-col class="grow">
                                {{ dialogMsg }}
                            </v-col>
                            <v-col class="shrink">
                            <v-btn color="warning"  @click="dialogWarning = false">Ok</v-btn>
                            </v-col>
                        </v-row>
                    </v-alert>
                </v-card-title> 
            </v-card>
        </v-dialog>  

        <v-dialog v-model="dialogSuccess" width="570" >
            <v-card>
                <v-card-title class="text-h5 text-center">
                    <v-alert prominent type="success" width="100%">
                        <v-row align="center">
                            <v-col class="grow">
                                {{dialogMsg}}
                            </v-col>
                            <v-col class="shrink">
                            <v-btn outlined  @click="dialogSuccess = false">Ok</v-btn>
                            </v-col>
                        </v-row>
                    </v-alert>
                </v-card-title> 
            </v-card>
        </v-dialog>

        <v-dialog 
            v-model="dialogAnexos" 
            width="900"  
            hide-overlay
            transition="dialog-bottom-transition"
        >
            <v-card>
                <v-toolbar dark color="primary">
                    <v-btn icon dark>
                        <v-icon>mdi-attachment </v-icon>
                    </v-btn>
                    <v-toolbar-title>Anexos</v-toolbar-title>            
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn dark text @click="dialogAnexos=false">
                        <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <v-container >
                    <template>
                        <v-card class="mx-3">
                            <v-card-title>
                                <v-row class="mt-3 mx-3">
                                    Anexos
                                    <v-spacer></v-spacer>
                                    <v-file-input
                                        v-if="!st_enviado" 
                                        :rules="rulesAnexo"
                                        accept=".png, .jpeg, .pdf, .doc, .xlsx, .xls"
                                        id="anexo" 
                                        ref="anexo" 
                                        v-model="anexo" 
                                        label="Anexo" 
                                        outlined
                                        height="40px"
                                        dense
                                    ></v-file-input>
                                    <v-btn 
                                        v-if="!st_enviado"
                                        color="primary" 
                                        height="40px" 
                                        @click="addAnexo()"
                                    >
                                        Adicionar
                                    </v-btn>
                                </v-row>
                            </v-card-title>
                            <v-data-table
                                :headers="headers"
                                :items="anexosAgendamento"
                            >
                                <template v-slot:[`item.acao`]="{ item }">
                                    <v-row>
                                        <v-tooltip top >
                                            <template v-slot:activator="{on, attrs}">
                                                <v-btn 
                                                    rounded 
                                                    icon  
                                                    class="mr-2" 
                                                    id="btnDownload" 
                                                    @click="download(item.arquivo.id_arquivo, item.arquivo.nm_arquivo)"
                                                    small 
                                                    :color="'primary'" 
                                                    v-bind="attrs" 
                                                    v-on="on"
                                                >
                                                    <v-icon> mdi-download </v-icon>
                                                </v-btn>
                                            </template>
                                            <span>DOWNLOAD</span>
                                        </v-tooltip>

                                        <v-tooltip top v-if="!st_enviado">
                                            <template v-slot:activator="{on, attrs}">
                                                <v-btn 
                                                    rounded 
                                                    icon  
                                                    class="mr-2" 
                                                    id="btnremover" 
                                                    @click="removerAnexo(item.arquivo.id_arquivo)"
                                                    small
                                                    :color="'error'" 
                                                    v-bind="attrs" 
                                                    v-on="on"
                                                >
                                                    <v-icon> mdi-delete-forever </v-icon>
                                                </v-btn>
                                            </template>
                                            <span>REMOVER</span>
                                        </v-tooltip>
                                    </v-row>
                                </template>    
                            </v-data-table>
                        </v-card>
                    </template>
                    
                </v-container>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="grey darken-1" text @click="dialogAnexos=false">Fechar</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog 
            v-model="dialogEditar" 
            width="900"  
            hide-overlay
            transition="dialog-bottom-transition"
        >
            <v-card>
                <v-toolbar dark color="primary">
                    <v-btn icon dark>
                        <v-icon>mdi-attachment </v-icon>
                    </v-btn>
                    <v-toolbar-title>Editar E-mail agendado</v-toolbar-title>            
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn dark text @click="dialogEditar=false">
                        <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <v-container>
                    <v-card>    
                        <v-row dense class="ma-0 pa-2">
                            <v-col cols="12" class="mx-0 px-3">
                                <v-text-field 
                                    id="emailAgendadotituloNotificacao" 
                                    ref="emailAgendadotituloNotificacao" 
                                    v-model="emailAgendado.tx_assunto" 
                                    dense 
                                    hide-details="auto" 
                                    label="Assunto do e-mail" 
                                    outlined 
                                    clearable 
                                    clear-icon="mdi-close-circle-outline" 
                                    :rules="validaCampo"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row dense class="ma-0 pa-2">
                            <v-col cols="12" class="mx-0 px-3">
                                Corpo do email
                            </v-col>
                            <v-col cols="12" class="mx-0 px-3">
                                <ckeditor 
                                    :editor="editor" 
                                    v-model="emailAgendado.tx_corpo" 
                                    :config="editorConfig" 
                                    :rules="validaCampo" 
                                    ref="emailAgendadotx_corpo"
                                ></ckeditor>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-container>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="grey darken-1" text @click="dialogEditar=false">Cancelar</v-btn>
                    <v-btn color="primary" text @click="editar()">Editar</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
        
        <v-dialog 
            v-model="dialogDetalhar" 
            width="900"  
            hide-overlay
            transition="dialog-bottom-transition"
        >
            <v-card>
                <v-toolbar dark color="primary">
                    <v-btn icon dark>
                        <v-icon>mdi-eye </v-icon>
                    </v-btn>
                    <v-toolbar-title>Detalhes do E-mail agendado</v-toolbar-title>            
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn dark text @click="dialogDetalhar=false">
                        <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <v-container>
                    <v-card>    
                        <v-row dense class="ma-0 pa-2">
                            <v-col cols="12" class="mx-0 px-3">
                                <v-text-field
                                    id="emailAgendadotituloNotificacao" 
                                    ref="emailAgendadotituloNotificacao" 
                                    v-model="emailAgendado.tx_assunto" 
                                    dense 
                                    hide-details="auto" 
                                    label="Assunto do e-mail" 
                                    outlined 
                                    clearable 
                                    disabled
                                    clear-icon="mdi-close-circle-outline" 
                                    :rules="validaCampo"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row dense class="ma-0 pa-2">
                            <v-col cols="12" class="mx-0 px-3">
                                Corpo do email
                            </v-col>
                            <v-col cols="12" class="mx-0 px-3">
                                <ckeditor 
                                    :editor="editor" 
                                    v-model="emailAgendado.tx_corpo" 
                                    :config="editorConfig" 
                                    :rules="validaCampo" 
                                    disabled
                                    ref="emailAgendadotx_corpo"
                                ></ckeditor>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-container>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="grey darken-1" text @click="dialogDetalhar=false">Fechar</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogExcluir" max-width="500px">
            <v-card>
                <h4 class="primary--text text-center py-6">
                    Tem certeza que deseja excluir?
                 </h4>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="grey darken-1" text @click="dialogExcluir = false">Fechar</v-btn>
                <v-btn color="red darken-1"  text @click="deletarAgendamento()">Excluir</v-btn>
                <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </div>
</template>


<script>
import Vue from 'vue';
import CKEditor from '@ckeditor/ckeditor5-vue2';

import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

Vue.use( CKEditor );

import { getAgendamentos } from '../api/email_agendado';
import { createAgendamento } from '../api/email_agendado';
import { addAnexoEmailAgendado } from '../api/email_agendado';
import { getAnexosEmailAgendado } from '../api/email_agendado';
import { downloadAnexo } from '../api/email_agendado';
import { removerAnexoEmailAgendado } from '../api/email_agendado';
import { getEmailAgendado } from '../api/email_agendado';
import { editarAgendamento } from '../api/email_agendado';
import { excluirEmailAgendado } from '../api/email_agendado';

export default {
    name: 'AgendarEmailCadastrar',
    components: {
        ckeditor: CKEditor.component
    },
    data() {
        return {
            validaCampo: [
                v => !!v || 'Campo obrigatório',
            ],
            
            editor: ClassicEditor,
            tx_corpo: '',
            editorConfig: {
                language: 'pt-br'
            },
            tx_assunto: '',
            dialogSuccess: false,
            dialogMsg: '',
            dialogWarning: false,
            headersAgendamentos: [
                { text: 'Assunto do E-mail', value: 'tx_assunto', sortable: false },
                { text: 'Data agendada para o envio', value: 'dt_agendamento', sortable: false },
                { text: 'Status', value: 'st_enviado', sortable: false },
                { text: 'Ações', value: 'acao', sortable: false, width: '200px' },
            ],
            emails: [],
            rulesAnexo: [
                value => !value || value.size < 80000000 || 'Tamanho deve ser menor que 80 MB!',
            ],
            dialogAnexos: false,
            anexo:null,
            anexosAgendamento: [],
            headers: [
                { text: 'Nome do anexo', value: 'arquivo.nm_arquivo' ,sortable: false},
                { text: 'Ação', value: 'acao' ,sortable: false},
            ],
            dialogEditar: false,
            emailAgendado:[],
            st_enviado: false,
            dialogDetalhar: false,
            dialogExcluir: false
        };
    },
    mounted() {
    },
    created() {
        this.listarAgendamentos();
    },
    methods: {
        agendar() {
            if (!this.tx_assunto) {
                this.dialogMsg = "Necessário ter o assunto";
                return this.dialogWarning = true;
            }

            if (!this.tx_corpo) {
                this.dialogMsg = "Necessário ter o corpo do e-mail";
                return this.dialogWarning = true;
            } 

            let data = new FormData();

            data.append('tx_assunto', this.tx_assunto);
            data.append('tx_corpo', this.tx_corpo);

            createAgendamento(data).then(response => {
                this.listarAgendamentos();
                this.tx_assunto = '';
                this.tx_corpo = '';
                this.dialogMsg = "Feito com sucesso";
                this.dialogSuccess = true;
            }).catch(erro => {
                this.dialogMsg = "erro ao salvar";
                this.dialogWarning = true;
            });
        },

        listarAgendamentos() {
            getAgendamentos().then(response => {
                this.emails = response.data.data;
            });
        },

        addAnexo() {
            if (this.anexo == null) {
                this.dialogMsg = 'Primeiro escolha um anexo' 
                return this.dialogWarning = true;
            }

            let data = new FormData();
            data.append('file', this.anexo);
            data.append('id_email_agendado', this.id_email_agendado);
            addAnexoEmailAgendado(data).then(response => {
                this.anexo = null;
                this.dialogMsg = 'Feito com sucesso' 
                this.dialogSuccess = true;
                this.listarAnexos(this.id_email_agendado);
            }).catch(erro => {
                this.msgDialog = "Erro ao adicionar.";
                this.dialogError = true;
            });
        },

        listarAnexos(id_email_agendado) {
            getAnexosEmailAgendado(JSON.stringify(id_email_agendado)).then(response => {
                this.anexosAgendamento = response.data.data;
            }).catch(erro => {
                this.msgDialog = "Erro ao listar anexos.";
                this.dialogError = true;
            });
        },
        
        download(id_arquivo, nm_arquivo) {
           downloadAnexo(id_arquivo).then(response => {
                let blob = new Blob([response.data], { type: 'application/pdf' });
                let link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = nm_arquivo;                
                link.click();
            }).catch(erro => {
                this.msgDialog = "Erro ao realizar download.";
                this.dialogError = true;
            });
        },

        removerAnexo(id_arquivo) {
           let data = new FormData();
            data.append('id_anexo', id_arquivo);

            removerAnexoEmailAgendado(data).then(response => {
                this.dialogMsg = 'Feito com sucesso'
                this.dialogSuccess =true;
                this.listarAnexos(this.id_email_agendado);
            }).catch(erro => {
                this.dialogMsg = "Erro ao remover.";
                this.dialogAlert = true;
            });
        },

        showDialogAnexos(id_email_agendado, st_enviado) {
            this.id_email_agendado = id_email_agendado;
            this.st_enviado = st_enviado;
            this.dialogAnexos = true;
            this.listarAnexos(this.id_email_agendado);
        },

        showDialogEditar(id_email_agendado) {
            this.dialogEditar = true;

            getEmailAgendado(id_email_agendado).then(response => {
                this.emailAgendado = response.data.data;
            });
        },

        showDialogDetalhar(id_email_agendado) {
            this.dialogDetalhar = true;

            getEmailAgendado(id_email_agendado).then(response => {
                this.emailAgendado = response.data.data;
            });
        },

        editar() {
            if (!this.emailAgendado.tx_assunto) {
                this.dialogMsg = "Necessário ter o assunto";
                return this.dialogWarning = true;
            }

            if (!this.emailAgendado.tx_corpo) {
                this.dialogMsg = "Necessário ter o corpo do e-mail";
                return this.dialogWarning = true;
            } 

            let data = new FormData();
            data.append('id_email_agendado', this.emailAgendado.id_email_agendado);
            data.append('tx_assunto', this.emailAgendado.tx_assunto);
            data.append('tx_corpo', this.emailAgendado.tx_corpo);

            editarAgendamento(data).then(response => {
                this.listarAgendamentos();
                this.dialogMsg = "Feito com sucesso";
                this.dialogSuccess = true;
                this.dialogEditar = false;
            }).catch(erro => {
                this.dialogMsg = "erro ao salvar";
                this.dialogWarning = true;
            });
        },

        showDialogExcluir(id_email_agendado) {
            this.dialogExcluir = true;
            this.id_email_agendado = id_email_agendado;
        },

        deletarAgendamento() {
            let data = new FormData();
            data.append('id_email_agendado', this.id_email_agendado);

            excluirEmailAgendado(data).then(response => {
                this.dialogExcluir = false;
                this.id_email_agendado = null;
                this.dialogMsg = "Feito com sucesso";
                this.dialogSuccess =true;
                this.listarAgendamentos();
            }).catch(erro => {
                this.dialogMsg = "Erro ao deletar.";
                this.dialogWarning = true;
            });
        },
    }
}
</script>
<template>
    <div>
        <template>
            <br>
            <v-row justify="center">
                <Loader v-if="loading" />
                <v-card v-else color="deep-purple lighten-5">
                    <v-card
                        color="primary"
                        dark
                        class="mx-auto"
                    >
                        <v-card-title>
                            <v-icon
                                large
                                left
                            >
                                mdi-account-box-multiple
                            </v-icon>
                            <span class="text-h6 font-weight-light">{{ servidor.nome }}</span>
                        </v-card-title>
                    </v-card>
                    
                    <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col
                                cols="2"
                                sm="2"
                                md="2"
                            >
                                <v-text-field
                                label="SIAPE"
                                dense
                                hide-details="auto"
                                :value=servidor.siape
                                readonly
                                ></v-text-field>
                            </v-col>
                            <v-col
                                cols="3"
                                sm="3"
                                md="3"
                            >
                                <v-text-field
                                label="CPF"
                                class="text-caption"
                                dense
                                hide-details="auto"
                                :value=servidor.cpf
                                readonly
                                ></v-text-field>
                            </v-col>
                            <v-col
                                cols="7"
                                sm="7"
                                md="7"
                            >
                                <v-text-field
                                label="Nome"
                                dense
                                hide-details="auto"
                                :value=servidor.nome
                                readonly
                                ></v-text-field>
                            </v-col>

                            <v-col
                                cols="4"
                                sm="4"
                                md="4"
                            >
                                <v-text-field
                                label="Telefone"
                                dense
                                hide-details="auto"
                                :value=servidor.telefone
                                readonly
                                ></v-text-field>
                            </v-col>
                            <v-col
                                cols="4"
                                sm="4"
                                md="4"
                            >
                                <v-text-field
                                label="E-mail institucional"
                                dense
                                hide-details="auto"
                                :value=servidor.email_institucional
                                readonly
                                ></v-text-field>
                            </v-col>
                            <v-col
                                cols="4"
                                sm="4"
                                md="4"
                            >
                                <v-text-field
                                label="E-mail pessoal"
                                dense
                                hide-details="auto"
                                :value=servidor.email_pessoal
                                readonly
                                ></v-text-field>
                            </v-col>

                            <v-col
                                cols="2"
                                sm="2"
                                md="2"
                            >
                                <v-text-field
                                label="Jornada Trabalho"
                                dense
                                hide-details="auto"
                                :value=servidor.jornada_trabalho
                                readonly
                                ></v-text-field>
                            </v-col>
                            <v-col
                                cols="4"
                                sm="4"
                                md="4"
                            >
                                <v-text-field
                                label="Situação"
                                dense
                                hide-details="auto"
                                :value=servidor.situacao_servidor
                                readonly
                                ></v-text-field>
                            </v-col>
                            <v-col
                                cols="6"
                                sm="6"
                                md="6"
                            >
                                <v-text-field
                                label="Gratificação"
                                dense
                                hide-details="auto"
                                :value=servidor.gratificacao
                                readonly
                                ></v-text-field>
                            </v-col>

                            <v-col
                                cols="6"
                                sm="6"
                                md="6"
                            >
                                <v-text-field
                                label="Cargo"
                                dense
                                hide-details="auto"
                                :value=servidor.cargo
                                readonly
                                ></v-text-field>
                            </v-col>
                            <v-col
                                cols="6"
                                sm="6"
                                md="6"
                            >
                                <v-text-field
                                label="Função"
                                dense
                                hide-details="auto"
                                :value=servidor.funcao
                                readonly
                                ></v-text-field>
                            </v-col>
                            <v-col
                                cols="12"
                                sm="12"
                                md="12"
                            >
                                <v-text-field
                                label="Órgão Requisitante"
                                dense
                                hide-details="auto"
                                :value=servidor.orgao_requisitante
                                readonly
                                ></v-text-field>
                            </v-col>
                            <v-col
                                cols="12"
                                sm="12"
                                md="12"
                            >
                                <v-text-field
                                label="Órgão Exercício"
                                dense
                                hide-details="auto"
                                :value=servidor.unidade_exercicio
                                readonly
                                ></v-text-field>
                            </v-col>
                            <v-col
                                cols="12"
                                sm="12"
                                md="12"
                            >
                                <v-text-field
                                label="Órgão Lotação"
                                dense
                                hide-details="auto"
                                :value=servidor.unidade_lotacao
                                readonly
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>

                    <div class="text-center pt-2">
                        <v-btn
                            color="primary"
                            @click="$router.go(-1)"
                        >
                            Voltar
                        </v-btn>    
                    </div>
                    
                    </v-card-text>

                </v-card>
            </v-row>
        </template>

    </div>
</template>


<script>
    import { getServidor } from '../api/servidor';
    import {mapState,mapMutations,mapAction } from 'vuex';
    import Loader from '../components/Loader.vue';

    export default {
        name: 'ServidoresDetalhar',
        components: {
            Loader
        },
        data() {
            return {
                servidor: [],
                dialog2: false,
          };
        },
        created() {
            let id_servidor = this.$route.params.id;
            getServidor(id_servidor)
                .then(response => { 
                    this.servidor = response.data.data; 
                });
        },
        computed: {
            ...mapState('loader',['loading'])
        },
        watch: {
            dialog2 (val) {
                if (!val) return
                setTimeout(() => (this.dialog2 = false), 4000)
            },
        },
    }
</script>
<template>
    <div v-if="plano_trabalho.id_plano_trabalho">
        <LiberarBloquearAvaliacaoDialog ref="liberarBloquearAvaliacaoDialog" />
        <servidorDialog ref="servidorDialog" />
        <pontosFocaisDialog ref="pontosFocaisDialog" :plano_trabalho="plano_trabalho" />
        <BuscaServidorSiapeCPFdialog ref="buscaServidorSiapeCPFdialog" :title="buscaServidorSiapeCPFdialog_title" :tipo="buscaServidorSiapeCPFdialog_tipo" @result:servidor="recebeuServidorFromBuscaSiapeCPF" />
        <BuscaServidorSiapeCPFdialogAdicionarMembro ref="buscaServidorSiapeCPFdialogAdicionarMembro" :plano_trabalho="plano_trabalho" @result:servidor="recebeuServidorFromBuscaSiapeCPFadicionarMembro" />
        <BuscaServidorSiapeCPFdialogDefinirChefiaImediata ref="buscaServidorSiapeCPFdialogDefinirChefiaImediata" :plano_trabalho="plano_trabalho" @result:servidor="recebeuServidorFromBuscaSiapeCPFdefinirChefiaImediata" />
        <BuscaServidorSiapeCPFdialogDefinirChefiaImediataSubst ref="buscaServidorSiapeCPFdialogDefinirChefiaImediataSubst" :plano_trabalho="plano_trabalho" @result:servidor="recebeuServidorFromBuscaSiapeCPFdefinirChefiaImediataSubst" />
        <BuscaServidorSiapeCPFdialogAdicionarPontoFocal ref="buscaServidorSiapeCPFdialogDefinirPontoFocal" :plano_trabalho="plano_trabalho" @result:servidor="recebeuServidorFromBuscaSiapeCPFdefinirPontoFocal" />
        <BuscaServidorSiapeCPFdialogAdicionarPontoFocalSubst ref="buscaServidorSiapeCPFdialogDefinirPontoFocalSubst" :plano_trabalho="plano_trabalho" @result:servidor="recebeuServidorFromBuscaSiapeCPFdefinirPontoFocalSubst" />
        <h1>{{plano_trabalho.nm_unidade}}</h1> 
        <v-row v-if="showBtnPlanoSuperior && plano_trabalho.id_plano_pai">
            <v-col class="mb-3">
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs}">
                        <v-btn id="btnVisualizarPlano"  :color="'primary'" v-bind="attrs" v-on="on" :to="{ name: 'planos-trabalho-detalhar', params: {id: plano_trabalho.id_plano_pai} }">
                            <v-icon> mdi-arrow-up-bold </v-icon> Visualizar Plano de Trabalho Superior
                        </v-btn>
                    </template>
                    <span>Ir para plano de trabalho superior</span>
                </v-tooltip>
            </v-col>
        </v-row> 
             
        <v-card class="px-2" color="grey lighten-4">
            <v-row>
                <v-col>
                    <v-card class="ma-2">
                        <v-card-title class="text-h5">
                            Ciclo de avaliação
                        </v-card-title>
                        <v-card-text>
                            <p>
                                <span class="font-weight-bold">Nome:</span>
                                {{ plano_trabalho.ciclo.nm_ciclo }}
                            </p>
                            <!-- <p>
                                <span class="font-weight-bold">Período:</span>
                                {{ plano_trabalho.ciclo.dt_inicio }} a {{ plano_trabalho.ciclo.dt_encerramento }}
                            </p> -->

                            <v-dialog v-model="dialogCronograma" width="50%" :fullscreen="$vuetify.breakpoint.xsOnly">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn color="primary" small v-bind="attrs" v-on="on">
                                        Cronograma das fases
                                    </v-btn>
                                </template>
                                <v-card>
                                    <v-card-title>
                                        <span class="headline">Cronograma: {{ plano_trabalho.ciclo.nm_ciclo }}</span>
                                    </v-card-title>
                                    <v-card-text>
                                        <v-timeline dense>
                                            <v-timeline-item v-for="fase in plano_trabalho.ciclo.fases" 
                                            :key="fase.id_fase" 
                                            :color="(parseToDateInicio(fase.dt_inicio) <= new Date() && parseToDateEncerramento(fase.dt_encerramento) >= new Date()) ? 'primary':'grey'">
                                                <v-card>
                                                    <v-card-title class="text-p">
                                                        {{ fase.nm_fase }}
                                                    </v-card-title>
                                                    <v-card-text>
                                                        <p>
                                                            {{ fase.ds_fase }}
                                                        </p>
                                                        <p>
                                                            <span class="font-weight-bold">Período:</span>
                                                            {{ fase.dt_inicio }} a {{ fase.dt_encerramento }}
                                                        </p>
                                                    </v-card-text>
                                                </v-card>
                                            </v-timeline-item>
                                        </v-timeline>
                                    </v-card-text>
                                    <v-divider></v-divider>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn color="primary" @click="dialogCronograma = false">
                                            Fechar
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col>
                    <v-card class="ma-2">
                        <v-card-text class="text-h5">
                            Chefia
                            <v-menu v-if="canTrocarChefia()" :close-on-content-click="true">
                                <template v-slot:activator="{ on: dialog, attrs }" >
                                    <v-tooltip top >
                                        <template v-slot:activator="{ on: tooltip }">
                                            <v-btn icon color="primary" small v-bind="attrs" v-on="{ ...tooltip, ...dialog }">
                                                <v-icon> mdi-swap-vertical </v-icon>
                                            </v-btn>
                                        </template>
                                        <span v-if="!plano_trabalho.in_troca_chefia">Substituir chefia imediata pelo substituto</span>
                                        <span v-else>Substituir substituto pela chefia imediata</span>
                                    </v-tooltip>
                                </template>
                                <v-card>
                                    <v-card-text>
                                        Tem certeza que deseja trocar a chefia desse plano de trabalho?
                                    </v-card-text>
                                    <v-card-actions class="justify-center">
                                        <v-btn color="default">
                                            Cancelar
                                        </v-btn>
                                        <v-btn color="success" @click="trocarChefia">
                                            Confirmar
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-menu>
                        </v-card-text>
                        <v-card-text>
                            <p>
                                <span class="font-weight-bold">Imediata:</span>
                                <span v-if="plano_trabalho.chefia">
                                    {{ plano_trabalho.chefia.nome }}
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on: tooltip}">
                                            <v-btn icon color="primary" small v-on="{ ...tooltip}" @click="() => modalServidor(plano_trabalho.id_chefia,'Chefia Imediata')">
                                                <v-icon> mdi-file-search-outline </v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Visualizar dados da chefia imediata</span>
                                    </v-tooltip>
                                    <v-menu v-if="canEditarChefia()" :close-on-content-click="true">
                                        <template v-slot:activator="{ on: dialog, attrs }">
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on: tooltip}">
                                                    <v-btn icon color="error" small v-bind="attrs" v-on="{ ...tooltip, ...dialog }">
                                                        <v-icon>
                                                            mdi-delete-outline
                                                        </v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Excluir chefia imediata</span>
                                            </v-tooltip>
                                        </template>
                                        <v-card>
                                            <v-card-text>
                                                Tem certeza que deseja excluir a chefia imediata?
                                            </v-card-text>
                                            <v-card-actions class="justify-center">
                                                <v-btn color="default">
                                                    Cancelar
                                                </v-btn>
                                                <v-btn color="error" @click="deletarChefiaTitular(plano_trabalho.chefia)">
                                                    Confirmar
                                                </v-btn>
                                            </v-card-actions>
                                        </v-card>
                                    </v-menu>
                                </span>
                                <v-tooltip v-else top>
                                    <template v-if="canEditarChefia()" v-slot:activator="{ on: tooltip}">
                                        <v-btn icon color="success" small v-on="{ ...tooltip}" @click="toggleBuscaServidorSiapeCPFdialogDefinirChefiaImediataVisible();">
                                            <v-icon>
                                                mdi-account-plus-outline
                                            </v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Adicionar chefia imediata</span>
                                </v-tooltip>
                            </p>
                            <p>
                                <span class="font-weight-bold">Substituto legal:</span>
                                <span v-if="plano_trabalho.substituto">
                                    {{ plano_trabalho.substituto.nome }}
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on: tooltip}">
                                            <v-btn icon color="primary" small v-on="{ ...tooltip}" @click="() => modalServidor(plano_trabalho.id_substituto,'Substituto Legal')">
                                                <v-icon> mdi-file-search-outline </v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Visualizar dados do substituto legal</span>
                                    </v-tooltip>
                                    <v-menu v-if="canEditarChefia()" :close-on-content-click="true">
                                        <template v-slot:activator="{ on: dialog, attrs }">
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on: tooltip}">
                                                    <v-btn icon color="error" small v-bind="attrs" v-on="{ ...tooltip, ...dialog }">
                                                        <v-icon>
                                                            mdi-delete-outline
                                                        </v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Excluir substituto legal</span>
                                            </v-tooltip>
                                        </template>
                                        <v-card>
                                            <v-card-text>
                                                Tem certeza que deseja excluir o substituto legal?
                                            </v-card-text>
                                            <v-card-actions class="justify-center">
                                                <v-btn color="default">
                                                    Cancelar
                                                </v-btn>
                                                <v-btn color="error" @click="deletarChefiaSubstituta">
                                                    Confirmar
                                                </v-btn>
                                            </v-card-actions>
                                        </v-card>
                                    </v-menu>
                                </span>
                                <v-tooltip v-else top>
                                    <template v-if="canEditarChefia()" v-slot:activator="{ on: tooltip}">
                                        <v-btn icon color="success" small v-on="{ ...tooltip}" @click="toggleBuscaServidorSiapeCPFdialogDefinirChefiaImediataSubstVisible()">
                                            <v-icon>
                                                mdi-account-plus-outline
                                            </v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Adicionar substituto legal</span>
                                </v-tooltip>
                            </p>
                            <p v-if="this.plano_trabalho.in_troca_chefia">
                                <v-alert type="info" prominent>
                                    <span>
                                        Substituto efetivado como chefe para efeitos de avaliação da equipe.
                                    </span>
                                </v-alert>
                                <span class="font-weight-bold"></span>
                            </p>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-card class="ma-2">
                        <v-card-title class="text-h5">
                            Unidade de avaliação
                        </v-card-title>
                        <v-card-text>
                            <p>
                                <span class="font-weight-bold">Identificação:</span>
                                {{ plano_trabalho.unidade_avaliacao.nm_unidade_avaliacao }}
                            </p>
                            <p>
                                <span class="font-weight-bold">Gestor setorial titular:</span>
                                {{ plano_trabalho.unidade_avaliacao.gestor_setorial ? plano_trabalho.unidade_avaliacao.gestor_setorial.nome:''}}
                                <v-tooltip top v-if="plano_trabalho.unidade_avaliacao.gestor_setorial">
                                    <template v-slot:activator="{ on: tooltip}">
                                        <v-btn icon color="primary" small v-on="{ ...tooltip}" @click="() => modalServidor(plano_trabalho.unidade_avaliacao.id_gestor_setorial,'Gestor Setorial Titular')">
                                            <v-icon> mdi-file-search-outline </v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Visualizar dados do gestor setorial</span>
                                </v-tooltip>
                            </p>
                            <p>
                                <span class="font-weight-bold">Gestor setorial substituto:</span>
                                {{ plano_trabalho.unidade_avaliacao.gestor_substituto? plano_trabalho.unidade_avaliacao.gestor_substituto.nome:'' }}
                                <v-tooltip top v-if="plano_trabalho.unidade_avaliacao.gestor_substituto">
                                    <template v-slot:activator="{ on: tooltip}">
                                        <v-btn icon color="primary" small v-on="{ ...tooltip}" @click="() => modalServidor(plano_trabalho.unidade_avaliacao.id_gestor_substituto,'Gestor Setorial Substituto')">
                                            <v-icon> mdi-file-search-outline </v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Visualizar dados do gestor setorial substituto</span>
                                </v-tooltip>
                            </p>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="6">
                    <v-card class="ma-2">
                        <v-card-title class="text-h5">Ponto Focal</v-card-title>
                        <v-card-text>
                            <p v-if="pontoFocalSuperior">
                                <span class="font-weight-bold">Ponto Focal Superior:</span>
                                <span>
                                    {{ pontoFocalSuperior.nm_servidor }}
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on: tooltip}">
                                            <v-btn icon color="primary" small v-on="{ ...tooltip}" @click="() => modalServidor(pontoFocalSuperior.id_servidor,'Ponto Focal Superior')">
                                                <v-icon> mdi-file-search-outline </v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Visualizar dados do ponto focal superior</span>
                                    </v-tooltip>
                                </span>
                            </p>
                            <p v-if="pontoFocalSubstitutoSuperior">
                                <span class="font-weight-bold">Ponto Focal Substituto Superior:</span>
                                <span>
                                    {{ pontoFocalSubstitutoSuperior.nm_servidor }}
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on: tooltip}">
                                            <v-btn icon color="primary" small v-on="{ ...tooltip}" @click="() => modalServidor(pontoFocalSubstitutoSuperior.id_servidor,'Ponto Focal Substituto Superior')">
                                                <v-icon> mdi-file-search-outline </v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Visualizar dados do ponto focal substituto superior</span>
                                    </v-tooltip>
                                </span>
                            </p>
                            <hr v-if="pontoFocalSubstitutoSuperior || pontoFocalSuperior">
                            <br>
                            <p>
                                <span class="font-weight-bold">Ponto Focal:</span>
                                <span v-if="plano_trabalho.pontoFocal">
                                    {{ plano_trabalho.pontoFocal.nome }}
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on: tooltip}">
                                            <v-btn icon color="primary" small v-on="{ ...tooltip}" @click="() => modalServidor(plano_trabalho.id_ponto_focal,'Ponto Focal')">
                                                <v-icon> mdi-file-search-outline </v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Visualizar dados do ponto focal</span>
                                    </v-tooltip>
                                    <v-menu v-if="canEditarPontoFocal()" :close-on-content-click="true">
                                        <template v-slot:activator="{ on: dialog, attrs }">
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on: tooltip}">
                                                    <v-btn icon color="error" small v-bind="attrs" v-on="{ ...tooltip, ...dialog }">
                                                        <v-icon>
                                                            mdi-delete-outline
                                                        </v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Excluir ponto focal</span>
                                            </v-tooltip>
                                        </template>
                                        <v-card>
                                            <v-card-text>
                                                Tem certeza que deseja excluir o ponto focal?
                                            </v-card-text>
                                            <v-card-actions class="justify-center">
                                                <v-btn color="default">
                                                    Cancelar
                                                </v-btn>
                                                <v-btn color="error" @click="deletarPontoFocal">
                                                    Confirmar
                                                </v-btn>
                                            </v-card-actions>
                                        </v-card>
                                    </v-menu>
                                </span>
                                <v-tooltip v-else top>
                                    <template v-if="canEditarPontoFocal()" v-slot:activator="{ on: tooltip}">
                                        <v-btn icon color="success" small v-on="{ ...tooltip}" @click="toggleBuscaServidorSiapeCPFdialogDefinirPontoFocalVisible()">
                                            <v-icon>
                                                mdi-account-plus-outline
                                            </v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Adicionar ponto focal</span>
                                </v-tooltip>
                            </p>
                            <p>
                                <span class="font-weight-bold">Ponto Focal Substituto:</span>
                                <span v-if="plano_trabalho.pontoFocalSubstituto">
                                    {{ plano_trabalho.pontoFocalSubstituto.nome }}
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on: tooltip}">
                                            <v-btn icon color="primary" small v-on="{ ...tooltip}" @click="() => modalServidor(plano_trabalho.id_ponto_focal_substituto,'Ponto Focal Substituto')">
                                                <v-icon> mdi-file-search-outline </v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Visualizar dados do ponto focal substituto</span>
                                    </v-tooltip>
                                    <v-menu v-if="canEditarPontoFocal()" :close-on-content-click="true">
                                        <template v-slot:activator="{ on: dialog, attrs }">
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on: tooltip}">
                                                    <v-btn icon color="error" small v-bind="attrs" v-on="{ ...tooltip, ...dialog }">
                                                        <v-icon>
                                                            mdi-delete-outline
                                                        </v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Excluir ponto focal substituto</span>
                                            </v-tooltip>
                                        </template>
                                        <v-card>
                                            <v-card-text>
                                                Tem certeza que deseja excluir o ponto focal substituto?
                                            </v-card-text>
                                            <v-card-actions class="justify-center">
                                                <v-btn color="default">
                                                    Cancelar
                                                </v-btn>
                                                <v-btn color="error" @click="deletarPontoFocalSubstituto">
                                                    Confirmar
                                                </v-btn>
                                            </v-card-actions>
                                        </v-card>
                                    </v-menu>
                                </span>
                                <v-tooltip v-else top>
                                    <template v-if="canEditarPontoFocal()" v-slot:activator="{ on: tooltip}">
                                        <v-btn icon color="success" small v-on="{ ...tooltip}" @click="toggleBuscaServidorSiapeCPFdialogDefinirPontoFocalSubstVisible()">
                                            <v-icon>
                                                mdi-account-plus-outline
                                            </v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Adicionar ponto focal substituto</span>
                                </v-tooltip>
                            </p>
                            <v-btn color="primary" small @click="togglePontosFocaisDialog()">
                                Ver lista de Pontos Focais
                            </v-btn>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="6">
                    <v-card class="ma-2">
                        <v-card-title class="text-h5">
                            Escopo de trabalho
                        </v-card-title>
                        <v-card-subtitle>
                            Competências setoriais da equipe, contendo as atribuições da área.
                        </v-card-subtitle>
                        <v-card-text>
                            <v-textarea solo readonly rows="5" no-resize :value="plano_trabalho.ds_escopo">
                            </v-textarea>
                            <v-dialog v-if="canEditarEscopo()" v-model="dialogEscopo" width="50%" :fullscreen="$vuetify.breakpoint.xsOnly">
                                <template v-slot:activator="{ on: on, attrs }">
                                    <v-btn color="success" small v-bind="attrs" v-on="on">
                                        Editar escopo
                                    </v-btn>
                                </template>
                                <v-card>
                                    <v-card-title>
                                        <span class="headline">Editar escopo</span>
                                    </v-card-title>
                                    <v-card-text>
                                        <v-textarea solo rows="7" v-model="escopoAlterado">
                                        </v-textarea>
                                    </v-card-text>
                                    <v-divider></v-divider>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn color="default" @click="dialogEscopo = false; escopoAlterado = plano_trabalho.ds_escopo">
                                            Cancelar
                                        </v-btn>
                                        <v-btn color="success" @click="editarEscopo">
                                            Salvar
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </v-card-text>
                    </v-card>
                    <v-card v-if="canVisualizarSubplano()" style="margin-top:30px;" class="mb-2 mx-2">
                        <v-card-title class="text-h5">
                            Subplanos de trabalho
                        </v-card-title>
                        <v-treeview :items="orderedPlanosFilhos" hoverable>
                            <template slot="label" slot-scope="{ item }">
                                <span class="text-wrap">
                                    {{ item.name }}
                                    <router-link :to="{ name: 'planos-trabalho-detalhar', params: { id: item.id } }">
                                        <v-icon dense color="primary">mdi-file-search-outline</v-icon>
                                    </router-link>
                                </span>
                            </template>
                        </v-treeview>
                    </v-card>
                </v-col>
                <v-col cols="6">
                    <v-card class="ma-2 scroll" id="equipe">
                        <v-card-title class="text-h5">
                            Equipe de trabalho
                        </v-card-title>
                        <v-card-subtitle v-if="canEditarEquipeTrabalho()" class="mt-1">
                            <v-tooltip top>
                                <template v-slot:activator="{ on: tooltip}">
                                    <v-btn color="success" small v-on="{ ...tooltip}" @click="toggleBuscaServidorSiapeCPFdialogAdicionarMembroVisible();">
                                        Adicionar membro
                                    </v-btn>
                                </template>
                                <span>Adicionar um novo membro à equipe de trabalho</span>
                            </v-tooltip>
                        </v-card-subtitle>
                        <v-card-text>

                            <v-simple-table>
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th class="text-left">Nome</th>
                                            <th class="text-left">SIAPE</th>
                                            <th class="text-left">Ações</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="equipe in orderedEquipe" v-if="equipe.id_servidor === plano_trabalho.id_chefia" class="light-green lighten-4">
                                            <td>{{ equipe.servidor.nome }}</td>
                                            <td>{{ equipe.servidor.siape }}</td>
                                            <td>

                                            <v-tooltip v-if="equipe.st_avaliado" top>
                                                <template v-slot:activator="{ on: tooltip}">
                                                    <v-btn icon color="warning" v-on="{ ...tooltip}" small @click.stop="openMetaIndividualDialog(equipe)">
                                                        <v-icon v-if="equipe.ds_meta_individual" color="warning" dense>mdi-clipboard-text-outline</v-icon>
                                                        <v-icon v-else color="warning" dense>mdi-clipboard-alert-outline</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Visualizar meta individual</span>
                                            </v-tooltip>
                                            <v-menu bottom left >
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn fab dark x-small color="blue-grey" v-bind="attrs" v-on="on">
                                                    <v-icon>mdi-dots-vertical</v-icon>
                                                    </v-btn>
                                                </template>
                                                <v-list>
                                                    <v-list-item>
                                                        <v-list-item-title>
                                                             <v-tooltip top>
                                                                <template v-slot:activator="{ on: tooltip}">
                                                                    <v-btn icon color="primary" small v-on="{ ...tooltip}" @click="() => modalServidor(equipe.id_servidor,'Membro')">
                                                                        <v-icon> mdi-file-search-outline </v-icon>
                                                                    </v-btn>
                                                                </template>
                                                                <span>Visualizar dados do membro</span>
                                                            </v-tooltip>
                                                            <v-tooltip  top v-if="showBtnBloquearDesbloquearAvaliacao && cicloVigente">
                                                                <template v-slot:activator="{ on: tooltip}">
                                                                    <v-btn icon color="primary" v-on="{ ...tooltip}" small @click.stop="liberarOuBloquearAvaliacao(equipe.avaliacaoBloqueada, equipe.id_servidor)">
                                                                        <v-icon v-if="equipe.avaliacaoBloqueada"> mdi mdi-lock-open </v-icon>
                                                                        <v-icon v-else> mdi mdi-lock </v-icon>
                                                                    </v-btn>
                                                                </template>
                                                                <span v-if="equipe.avaliacaoBloqueada && cicloVigente">Liberar Avaliação</span>
                                                                <span v-else>Bloquear Avaliação</span>
                                                            </v-tooltip>

                                                            <v-tooltip top>
                                                                <template v-slot:activator="{ on: tooltip}">
                                                                    <v-btn icon color="grey" small v-on="{ ...tooltip }">
                                                                        <v-icon>
                                                                            mdi-delete-outline
                                                                        </v-icon>
                                                                    </v-btn>
                                                                </template>
                                                                <span><strong>{{ equipe.servidor.nome }}</strong> é chefe da equipe, não pode ser removido.</span>
                                                            </v-tooltip>
                                                        </v-list-item-title>
                                                    </v-list-item>
                                                </v-list>
                                            </v-menu>
                                            </td>
                                        </tr>
                                        <tr v-for="plano_filho in orderedPlanosFilhosChefia" class="yellow lighten-4">
                                            <td>{{ plano_filho.chefia.nome }}</td>
                                            <td>{{ plano_filho.chefia.siape }}</td>
                                            <td>
                                                <v-tooltip v-if="plano_filho && plano_filho.equipe_chefia && plano_filho.equipe_chefia.st_avaliado" top>
                                                    <template v-slot:activator="{ on: tooltip}">
                                                        <v-btn v-if="plano_filho.equipe_chefia != null" v-on="{ ...tooltip}" icon color="warning" small @click.stop="openMetaIndividualDialog(plano_filho.equipe_chefia)">
                                                            <v-icon v-if="plano_filho.equipe_chefia != null && plano_filho.equipe_chefia.ds_meta_individual" color="warning" dense>mdi-clipboard-text-outline</v-icon>
                                                            <v-icon v-else color="warning" dense>mdi-clipboard-alert-outline</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>Visualizar meta individual</span>
                                                </v-tooltip>
                                                <v-menu bottom left >
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn fab dark x-small color="blue-grey" v-bind="attrs" v-on="on">
                                                    <v-icon>mdi-dots-vertical</v-icon>
                                                    </v-btn>
                                                </template>
                                                <v-list>
                                                    <v-list-item>
                                                        <v-list-item-title>
                                                            <v-tooltip top>
                                                                <template v-slot:activator="{ on: tooltip}">
                                                                    <v-spacer></v-spacer>
                                                                    <v-tooltip  top></v-tooltip>
                                                                    <v-btn icon color="primary" small v-on="{ ...tooltip}" @click="() => modalServidor(plano_filho.chefia.id,'Servidor(a)')">
                                                                        <v-icon> mdi-file-search-outline </v-icon>
                                                                    </v-btn>
                                                                </template>
                                                                <span>Ver detalhes do servidor</span>
                                                            </v-tooltip>
                                                        </v-list-item-title>
                                                    </v-list-item>
                                                </v-list>
                                                </v-menu>
                                                
                                            </td>
                                        </tr>
                                        <tr v-for="equipe in orderedEquipe" v-if="equipe.id_servidor !== plano_trabalho.id_chefia">
                                            <td>{{ equipe.servidor.nome }}</td>
                                            <td>{{ equipe.servidor.siape }}</td>
                                            <td>
                                                <v-tooltip v-if="equipe.st_avaliado" top>
                                                    <template v-slot:activator="{ on: tooltip}">
                                                        <v-btn icon color="warning" small v-on="{ ...tooltip}" @click.stop="openMetaIndividualDialog(equipe)">
                                                            <v-icon v-if="equipe.ds_meta_individual" color="warning" dense>mdi-clipboard-text-outline</v-icon>
                                                            <v-icon v-else color="warning" dense>mdi-clipboard-alert-outline</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>Visualizar meta individual</span>
                                                </v-tooltip>
                                                <v-menu bottom left >
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn fab dark x-small color="blue-grey" v-bind="attrs" v-on="on">
                                                        <v-icon>mdi-dots-vertical</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <v-list>
                                                        <v-list-item>
                                                            <v-list-item-title>
                                                                <v-tooltip top>
                                                                <template v-slot:activator="{ on: tooltip}">
                                                                    <v-btn icon color="primary" small v-on="{ ...tooltip}" @click="() => modalServidor(equipe.id_servidor,'Membro')">
                                                                        <v-icon> mdi-file-search-outline </v-icon>
                                                                    </v-btn>
                                                                </template>
                                                                <span>Visualizar dados do membro</span>
                                                            </v-tooltip>
                                                            <v-tooltip  top v-if="showBtnBloquearDesbloquearAvaliacao && cicloVigente">
                                                                <template v-slot:activator="{ on: tooltip}">
                                                                    <v-btn icon color="primary" v-on="{ ...tooltip}" small @click.stop="liberarOuBloquearAvaliacao(equipe.avaliacaoBloqueada, equipe.id_servidor)">
                                                                        <v-icon v-if="equipe.avaliacaoBloqueada"> mdi mdi-lock-open </v-icon>
                                                                        <v-icon v-else> mdi mdi-lock </v-icon>
                                                                    </v-btn>
                                                                </template>
                                                                <span v-if="equipe.avaliacaoBloqueada">Liberar Avaliação</span>
                                                                <span v-else>Bloquear Avaliação</span>
                                                            </v-tooltip>
                                                            <v-menu v-if="canEditarEquipeTrabalho()" :close-on-content-click="true">
                                                                <template v-slot:activator="{ on: dialog, attrs }">
                                                                    <v-tooltip top>
                                                                        <template v-slot:activator="{ on: tooltip}">
                                                                            <v-btn icon color="error" small v-bind="attrs" v-on="{ ...tooltip, ...dialog }">
                                                                                <v-icon>
                                                                                    mdi-delete-outline
                                                                                </v-icon>
                                                                            </v-btn>
                                                                        </template>
                                                                        <span>Excluir membro da equipe de trabalho</span>
                                                                    </v-tooltip>
                                                                </template>
                                                                <v-card>
                                                                    <v-card-text>
                                                                        Tem certeza que deseja excluir <strong>{{ equipe.servidor.nome }}</strong> da equipe de trabalho?
                                                                    </v-card-text>
                                                                    <v-card-actions class="justify-center">
                                                                        <v-btn color="default">
                                                                            Cancelar
                                                                        </v-btn>
                                                                        <v-btn color="error" @click="excluirMembro(equipe.id_equipe_trabalho)">
                                                                            Confirmar
                                                                        </v-btn>
                                                                    </v-card-actions>
                                                                </v-card>
                                                            </v-menu>
                                                            </v-list-item-title>
                                                        </v-list-item>
                                                    </v-list>
                                                </v-menu>
                                            </td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-card class="mb-2 mx-2">
                        <v-card-title class="text-h5">
                            Meta Intermediária
                        </v-card-title>
                        <v-card-text>
                            <v-dialog v-model="dialogAdicionarMetaIntermediaria" width="70%" :fullscreen="$vuetify.breakpoint.xsOnly">
                                <template v-slot:activator="{ on: dialog, attrs }">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on: tooltip}">
                                            <v-spacer></v-spacer>
                                            <v-btn v-if="canEditarMetaIntermediaria()" color="success" small @click="openDialogMetaIntermediaria()" v-bind="attrs" v-on="{ ...tooltip, ...dialog }">
                                                Adicionar Meta
                                            </v-btn>
                                        </template>
                                        <span>Adicionar Meta Intermediária</span>
                                    </v-tooltip>
                                </template>
                                <v-card>
                                    <v-form ref="form_metaIntermediaria">
                                        <v-card-title class="mb-2">
                                            <span class="text-h5">Meta Intermediária</span>
                                        </v-card-title>
                                        <v-card-text>
                                            <v-col>
                                                <v-textarea dense hide-details="auto" outlined v-model="metaIntermediariaDialog.ds_meta_intermediaria" rows="3" label="Descrição" :readonly="disableMetaIntermediariaEdit" counter=200 required :rules="[() => !!metaIntermediariaDialog.ds_meta_intermediaria || 'Campo obrigatório.']">
                                                </v-textarea>
                                            </v-col>
                                            <v-col>
                                                <v-text-field class="mt-n3" v-model="metaIntermediariaDialog.nm_indicador" :readonly="disableMetaIntermediariaEdit" dense hide-details label="Indicador:" outlined></v-text-field>
                                            </v-col>
                                            <v-col>
                                                <v-text-field v-model="metaIntermediariaDialog.nm_formula" :readonly="disableMetaIntermediariaEdit" dense hide-details label="Fórmula de Cálculo:" outlined></v-text-field>
                                            </v-col>
                                            <v-col>
                                                <v-text-field v-model="metaIntermediariaDialog.nm_fonte_info" :readonly="disableMetaIntermediariaEdit" dense hide-details label="Fonte de Informação:" outlined></v-text-field>
                                            </v-col>
                                            <v-divider></v-divider>
                                            <v-card class="mb-2 mx-2 pa-3">
                                                <span class="font-weight-bold">
                                                    Informações sobre a realização da meta
                                                </span>
                                                <v-col>
                                                    <v-select filled v-model="metaIntermediariaDialog.id_unidade_medida" :readonly="disableMetaIntermediariaEdit" :items="unidadeMedida" item-value="id_unidade_medida" item-text="nm_unidade_medida" label="Unidade de medida:" :rules="[() => !!metaIntermediariaDialog.id_unidade_medida || 'Campo obrigatório.']" dense hide-details="auto">
                                                    </v-select>
                                                </v-col>
                                                <v-col>
                                                    <v-text-field v-if="dialogAdicionarMetaIntermediaria" :readonly="disableMetaIntermediariaEdit" v-model="metaIntermediariaDialog.nr_previsto" dense hide-details label="Previsto:" outlined v-mask="metaIntermediariaNr_mask" required outlined :rules="[() => !!metaIntermediariaDialog.nr_previsto || 'Campo obrigatório.']">></v-text-field>
                                                </v-col>
                                                <v-col>
                                                    <v-text-field v-if="dialogAdicionarMetaIntermediaria" :readonly="disableMetaIntermediariaEdit" v-model="metaIntermediariaDialog.nr_realizado" v-mask="metaIntermediariaNr_mask" dense hide-details label="Realizado:" outlined></v-text-field>
                                                </v-col>
                                            </v-card>
                                        </v-card-text>
                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn color="default" @click="dialogAdicionarMetaIntermediaria = false;">
                                                Cancelar
                                            </v-btn>
                                            <v-btn color="success" v-if="btSalvaMeta && !disableMetaIntermediariaEdit" @click="salvarMetaIntermediaria">
                                                Salvar
                                            </v-btn>
                                            <v-btn color="success" v-if="btEditaMeta && !disableMetaIntermediariaEdit" @click="atualizaMetaIntermediaria">
                                                Atualizar
                                            </v-btn>
                                        </v-card-actions>
                                    </v-form>
                                </v-card>
                            </v-dialog>
                            <v-simple-table>
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th class="text-left">Descrição</th>
                                            <th class="text-left">Unidade</th>
                                            <th class="text-left">Previsto</th>
                                            <th class="text-left">Realizado</th>
                                            <th class="text-left" width="12%">Ações</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="meta in orderedMetasIntermediarias">
                                            <td>{{meta.ds_meta_intermediaria}}</td>
                                            <td>{{meta.unidade_medida.nm_unidade_medida}}</td>
                                            <td>{{meta.nr_previsto}}</td>
                                            <td>{{meta.nr_realizado}}</td>
                                            <td>
                                                <v-btn icon color="success" small @click="openDialogEditarMetaIntermediaria(meta)">
                                                    <v-icon color="primary" dense>mdi-eye</v-icon>
                                                </v-btn>
                                                <v-btn v-if="canEditarMetaIntermediaria()" icon color="success" small @click="openDialogEditarMetaIntermediaria(meta)">
                                                    <v-icon color="warning" dense>mdi-pencil</v-icon>
                                                </v-btn>
                                                <v-menu v-if="canEditarMetaIntermediaria()" :close-on-content-click="true">
                                                    <template v-slot:activator="{ on: dialog, attrs }">
                                                        <v-tooltip top>
                                                            <template v-slot:activator="{ on: tooltip}">
                                                                <v-btn icon color="error" small v-bind="attrs" v-on="{ ...tooltip, ...dialog }">
                                                                    <v-icon> mdi-delete-outline</v-icon>
                                                                </v-btn>
                                                            </template>
                                                            <span>Excluir meta intermediária</span>
                                                        </v-tooltip>
                                                    </template>
                                                    <v-card>
                                                        <v-card-text>
                                                            Tem certeza que deseja excluir a meta intermediária <strong>{{meta.ds_meta_intermediaria}}</strong>?
                                                        </v-card-text>
                                                        <v-card-actions class="justify-center">
                                                            <v-btn color="default">
                                                                Cancelar
                                                            </v-btn>
                                                            <v-btn color="error" @click="excluirMetaIntermediaria(meta.id_meta_intermediaria)">
                                                                Confirmar
                                                            </v-btn>
                                                        </v-card-actions>
                                                    </v-card>
                                                </v-menu>
                                            </td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-card>
        <v-dialog v-model="dialogMetaIndividual" width="50%" :fullscreen="$vuetify.breakpoint.xsOnly">
            <v-card>
                <v-card-title>
                    <span class="headline">Meta individual</span>
                    <v-card-subtitle>
                        <v-icon color="primary">mdi-chevron-double-right</v-icon>
                        {{ editaEquipe.servidor.nome }}
                    </v-card-subtitle>
                </v-card-title>
                <v-card-text>
                    <v-textarea solo rows="7" v-model="editaEquipe.ds_meta_individual" :readonly="!canEditarMetaIndividual(editaEquipe.servidor.id)" :rules="[v => (v || '' ).length <= 500 || 'A Meta Individual deve conter no máximo 500 caracteres']" hint="Para atender ao Índice de Gestão e Governança (IGG) é recomendado que o avaliador utilize este espaço para, além de incluir a meta individual, sugerir competências a serem trabalhadas durante o ciclo." persistent-hint :disabled="comAvaliacao"></v-textarea>
                    <div>{{ informarDeAcordo() }}</div>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <span v-if="!validarEdicaoDeAcordo(editaEquipe.servidor.id) || usuarioAutenticado.id_servidor == editaEquipe.servidor.id">
                        <v-btn color="primary" @click="editarDeAcordoMetaIndividual" :disabled="validarEdicaoDeAcordo(editaEquipe.servidor.id)" width="95%">
                            Ciente
                        </v-btn>
                    </span>
                    <v-btn color="success" @click="editarMetaIndividual" v-if="comAvaliacao == false && canEditarMetaIndividual(editaEquipe.servidor.id)" :disabled="validarTamanhoMetaIndividual">
                        Salvar
                    </v-btn>
                    <v-btn v-if="comAvaliacao" text disabled>Servidor já avaliado</v-btn>
                    <v-btn color="default" @click="closeDialogMetaIndividual">
                        Cancelar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<style>
    .v-messages__message {
        color: red;
    }
</style>

<script>
    import {
        getPlanoTrabalho,
        updatePlanoTrabalho,
        trocarChefiaPlano,
        getPontoFocalPai
    } from '../api/plano_trabalho';
    import {
        getEquipeTrabalhoBySiapeCiclo,
        createEquipeTrabalho,
        deleteEquipeTrabalho,
        updateEquipeTrabalho
    } from '../api/equipe_trabalho';
    import {
        getMetaIntermediariaByPlanoTrabalho,
        getMetaIntermediariaByIdMeta,
        deleteMetaIntermediaria,
        createMetaIntermediaria,
        updateMetaIntermediaria
    } from '../api/meta_intermediaria';
    import {
        getUnidadesMedidas
    } from '../api/unidades_medidas';
    import {
        getDetalheServidor
    } from '../api/servidor';
    
    import {
        getFuncoes
    } from '../api/funcao';

    import {
        getUsuarioAutenticado
    } from '../api/auth';
    import servidorDialog from '../components/ServidorDialog.vue';
    import pontosFocaisDialog from '../components/PontosFocaisDialog.vue';
    import BuscaServidorSiapeCPFdialog from '../components/BuscaServidorSiapeCPFdialog.vue';
    import BuscaServidorSiapeCPFdialogAdicionarMembro from '../components/BuscaServidorSiapeCPFdialogAdicionarMembro.vue';
    import BuscaServidorSiapeCPFdialogDefinirChefiaImediata from '../components/BuscaServidorSiapeCPFdialogDefinirChefiaImediata.vue';
    import BuscaServidorSiapeCPFdialogDefinirChefiaImediataSubst from '../components/BuscaServidorSiapeCPFdialogDefinirChefiaImediataSubst.vue';
    import BuscaServidorSiapeCPFdialogAdicionarPontoFocal from '../components/BuscaServidorSiapeCPFdialogAdicionarPontoFocal.vue';
    import BuscaServidorSiapeCPFdialogAdicionarPontoFocalSubst from '../components/BuscaServidorSiapeCPFdialogAdicionarPontoFocalSubst.vue';
    import LiberarBloquearAvaliacaoDialog from '../components/LiberarBloquearAvaliacaoDialog.vue'

    import { getAvaliacaoByAvaliado } from '../api/avaliacao';

    export function formataNumeroComPontosEVirgulasMask(value) {
        if (!value) return []
        let retorno = value.replace(/[^0-9]/g, '');
        if (retorno.length > 17) {
            retorno = retorno.substring(0, 17)
        }
        const formatPrice = (numberStringed) => { 
            let val = (Number(numberStringed) / 100).toFixed(2).replace('.', ',') 
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        }
        return [...formatPrice(retorno)]
    }

    export default {
        name: 'PlanosTrabalhoDetalhar',
        components: {
            servidorDialog,
            BuscaServidorSiapeCPFdialog,
            BuscaServidorSiapeCPFdialogAdicionarMembro,
            BuscaServidorSiapeCPFdialogDefinirChefiaImediata,
            pontosFocaisDialog,
            BuscaServidorSiapeCPFdialogDefinirChefiaImediataSubst,
            BuscaServidorSiapeCPFdialogAdicionarPontoFocal,
            BuscaServidorSiapeCPFdialogAdicionarPontoFocalSubst,
            LiberarBloquearAvaliacaoDialog
        },
        data() {
            return {
                usuarioAutenticado: [],
                plano_trabalho: {
                    ciclo: [],
                    chefia: [],
                    substituto: [],
                    pontoFocal: [],
                    pontoFocalSubstituto: [],
                    unidade_avaliacao: {
                        gestor_setorial: [],
                        gestor_substituto: [],
                    },
                    equipe_trabalho: [],
                    planos_filhos: [],
                    metas_intermediarias: []
                },
                cicloVigente: false,
                fasePermiteEdicao: false,
                dialogCronograma: false,
                dialogEscopo: false,
                escopoAlterado: null,
                dialogMetaIndividual: false,
                dialogAdicionarMetaIntermediaria: false,
                editaEquipe: {
                    ds_meta_individual: '',
                    st_acordado: false,
                    dt_acordo: '',
                    servidor: []
                },
                siapeBusca: null,
                siapeBuscaResultado: [],
                unidadeMedida: [],
                metaIntermediariaDialog: {
                    id_meta_intermediaria: null,
                    ds_meta_intermediaria: '',
                    nm_indicador: '',
                    nm_formula: '',
                    nm_fonte_info: '',
                    id_unidade_medida: 4,
                    nr_previsto: null,
                    nr_realizado: null,
                    id_plano_trabalho: null,
                },
                metaIntermediariaNr_mask: formataNumeroComPontosEVirgulasMask,
                disableMetaIntermediariaEdit: false,
                btSalvaMeta: false,
                btEditaMeta: false,

                buscaServidorSiapeCPFdialog_title: '',
                buscaServidorSiapeCPFdialog_tipo: '',
                funcoes: [],
                showBtnPlanoSuperior: false,
                showBtnBloquearDesbloquearAvaliacao: false,
                fasePermiteTrocaChefia: false,
                pontoFocalSuperior: null,
                pontoFocalSubstitutoSuperior: null,
                comAvaliacao: false
            };
        },
        created() {
            getUsuarioAutenticado(this.$store.getters['auth/usuarioAutenticadoToken'])
                .then(response => {
                    this.usuarioAutenticado = response.data.data;

                    var idPlano = this.$route.params.id_plano_trabalho ? this.$route.params.id_plano_trabalho : this.$route.params.id;
                    getPlanoTrabalho(idPlano)
                        .then(response => {
                            getFuncoes()
                                .then(response2 => {
                                    this.funcoes = response2.data.data
                                    this.plano_trabalho = response.data.data;
                                    this.loadAll_st_avaliado();
                                    this.escopoAlterado = this.plano_trabalho.ds_escopo;
                                    const current = new Date();
                                    if ((this.parseToDateInicio(this.plano_trabalho.ciclo.dt_inicio) <= current) 
                                    && (this.parseToDateEncerramento(this.plano_trabalho.ciclo.dt_encerramento) >= current)) {
                                        this.cicloVigente = true;
                                        let faseElaboracao = this.plano_trabalho.ciclo.fases.find(item => item.nm_fase == 'Elaboração do Plano de Trabalho');
                                        
                                        if (this.hasPerfilAdminOrGestorOrgao()) {
                                            this.fasePermiteEdicao = true;
                                        } else {
                                            if ((this.parseToDateInicio(faseElaboracao.dt_inicio) <= current) 
                                            && (this.parseToDateEncerramento(faseElaboracao.dt_encerramento) >= current)) {
                                            this.fasePermiteEdicao = true;
                                            } else {
                                                let faseRevisao = this.plano_trabalho.ciclo.fases.find(item => item.nm_fase == 'Revisão do Plano de Trabalho');
                                            if ((this.parseToDateInicio(faseRevisao.dt_inicio) <= current) 
                                                && (this.parseToDateEncerramento(faseRevisao.dt_encerramento) >= current)) {
                                                    this.fasePermiteEdicao = true;
                                                }
                                            }
                                        }
                                        
                                        let faseAvaliacao = this.plano_trabalho.ciclo.fases.find(item => item.nm_fase == 'Avaliação dos Servidores');

                                        if (this.hasPerfilAdminOrGestorOrgao()) {
                                            this.fasePermiteTrocaChefia = true;
                                        } else {
                                            if ( (this.parseToDateInicio(faseAvaliacao.dt_inicio) <= current) && 
                                                (this.parseToDateEncerramento(faseAvaliacao.dt_encerramento) >= current)) {
                                                this.fasePermiteTrocaChefia = true;
                                            }
                                        }
                                        
                                    }
                                    if (!this.cicloVigente) {
                                        this.$store.dispatch('alertas/show', {
                                            tipo: 'Warning',
                                            titulo: 'O ciclo desse plano de trabalho não está vigente, não permitindo alteração de dados.',
                                        });
                                    } else if (!this.fasePermiteEdicao) {
                                        this.$store.dispatch('alertas/show', {
                                            tipo: 'Warning',
                                            titulo: 'A fase atual do ciclo não permite edição dos dados do plano de trabalho.',
                                        });
                                    }
                                });
                        }).catch(erro => {
                            this.$vuetify.goTo(0);
                            this.$router.push(
                                {
                                    name: 'planos-trabalho-listar',
                                },
                                () => this.$store.dispatch('alertas/show', {
                                    tipo: 'Erro',
                                    titulo: 'Plano de trabalho inexistente ou removido.',
                                })
                            );
                        });
                });

            getUnidadesMedidas()
                .then(response => {
                    this.unidadeMedida = response.data.data;
                });

            getPontoFocalPai(this.$route.params.id_plano_trabalho ? this.$route.params.id_plano_trabalho : this.$route.params.id).then(response => {
                this.pontoFocalSuperior = response.data.data.pontoFocal;
                this.pontoFocalSubstitutoSuperior = response.data.data.pontoFocalSubstituto;
            });

            if (this.$store.getters['auth/usuarioAutenticadoRoles'].indexOf("administrador") >= 0
                || this.$store.getters['auth/usuarioAutenticadoRoles'].indexOf("pontoFocal") >= 0
                || this.$store.getters['auth/usuarioAutenticadoRoles'].indexOf("gestorSetorial") >= 0
                || this.$store.getters['auth/usuarioAutenticadoRoles'].indexOf("chefia") >= 0
                || this.$store.getters['auth/usuarioAutenticadoRoles'].indexOf("gestorOrgao") >= 0
            ) {
                this.showBtnPlanoSuperior = true;
            }
            if (this.$store.getters['auth/usuarioAutenticadoRoles'].indexOf("administrador") >= 0
                || this.$store.getters['auth/usuarioAutenticadoRoles'].indexOf("gestorOrgao") >= 0
            ) {
                this.showBtnBloquearDesbloquearAvaliacao = true;
            }
        },
        computed: {
            orderedEquipe: function () {
                var listaOrdenada;
                let chefes = this.plano_trabalho.equipe_trabalho.filter(item => item.id_servidor == this.plano_trabalho.id_chefia);
                let listaEquipe = this.plano_trabalho.equipe_trabalho.filter(item => item.id_servidor != this.plano_trabalho.id_chefia);
                listaEquipe = _.orderBy(listaEquipe, 'servidor.nome');
                listaOrdenada = chefes.concat(listaEquipe);

                return listaOrdenada;
            },
            orderedPlanosFilhos: function () {
                return _.orderBy(this.plano_trabalho.planos_filhos, 'name');
            },
            orderedPlanosFilhosChefia: function () {
                let planos_filhos_nao_nulos = this.plano_trabalho.planos_filhos.filter(item => item.chefia !== null);
                return _.orderBy(planos_filhos_nao_nulos, 'chefia.name');
            },
            validarTamanhoMetaIndividual() {
                if (this.editaEquipe.ds_meta_individual == null)
                    return true;
                else
                    return this.editaEquipe.ds_meta_individual.length > 500;
            },
            orderedMetasIntermediarias: function () {
                return _.orderBy(this.plano_trabalho.metas_intermediarias, 'id_meta_intermediaria');
            }
        },
        methods: {
            parseToDateInicio(dateString_YYYY_MM_DD){ // criar uma funcao que recebe a data string conforme o banco "2022-10-02" e retorna objeto tipo Date javascript referente ao dia
                let pieces = dateString_YYYY_MM_DD.split("/");
                return new Date(Number(pieces[2]), Number(pieces[1]) - 1, Number(pieces[0]));        
            },                         
            parseToDateEncerramento(dateString_YYYY_MM_DD){ // criar uma funcao que recebe a data string conforme o banco "2022-10-02" e retorna objeto tipo Date javascript referente ao dia
                let pieces = dateString_YYYY_MM_DD.split("/");
                return new Date(new Date(Number(pieces[2]), Number(pieces[1]) - 1, Number(pieces[0])).getTime() + (1000 * 60 * 60 * 24 - 1));        
            },                         
            loadAll_st_avaliado(){
                this.plano_trabalho.equipe_trabalho.map(eq => {
                    if (eq.servidor && eq.servidor.id_funcao){
                        eq.st_avaliado = this.funcoes.filter(func => func.id_funcao === eq.servidor.id_funcao)[0].st_avaliado
                    } else {
                        eq.st_avaliado = true;
                    }
                });
                this.plano_trabalho.planos_filhos.map(plano_filho => {
                    if (plano_filho.equipe_chefia){
                        if (plano_filho.equipe_chefia.servidor && plano_filho.equipe_chefia.servidor.id_funcao){
                            plano_filho.equipe_chefia.st_avaliado = this.funcoes.filter(func => func.id_funcao === plano_filho.equipe_chefia.servidor.id_funcao)[0].st_avaliado
                        } else {
                            plano_filho.equipe_chefia.st_avaliado = true;
                        }
                    }
                });
            },
            adicionarChefiaSubstituta(servidor) {
                let plano_trabalho = {
                    id_ciclo: this.plano_trabalho.id_ciclo,
                    id_unidade_avaliacao: this.plano_trabalho.id_unidade_avaliacao,
                    id_unidade: this.plano_trabalho.id_unidade,
                    nm_unidade: this.plano_trabalho.nm_unidade,
                    id_substituto: servidor.id_servidor
                };
                updatePlanoTrabalho(this.plano_trabalho.id_plano_trabalho, JSON.stringify(plano_trabalho))
                    .then(response => {
                        this.plano_trabalho = response.data.data;
                        this.$store.dispatch('alertas/show', {
                            tipo: 'Sucesso',
                            titulo: 'Chefia substituta cadastrada com sucesso!',
                        });
                        this.$vuetify.goTo(0);
                    });
            },
            deletarChefiaSubstituta() {
                let plano_trabalho = {
                    id_ciclo: this.plano_trabalho.id_ciclo,
                    id_unidade_avaliacao: this.plano_trabalho.id_unidade_avaliacao,
                    id_unidade: this.plano_trabalho.id_unidade,
                    nm_unidade: this.plano_trabalho.nm_unidade,
                    id_substituto: null
                };
                updatePlanoTrabalho(this.plano_trabalho.id_plano_trabalho, JSON.stringify(plano_trabalho))
                    .then(response => {
                        this.plano_trabalho = response.data.data;
                        this.$store.dispatch('alertas/show', {
                            tipo: 'Sucesso',
                            titulo: 'Chefia substituta excluída com sucesso!',
                        });
                        this.$vuetify.goTo(0);
                    });
            },
            adicionarChefiaTitular(servidor) {
                let plano_trabalho = {
                    id_ciclo: this.plano_trabalho.id_ciclo,
                    id_unidade_avaliacao: this.plano_trabalho.id_unidade_avaliacao,
                    id_unidade: this.plano_trabalho.id_unidade,
                    nm_unidade: this.plano_trabalho.nm_unidade,
                    id_chefia: servidor.id_servidor
                };
                updatePlanoTrabalho(this.plano_trabalho.id_plano_trabalho, JSON.stringify(plano_trabalho))
                    .then(response => {
                        this.plano_trabalho = response.data.data;
                        this.$store.dispatch('alertas/show', {
                            tipo: 'Sucesso',
                            titulo: 'Chefia imediata cadastrada com sucesso!',
                        });
                        this.$vuetify.goTo(0);
                    });
            },

            deletarChefiaTitular(chefia) {
                let plano_trabalho = {
                    id_ciclo: this.plano_trabalho.id_ciclo,
                    id_unidade_avaliacao: this.plano_trabalho.id_unidade_avaliacao,
                    id_unidade: this.plano_trabalho.id_unidade,
                    nm_unidade: this.plano_trabalho.nm_unidade,
                    id_chefia: null,
                    id_chefia_servidor: chefia.id
                };
                updatePlanoTrabalho(this.plano_trabalho.id_plano_trabalho, JSON.stringify(plano_trabalho))
                    .then(response => {
                        this.plano_trabalho = response.data.data;
                        this.$store.dispatch('alertas/show', {
                            tipo: 'Sucesso',
                            titulo: 'Chefia imediata excluída com sucesso!',
                        });
                        this.$vuetify.goTo(0);
                    });
            },
            adicionarMembro(servidor) {
                if (servidor.equipes_trabalho.length) {
                    deleteEquipeTrabalho(servidor.equipes_trabalho[0].id_equipe_trabalho)
                        .then(response => {
                            this.plano_trabalho.equipe_trabalho = this.plano_trabalho.equipe_trabalho.filter(item => item.id_equipe_trabalho !== servidor.equipes_trabalho[0].id_equipe_trabalho);
                            this.salvarEquipe(servidor);
                            this.$vuetify.goTo(0);
                            this.closeDialogAdicionarMembro();
                        });
                } else {
                    this.salvarEquipe(servidor);
                    this.$vuetify.goTo(0);
                    this.closeDialogAdicionarMembro();
                }
            },

            salvarEquipe(servidor) {
                let equipe_trabalho = {
                    id_plano_trabalho: this.plano_trabalho.id_plano_trabalho,
                    id_servidor: servidor.id_servidor,
                    id_gratificacao: servidor.id_gratificacao,
                    id_funcao: servidor.id_funcao,
                };
                createEquipeTrabalho(JSON.stringify(equipe_trabalho))
                    .then(
                        response => {
                            this.plano_trabalho.equipe_trabalho.push(response.data.data);
                            this.$store.dispatch('alertas/show', {
                                tipo: 'Sucesso',
                                titulo: 'Servidor adicionado na equipe de trabalho com sucesso!',
                            });
                        },
                        error => {
                            this.$store.dispatch('alertas/show', {
                                tipo: 'Erro',
                                titulo: 'Ocorreu um erro ao adicionar o servidor à equipe de trabalho. Tente novamente.',
                            });
                        }
                    );
            },
            excluirMembro(id) {
                deleteEquipeTrabalho(id)
                    .then(response => {
                        this.plano_trabalho.equipe_trabalho = this.plano_trabalho.equipe_trabalho.filter(item => item.id_equipe_trabalho !== id);

                        this.$store.dispatch('alertas/show', {
                            tipo: 'Sucesso',
                            titulo: 'Servidor excluído equipe de trabalho com sucesso!',
                        });
                        this.$vuetify.goTo(0);
                        this.closeDialogAdicionarMembro();
                    });
            },
            removerChefiaDePlanoDeServidor(servidor) {
                let plano_trabalho = {
                    id_ciclo: servidor.equipes_trabalho[0].plano_trabalho.id_ciclo,
                    id_unidade_avaliacao: servidor.equipes_trabalho[0].plano_trabalho.id_unidade_avaliacao,
                    id_unidade: servidor.equipes_trabalho[0].plano_trabalho.id_unidade,
                    nm_unidade: servidor.equipes_trabalho[0].plano_trabalho.nm_unidade,
                    id_chefia: null
                };
                updatePlanoTrabalho(servidor.equipes_trabalho[0].plano_trabalho.id_plano_trabalho, JSON.stringify(plano_trabalho))
                    .then(response => {
                        this.$store.dispatch('alertas/show', {
                            tipo: 'Sucesso',
                            titulo: 'Chefia imediata excluída com sucesso!',
                        });
                        this.$vuetify.goTo(0);
                    });
            },
            removerChefiaSubstDePlanoDeServidor(servidor) {
                let plano_trabalho = {
                    id_ciclo: servidor.equipes_trabalho[0].plano_trabalho.id_ciclo,
                    id_unidade_avaliacao: servidor.equipes_trabalho[0].plano_trabalho.id_unidade_avaliacao,
                    id_unidade: servidor.equipes_trabalho[0].plano_trabalho.id_unidade,
                    nm_unidade: servidor.equipes_trabalho[0].plano_trabalho.nm_unidade,
                    id_substituto: null
                };
                updatePlanoTrabalho(servidor.equipes_trabalho[0].plano_trabalho.id_plano_trabalho, JSON.stringify(plano_trabalho))
                    .then(response => {
                        this.$store.dispatch('alertas/show', {
                            tipo: 'Sucesso',
                            titulo: 'Chefia imediata substituta excluída com sucesso!',
                        });
                        this.$vuetify.goTo(0);
                    });
            },
            closeDialogAdicionarMembro() {
                this.dialogAdicionarMembro = false;
                this.siapeBusca = null;
                this.siapeBuscaResultado = [];
            },

            openMetaIndividualDialog(equipe) {
                
                if (equipe.id_servidor) {
                    
                    getAvaliacaoByAvaliado(equipe.id_servidor).then(res => {
                        if (res.data.data) {
                            this.comAvaliacao = true;
                            this.editaEquipe = JSON.parse(JSON.stringify(equipe));
                            this.dialogMetaIndividual = true;
                        } else {
                            this.comAvaliacao = false;
                            this.editaEquipe = JSON.parse(JSON.stringify(equipe));
                            this.dialogMetaIndividual = true;
                        }
                    });
                } else {
                    this.comAvaliacao = false;
                    this.editaEquipe = JSON.parse(JSON.stringify(equipe));
                    this.dialogMetaIndividual = true;
                }
            },

            editarMetaIndividual() {
                this.editaEquipe.st_acordado = false;
                this.editaEquipe.dt_acordo = '';
                updateEquipeTrabalho(this.editaEquipe.id_equipe_trabalho, this.editaEquipe)
                    .then(response => {
                        if (this.plano_trabalho.id_plano_trabalho === this.editaEquipe.id_plano_trabalho) {
                            this.plano_trabalho.equipe_trabalho = this.plano_trabalho.equipe_trabalho.filter(item => item.id_equipe_trabalho !== response.data.data.id_equipe_trabalho);
                            this.plano_trabalho.equipe_trabalho.push(response.data.data);
                        } else {
                            let plano_trabalho_filho = this.plano_trabalho.planos_filhos.filter(item => item.id === response.data.data.id_plano_trabalho)[0];
                            plano_trabalho_filho.equipe_chefia = response.data.data;
                        }

                        this.$store.dispatch('alertas/show', {
                            tipo: 'Sucesso',
                            titulo: 'Meta individual alterada com sucesso!',
                        });
                        this.$vuetify.goTo(0);
                        this.closeDialogMetaIndividual();
                        this.loadAll_st_avaliado();
                    });
            },
            editarDeAcordoMetaIndividual() {
                this.editaEquipe.st_acordado = true;
                this.editaEquipe.dt_acordo = new Date().toISOString().slice(0, 10);
                updateEquipeTrabalho(this.editaEquipe.id_equipe_trabalho, this.editaEquipe)
                    .then(response => {
                        this.plano_trabalho.equipe_trabalho = this.plano_trabalho.equipe_trabalho.filter(item => item.id_equipe_trabalho !== response.data.data.id_equipe_trabalho);
                        this.plano_trabalho.equipe_trabalho.push(response.data.data);
                        this.loadAll_st_avaliado()
                        this.$store.dispatch('alertas/show', {
                            tipo: 'Sucesso',
                            titulo: 'Meta acordada com sucesso!',
                        });
                        this.$vuetify.goTo(0);
                        this.closeDialogMetaIndividual();
                    });
            },
            closeDialogMetaIndividual() {
                this.dialogMetaIndividual = false;
                this.editaEquipe = {
                    ds_meta_individual: '',
                    st_acordado: false,
                    dt_acordo: '',
                    servidor: []
                };
            },
            editarEscopo() {
                let plano_trabalho = {
                    id_ciclo: this.plano_trabalho.id_ciclo,
                    id_unidade_avaliacao: this.plano_trabalho.id_unidade_avaliacao,
                    id_unidade: this.plano_trabalho.id_unidade,
                    nm_unidade: this.plano_trabalho.nm_unidade,
                    ds_escopo: this.escopoAlterado
                };
                updatePlanoTrabalho(this.plano_trabalho.id_plano_trabalho, JSON.stringify(plano_trabalho))
                    .then(response => {
                        this.plano_trabalho = response.data.data;
                        this.$store.dispatch('alertas/show', {
                            tipo: 'Sucesso',
                            titulo: 'Escopo alterado com sucesso!',
                        });
                        this.$vuetify.goTo(0);
                        this.dialogEscopo = false;
                    });
            },
            adicionarPontoFocal(servidor) {
                let plano_trabalho = {
                    id_ciclo: this.plano_trabalho.id_ciclo,
                    id_unidade_avaliacao: this.plano_trabalho.id_unidade_avaliacao,
                    id_unidade: this.plano_trabalho.id_unidade,
                    nm_unidade: this.plano_trabalho.nm_unidade,
                    id_ponto_focal: servidor.id_servidor
                };
                updatePlanoTrabalho(this.plano_trabalho.id_plano_trabalho, JSON.stringify(plano_trabalho))
                    .then(response => {
                        this.plano_trabalho = response.data.data;
                        this.$store.dispatch('alertas/show', {
                            tipo: 'Sucesso',
                            titulo: 'Ponto focal cadastrado com sucesso!',
                        });
                        this.$vuetify.goTo(0);
                    });
            },
            deletarPontoFocal() {
                let plano_trabalho = {
                    id_ciclo: this.plano_trabalho.id_ciclo,
                    id_unidade_avaliacao: this.plano_trabalho.id_unidade_avaliacao,
                    id_unidade: this.plano_trabalho.id_unidade,
                    nm_unidade: this.plano_trabalho.nm_unidade,
                    id_ponto_focal: null
                };
                updatePlanoTrabalho(this.plano_trabalho.id_plano_trabalho, JSON.stringify(plano_trabalho))
                    .then(response => {
                        this.plano_trabalho = response.data.data;
                        this.$store.dispatch('alertas/show', {
                            tipo: 'Sucesso',
                            titulo: 'Ponto focal excluído com sucesso!',
                        });
                        this.$vuetify.goTo(0);
                    });
            },
            adicionarPontoFocalSubstituto(servidor) {
                let plano_trabalho = {
                    id_ciclo: this.plano_trabalho.id_ciclo,
                    id_unidade_avaliacao: this.plano_trabalho.id_unidade_avaliacao,
                    id_unidade: this.plano_trabalho.id_unidade,
                    nm_unidade: this.plano_trabalho.nm_unidade,
                    id_ponto_focal_substituto: servidor.id_servidor
                };
                updatePlanoTrabalho(this.plano_trabalho.id_plano_trabalho, JSON.stringify(plano_trabalho))
                    .then(response => {
                        this.plano_trabalho = response.data.data;
                        this.$store.dispatch('alertas/show', {
                            tipo: 'Sucesso',
                            titulo: 'Ponto focal substituto cadastrado com sucesso!',
                        });
                        this.$vuetify.goTo(0);
                    });
            },
            deletarPontoFocalSubstituto() {
                let plano_trabalho = {
                    id_ciclo: this.plano_trabalho.id_ciclo,
                    id_unidade_avaliacao: this.plano_trabalho.id_unidade_avaliacao,
                    id_unidade: this.plano_trabalho.id_unidade,
                    nm_unidade: this.plano_trabalho.nm_unidade,
                    id_ponto_focal_substituto: null
                };
                updatePlanoTrabalho(this.plano_trabalho.id_plano_trabalho, JSON.stringify(plano_trabalho))
                    .then(response => {
                        this.plano_trabalho = response.data.data;
                        this.$store.dispatch('alertas/show', {
                            tipo: 'Sucesso',
                            titulo: 'Ponto focal substituto excluído com sucesso!',
                        });
                        this.$vuetify.goTo(0);
                    });
            },
            modalServidor(id_servidor, tipoServidor) {
                var servidor;
                getDetalheServidor(id_servidor)
                    .then(response => {
                        servidor = response.data.data;
                        this.$refs.servidorDialog.mostraServidorDialog(servidor, tipoServidor);
                    });
            },
            openDialogMetaIntermediaria() {
                this.btSalvaMeta = true;
                this.btEditaMeta = false;
                this.metaIntermediariaDialog.id_meta_intermediaria = null;
                this.metaIntermediariaDialog.ds_meta_intermediaria = '';
                this.metaIntermediariaDialog.nm_indicador = '';
                this.metaIntermediariaDialog.nm_formula = '';
                this.metaIntermediariaDialog.nm_fonte_info = '';
                this.metaIntermediariaDialog.id_unidade_medida = 4;
                this.metaIntermediariaDialog.nr_previsto = null;
                this.metaIntermediariaDialog.nr_realizado = null;
                this.metaIntermediariaDialog.id_plano_trabalho = null;
                this.dialogAdicionarMetaIntermediaria = true;
                //this.$refs.form_metaIntermediaria.reset(); // NAO fazer reset pois ele mexe no formulário ao abrir e fechar a dialog. Solução aqui = criar o componente para CRUD meta intermediária e comunicação via eventos.
            },
            openDialogEditarMetaIntermediaria(meta_intermediaria) {
                this.disableMetaIntermediariaEdit = (this.cicloVigente) ? false : true;
                this.metaIntermediariaDialog = JSON.parse(JSON.stringify(meta_intermediaria));
                this.btSalvaMeta = false;
                this.btEditaMeta = true;
                this.dialogAdicionarMetaIntermediaria = true;
            },
            salvarMetaIntermediaria() {
                if (this.$refs['form_metaIntermediaria'].validate()) {
                    this.metaIntermediariaDialog.id_plano_trabalho = this.plano_trabalho.id_plano_trabalho;
                    createMetaIntermediaria(JSON.stringify(this.metaIntermediariaDialog)).then(
                        response => {
                            let newMetaIntermediariaFromResponse = response.data.data
                            newMetaIntermediariaFromResponse.unidade_medida = this.unidadeMedida.find(uM => uM.id_unidade_medida === newMetaIntermediariaFromResponse.id_unidade_medida)
                            this.plano_trabalho.metas_intermediarias.push(newMetaIntermediariaFromResponse);
                            this.$store.dispatch('alertas/show', {
                                tipo: 'Sucesso',
                                titulo: 'Meta Intermediaria adicionada no plano de trabalho com sucesso'
                            });
                        },
                        error => {
                            this.$store.dispatch('alertas/show', {
                                tipo: 'Erro',
                                titulo: 'Ocorreu um erro ao adicionar a meta intermediária. Tente novamente.',
                            });
                        });
                    this.dialogAdicionarMetaIntermediaria = false;
                    this.$vuetify.goTo(0);
                } else {}
            },
            atualizaMetaIntermediaria() {
                if (this.$refs['form_metaIntermediaria'].validate()) {
                    updateMetaIntermediaria(this.metaIntermediariaDialog.id_meta_intermediaria, this.metaIntermediariaDialog)
                        .then(response => {
                            this.plano_trabalho.metas_intermediarias = this.plano_trabalho.metas_intermediarias.filter(item => item.id_meta_intermediaria !== response.data.data.id_meta_intermediaria);
                            this.plano_trabalho.metas_intermediarias.push(response.data.data);
                            this.$store.dispatch('alertas/show', {
                                    tipo: 'Sucesso',
                                    titulo: 'Meta Intermediaria alterada com sucesso!',
                                },
                                error => {
                                    this.$store.dispatch('alertas/show', {
                                        tipo: 'Erro',
                                        titulo: 'Ocorreu um erro ao alterar meta intermediária. Tente novamente.',
                                    });
                                });
                        });
                    this.dialogAdicionarMetaIntermediaria = false;
                    this.$vuetify.goTo(0);
                } else {}
            },
            excluirMetaIntermediaria(id_meta_intermediaria) {
                deleteMetaIntermediaria(id_meta_intermediaria)
                    .then(response => {
                        this.plano_trabalho.metas_intermediarias = this.plano_trabalho.metas_intermediarias.filter(item => item.id_meta_intermediaria !== id_meta_intermediaria);
                        this.$store.dispatch('alertas/show', {
                            tipo: 'Sucesso',
                            titulo: 'Meta Intermediaria excluída com sucesso!',
                        });
                    });
                this.$vuetify.goTo(0);

            },
            validarMostrarMetaIndividual(id_usuario) {
                if (this.plano_trabalho.chefia !== null) {
                    if (this.usuarioAutenticado.id_servidor == this.plano_trabalho.chefia.id)
                        return true;
                }

                if (this.plano_trabalho.unidade_avaliacao.gestor_setorial !== null) {
                    if (this.usuarioAutenticado.id_servidor == this.plano_trabalho.unidade_avaliacao.gestor_setorial.id)
                        return true;
                }

                if (this.plano_trabalho.pontoFocal !== null) {
                    if (this.usuarioAutenticado.id_servidor == this.plano_trabalho.pontoFocal.id)
                        return true;
                }

                return (this.usuarioAutenticado.id_servidor == id_usuario);
            },
            validarEdicaoMetaIndividual(id_servidor) {
                if (this.usuarioAutenticado.id_servidor == id_servidor)
                    return true;

                if (this.usuarioAutenticado.id_servidor == this.plano_trabalho.id_chefia)
                    return false;

                if (this.plano_trabalho.unidade_avaliacao.gestor_setorial !== null) {
                    if (this.usuarioAutenticado.id_servidor == this.plano_trabalho.unidade_avaliacao.gestor_setorial.id)
                        return false;
                }

                if (this.plano_trabalho.pontoFocal !== null) {
                    if (this.usuarioAutenticado.id_servidor == this.plano_trabalho.pontoFocal.id)
                        return false;
                }

                return true;
            },
            validarEdicaoDeAcordo(id_servidor) {
                return !(this.usuarioAutenticado.id_servidor == id_servidor) || this.editaEquipe.st_acordado || this.editaEquipe.ds_meta_individual === null;
            },
            informarDeAcordo() {
                let DataFormatada = ''
                if (this.editaEquipe.st_acordado) {
                    DataFormatada = this.editaEquipe.dt_acordo.slice(8, 10) + "/" + this.editaEquipe.dt_acordo.slice(5, 7) + "/" + this.editaEquipe.dt_acordo.slice(0, 4);
                    return "Meta individual acordada por " + this.editaEquipe.servidor.nome + " em " + DataFormatada;
                }
                return '';
            },
            toggleBuscaServidorSiapeCPFdialogVisible() {
                this.$refs.buscaServidorSiapeCPFdialog.toggleVisible();
            },
            toggleBuscaServidorSiapeCPFdialogAdicionarMembroVisible() {
                this.$refs.buscaServidorSiapeCPFdialogAdicionarMembro.toggleVisible();
            },
            toggleBuscaServidorSiapeCPFdialogDefinirChefiaImediataVisible() {
                this.$refs.buscaServidorSiapeCPFdialogDefinirChefiaImediata.toggleVisible();
            },
            togglePontosFocaisDialog() {
                this.$refs.pontosFocaisDialog.toggleVisible();
                this.$refs.pontosFocaisDialog.mostrarPontosFocais();
            },
            toggleBuscaServidorSiapeCPFdialogDefinirChefiaImediataSubstVisible() {
                this.$refs.buscaServidorSiapeCPFdialogDefinirChefiaImediataSubst.toggleVisible();
            },
            toggleBuscaServidorSiapeCPFdialogDefinirPontoFocalVisible() {
                this.$refs.buscaServidorSiapeCPFdialogDefinirPontoFocal.toggleVisible();
            },
            toggleBuscaServidorSiapeCPFdialogDefinirPontoFocalSubstVisible() {
                this.$refs.buscaServidorSiapeCPFdialogDefinirPontoFocalSubst.toggleVisible();
            },
            recebeuServidorFromBuscaSiapeCPF(servidor, tipo) {
                switch (tipo) {
                    case "PONTO_FOCAL":
                        this.adicionarPontoFocal(servidor);
                        break;
                    case "PONTO_FOCAL_SUBSTITUTO":
                        this.adicionarPontoFocalSubstituto(servidor);
                        break;
                    case "CHEFIA_IMEDIATA":
                        this.adicionarChefiaTitular(servidor);
                        break;
                    case "SUBSTITUTO_LEGAL":
                        this.adicionarChefiaSubstituta(servidor);
                        break;
                }
            },
            recebeuServidorFromBuscaSiapeCPFadicionarMembro(servidor) {
                this.adicionarMembro(servidor);
            },
            recebeuServidorFromBuscaSiapeCPFdefinirChefiaImediata(servidor) {
                this.servidor_buscado = servidor;

                if (servidor.equipes_trabalho && servidor.equipes_trabalho.length > 0 && servidor.equipes_trabalho[0].plano_trabalho && servidor.equipes_trabalho[0].plano_trabalho.id_chefia === servidor.id_servidor) {
                    this.removerChefiaDePlanoDeServidor(servidor)
                }
                this.adicionarMembro(servidor);
                this.adicionarChefiaTitular(servidor);
            },
            recebeuServidorFromBuscaSiapeCPFdefinirChefiaImediataSubst(servidor) {
                this.servidor_buscado = servidor;

                if (servidor.equipes_trabalho && servidor.equipes_trabalho.length > 0 &&
                    servidor.equipes_trabalho[0].plano_trabalho &&
                    servidor.equipes_trabalho[0].plano_trabalho.id_substituto === servidor.id_servidor) {
                    this.removerChefiaSubstDePlanoDeServidor(servidor)
                }

                //this.adicionarMembro(servidor);
                this.adicionarChefiaSubstituta(servidor);
            },
            recebeuServidorFromBuscaSiapeCPFdefinirPontoFocal(servidor) {
                this.adicionarPontoFocal(servidor);
            },
            recebeuServidorFromBuscaSiapeCPFdefinirPontoFocalSubst(servidor) {
                this.adicionarPontoFocalSubstituto(servidor);
            },
            hasPerfilAdminOrGestorOrgao() {
                if (this.usuarioAutenticado.perfis.find(item => item == 'administrador') 
                    || this.usuarioAutenticado.perfis.find(item => item == 'gestorOrgao')
                ) {
                    return true;
                }
                return false;
            },
            isGestorPlano() {
                if (this.usuarioAutenticado.id_servidor == this.plano_trabalho.unidade_avaliacao.id_gestor_setorial || this.usuarioAutenticado.id_servidor == this.plano_trabalho.unidade_avaliacao.id_gestor_substituto) {
                    return true;
                }
                return false;
            },
            isPontoFocalPlano() {
                if (this.usuarioAutenticado.id_servidor == this.plano_trabalho.id_ponto_focal ||
                    this.usuarioAutenticado.id_servidor == this.plano_trabalho.id_ponto_focal_substituto ||
                    this.plano_trabalho.planospais.find(item => item.id_ponto_focal == this.usuarioAutenticado.id_servidor || item.id_ponto_focal_substituto == this.usuarioAutenticado.id_servidor)
                ) {
                    return true;
                }
                return false;
            },
            isChefiaPlano() {
                if (this.usuarioAutenticado.id_servidor == this.plano_trabalho.id_chefia) {
                    return true;
                }
                return false;
            },
            isAdminOrGestorOrPontoANDbeforeFaseAvaliacaoServidores(){
                return (this.hasPerfilAdminOrGestorOrgao() || this.isGestorPlano() || this.isPontoFocalPlano()) && this.plano_trabalho && 
                this.parseToDateInicio(this.plano_trabalho.ciclo.fases.filter(f => f.nm_fase === 'Avaliação dos Servidores')[0].dt_inicio) >= new Date()
            },
            canEditarEscopo() {
                if (this.hasPerfilAdminOrGestorOrgao()) {
                    return true;
                }

                if (this.cicloVigente && this.fasePermiteEdicao) {
                    if (this.hasPerfilAdminOrGestorOrgao() || this.isGestorPlano() || this.isPontoFocalPlano() || this.isChefiaPlano()) {
                        return true;
                    }
                } else if (this.isAdminOrGestorOrPontoANDbeforeFaseAvaliacaoServidores()) {
                    return true
                }
                return false;
            },
            canEditarMetaIntermediaria() {
                if (this.hasPerfilAdminOrGestorOrgao()) {
                    return true;
                }

                if (this.cicloVigente && this.fasePermiteEdicao) {
                    if (this.hasPerfilAdminOrGestorOrgao() || this.isGestorPlano() || this.isPontoFocalPlano() || this.isChefiaPlano()) {
                        return true;
                    }
                } else if (this.isAdminOrGestorOrPontoANDbeforeFaseAvaliacaoServidores()) {
                    return true
                }
                return false;
            },
            canEditarChefia() {
                if (this.hasPerfilAdminOrGestorOrgao()) {
                    return true;
                }

                if (this.cicloVigente && this.fasePermiteEdicao) {
                    if (this.hasPerfilAdminOrGestorOrgao() || this.isGestorPlano() || this.isPontoFocalPlano()) {
                        return true;
                    }
                } else if (this.isAdminOrGestorOrPontoANDbeforeFaseAvaliacaoServidores()) {
                    return true
                }
                return false;
            },
            canEditarPontoFocal() {
                if (this.hasPerfilAdminOrGestorOrgao()) {
                    return true;
                }

                if (this.cicloVigente && this.fasePermiteEdicao) {
                    if (this.hasPerfilAdminOrGestorOrgao() || this.isGestorPlano() || this.isPontoFocalPlano()) {
                        return true;
                    }
                } else if (this.isAdminOrGestorOrPontoANDbeforeFaseAvaliacaoServidores()) {
                    return true
                }
                return false;
            },
            canEditarEquipeTrabalho() {
                if (this.hasPerfilAdminOrGestorOrgao()) {
                    return true;
                }

                if (this.cicloVigente && this.fasePermiteEdicao) {
                    if (this.hasPerfilAdminOrGestorOrgao() || this.isGestorPlano() || this.isPontoFocalPlano() || this.isChefiaPlano()) {
                        return true;
                    }
                } else if (this.isAdminOrGestorOrPontoANDbeforeFaseAvaliacaoServidores()) {
                    return true
                }
                return false;
            },
            canEditarMetaIndividual(id_servidor) {
                
                if (this.cicloVigente) {
                    
                    if (this.hasPerfilAdminOrGestorOrgao() || this.isGestorPlano() || this.isPontoFocalPlano() || this.isChefiaPlano()) {
                        return true;
                    }

                    if (this.usuarioAutenticado.id_servidor == id_servidor) {
                        return false;
                    } else if (this.hasPerfilAdminOrGestorOrgao() || this.isGestorPlano() || this.isPontoFocalPlano() || this.isChefiaPlano()) {
                        return true;
                    }

                    if (this.isAdminOrGestorOrPontoANDbeforeFaseAvaliacaoServidores()) {
                        return true
                    }
                } else {
                    return false;
                }
            },

            canVisualizarSubplano() {
                if (this.hasPerfilAdminOrGestorOrgao()) {
                    return true;
                }

                if (this.hasPerfilAdminOrGestorOrgao() || this.isGestorPlano() || this.isPontoFocalPlano() || this.isChefiaPlano()) {
                    return true;
                }
                return false;
            },

            liberarOuBloquearAvaliacao(st_bloqueado, id_servidor) {
                this.$refs.liberarBloquearAvaliacaoDialog.showDialog(st_bloqueado, id_servidor);
            },
            canTrocarChefia() {
                if (this.hasPerfilAdminOrGestorOrgao()) {
                    return true;
                }

                if ( this.hasPerfilAdminOrGestorOrgao() && this.fasePermiteTrocaChefia && this.plano_trabalho.substituto ) {
                    return true;
                }

                if (this.usuarioAutenticado.perfis.find(item => item == 'gestorSetorial')) {
                    return true;
                }

                return false;
            },
            trocarChefia() {
                let plano_trabalho = {
                    id_ciclo: this.plano_trabalho.id_ciclo,
                    id_unidade_avaliacao: this.plano_trabalho.id_unidade_avaliacao,
                    id_unidade: this.plano_trabalho.id_unidade,
                    nm_unidade: this.plano_trabalho.nm_unidade,
                    in_troca_chefia: !this.plano_trabalho.in_troca_chefia ? true : false
                };
                trocarChefiaPlano(this.plano_trabalho.id_plano_trabalho, JSON.stringify(plano_trabalho))
                    .then(response => {
                        this.plano_trabalho = response.data.data;
                        this.$store.dispatch('alertas/show', {
                            tipo: 'Sucesso',
                            titulo: 'Troca de chefia efetuada com sucesso!',
                        });
                        this.$vuetify.goTo(0);
                    });
            },
        }
    }
</script>

<style scoped>
    html {
        overflow: hidden !important;
    }

    #equipe.v-card {
        display: flex !important;
        flex-direction: column;
        max-height: 1000px;
    }

    .v-card__text {
        flex-grow: 1;
        overflow: auto;
    }
</style>
<template>
    <Loader />
</template>


<script>
    import Loader from '../Loader.vue';

    export default {
        name: 'auth-valida-app',
        components: {
            Loader
        },
        mounted() {
        	var code = this.$route.query.code;

        	this.$store.dispatch('auth/login',code).then(
                response => {
                    if ( localStorage.getItem("id_servidor_externo") && !localStorage.getItem("id_servidor") ) {
                        this.$router.push({ name: 'Realizar Avaliação' });
                    } else if ( this.$store.getters['auth/isExternoLoggedIn'] ) {
                        this.$router.push({ name: 'formulario-cedidos-requisitados' });
                    } else if ( localStorage.getItem("hasCiclo") == 'false') {
                        this.$router.push(
	                        {
	                            name: 'ciclos-listar',
	                        },
	                        () => this.$store.dispatch('alertas/show', {
	                            tipo: 'Erro',
	                            titulo: 'Sem ciclo vigente, favor atualizar algum para que o sistema funcione normalmente.',
	                        })
                        );
                    } else {
                        this.$router.push({ name: 'tela-inicial' });
                    }
                },
                error => {
                    this.$store.dispatch('auth/logout').then(
                        this.$router.push({ name: 'home'}, 
                            () => this.$store.dispatch('alertas/show', {
                                tipo: 'Erro', 
                                titulo: error.response.data.title, 
                                mensagem: error.response.data.msg
                            })
                        )
                    );
                }
            );
		},
    }
</script>
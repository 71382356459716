<template>
    <div>
        <h1>Relatório de equipe de trabalho</h1>
        
        <v-row>
            <v-col cols="12">
                <v-card>
                    <v-row class="ma-0 pa-2">
                        <v-col cols="12">
                            <v-form  ref="form_ciclo">
                                <v-col cols="6" class="mt-3 mb-3 mx-0 px-3">
                                    <v-select
                                        id="ciclo"
                                        :items="ciclos"
                                        v-model="filtros.id_ciclo"
                                        item-text="nm_ciclo"
                                        item-value="id_ciclo"
                                        label="Ciclo"
                                        outlined
                                        dense
                                        hide-details="auto"
                                        required 
                                        :rules="[() => !!filtros.id_ciclo || 'Campo obrigatório.']"
                                        v-on:change="carregarUnidade(filtros.id_ciclo,filtros.orgao), carregarChefia(`${filtros.id_ciclo}`), carregarChefiaSubstituta(`${filtros.id_ciclo}`)"
                                    ></v-select>
                                </v-col>
                            </v-form>
                            
                            <v-row class="px-3">
                                <v-col cols="6" class="mx-0 px-3">
                                    <v-combobox
                                        id="nomeChefia"
                                        ref="chefia"
                                        :items="chefias"
                                        v-model="filtros.id_chefia"
                                        hide-details
                                        outlined 
                                        dense
                                        label="Nome da chefia"
                                        ></v-combobox>
                                </v-col>
                                <v-col cols="6" class="mx-0 px-3">
                                    <v-combobox
                                        id="nomeChefiaSubstituta"
                                        ref="chefiaSubstituta"
                                        :items="chefiasSubstitutas"
                                        v-model="filtros.id_substituto"
                                        hide-details
                                        outlined 
                                        dense
                                        label="Nome da chefia substituta"
                                        ></v-combobox>
                                </v-col>
                            </v-row>  

                             <v-row  class="px-3">
                                <v-col cols="3" class="mx-0">
                                    <v-combobox
                                        id="orgao"
                                        :items="orgaos"
                                        v-model="filtros.cd_orgao"
                                        item-value="cd_orgao"
                                        item-text="nm_orgao"
                                        label="Órgão"
                                        outlined
                                        dense
                                        hide-details="auto"
                                        v-on:change="carregarUnidade(filtros.id_ciclo,filtros.cd_orgao)"
                                    ></v-combobox>
                                </v-col>
                                <v-col cols="3" class="mx-0">
                                    <v-select
                                        id="meta_individual_pactuada"
                                        :items="meta_individual_pactuada"
                                        v-model="filtros.meta_individual_pactuada"
                                        label="Meta Individual Pactuada"
                                        outlined
                                        dense
                                        hide-details="auto"
                                    ></v-select>
                                </v-col>
                                
                                <v-col cols="3" class="mx-0">
                                    <v-select
                                        id="servidor_chefe"
                                        :items="servidor_chefe"
                                        v-model="filtros.servidor_chefe"
                                        label="Servidor é chefe"
                                        outlined
                                        dense
                                        hide-details="auto"
                                    ></v-select>
                                </v-col>
                                <v-col cols="3" class="mx-0">
                                    <v-select
                                        id="servidor_chefe_substituto"
                                        :items="servidor_chefe_substituto"
                                        v-model="filtros.servidor_chefe_substituto"
                                        label="Servidor é chefe substituto"
                                        outlined
                                        dense
                                        hide-details="auto"
                                    ></v-select>
                                </v-col>                                
                            </v-row> 
                            <v-row class="px-3">
                                <v-col cols="6" class="mx-0">
                                    <v-select
                                        id="unidade"
                                        :items="unidades"
                                        v-model="filtros.id_unidade_avaliacao"
                                        item-value="id_unidade_avaliacao"
                                        item-text="nm_unidade_avaliacao"
                                        label="Unidade de Avaliação"
                                        outlined
                                        dense
                                        hide-details="auto"
                                        v-on:change="carregarPlanosTrabalho(`${filtros.id_unidade_avaliacao}`)"
                                    ></v-select>
                                </v-col>
                                <v-col cols="6" class="mx-0">
                                    <v-combobox
                                        id="planoTrabalho"
                                        ref="planoTrabalho"
                                        :items="planosTrabalho"
                                        v-model="filtros.id_plano_trabalho"
                                        hide-details
                                        outlined 
                                        dense
                                        placeholder="Digite o plano de trabalho"
                                        ></v-combobox>
                                </v-col>
                            </v-row>

                            <v-row v-if="filtros.id_ciclo == id_ciclo_vigente" class="px-3">
                                <v-col cols="6" class="mx-0 ">
                                    <v-combobox
                                        id="funcao"
                                        ref="funcoes"
                                        :items="funcoes"
                                        v-model="filtros.funcao"
                                        outlined
                                        dense
                                        placeholder="Digite a função"
                                        ></v-combobox>
                                </v-col>
                                <v-col cols="3" class="mx-0 ">
                                    <v-select
                                        id="gratificacao"
                                        :items="gratificacoes"
                                        v-model="filtros.id_gratificacao"
                                        item-value="id_gratificacao"
                                        item-text="sg_gratificacao"
                                        label="Gratificação "
                                        outlined
                                        dense
                                        hide-details="auto"
                                    ></v-select>
                                </v-col>
                                
                                <!--<v-col cols="3" class="mx-0">
                                    <v-select
                                        id="semEquipe"
                                        :items="sem_equipe"
                                        v-model="filtros.sem_equipe"
                                        label="Servidor sem equipe?"
                                        outlined
                                        dense
                                        hide-details="auto"
                                    ></v-select>
                                </v-col>-->
                            </v-row>

                            <v-row v-else class="px-3">
                                <v-col cols="6" class="mx-0 ">
                                    <v-select
                                        id="gratificacao"
                                        :items="gratificacoes"
                                        v-model="filtros.id_gratificacao"
                                        item-value="id_gratificacao"
                                        item-text="sg_gratificacao"
                                        label="Gratificação "
                                        outlined
                                        dense
                                        hide-details="auto"
                                    ></v-select>
                                </v-col>
                                <v-col cols="6" class="mx-0 ">
                                    <v-combobox
                                        id="funcao"
                                        ref="funcoes"
                                        :items="funcoes"
                                        v-model="filtros.funcao"
                                        outlined
                                        dense
                                        placeholder="Digite a função"
                                    ></v-combobox>
                                </v-col>
                                
                            </v-row>
                            
                        </v-col>
                       
                    </v-row>
                    <v-row>
                        <v-col cols="10" class="text-right"></v-col>
                        <v-col cols="2" class="ma-0 mb-4 pa-2 px-8 d-flex flex-row justify-end align-end text-right elevation-0">
                            <v-btn id="btnFiltrar" color="primary" @click="gerarRelatorioEquipeTrabalho()">
                                <v-icon>mdi-magnify</v-icon> Filtrar
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
        <br />
        <br />
        <v-row v-show="tableResult">
            <v-col cols="12">
                <v-data-table
                    :headers="headers"
                    :items="relatorioEquipeTrabalho"
                    :items-per-page="pagination.itemsPerPage"
                    :footer-props="{'items-per-page-options':[30, 50, 100]}"
                    :options.sync="pagination" 
                    @update:options="gerarRelatorioEquipeTrabalho"
                    :server-items-length="pagination.total"
                    class="elevation-1"
                >
                    <template v-slot:[`item.acao`]="{ item }">
                        <v-row justify="center" v-if="item.id_plano_trabalho">
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs}">
                                    <v-btn id="btnVisualizar" target="_blank" icon small :color="'primary'" v-bind="attrs" v-on="on" :to="{ name: 'EquipeTrabalhoModal', params: {id: item.id_plano_trabalho , id_servidor: item.id_servidor} }">
                                        <v-icon> mdi-eye </v-icon>
                                    </v-btn>
                                </template>
                                <span>Dados da equipe de trabalho</span>
                            </v-tooltip>
                        </v-row>
                        <v-row justify="center" v-else>
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs}">
                                    <v-btn id="btnVisualizar" target="_blank" icon small :color="'primary'" v-bind="attrs" v-on="on" disabled>
                                        <v-icon> mdi mdi-eye-off </v-icon>
                                    </v-btn>
                                </template>
                                <span>Servidor sem equipe</span>
                            </v-tooltip>
                        </v-row>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
        <v-row v-if="pagination.total">
            <v-col cols="10" class="text-right"></v-col>
            <v-col cols="2" class="ma-0 mb-6 pa-2 px-4 d-flex flex-row justify-end align-end text-right elevation-0">
                <v-btn color="success" @click="exportarRelatorioEquipeTrabalho()">
                    <v-icon>mdi-download</v-icon> Exportar para excel
                </v-btn>
            </v-col>
        </v-row>
        <v-dialog v-model="dialogAlert" width="570">
            <v-card>
                <v-card-title class="text-h5">
                    <v-alert prominent type="warning" outlined width="100%">
                        <v-row align="center">
                            <v-col class="grow">
                                {{dialogMsg}}
                            </v-col>
                            <v-col class="shrink">
                            <v-btn color="warning"  @click="dialogAlert = false">Ok</v-btn>
                            </v-col>
                        </v-row>
                    </v-alert>
                </v-card-title> 
            </v-card>
        </v-dialog>
    </div>
</template>

<script>

    import { getAllCiclos, getCiclosVigentes } from '../api/ciclo';
    import { getGratificacoes } from '../api/gratificacao';
    import { getFuncoes } from '../api/funcao';
    import { getUnidadeAvaliacaoByCicloOrgao } from '../api/unidade_avaliacao';
    import { getPlanoTrabalhoByUnidadeAvaliacao, getChefiaByCicloAndPlano, getChefiaSubstitutaByCicloAndPlano } from '../api/plano_trabalho';
    import { gerarRelatorioEquipeTrabalhoFiltrados, getTotalRelatorioEquipeTrabalho, gerarExtracaoRelatorioEquipeTrabalho } from '../api/equipe_trabalho';
    import { getOrgaos } from '../api/orgao';
    import { getOrgaoGestorOrgao } from '../api/orgao';


    export default {
        name: 'RelatorioEquipeTrabalhoListar',
        data() {
            return {
                ciclos: [],
                gratificacoes: [],
                funcoes: [],
                unidades: [],
                planosTrabalho: [],
                tableResult: false,
                relatorioEquipeTrabalho: [],
                chefias: [],
                chefiasSubstitutas: [],
                headers: [
                    { text: 'CPF do servidor', value: 'nr_cpf', sortable: false, width: '130px' },
                    { text: 'Nome do servidor', value: 'nm_servidor', sortable: false },
                    { text: 'Plano de trabalho', value: 'plano_trabalho', sortable: false },
                    { text: 'Chefia', value: 'nm_servidor_chefia', sortable: false },
                    { text: 'Meta individual', value: 'ds_meta_individual', sortable: false },
                    { text: 'Gratificação', value: 'sg_gratificacao', sortable: false },
                    { text: 'Função', value: 'nm_funcao', sortable: false },
                    { text: 'Órgão', value: 'nm_orgao', sortable: false },
                    { text: 'Ações', value: 'acao', align: 'center', sortable: false},
                ],
                pagination: {
                    current: 1,
                    total: 0,
                    page: 1,
                    itemsPerPage: 30
                },
                filtros: {
                    nm_chefia: '',
                    nm_chefia_substituto: '',
                },
                meta_individual_pactuada: [
                    { text: 'Todos', value: -1},
                    { text: 'Pactuada', value: 1},
                    { text: 'Atribuída', value: 2},
                    { text: 'Não atribuída', value: 3},
                ],
                servidor_chefe: [
                    { text: 'Todos', value: -1},
                    { text: 'Sim', value: 1},
                    { text: 'Não', value: 2}
                ],
                servidor_chefe_substituto: [
                    { text: 'Todos', value: -1},
                    { text: 'Sim', value: 1},
                    { text: 'Não', value: 2}
                ],
                sem_equipe: [
                    { text: 'Selecione', value: -1},
                    { text: 'Sim', value: 1},
                    { text: 'Não', value: 2}
                ],
                id_ciclo_vigente:null,
                orgaos: [],
                disabled: false,
                dialogAlert: false,
                dialogMsg:'',
                isGestorOrgao: false
            };
        },
        mounted() {
            getAllCiclos()
                .then(response => { 
                    this.ciclos = response.data.data; 
                });

            getGratificacoes()
                .then(response => {
                    this.gratificacoes = response.data.data;
                    this.gratificacoes = _.orderBy(this.gratificacoes, 'sg_gratificacao');
                    this.gratificacoes.unshift({id_gratificacao: -2, sg_gratificacao: 'Sem gratificação'});
                    this.gratificacoes.unshift({id_gratificacao: -3, sg_gratificacao: 'Com gratificação'});
                    this.gratificacoes.unshift({id_gratificacao: -1, sg_gratificacao: 'Todas'});
            });

            getFuncoes()
                .then(response => { 
                    this.funcoes = response.data.data; 
                    this.funcoes.sort((a,b) => {   return a.nm_funcao.localeCompare(b.nm_funcao);});
                    this.funcoes.map(func =>{
                        func.text = func.cd_funcao + ' - ' + func.nm_funcao
                        func.value = func.id_funcao
                        func.nm_funcao_com_sigla = func.cd_funcao + ' - ' + func.nm_funcao
                    })
                    this.funcoes.unshift({text: 'Qualquer', value: -1, id_funcao: -1})                   
            });

            getCiclosVigentes(this.$store.getters['auth/usuarioAutenticado']).then(response => {
                this.id_ciclo_vigente = response.data.data[0].id_ciclo;
            });

            if (
                (this.$store.getters['auth/usuarioAutenticadoRoles'].indexOf("gestorOrgao") >= 0
                || this.$store.getters['auth/usuarioAutenticadoRoles'].indexOf("gestorSetorial") >= 0
                || this.$store.getters['auth/usuarioAutenticadoRoles'].indexOf("pontoFocal") >= 0)
                && this.$store.getters['auth/usuarioAutenticadoRoles'].indexOf("administrador") < 0
                
            )  {
                this.isGestorOrgao = true;
                getOrgaoGestorOrgao().then(response => { 
                    this.orgaos = response.data.data;
                    if ( response.data.data.length === 1 ) {
                        this.filtros.cd_orgao = this.orgaos.find(org => org.cd_orgao == response.data.data[0].cd_orgao);
                    } 
                });
            } else {
                getOrgaos().then(response => {
                    this.orgaos = response.data.data; 
                    this.orgaos.map(org =>{
                        org.text = org.sg_sigla + ' - ' + org.nm_orgao
                        org.value = org.cd_orgao
                    })
                    this.orgaos.unshift({cd_orgao: -1, nm_orgao: 'Todos'});

                });
            }
        },
        methods: { 
            getFiltros(){
                var filtros = [];
                var obj = {};

                if ( this.filtros.id_ciclo ) {
                    obj['id_ciclo'] = this.filtros.id_ciclo;
                }

                // Filtro funcao
                if( typeof this.filtros.funcao === 'object' ) {
                    obj['funcao'] = this.filtros.funcao;
                } else {
                    this.$refs.funcoes.lazyValue = undefined;
                }

                // Filtro gratificacao
                if ( this.filtros.id_gratificacao) {
                    obj['id_gratificacao'] = this.filtros.id_gratificacao;
                }

                // Filtro unidade de avaliacao
                if ( this.filtros.id_unidade_avaliacao ) {
                    obj['id_unidade_avaliacao'] = this.filtros.id_unidade_avaliacao;
                }

                // Filtro plano de trabalho
                if ( typeof this.filtros.id_plano_trabalho === 'object' ) {
                    obj['id_plano_trabalho'] = this.filtros.id_plano_trabalho;
                } else {
                    this.$refs.planoTrabalho.lazyValue = undefined;
                }

                // Filtro meta_individual_pactuada
                if ( this.filtros.meta_individual_pactuada ) {
                    obj['meta_individual_pactuada'] = this.filtros.meta_individual_pactuada;
                }

                // Filtro chefia
                if ( typeof this.filtros.id_chefia === 'object' ) {
                    obj['id_chefia'] = this.filtros.id_chefia;
                } else {
                    this.$refs.chefia.lazyValue = undefined;
                }

                // Filtro chefia substituta
                if ( typeof this.filtros.id_substituto === 'object' ) {
                    obj['id_substituto'] = this.filtros.id_substituto;
                } else {
                    this.$refs.chefiaSubstituta.lazyValue = undefined;
                }

                // Filtro servidor_chefe
                if ( this.filtros.servidor_chefe ) {
                    obj['servidor_chefe'] = this.filtros.servidor_chefe;
                }

                // Filtro servidor_substituto
                if ( this.filtros.servidor_chefe_substituto ) {
                    obj['servidor_chefe_substituto'] = this.filtros.servidor_chefe_substituto;
                }

                // Filtro sem_equipe
                if ( this.filtros.sem_equipe && this.filtros.id_ciclo == this.id_ciclo_vigente) {
                    obj['sem_equipe'] = this.filtros.sem_equipe;
                }

                // Filtro orgão
                if (this.filtros.cd_orgao && this.filtros.cd_orgao !== -1) {
                    if ( typeof this.filtros.cd_orgao === 'object' ) {
                        obj['cd_orgao'] = this.filtros.cd_orgao.cd_orgao;
                    } else {
                        obj['cd_orgao'] = this.filtros.cd_orgao;
                    }
                } 


                filtros.push(obj);

                return filtros;
            },

            gerarRelatorioEquipeTrabalho() {
                if ( this.$refs['form_ciclo'].validate() ) {
                    if (this.isGestorOrgao && this.filtros.cd_orgao === undefined) {
                        this.dialogMsg = "Campo órgão obrigatório";
                        return this.dialogAlert = true;
                    }
                    this.tableResult = true;

                    var filtros = [];
                    filtros = this.getFiltros();

                    gerarRelatorioEquipeTrabalhoFiltrados(this.pagination.page, this.pagination.itemsPerPage, filtros)
                        .then(response => { 
                            this.relatorioEquipeTrabalho = response.data.data;
                        });
                       
                    getTotalRelatorioEquipeTrabalho(filtros)
                        .then(response => {
                            this.pagination.total = response.data;
                        });
                }
            },

            carregarUnidade(id_ciclo,orgao = null) {
                if (orgao && id_ciclo) {
                    getUnidadeAvaliacaoByCicloOrgao(id_ciclo, orgao.cd_orgao).then(response => { 
                        if (response.data.data.length > 0) {
                            this.unidades = response.data.data; 
                            this.unidades = _.orderBy(this.unidades, 'nm_unidade_avaliacao');
                            this.unidades.unshift({id_unidade_avaliacao: -1, nm_unidade_avaliacao: 'Todas'});
                        }
                    });
                }
            },

            carregarPlanosTrabalho(id_unidade_avaliacao) {
                getPlanoTrabalhoByUnidadeAvaliacao(id_unidade_avaliacao)
                    .then(response => { 
                        this.$refs.planoTrabalho.lazyValue = undefined; 
                        this.planosTrabalho = response.data.data; 
                        this.planosTrabalho.map(plano =>{
                            plano.text = plano.nm_unidade
                            plano.value = plano.id_plano_trabalho
                        })
                        this.planosTrabalho.unshift({text: 'Todos', value: -1, id_funcao: -1})                   
                });
            },

            carregarChefia(id_ciclo) {
                getChefiaByCicloAndPlano(id_ciclo)
                    .then(response => { 
                        this.$refs.chefia.lazyValue = undefined; 
                        this.chefias = response.data.data; 
                        this.chefias.map(item =>{
                            item.text = item.chefia.nm_servidor
                            item.value = item.chefia.id_servidor
                        })
                        this.chefias = _.orderBy(this.chefias, 'text');
                        this.chefias.unshift({text: 'Todas', value: -1, id_funcao: -1})                   
                });
            },

            carregarChefiaSubstituta(id_ciclo) {
                getChefiaSubstitutaByCicloAndPlano(id_ciclo)
                    .then(response => { 
                        this.$refs.chefiaSubstituta.lazyValue = undefined; 
                        this.chefiasSubstitutas = response.data.data; 
                        this.chefiasSubstitutas.map(item =>{
                            item.text = item.substituto.nm_servidor
                            item.value = item.substituto.id_servidor
                        })
                        this.chefiasSubstitutas = _.orderBy(this.chefiasSubstitutas, 'text');
                        this.chefiasSubstitutas.unshift({text: 'Todas', value: -1, id_funcao: -1})                   
                });
            },

             exportarRelatorioEquipeTrabalho() {
                var filtros = [];
                filtros = this.getFiltros();

                gerarExtracaoRelatorioEquipeTrabalho(filtros)
                    .then(response => {
                        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                        var fileLink = document.createElement('a');
                        fileLink.href = fileURL;
                        fileLink.setAttribute('download', 'Relatório de equipe de trabalho.xlsx');
                        document.body.appendChild(fileLink);
                        fileLink.click();
                    });
            },
        }
    }
</script>

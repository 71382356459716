<template>
    <div>
        <h1>Gratificações</h1>
        <v-row>
            <v-col cols="12">
                <v-card class="mb-2">
                    <v-row dense class="ma-0 pa-2"> 
                        <v-col cols="12" md="6" class="mx-0 px-3">
                            <v-text-field 
                                id="sigla" 
                                v-model="filtro.sg_gratificacao" 
                                ref="sigla" 
                                @keyup="uppercase"
                                dense 
                                hide-details="auto" 
                                label="Sigla da gratificação" 
                                outlined 
                                clearable 
                                clear-icon="mdi-close-circle-outline"
                            ></v-text-field>
                        </v-col> 
                        <v-col cols="12" md="6" class="mx-0 px-3">
                            <v-text-field 
                                id="nome_gratificacao" 
                                v-model="filtro.nm_gratificacao" 
                                @keyup="uppercase"
                                ref="nome_gratificacao" 
                                dense 
                                hide-details="auto" 
                                label="Nome da gratificação" 
                                outlined 
                                clearable 
                                clear-icon="mdi-close-circle-outline"
                            ></v-text-field>
                        </v-col>          
                    </v-row>
                     <v-row dense class="ma-0 pa-2">
                        <v-col cols="12" md="6" class="mx-0 px-3">
                            <v-text-field 
                                id="codigo_rubrica" 
                                v-model="filtro.cd_rubrica" 
                                ref="codigo_rubrica" 
                                dense 
                                hide-details="auto" 
                                label="Código da rúbrica" 
                                v-mask="'#####'" 
                                outlined 
                                clearable 
                                clear-icon="mdi-close-circle-outline"
                            ></v-text-field>
                        </v-col> 
                        <v-col cols="12" md="6" class="mx-0 px-3">
                           <v-text-field 
                                id="nome_rubrica" 
                                v-model="filtro.nm_rubrica" 
                                ref="nome_rubrica" 
                                dense 
                                hide-details="auto" 
                                label="Nome da rúbrica" 
                                outlined
                                @keyup="uppercase" 
                                clearable 
                                clear-icon="mdi-close-circle-outline"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row dense class="ma-0">
                       <v-col cols="12" md="3" class="mx-0 px-4">
                        <v-checkbox
                                v-model="filtro.st_autoavaliacao"
                                value= "1"
                                label="Possui Autoavaliação"
                                dense 
                                hide-details="auto" 
                            ></v-checkbox>
                           
                        </v-col>
                        <v-col cols="12" md="3" class="mx-0 px-2">
                           <v-checkbox
                                v-model="filtro.st_equipe"
                                value= "1"
                                label="Possui Avaliação de Equipe"
                                dense 
                                hide-details="auto" 
                            ></v-checkbox>
                        </v-col>
                        <v-col cols="12" md="6" class="mx-0 px-2">
                           <v-checkbox
                                v-model="filtro.st_chefia"
                                value= "1"
                                label="Possui Avaliação de Chefia"
                                dense 
                                hide-details="auto" 
                            ></v-checkbox>
                        </v-col>
                    </v-row>
                    <v-row>
                         <v-col class="d-flex flex-row justify-end align-end text-right">
                            <v-btn class="success mr-3" @click="salvar()">
                                Cadastrar
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
        <br>
        <v-card>
            <template>
                <v-data-table
                    :headers="headersGratificacoes"
                    :items="gratificacoes"
                    :items-per-page="5"
                    class="elevation-1"
                >
                    <template v-slot:[`item.acao`]="{ item }">
                        <v-row>
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs}">
                                    <v-btn 
                                        id="detalharGratificacao" 
                                        icon 
                                        class="mr-2"
                                        small 
                                        :color="'primary'" 
                                        v-bind="attrs" 
                                        v-on="on" 
                                        @click="showDialogDetalhar(item.id_gratificacao)"
                                    >
                                        <v-icon> mdi-eye </v-icon>
                                    </v-btn>
                                </template>
                                <span>Detalhar gratificação</span>
                            </v-tooltip>
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs}">
                                    <v-btn 
                                        id="editarGratificacao" 
                                        icon 
                                        class="mr-2"
                                        small 
                                        :color="'warning'" 
                                        v-bind="attrs" 
                                        v-on="on" 
                                        @click="showDialogEditar(item.id_gratificacao)"
                                    >
                                        <v-icon> mdi-pencil </v-icon>
                                    </v-btn>
                                </template>
                                <span>Editar gratificação</span>
                            </v-tooltip>
                            <v-tooltip top>
                                <template v-slot:activator="{on, attrs}">
                                    <v-btn 
                                        id="btnDelete" 
                                        icon 
                                        small 
                                        v-bind="attrs" 
                                        :color="'error'" 
                                        v-on="on" 
                                        @click="showDialogExcluir(item.id_gratificacao)"
                                    >
                                        <v-icon> mdi-delete-forever </v-icon>
                                    </v-btn>
                                </template>
                                <span>Excluir gratificação</span>
                            </v-tooltip>
                        </v-row>
                    </template>    
                </v-data-table>
            </template>
        </v-card>

        <v-dialog v-model="dialogDetalhar" width="70%" :fullscreen="$vuetify.breakpoint.xsOnly">
            <v-card>
                <v-toolbar dark color="primary">
                <v-btn icon dark>
                    <v-icon>mdi-eye</v-icon>
                </v-btn>
                <v-toolbar-title>Detalhes da Gratificação</v-toolbar-title>            
                <v-spacer></v-spacer>
                <v-toolbar-items>
                    <v-btn dark text @click="dialogDetalhar=false">
                    <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar-items>
                </v-toolbar>
                <br>
                <v-card-text>
                    <v-col cols="12">
                        <v-card class="mb-2">
                            <v-row dense class="ma-0 pa-2"> 
                                <v-col cols="12" md="6" class="mx-0 px-3">
                                    <span class="text-h5 primary--text">Sigla da gratificação:</span><br>
                                    <span class="">{{gratificacao.sg_gratificacao}}</span>
                                </v-col> 
                                <v-col cols="12" md="6" class="mx-0 px-3">
                                    <span class="text-h5 primary--text">Nome da gratificação:</span><br>
                                    <span class="">{{gratificacao.nm_gratificacao}}</span>
                                </v-col>          
                            </v-row>
                            <v-row dense class="ma-0 pa-2">
                                <v-col cols="12" md="6" class="mx-0 px-3">
                                    <span class="text-h5 primary--text">Código da rúbrica:</span><br>
                                    <span class="">{{gratificacao.cd_rubrica}}</span>
                                </v-col> 
                                <v-col cols="12" md="6" class="mx-0 px-3">
                                    <span class="text-h5 primary--text">Nome da rúbrica:</span><br>
                                    <span class="">{{gratificacao.nm_rubrica}}</span>
                                </v-col>
                            </v-row>
                            <v-row dense class="ma-0">
                                <v-col cols="12" md="4" class="mx-0 px-4">
                                    <v-checkbox
                                        disabled
                                        v-model="gratificacao.st_autoavaliacao"
                                        :value= gratificacao.st_autoavaliacao
                                        label="Possui Autoavaliação"
                                        dense 
                                        hide-details="auto" 
                                    ></v-checkbox>
                                </v-col>
                                <v-col cols="12" md="4" class="mx-0 px-2">   
                                    <v-checkbox
                                        disabled
                                        v-model="gratificacao.st_equipe"
                                        :value= gratificacao.st_equipe
                                        label="Possui Avaliação de Equipe"
                                        dense 
                                        hide-details="auto" 
                                    ></v-checkbox>
                                </v-col>
                                <v-col cols="12" md="4" class="mx-0 px-2">
                                    <v-checkbox
                                        disabled
                                        v-model="gratificacao.st_chefia"
                                        :value= gratificacao.st_chefia
                                        label="Possui Avaliação de Chefia"
                                        dense 
                                        hide-details="auto" 
                                    ></v-checkbox>
                                </v-col>
                            </v-row>
                            <br>
                        </v-card>
                    </v-col>                  
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions class="py-4">
                    <v-spacer></v-spacer>
                    <v-btn
                        color="default"
                        @click="dialogDetalhar = false"
                    >
                        Fechar detalhes da gratificação
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogWarning" width="600" >
            <v-card>
                <v-card-title class="text-h5 text-center">
                    <v-alert prominent type="warning" outlined width="100%">
                        <v-row align="center">
                            <v-col class="grow">
                                {{ dialogMsg }}
                            </v-col>
                            <v-col class="shrink">
                            <v-btn color="warning"  @click="dialogWarning = false">Ok</v-btn>
                            </v-col>
                        </v-row>
                    </v-alert>
                </v-card-title> 
            </v-card>
        </v-dialog>  

        <v-dialog v-model="dialogSuccess" width="570" >
            <v-card>
                <v-card-title class="text-h5 text-center">
                    <v-alert prominent type="success" width="100%">
                        <v-row align="center">
                            <v-col class="grow">
                                {{dialogMsg}}
                            </v-col>
                            <v-col class="shrink">
                            <v-btn outlined  @click="dialogSuccess = false">Ok</v-btn>
                            </v-col>
                        </v-row>
                    </v-alert>
                </v-card-title> 
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogEditar" width="70%" :fullscreen="$vuetify.breakpoint.xsOnly">
            <v-card>
                <v-toolbar dark color="primary">
                <v-btn icon dark>
                    <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-toolbar-title>Editar Gratificação</v-toolbar-title>            
                <v-spacer></v-spacer>
                <v-toolbar-items>
                    <v-btn dark text @click="dialogEditar=false">
                    <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar-items>
                </v-toolbar>
                <br>
                <v-card-text>
                    <v-col cols="12">
                        <v-card class="mb-2">
                            <v-row dense class="ma-0 pa-2"> 
                                <v-col cols="12" md="6" class="mx-0 px-3">
                                    <v-text-field 
                                        id="sigla_editar" 
                                        v-model="gratificacao.sg_gratificacao" 
                                        ref="sigla_editar" 
                                        @keyup="uppercase"
                                        dense 
                                        hide-details="auto" 
                                        label="Sigla da gratificação" 
                                        outlined 
                                        clearable 
                                        clear-icon="mdi-close-circle-outline"
                                    ></v-text-field>
                                </v-col> 
                                <v-col cols="12" md="6" class="mx-0 px-3">
                                    <v-text-field 
                                        id="nome_gratificacao_editar" 
                                        v-model="gratificacao.nm_gratificacao" 
                                        @keyup="uppercase"
                                        ref="nome_gratificacao_editar" 
                                        dense 
                                        hide-details="auto" 
                                        label="Nome da gratificação" 
                                        outlined 
                                        clearable 
                                        clear-icon="mdi-close-circle-outline"
                                    ></v-text-field>
                                </v-col>          
                            </v-row>
                            <v-row dense class="ma-0 pa-2">
                                <v-col cols="12" md="6" class="mx-0 px-3">
                                    <v-text-field 
                                        id="codigo_rubrica_editar" 
                                        v-model="gratificacao.cd_rubrica" 
                                        ref="codigo_rubrica_editar" 
                                        dense 
                                        hide-details="auto" 
                                        label="Código da rúbrica" 
                                        v-mask="'#####'" 
                                        outlined 
                                        clearable 
                                        clear-icon="mdi-close-circle-outline"
                                    ></v-text-field>
                                </v-col> 
                                <v-col cols="12" md="6" class="mx-0 px-3">
                                <v-text-field 
                                        id="nome_rubrica_editar" 
                                        v-model="gratificacao.nm_rubrica" 
                                        ref="nome_rubrica_editar" 
                                        dense 
                                        hide-details="auto" 
                                        label="Nome da rúbrica" 
                                        outlined
                                        @keyup="uppercase" 
                                        clearable 
                                        clear-icon="mdi-close-circle-outline"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row dense class="ma-0">
                                <v-col cols="12" md="3" class="mx-0 px-4">
                                    <v-checkbox
                                        v-model="gratificacao.st_autoavaliacao"
                                        :value= gratificacao.st_autoavaliacao
                                        label="Possui Autoavaliação"
                                        dense 
                                        hide-details="auto" 
                                    ></v-checkbox>
                                </v-col>
                                <v-col cols="12" md="3" class="mx-0 px-2">
                                    <v-checkbox
                                        v-model="gratificacao.st_equipe"
                                        :value= gratificacao.st_equipe
                                        label="Possui Avaliação de Equipe"
                                        dense 
                                        hide-details="auto" 
                                    ></v-checkbox>
                                </v-col>
                                <v-col cols="12" md="6" class="mx-0 px-2">
                                    <v-checkbox
                                        v-model="gratificacao.st_chefia"
                                        :value= gratificacao.st_chefia
                                        label="Possui Avaliação de Chefia"
                                        dense 
                                        hide-details="auto" 
                                    ></v-checkbox>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>            
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions class="py-4">
                    <v-spacer></v-spacer>
                    <v-btn
                        color="default"
                        @click="dialogEditar = false"
                    >
                        Cancelar
                    </v-btn>
                    <v-btn
                        color="primary"
                        @click="editar()"
                    >
                        Salvar edição
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogExcluir" max-width="500px">
            <v-card>
                <h4 class="primary--text text-center py-6">
                    Tem certeza que deseja excluir?
                 </h4>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="grey darken-1" text @click="dialogExcluir = false">Fechar</v-btn>
                <v-btn color="red darken-1"  text @click="deletar()">Excluir</v-btn>
                <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { getOrgaos } from '../api/orgao';
import { getGratificacoes } from '../api/gratificacao';
import { getGratificacao } from '../api/gratificacao';
import { salvarGratificacao } from '../api/gratificacao';
import { editarGratificacao } from '../api/gratificacao';
import { excluirGratificacao } from '../api/gratificacao';

export default {
    name: 'GratificacoesListar',
    components: {
    },
    data() {
        return {
            filtro: [],
            orgaos: [],
            gratificacoes: [],
            headersGratificacoes: [
                { text: 'Sigla da gratificação', value: 'sg_gratificacao', sortable: false },
                { text: 'Nome da gratificação', value: 'nm_gratificacao', sortable: false },
                { text: 'Código da rúbrica', value: 'cd_rubrica', sortable: false },
                { text: 'Nome da rúbrica', value: 'nm_rubrica', sortable: false },
                { text: 'Órgão', value: '', sortable: false },
                { text: 'Ações', value: 'acao', sortable: false, width: '150px' },
            ],
            dialogDetalhar: false,
            gratificacao: [],
            dialogWarning: false,
            dialogSuccess: false,
            dialogMsg:'',
            dialogEditar: false,
            dialogExcluir: false,
            id_gratificacao:''
        };
    },
    created() {
        this.listarOrgaos();
        this.listarGratificacoes();
    },
    methods: {
        uppercase() {
            if (this.filtro.sg_gratificacao) {
                this.filtro.sg_gratificacao = 
                    this.filtro.sg_gratificacao = null 
                    ? null 
                    : this.filtro.sg_gratificacao.toUpperCase();
            }

            if (this.filtro.nm_rubrica) {
                this.filtro.nm_rubrica = 
                    this.filtro.nm_rubrica = null 
                    ? null 
                    : this.filtro.nm_rubrica.toUpperCase();
            }

            if (this.filtro.nm_gratificacao) {
                this.filtro.nm_gratificacao = 
                    this.filtro.nm_gratificacao = null 
                    ? null 
                    : this.filtro.nm_gratificacao.toUpperCase();
            }
        },

        listarOrgaos() {
            getOrgaos().then(response => {
                this.orgaos = response.data.data; 
                if (this.orgaos.length > 0) {
                    this.orgaos.map(org =>{
                        org.text = org.sg_sigla + ' - ' + org.nm_orgao
                        org.value = org.cd_orgao
                    })
                } else {
                    this.orgaos = [];
                }
            });
        },

        listarGratificacoes() {
            getGratificacoes().then(response => {
                    this.gratificacoes = response.data.data;
            });
        },

        showDialogDetalhar(id) {
            this.dialogDetalhar = true;
            getGratificacao(id).then(response => {
                this.gratificacao = response.data.data;
            });
        },

        salvar() {
            if (!this.filtro.sg_gratificacao) {
                this.dialogMsg = "Necessário ter a sigla da gratificação";
                return this.dialogWarning = true;
            }

            if (!this.filtro.nm_gratificacao) {
                this.dialogMsg = "Necessário ter o nome da gratificação";
                return this.dialogWarning = true;
            }

            if (!this.filtro.cd_rubrica) {
                this.dialogMsg = "Necessário ter o código da rúbrica";
                return this.dialogWarning = true;
            }

            if (!this.filtro.nm_rubrica) {
                this.dialogMsg = "Necessário ter o nome da rúbrica";
                return this.dialogWarning = true;
            }

            if (this.filtro.nm_gratificacao && this.filtro.nm_gratificacao.length < 3) {
                this.dialogMsg = "Gratificação deve ter no mínimo 3 caracteres";
                return this.dialogWarning = true;
            }

            if (this.filtro.nm_rubrica && this.filtro.nm_rubrica.length < 3) {
                this.dialogMsg = "Nome da rúbrica deve ter no mínimo 3 caracteres";
                return this.dialogWarning = true;
            }

            if (this.filtro.cd_rubrica && this.filtro.cd_rubrica.length < 5) {
                this.dialogMsg = "Codigo deve ter 5 caracteres";
                return this.dialogWarning = true;
            }

            let data = new FormData();

            data.append('sg_gratificacao', this.filtro.sg_gratificacao);
            data.append('nm_gratificacao', this.filtro.nm_gratificacao);
            data.append('cd_rubrica', this.filtro.cd_rubrica);
            data.append('nm_rubrica', this.filtro.nm_rubrica);
            data.append('st_autoavaliacao', this.filtro.st_autoavaliacao != 1 ? false : true);
            data.append('st_equipe', this.filtro.st_equipe != 1 ? false : true);
            data.append('st_chefia', this.filtro.st_chefia != 1 ? false : true);

            salvarGratificacao(data).then(response => {
                this.listarGratificacoes();
                this.filtro = [];
                this.dialogMsg = "Feito com sucesso";
                this.dialogSuccess = true;
            }).catch(erro => {
                this.dialogMsg = "erro ao salvar";
                this.dialogWarning = true;
            });
        },

        showDialogEditar(id) {
            this.dialogEditar = true;
            getGratificacao(id).then(response => {
                this.gratificacao = response.data.data;
            });
        },

        editar() {
            if (!this.gratificacao.sg_gratificacao) {
                this.dialogMsg = "Necessário ter a sigla da gratificação";
                return this.dialogWarning = true;
            }

            if (!this.gratificacao.nm_gratificacao) {
                this.dialogMsg = "Necessário ter o nome da gratificação";
                return this.dialogWarning = true;
            }

            if (!this.gratificacao.cd_rubrica) {
                this.dialogMsg = "Necessário ter o código da rúbrica";
                return this.dialogWarning = true;
            }

            if (!this.gratificacao.nm_rubrica) {
                this.dialogMsg = "Necessário ter o nome da rúbrica";
                return this.dialogWarning = true;
            }

            if (this.gratificacao.nm_gratificacao && this.gratificacao.nm_gratificacao.length < 3) {
                this.dialogMsg = "Gratificação deve ter no mínimo 3 caracteres";
                return this.dialogWarning = true;
            }

            if (this.gratificacao.nm_rubrica && this.gratificacao.nm_rubrica.length < 3) {
                this.dialogMsg = "Nome da rúbrica deve ter no mínimo 3 caracteres";
                return this.dialogWarning = true;
            }

            if (this.gratificacao.cd_rubrica && this.gratificacao.cd_rubrica.length < 5) {
                this.dialogMsg = "Codigo deve ter 5 caracteres";
                return this.dialogWarning = true;
            }

            let data = new FormData();
            data.append('id_gratificacao', this.gratificacao.id_gratificacao);
            data.append('sg_gratificacao', this.gratificacao.sg_gratificacao);
            data.append('nm_gratificacao', this.gratificacao.nm_gratificacao);
            data.append('cd_rubrica', this.gratificacao.cd_rubrica);
            data.append('nm_rubrica', this.gratificacao.nm_rubrica);
            data.append('st_autoavaliacao', this.gratificacao.st_autoavaliacao == true ? 1 : 0);
            data.append('st_equipe', this.gratificacao.st_equipe == true ? 1 : 0);
            data.append('st_chefia', this.gratificacao.st_chefia == true ? 1 : 0);

            editarGratificacao(data).then(response => {
                this.listarGratificacoes();
                this.dialogEditar = false;
                this.gratificacao = [];
                this.dialogMsg = "Feito com sucesso";
                this.dialogSuccess = true;
            }).catch(erro => {
                this.dialogMsg = "erro ao salvar";
                this.dialogWarning = true;
            });
        },

        showDialogExcluir(id) {
            this.dialogExcluir = true;
            this.id_gratificacao = id;
        },

        deletar() {
            let data = new FormData();
            data.append('id_gratificacao', this.id_gratificacao);

            excluirGratificacao(data).then(response => {
                this.dialogExcluir = false;
                this.id_gratificacao = null;
                this.dialogMsg = "Feito com sucesso";
                this.dialogSuccess =true;
                this.listarGratificacoes();
            }).catch(erro => {
                this.dialogMsg = "Erro ao deletar.";
                this.dialogWarning = true;
            });
        },
    }
}
</script>
<template>
    <div>
        <h1>Administradores</h1>
        <v-row class="mb-5">
            <v-dialog v-model="dialogAdicionarAdministrador" width="70%" :fullscreen="$vuetify.breakpoint.xsOnly">
                <template v-slot:activator="{ on: dialog, attrs }">
                    <v-btn
                        color="primary"
                        v-bind="attrs"
                        v-on="{ ...dialog }"
                    >
                        <v-icon>mdi-plus</v-icon> Adicionar
                    </v-btn>
                </template>

                <v-card>
                    <v-card-title class="mb-2">
                        <span class="text-h5">Adicionar administrador</span>
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col cols="5">
                                <v-text-field v-model="siapeBusca" dense hide-details label="SIAPE" @keyup.enter="buscaServidorPorSiape" v-mask="'#########'" required outlined></v-text-field>
                            </v-col>
                            <v-col cols="4">
                                <v-btn 
                                    color="primary"
                                    @click="buscaServidorPorSiape"
                                >
                                    <v-icon>mdi-magnify</v-icon> Buscar
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row v-if="dialogError != null">
                            <v-col cols="12">
                                <v-alert
                                    dense
                                    outlined
                                    type="error"
                                    class="mb-2"
                                >
                                    {{ dialogError }}
                                </v-alert>  
                            </v-col>
                        </v-row>
                        <v-row class="my-5">
                            <v-divider></v-divider>
                        </v-row>
                        <v-row>
                            <v-col cols="4">
                                <v-text-field v-model="siapeBuscaResultado.nr_cpf" dense hide-details label="CPF" required readonly></v-text-field>
                            </v-col>
                            <v-col cols="8" class="mx-0">
                                <v-text-field v-model="siapeBuscaResultado.nm_servidor" dense hide-details label="Nome" required readonly></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6">
                                <v-text-field v-model="siapeBuscaResultado.ds_email_institucional" dense hide-details label="E-mail institucional"  required readonly></v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field v-model="siapeBuscaResultado.nr_telefone" dense hide-details label="Telefone" required readonly></v-text-field>
                            </v-col>
                        </v-row>
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions class="py-4">
                        <v-spacer></v-spacer>
                        <v-btn
                            color="default"
                            @click="closeDialogAdicionarAdministrador"
                        >
                            Cancelar
                        </v-btn>
                        <v-btn
                            :disabled="siapeBuscaResultado == 0"
                            color="success"
                            @click="adicionarAdministrador"
                        >
                            Adicionar
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
        <v-row>
            <v-data-table
            :headers="headers"
                :items="orderedAdministradores"
                :items-per-page="10"
                class="elevation-1"   
            >
                <template v-slot:item.servidor.cpf="{ item }">
                    
                    <span>{{ item.servidor.cpf | VMask('###.###.###-##') }}</span>
                </template>
                <template v-slot:item.actions="{ item }">
                    <v-menu :close-on-content-click="true">
                        <template v-slot:activator="{ on: dialog, attrs }">
                            <v-tooltip top>
                                <template v-slot:activator="{ on: tooltip}">
                                    <v-btn
                                        icon
                                        color="error"
                                        small
                                        v-bind="attrs"
                                        v-on="{ ...tooltip, ...dialog }"
                                    >
                                        <v-icon >
                                            mdi-delete-outline
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <span>Excluir administrador</span>
                            </v-tooltip>
                        </template>
                        <v-card>
                            <v-card-subtitle class="text-center font-weight-medium">
                                Excluir administrador
                            </v-card-subtitle>
                            <v-card-text>
                                Tem certeza que deseja excluir <strong>{{ item.servidor.nome }}</strong> do perfil de administrador?
                            </v-card-text>
                            <v-card-actions class="justify-center">
                                <v-btn color="default">
                                    Cancelar
                                </v-btn>
                                <v-btn 
                                    color="error" 
                                    @click="deletarAdministrador(item.id_administrador)"
                                >
                                    Confirmar
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-menu>
                </template>
            </v-data-table>
        </v-row>
    </div>
</template>

<script>
    import { getAdministradores, createAdministrador, deleteAdministrador } from '../api/administrador';
    import { getServidorBySiape } from '../api/servidor';

    export default {
        name: 'AdministradoresListar',
        data() {
            return {
                administradores: [],
                headers: [
                    {text: 'CPF', value: 'servidor.cpf', sortable: false},
                    { text: 'Nome', value: 'servidor.nome' },
                    { text: 'E-mail institucional', value: 'servidor.email_institucional' },
                    { text: 'Ações', value: 'actions', sortable: false },
                ],
                dialogAdicionarAdministrador: false,
                siapeBusca: null,
                dialogError: null,
                siapeBuscaResultado: []
            };
        },
        computed: {
            orderedAdministradores: function () {
                return _.orderBy(this.administradores, 'servidor.nome');
            },
        },
        created() {
            getAdministradores()
                .then(response => {
                    this.administradores = response.data.data;
                });
        },
        methods: {
            buscaServidorPorSiape() {
                getServidorBySiape(this.siapeBusca).then(
                    response => {
                        this.siapeBuscaResultado = response.data.data;
                        this.dialogError = null;
                    },
                    error => {
                        this.siapeBusca = null;
                        this.siapeBuscaResultado = [];
                        this.dialogError = 'Não foi possível localizar o servidor informado.';
                    }
                );
            },
            adicionarAdministrador() {
                let administrador = {
                    id_servidor: this.siapeBuscaResultado.id_servidor
                };
                createAdministrador(JSON.stringify(administrador))
                    .then(response => { 
                        this.administradores.push(response.data.data); 
                        this.$store.dispatch('alertas/show', {
                            tipo: 'Sucesso', 
                            titulo: 'Administrador cadastrado com sucesso!', 
                        });
                        this.$vuetify.goTo(0);
                        this.closeDialogAdicionarAdministrador();
                    });
            },
            closeDialogAdicionarAdministrador() {
                this.dialogAdicionarAdministrador = false;
                this.siapeBusca = null;
                this.siapeBuscaResultado = [];
                this.dialogError = null;
            },
            deletarAdministrador(id) {
                deleteAdministrador(id)
                    .then(response => {
                        this.administradores = this.administradores.filter(item => item.id_administrador !== id);
                        this.$store.dispatch('alertas/show', {
                            tipo: 'Sucesso', 
                            titulo: 'Administrador excluído com sucesso!', 
                        });
                        this.$vuetify.goTo(0);
                    });
            }
        }
    }
</script>

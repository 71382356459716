<template>
<div class="home pa-3">
    <v-sheet class="mt-n2 pb-3 white lighten3">
        <h1>Editar RDI</h1>
    </v-sheet>
    <v-stepper v-model="e1" class="mb-10">
        <v-stepper-header>
            <v-stepper-step :complete="e1 > 1" step="1">
                Informações básicas
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step :complete="e1 > 2" step="2">
                Gratificações
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step :complete="e1 > 3" step="3">
                Fatores
            </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
            <v-stepper-content step="1">
                <v-card class="pa-4 mx-2 mb-5 grey lighten-4">
                    <v-form class="pa-0" ref="form_step1">
                        <v-row dense class="ma-2">
                            <v-col cols="12" sm="12">
                                <v-text-field ref="novoQuestionatio_fator" placeholder="Título do questionário" label="Título do questionário" v-model="rdi.nm_rdi" outlined hide-details="auto" background-color="white" required :rules="[() => !!rdi.nm_rdi || 'Campo obrigatório.']"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row dense class="ma-2">
                            <v-col cols="12" sm="12">
                                <v-textarea ref="novoQuestionatio_ds_rdi" rows="3" placeholder="Descrição do questionário" label="Descrição do questionário" v-model="rdi.ds_rdi" outlined hide-details="auto" background-color="white" required :rules="[() => !!rdi.ds_rdi || 'Campo obrigatório.']"></v-textarea>
                            </v-col>
                        </v-row>
                        <v-sheet class="d-flex grey lighten-4">
                            <v-sheet class="grey lighten-4 mx-5 mt-2">
                                <v-radio-group ref="novoRDI_radioChefiaOuAvaliado" v-model="rdi.st_chefia">
                                    <v-radio label="Chefia" :value="true"></v-radio>
                                    <v-radio label="Avaliado" :value="false"></v-radio>
                                </v-radio-group>
                            </v-sheet>
                        </v-sheet>
                        <v-sheet v-if="alertValidationStep1" class="pa-0 ma-0 d-flex grey lighten-4 elevation-0 justify-end align-end">
                            <v-alert class="mx-10 my-3 lighten-5" border="right" colored-border type="error" elevation="5">
                                Confira os campos mandatórios.
                            </v-alert>
                        </v-sheet>
                    </v-form>
                </v-card>
                <v-card class="text-right" elevation="0" cols="12" sm="6" md="1">
                    <router-link :to="{ name: 'rdi-listar'}" style="text-decoration: none">
                        <v-btn class="ma-3" color="warning">
                            <v-icon style="text-decoration: none">mdi-keyboard-return</v-icon>
                            Voltar
                        </v-btn>
                    </router-link>
                    <v-btn class="ma-3" color="success" @click="goToStep2()">
                        Avançar
                    </v-btn>
                </v-card>
            </v-stepper-content>

            <v-stepper-content step="2">
                <v-card class="pa-4 mx-2 mb-5 grey lighten-4">
                    <v-card class="ma-3 pa-6 rounded-xl elevation-0" color="grey lighten-4">
                        <v-card-title>
                            <span class="headline">Selecionar gratificações</span>
                        </v-card-title>
                        <v-row dense>
                            <v-col cols="12" sm="10" md="10">
                                <v-checkbox v-model="allGratificaçõesSelected" :label="`Selecionar todas`" @click="selectAllGratificacoes()"></v-checkbox>
                            </v-col>
                        </v-row>
                        <v-divider class="ma-0"></v-divider>
                        <div v-for="(gratificacao,i) in novoRDI.gratificacoes" :key="gratificacao.sg_gratificacao" class="ma-0">
                            <v-list-item @click="gratificacaoSelected(i)">
                                <template v-slot:default>
                                    <v-list-item-action>
                                        <v-checkbox :input-value="gratificacao.selected" color="primary"></v-checkbox>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                        <v-list-item-title>{{gratificacao.sg_gratificacao + ' - ' +gratificacao.nm_gratificacao}}</v-list-item-title>
                                    </v-list-item-content>
                                </template>
                            </v-list-item>
                            <v-divider class="ma-0"></v-divider>
                        </div>
                    </v-card>
                    <v-sheet v-if="selecionarGratificaçõesRequiredFieldsAlertShow" class="pa-0 ma-0 d-flex grey lighten-3 elevation-0 justify-end align-end" width="100%">
                        <v-alert class="mx-10 my-3 lighten-5" border="right" colored-border type="error" elevation="5">
                            Escolha ao menos uma gratificação.
                        </v-alert>
                    </v-sheet>
                </v-card>

                <v-card class="text-right" elevation="0" cols="12" sm="6" md="1">
                    <v-btn class="ma-3" color="warning" @click="e1 = 1">
                        Voltar
                    </v-btn>
                    <v-btn class="ma-3" color="success" @click="goToStep3()">
                        Avançar
                    </v-btn>
                </v-card>
            </v-stepper-content>

            <v-stepper-content step="3">
                <v-card class="pa-4 mx-2 mb-5 grey lighten-4">
                    <template>
                        <v-row justify="space-around">
                            <v-col cols="auto">
                                <v-dialog transition="dialog-top-transition" max-width="1000" scrollable persistent>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-sheet class="d-flex flex-column grey lighten-3">
                                            <v-btn color="primary my-3 align-self-end" dark style="text-decoration: none;" v-bind="attrs" v-on="on">
                                                Selecionar Fatores
                                                <i class="icon fas fa-plus"></i></v-btn>
                                        </v-sheet>
                                    </template>
                                    <template v-slot:default="dialog">
                                        <v-card>
                                            <v-card-title class="primary">
                                                <span class="text-h6 white--text">Seleção de Fatores para {{chefiaOuAvaliado_radio_value}}</span>
                                            </v-card-title>
                                            <v-card-text style="height: '80%'">
                                                <v-sheet class="d-flex flex-column align-center white lighten-3 ml-n5 mt-5">
                                                    <v-sheet v-for="item in fatoresFiltrados" :key="item.id_fator" width="100%" max-width="900">
                                                        <v-hover v-slot="{ hover }">
                                                            <v-card class="ma-3 pt-5 pr-4 mb-4 rounded-lg grey lighten-4" width="100%" max-width="900" :elevation="hover ? 8 : 3">
                                                                <v-row dense class="px-4">
                                                                    <v-col cols="3">
                                                                        <v-subheader class="font-weight-medium">Fator</v-subheader>
                                                                    </v-col>
                                                                    <v-col cols="9">
                                                                        <v-text-field dense v-model="item.nm_fator" hide-details="auto" outlined readonly />
                                                                    </v-col>
                                                                </v-row>
                                                                <v-row dense class="px-4">
                                                                    <v-col cols="3">
                                                                        <v-subheader class="font-weight-medium">Descrição</v-subheader>
                                                                    </v-col>
                                                                    <v-col cols="9">
                                                                        <v-textarea dense v-model="item.ds_fator" hide-details="auto" outlined readonly rows="3" />
                                                                    </v-col>
                                                                </v-row>
                                                                <v-row dense class="px-5 mt-1 mb-3 grey-lighten-4">
                                                                    <v-sheet class="d-flex justify-space-between elevation-0 grey lighten-4" width="100%">
                                                                        <v-sheet class="d-flex flex-row grey lighten-4">
                                                                            <!--
                                                                            <v-sheet class="grey lighten-4 mx-5 mb-5">
                                                                                <v-checkbox dense v-model="item.st_chefia" :value="item.st_chefia" label="Chefia" hide-details="auto" readonly color="grey lighten-0"></v-checkbox>
                                                                            </v-sheet>
                                                                            <v-sheet class="grey lighten-4 mx-5 mb-5">
                                                                                <v-checkbox dense v-model="item.st_avaliado" :value="item.st_avaliado" label="Avaliado" hide-details="auto" readonly color="grey lighten-0"></v-checkbox>
                                                                            </v-sheet>
                                                                            -->
                                                                        </v-sheet>
                                                                        <v-sheet class="d-flex flex-row mb-5 grey lighten-4">
                                                                            <v-btn v-if="!item.isAddedToRDI" color="primary lighten-2 mx-5 mt-4 elevation-2 pa-2" @click="adicionarFatorAoRDI(item.id_fator)" :disabled="item.isAddedToRDI">
                                                                                Adicionar ao questionário
                                                                                <i class="icon fas fa-plus"></i>
                                                                            </v-btn>
                                                                            <v-btn v-if="item.isAddedToRDI" dark color="red lighten-2 mx-5 mt-4 elevation-2 pa-2" @click="removerFatorDoRDISemDialogConfirmação(item.id_fator)" :disabled="!item.isAddedToRDI">
                                                                                Remover do questionário
                                                                                <i class="icon fas fa-minus"></i>
                                                                            </v-btn>
                                                                        </v-sheet>
                                                                    </v-sheet>
                                                                </v-row>
                                                            </v-card>
                                                        </v-hover>
                                                    </v-sheet>
                                                </v-sheet>
                                            </v-card-text>
                                            <v-divider class="ma-0"></v-divider>

                                            <v-card-actions class="justify-end">
                                                <v-btn text @click="dialog.value = false">Fechar</v-btn>
                                            </v-card-actions>
                                        </v-card>
                                    </template>
                                </v-dialog>
                            </v-col>
                        </v-row>
                    </template>
                    <v-sheet class="mx-3 grey lighten-3">
                        <card-list v-model="novoRDI.fatores" #default="{ item }">
                            <v-row dense>
                                <v-col cols="3">
                                    <v-subheader>Fator</v-subheader>
                                </v-col>
                                <v-col cols="9">
                                    <v-text-field dense v-model="item.nm_fator" hide-details="auto" outlined readonly />
                                </v-col>
                            </v-row>
                            <v-row dense>
                                <v-col cols="3">
                                    <v-subheader>Descrição</v-subheader>
                                </v-col>
                                <v-col cols="9">
                                    <v-textarea dense v-model="item.ds_fator" hide-details="auto" outlined readonly rows="3" />
                                </v-col>
                            </v-row>
                            <v-row dense class="elevation-0">
                                <v-col cols="3" class="d-flex flex-row align-center justify-center elevation-0">
                                    <v-sheet class="d-flex flex-column justify-space-between elevation-0 white lighten-3" width="100%">
                                        <v-subheader>Peso</v-subheader>
                                    </v-sheet>
                                </v-col>
                                <v-col cols="5" class="elevation-0">
                                    <v-sheet class="pt-9 mx-0 white lighten-3 rounded-lg outlined" outlined width="360">
                                        <v-sheet class="mx-0 mt-0 white lighten-3 rounded-lg outlined" outlined width="310">
                                            <v-slider v-model="item.nr_peso" :value="peso_value" :max="5" :min="1" :tick-labels="peso_items" thumb-label="always"></v-slider>
                                        </v-sheet>
                                    </v-sheet>
                                </v-col>
                                <v-col cols="4" class="elevation-0">
                                    <v-sheet class="d-flex flex-column mb-5">
                                        <v-btn color="grey lighten-3 mt-8 align-self-end elevation-2 pa-2" @click="fatorParaRemover = item; comfirmarRemoçãoDialog = true">
                                            REMOVER FATOR
                                            <v-icon color="red lighten-2" class="ml-1">mdi-delete</v-icon>
                                        </v-btn>
                                    </v-sheet>
                                </v-col>
                            </v-row>
                        </card-list>
                    </v-sheet>
                </v-card>
                <v-card class="text-right" elevation="0" cols="12" sm="6" md="1">
                    <v-btn class="ma-3" color="warning" @click="e1 = 2">
                        Voltar
                    </v-btn>
                    <v-btn class="ma-3" color="success" @click="editarRDI(rdi.id_rdi)">
                        Salvar edição
                    </v-btn>
                </v-card>
            </v-stepper-content>
        </v-stepper-items>
    </v-stepper>

    <v-dialog v-model="comfirmarRemoçãoDialog" max-width="600" persistent>
        <v-card class="pa-5 rounded-lg">
            <v-card-title class="text-h5">
                Remoção de fator do Questionário
            </v-card-title>
            <v-card-text>
                <b>ID:</b> {{fatorParaRemover.id_fator}}
                <br>
                <b>Fator:</b> {{fatorParaRemover.nm_fator}}
                <br>
                <b>Descrição:</b> {{fatorParaRemover.ds_fator}}
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <h6 v-if="confirmarRemocaoBtnsDisabled">Removendo fator...</h6>
                <v-btn class="mx-5" :disabled="confirmarRemocaoBtnsDisabled" color="grey" text @click="(comfirmarRemoçãoDialog = false)">
                    Cancelar
                </v-btn>
                <v-btn class="mx-5" :disabled="confirmarRemocaoBtnsDisabled" color="red lighten-2" dark @click="removerFatorDoRDI()">
                    remover
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</div>
</template>

<script>
import {getGratificacoes} from '../api/gratificacao';
import {getFatores} from '../api/fator';
import {getCiclo} from '../api/ciclo';
import {getCiclosVigentes} from '../api/ciclo';
import {updateRDI} from '../api/rdi';
import {getRDI} from '../api/rdi';

import CardList from '../components/other/CardList';

export default {
    name: 'rdi-editar',
    components: {
        CardList
    },
     data() {
        return {
            e1: 1,
            novoRDI: {
                nm_rdi: '',
                ds_rdi: '',
                chefiaOuAvaliado: null,
                gratificacoes: [],
                fatores: [],
                ciclo: {},
            },
            peso_items: [1, 2, 3, 4, 5],
            peso_value: 1,
            chefiaOuAvaliado_radio_items: ['Chefia', 'Avaliado'],
            chefiaOuAvaliado_radio_value: '',
            alertValidationStep1: false,
            selecionarGratificaçõesRequiredFieldsAlertShow: false,
            allGratificaçõesSelected: false,
            fatoresFiltrados: [],
            todosFatores: [],
            counter: 10,
            comfirmarRemoçãoDialog: false,
            confirmarRemocaoBtnsDisabled: false,
            fatorParaRemover: {
                "id_fator": 0,
                "nm_fator": '',
                "ds_fator": '',
            },
            ciclosVigentes: [],
            ultimoCicloVigente: [],
            rdi: [],
        }
    },
    created() {
        let id_rdi = this.$route.params.id;

        getRDI(id_rdi).then(response => {
            this.rdi = response.data.data;

            getGratificacoes().then(response => {
                response.data.data.forEach((gratificacao) => {
                    this.rdi.gratificacoes.map((gr)=>{
                        if (gr.id_gratificacao == gratificacao.id_gratificacao) {
                            gratificacao.selected = true;
                        }
                    });
                    this.novoRDI.gratificacoes.push(gratificacao);
                });
            });
        });
        
        this.carregaFatores();

        getCiclosVigentes().then(response => {
            this.ciclosVigentes = response.data.data;
            let ultimoCicloVigente = this.ciclosVigentes[Object.keys(this.ciclosVigentes).pop()];
            
            getCiclo(ultimoCicloVigente.id_ciclo).then(response => {
                this.ultimoCicloVigente = response.data.data;
                this.novoRDI.ciclo = this.ultimoCicloVigente;
            });
        }).catch(erro => {
            console.log('erro na busca de ciclos vigentes');
        });
    },
    mounted() {
        this.selectAllGratificacoes(true);
    },
    methods: {
        goToStep2() {
            if (this.$refs.form_step1.validate()) {
                this.novoRDI.chefiaOuAvaliado = this.chefiaOuAvaliado_radio_value;
                let fatoresFiltrados = []
                fatoresFiltrados = fatoresFiltrados.concat(this.todosFatores.filter(fator => fator.id_fator))
                this.novoRDI.fatores = this.novoRDI.fatores.filter(fator => fator.id_fator)
                for (let fator of fatoresFiltrados) {
                    fator.isAddedToRDI = false;
                }
                this.alertValidationStep1 = false;
                this.fatoresFiltrados = fatoresFiltrados;
                this.e1 = 2;
            } else {
                this.alertValidationStep1 = true;
            }
        },
        goToStep3() {
            if (this.novoRDI.gratificacoes.filter(grat => grat.selected === true).length > 0) {
                this.e1 = 3;
                this.selecionarGratificaçõesRequiredFieldsAlertShow = false;
            } else {
                this.selecionarGratificaçõesRequiredFieldsAlertShow = true;
            }

            this.rdi.fatores.map((f) =>{
                this.adicionarFatorAoRDI(f.id_fator);
            });
        },
        gratificacaoSelected(index) {
            this.novoRDI.gratificacoes[index].selected = !this.novoRDI.gratificacoes[index].selected;
            if (this.selecionarGratificaçõesRequiredFieldsAlertShow && this.novoRDI.gratificacoes.length > 0) {
                this.selecionarGratificaçõesRequiredFieldsAlertShow = false
            }
            this.allGratificaçõesSelected = (this.novoRDI.gratificacoes.filter(grat => grat.selected === true).length === this.novoRDI.gratificacoes.length) ?
                true :
                false;
        },
        selectAllGratificacoes(valueToSet) {
            let newValueToSet = (valueToSet) ? valueToSet : (this.allGratificaçõesSelected) ? true : false;
            for (let gratificacao of this.novoRDI.gratificacoes) {
                gratificacao.selected = newValueToSet;
            }
            if (this.selecionarGratificaçõesRequiredFieldsAlertShow && this.novoRDI.gratificacoes.length > 0) {
                this.selecionarGratificaçõesRequiredFieldsAlertShow = false
            }
        },
        carregaFatores() {

            this.todosFatores = [];
            getFatores()
                .then(response => {
                    response.data.data.forEach((fator) => {
                        fator.id = fator.id_fator
                        fator.cardTitle = 'Fator'
                        this.todosFatores.push(fator);
                    })
                });
        },
        adicionarFatorAoRDI(id_fator) {
            this.fatoresFiltrados.filter(fator => fator.id === id_fator)[0].isAddedToRDI = true;
            let fatorParaAdicionar = this.fatoresFiltrados.filter(fator => fator.id === id_fator)[0]
            fatorParaAdicionar.nr_peso = 3;
            this.novoRDI.fatores.push(fatorParaAdicionar);
            this.fatoresFiltrados = JSON.parse(JSON.stringify(this.fatoresFiltrados));
        },
        removerFatorDoRDISemDialogConfirmação(id_fator) {
            this.fatoresFiltrados.filter(fator => fator.id === id_fator)[0].isAddedToRDI = false;
            this.novoRDI.fatores = this.novoRDI.fatores.filter(fator => fator.id !== id_fator);
            this.fatoresFiltrados = JSON.parse(JSON.stringify(this.fatoresFiltrados));
        },
        removerFatorDoRDI() {
            this.confirmarRemocaoBtnsDisabled = true;
            this.novoRDI.fatores = this.novoRDI.fatores.filter(fator => fator.id !== this.fatorParaRemover.id_fator);
            this.fatoresFiltrados.filter(fator => fator.id === this.fatorParaRemover.id_fator)[0].isAddedToRDI = false;
            this.comfirmarRemoçãoDialog = false;
            this.confirmarRemocaoBtnsDisabled = false;
        },

        editarRDI(id) {
            updateRDI(id, this.gerarJson()).then(response => {
                this.$router.push({
                        name: 'rdi-listar',
                    },
                    () => this.$store.dispatch('alertas/show', {
                        tipo: 'Sucesso',
                        titulo: 'RDI editado com sucesso!',
                    })
                )
            });
        },

        gerarJson() {
            let gratificacoes = this.novoRDI.gratificacoes.filter(gratificacao => (gratificacao.selected == true))
                .map(function (gratificacao, i) {
                    return {
                        id_gratificacao: gratificacao.id_gratificacao,
                    }
                });
            let fatores = this.novoRDI.fatores.map(fator => {
                return {
                    id_fator: fator.id_fator,
                    nr_peso: fator.nr_peso,
                }
            });
            let ciclo = [{
                id_ciclo: this.novoRDI.ciclo.id_ciclo,
            }]
            let json = {
                id_rdi: this.rdi.id_rdi,
                nm_rdi: this.rdi.nm_rdi,
                ds_rdi: this.rdi.ds_rdi,
                st_chefia: this.rdi.st_chefia,
                fatores: fatores,
                gratificacoes: gratificacoes,
                ciclo: ciclo,
            }
            return JSON.stringify(json);
        },
    },
}
</script>

<style scoped>
.container {
    max-width: 600px !important;
}
</style>

<template>
<div>
    <h1>Legislação Aplicada</h1>
    <v-row>
        <v-col cols="12">
            <v-card>
                <v-row dense class="ma-0 pa-2">
                    <v-col cols="12" class="mx-0 px-3">
                        <v-text-field 
                            id="titulo" 
                            ref="titulo" 
                            v-model="legislacao.ds_titulo" 
                            dense 
                            hide-details="auto" 
                            label="Título da página" 
                            outlined 
                            clearable 
                            clear-icon="mdi-close-circle-outline" 
                            required 
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row dense class="ma-0 pa-2">
                    <v-col cols="12" class="mx-0 px-3">
                        Texto inicial da página
                    </v-col>
                    <v-col cols="12" class="mx-0 px-3">
                        <ckeditor 
                            :editor="editor" 
                            v-model="legislacao.ds_texto" 
                            :config="editorConfig" 
                            required 
                            ref="texto"
                        ></ckeditor>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="d-flex flex-row justify-end align-end text-right">
                        <v-btn class="success mr-3" :disabled='legislacao.ds_texto || legislacao.ds_titulo ? false : true' @click="save">
                            Salvar
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card>
            <br />
        </v-col>
    </v-row>
    <v-row>
        <v-col cols="12">
            <template>
                <v-card class="mx-0">
                    <v-card-title>
                        <v-row class="mt-3 mx-3">
                            Anexos
                            <v-spacer></v-spacer>
                            
                            <v-btn 
                                color="primary" 
                                height="40px" 
                                @click="dialogAddAnexo = true"
                            >
                                Adicionar anexo
                            </v-btn>
                        </v-row>
                    </v-card-title>
                    <v-data-table
                        :headers="headersAnexo"
                        :items="anexosLegislacao"
                        :items-per-page="5"
                    >
                        <template v-slot:[`item.acao`]="{ item }">
                            <v-row>
                                <v-tooltip top >
                                    <template v-slot:activator="{on, attrs}">
                                        <v-btn 
                                            rounded 
                                            icon  
                                            class="mr-2" 
                                            id="btnDownload" 
                                            small 
                                            :color="'primary'" 
                                            v-bind="attrs" 
                                            v-on="on"
                                            @click="download(item.arquivo.id_arquivo, item.arquivo.nm_arquivo)"
                                        >
                                            <v-icon> mdi-download </v-icon>
                                        </v-btn>
                                    </template>
                                    <span>DOWNLOAD</span>
                                </v-tooltip>

                                <v-tooltip top >
                                    <template v-slot:activator="{on, attrs}">
                                        <v-btn 
                                            rounded 
                                            icon  
                                            class="mr-2" 
                                            id="btnremover" 
                                            small
                                            :color="'error'" 
                                            v-bind="attrs" 
                                            v-on="on"
                                            @click="removerAnexo(item.arquivo.id_arquivo)"
                                        >
                                            <v-icon> mdi-delete-forever </v-icon>
                                        </v-btn>
                                    </template>
                                    <span>REMOVER</span>
                                </v-tooltip>
                            </v-row>
                        </template>    
                    </v-data-table>
                </v-card>
            </template>
        </v-col>
    </v-row>

    <v-dialog v-model="dialogWarning" width="570" >
        <v-card>
            <v-card-title class="text-h5 text-center">
                <v-alert prominent type="warning" outlined width="100%">
                    <v-row align="center">
                        <v-col class="grow">
                            {{ dialogMsg }}
                        </v-col>
                        <v-col class="shrink">
                        <v-btn color="warning"  @click="dialogWarning = false">Ok</v-btn>
                        </v-col>
                    </v-row>
                </v-alert>
            </v-card-title> 
        </v-card>
    </v-dialog>  

    <v-dialog v-model="dialogSuccess" width="570" >
        <v-card>
            <v-card-title class="text-h5 text-center">
                <v-alert prominent type="success" width="100%">
                    <v-row align="center">
                        <v-col class="grow">
                            {{dialogMsg}}
                        </v-col>
                        <v-col class="shrink">
                        <v-btn outlined  @click="dialogSuccess = false">Ok</v-btn>
                        </v-col>
                    </v-row>
                </v-alert>
            </v-card-title> 
        </v-card>
    </v-dialog> 

    <v-dialog v-model="dialogAddAnexo" max-width="500px">
        <v-card>
            <h4 class="text-center primary--text mt-2 pt-6" >
                Adicinar anexo
            </h4>
            <v-card-text>
                <v-container>
                    <v-row dense class="ma-0 pa-2">
                    <v-col cols="12" class="mx-0 px-3">
                        <v-text-field 
                            id="tituloAnexo" 
                            ref="tituloAnexo" 
                            v-model="tituloAnexo" 
                            dense 
                            hide-details="auto" 
                            label="Título do anexo"  
                            clearable 
                            prepend-icon="mdi mdi-file-document"
                            outlined
                            clear-icon="mdi-close-circle-outline" 
                            required 
                        ></v-text-field>
                    </v-col>
                    <br>
                    <v-col cols="12" class="mx-0 px-3">
                        <v-file-input 
                            id="anexo" 
                            ref="anexo" 
                            :rules="rulesAnexo"
                            accept=".png, .jpeg, .pdf, .doc, .xlsx, .xls, .docx, .jpg"
                            v-model="anexo" 
                            label="Anexo" 
                            outlined
                            height="40px"
                            dense
                        ></v-file-input>
                    </v-col>
                </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="secoundary darken-1" text @click="dialogAddAnexo = false">Cancelar</v-btn>
            <v-btn color="primary" text @click="addAnexo">Salvar</v-btn>
            <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>   
</div>
</template>

<script>
import { salvarLegislacaoAplicada } from '../api/legislacao_aplicada';
import { getLegislacaoAplicada } from '../api/legislacao_aplicada';
import { addAnexoLegislacaoAplicada } from '../api/legislacao_aplicada';
import { getAnexosLegislacaoAplicada } from '../api/legislacao_aplicada';
import { downloadAnexoLegislacaoAplicada } from '../api/legislacao_aplicada';
import { removerAnexoLegislacaoAplicada } from '../api/legislacao_aplicada';

import Vue from 'vue';
import CKEditor from '@ckeditor/ckeditor5-vue2';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
Vue.use( CKEditor );

export default {
    name: 'LegislacaoAplicadaCadastrar',
    components: {
        ckeditor: CKEditor.component
    },
    data() {
        return {
            editor: ClassicEditor,
            editorConfig: {
                language: 'pt-br'
            },
            editor: ClassicEditor,
            legislacao : [],
            headersAnexo: [
                { text: 'Título do anexo', value: 'ds_titulo' ,sortable: false},
                { text: 'Anexo', value: 'arquivo.nm_arquivo' ,sortable: false},
                { text: 'Ação', value: 'acao' ,sortable: false, width: '100px'},
            ],
            anexosLegislacao: [],
            arquivo:[],
            rulesAnexo: [
                value => !value || value.size < 900000000 || 'Tamanho deve ser menor que 900 MB!'
            ],
            anexo: null,
            tituloAnexo:'',
            dialogSuccess: false,
            dialogWarning: false,
            dialogMsg:'',
            dialogAddAnexo: false
        };
    },

    mounted() {
    },
    created() {
        this.getLegislacao();
        this.getAnexos();
    },

    methods: {
        save () {
            let data = new FormData();
            if (this.legislacao.id_legislacao_aplicada) {
                data.append('id_legislacao_aplicada', this.legislacao.id_legislacao_aplicada);
            }
            data.append('ds_titulo', this.legislacao.ds_titulo ? this.legislacao.ds_titulo : '');                
            data.append('ds_texto', this.legislacao.ds_texto ? this.legislacao.ds_texto : '');

            salvarLegislacaoAplicada(data).then(response => {
                this.dialogMsg = "Feito com sucesso";
                this.dialogSuccess = true;
                this.getLegislacao();
            }).catch(erro => {
                this.dialogMsg = "Erro ao salvar";
                this.dialogWarning = true;
            });
        },

        getLegislacao () {
            getLegislacaoAplicada()
            .then(response => {
                var res = response.data.data;
                
                if (res.id_legislacao_aplicada) {
                    this.legislacao = res;
                } else {
                    this.legislacao = [];
                } 
            });
        },

        addAnexo() {
            if (this.anexo == null) {
                this.dialogMsg = 'Escolha um anexo' 
                return this.dialogWarning = true;
            }

            if (this.tituloAnexo == null || this.tituloAnexo == '') {
                this.dialogMsg = 'Necessário ter o título do anexo' 
                return this.dialogWarning = true;
            }

            let data = new FormData();
            data.append('file', this.anexo);
            data.append('ds_titulo', this.tituloAnexo);
            addAnexoLegislacaoAplicada(data).then(response => {
                this.getAnexos();
                this.dialogAddAnexo = false;
                this.dialogMsg = 'Feito com sucesso' 
                this.dialogSuccess =true;
                this.anexo = null;
                this.tituloAnexo = '';
            }).catch(erro => {
                this.dialogMsg = "Erro ao adicionar.";
                this.dialogWarning = true;
            });
        },

        getAnexos() {
            getAnexosLegislacaoAplicada().then(response => {
                this.anexosLegislacao = response.data.data;
            }).catch(erro => {
                this.dialogMsg = "Erro ao listar anexos.";
                this.dialogWarning = true;
            });
        },

        download(id_arquivo, nm_arquivo) {
            downloadAnexoLegislacaoAplicada(id_arquivo).then(response => {
                let blob = new Blob([response.data], { type: 'application/pdf' });
                let link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = nm_arquivo;                
                link.click();
            }).catch(erro => {
                this.dialogMsg = "Erro ao realizar download.";
                this.dialogWarning = true;
            });
        },

        removerAnexo(id_arquivo) {
            let data = new FormData();
            data.append('id_anexo', id_arquivo);

            removerAnexoLegislacaoAplicada(data).then(response => {
                this.dialogMsg = "Feito com sucesso";
                this.dialogSuccess =true;
                this.getAnexos();
            }).catch(erro => {
                this.dialogMsg = "Erro ao remover.";
                this.dialogAlert = true;
            });
        },
    }
}
</script>

<template>
    <div class="pt-3">
        <h1>Unidades de gestão de pessoas e servidores externos</h1>
        <v-col cols="12">
            <v-card>
                <v-data-table
                    :headers="headers"
                    :items="notificacoes"
                    :items-per-page="5"
                    class="elevation-1"
                >
                <template v-slot:top>
                    <v-toolbar
                        flat
                    >
                        <v-toolbar-title>Notificações</v-toolbar-title>
                        <v-divider
                        class="mx-4"
                        inset
                        vertical
                        ></v-divider>
                        <v-spacer></v-spacer>
                        <v-btn v-if="semCiclo" class="primary float-md-right" @click="semCicloVigente()">
                            <v-icon>mdi-plus</v-icon> Cadastrar
                        </v-btn>
                        <router-link v-else :to="{ name: 'cadastrarNotificacaoUnidadeGestao'}" style="text-decoration: none">
                            <v-btn class="primary float-md-right">
                                <v-icon>mdi-plus</v-icon> Cadastrar
                            </v-btn>
                        </router-link>
                    </v-toolbar>
                </template>

                    <template v-slot:[`item.acao`]="{ item }">
                        <v-row justify="center">
                            <v-tooltip top >
                                <template v-slot:activator="{on, attrs}">
                                    <v-btn class="mr-2" id="btnVisualizar" icon small :color="'primary'" v-bind="attrs" v-on="on" @click="() => visualizar(item.id_notificacao_unidade_gestao, false)">
                                        <v-icon> mdi-eye </v-icon>
                                    </v-btn>
                                </template>
                                <span>VISUALIZAR NOTIFICAÇÃO</span>
                            </v-tooltip>
                            <v-tooltip top>
                                <template v-slot:activator="{on, attrs}">
                                    <v-btn class="mr-2" id="btnReenviar" icon small :color="'primary'" v-bind="attrs" v-on="on" @click="() => visualizar(item.id_notificacao_unidade_gestao, true)">
                                        <v-icon> mdi-email-send </v-icon>
                                    </v-btn>
                                </template>
                                <span>REENVIAR NOTIFICAÇÃO</span>
                            </v-tooltip>
                        </v-row>
                    </template>
                </v-data-table>
            </v-card>
        </v-col>

        <v-dialog v-model="modalNotificacao" hide-overlay transition="dialog-bottom-transition">
            <v-card>
                <v-toolbar dark color="primary">
                    <v-icon class="ml-4"> mdi-eye </v-icon>
                    <v-toolbar-title class="ml-3">
                        {{titulo}}
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn dark text @click="modalNotificacao = false">
                            <v-icon> mdi-close </v-icon>
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <div>
                    <v-col cols="12">
                        <v-card class="pa-10">
                            <v-row dense class="ma-0 pa-2">
                                <v-col cols="12" class="mx-0 px-3">
                                    <v-text-field id="orgao" v-model="dataModel.orgaoRequisitante" ref="orgao"  dense hide-details="auto" label="Órgão requisitante do avaliado"  disabled></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row v-show="tableServidor" dense class="ma-0 pa-2">
                                <v-col cols="12" class="mx-0 px-3">
                                    Servidores cedidos/requisitados do órgão
                                </v-col>
                                <v-col cols="12" class="mx-0 px-3">
                                    <v-data-table
                                        :headers="headersServidor"
                                        :items="servidores"
                                        :items-per-page="5"
                                        class="elevation-1"
                                    >
                                    </v-data-table>
                                </v-col>
                            </v-row>
                            
                            <v-row dense class="ma-0 pa-2">
                                <v-col cols="12" class="mx-0 px-3">
                                    <v-text-field id="nmUnidadeGestao" v-model="dataModel.nm_unidade_gestao" ref="nmUnidadeGestao"  dense hide-details="auto" label="Nome da unidade de gestão de pessoas" disabled></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row dense class="ma-0 pa-2">
                                <v-col cols="8" class="mx-0 px-3">
                                    <v-text-field id="emailUnidade" v-model="dataModel.ds_email" ref="emailUnidade"  dense hide-details="auto" label="Email da unidade de gestão de pessoas " disabled></v-text-field>
                                </v-col>
                                <v-col cols="4" class="mx-0 px-3">
                                    <v-text-field id="telefoneUnidade" v-model="dataModel.nr_telefone" ref="telefoneUnidade"  dense hide-details="auto" label="Telefone da unidade de gestão de pessoas" v-mask="'(##) ####-#####'" disabled></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row dense class="ma-0 pa-2">
                                <v-col cols="12" class="mx-0 px-3">
                                    <v-text-field id="tituloNotificacao" v-model="dataModel.tx_assunto" ref="tituloNotificacao" dense hide-details="auto" label="Título do email" :disabled="disabledCampo" :rules="validaCampo"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row dense class="ma-0 pa-2">
                                <v-col cols="12" class="mx-0 px-3">
                                    Corpo do email
                                </v-col>
                                <v-col cols="12" class="mx-0 px-3">
                                    <ckeditor :editor="editor"  :config="editorConfig" v-model="dataModel.tx_corpo" :disabled ="disabledCampo"></ckeditor>
                                </v-col>
                            </v-row>
                            <v-row v-show="tableAnexos" dense class="ma-0 pa-2">
                                <v-col cols="12" class="mx-0 px-3">
                                    Anexos
                                </v-col>
                                <v-col cols="12" class="mx-0 px-3">
                                    <v-data-table
                                        :headers="headersAnexos"
                                        :items="anexos"
                                        :items-per-page="5"
                                        class="elevation-1"
                                    >
                                    <template v-slot:[`item.acao`]="{ item }">
                                        <v-row>
                                            <v-tooltip top >
                                                <template v-slot:activator="{on, attrs}">
                                                    <v-btn class="mr-2" id="btnDownload" @click="() => download(item.id_arquivo, item.nm_arquivo)" small :color="'primary'" v-bind="attrs" v-on="on">
                                                        <v-icon> mdi-download </v-icon>Download
                                                    </v-btn>
                                                </template>
                                                <span>DOWNLOAD</span>
                                            </v-tooltip>
                                        </v-row>
                                    </template>
                                    </v-data-table>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                </div>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="modalNotificacao = false">FECHAR</v-btn>
                    <v-btn color="primary" v-if="btnReenviar" @click="reenviarDados()" >REENVIAR NOTIFICAÇÃO</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogError" width="570" >
        <v-card>
            <v-card-title class="text-h5 text-center">
                <v-alert prominent type="warning" outlined width="100%">
                    <v-row align="center">
                        <v-col class="grow">{{msgDialog}}</v-col>
                        <v-col class="shrink">
                        <v-btn color="warning"  @click="dialogError = false">Ok</v-btn>
                        </v-col>
                    </v-row>
                </v-alert>
            </v-card-title> 
        </v-card>
    </v-dialog>
    <v-dialog v-model="dialogSuccess" width="570" >
        <v-card>
            <v-card-title class="text-h5 text-center">
                <v-alert prominent type="success" width="100%">
                    <v-row align="center">
                        <v-col class="grow">
                            {{msgDialog}}
                        </v-col>
                        <v-col class="shrink">
                        <v-btn outlined  @click="dialogSuccess = false">Ok</v-btn>
                        </v-col>
                    </v-row>
                </v-alert>
            </v-card-title> 
        </v-card>
    </v-dialog>

    </div>
</template>
<script>
import Vue from 'vue';
import CKEditor from '@ckeditor/ckeditor5-vue2';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import '@ckeditor/ckeditor5-build-classic/build/translations/pt-br.js';
Vue.use( CKEditor );

import { getNotificacoesUnidadeGestao } from '../api/notificacao_unidade_gestao';
import { visualizarNotificacao } from '../api/notificacao_unidade_gestao';
import { downloadAnexo } from '../api/notificacao_unidade_gestao';
import { createNotificacaoUnidadeGestao } from '../api/notificacao_unidade_gestao';
import { getNotificacoesUnidadeGestaoGestor } from '../api/notificacao_unidade_gestao';

import { getServidorOrgaoRequisitante } from '../api/servidor';
import { getCiclosVigentes } from '../api/ciclo';

export default {
    name: 'NotificacaoUnidadeGestaoListar',
    data () {
      return {
        headers: [
          { text: 'ÓRGÃO REQUISITANTE',align: 'start',sortable: false, value: 'nm_orgao_requisitante'},
          { text: 'EMAIL DO RH DO ORGAO', value: 'ds_email',sortable: false },
          { text: 'TELEFONE DO RH DO ORGAO', value: 'nr_telefone',sortable: false },
          { text: 'DATA/HORA DE ENVIO', value: 'created_at',sortable: false },
          { text: 'AÇÕES', value: 'acao',sortable: false },
        ],
        notificacoes: [],
        modalNotificacao: false,
        editor: ClassicEditor,
        editorConfig: { language: 'pt-br' },
        dataModel:{
            cd_orgao_requisitante:'',
            orgaoRequisitante:'',
            nm_unidade_gestao:'',
            ds_email:'',
            nr_telefone:'',
            tx_assunto:'',
            tx_corpo:'',
            anexo:null,
        },
        servidores: [],
        tableServidor: false,
        headersServidor: [],
        anexos: [],
        tableAnexos :false,
        headersAnexos: [],
        btnReenviar: false,
        dialogError: false,
        msgDialog: '',
        validaCampo: [v => !!v || 'Campo obrigatório'],
        dialogSuccess: false,
        showCampo: false,
        disabledCampo: true,
        titulo: '',
        semCiclo: false
      }
    },
    created() {
            if (
                this.$store.getters['auth/usuarioAutenticadoRoles'].indexOf("gestorOrgao") >= 0
                && this.$store.getters['auth/usuarioAutenticadoRoles'].indexOf("administrador") < 0
            ) {
                getNotificacoesUnidadeGestaoGestor().then(response => {
                    this.notificacoes = response.data.data;
                });
            } else {
                getNotificacoesUnidadeGestao().then(response => {
                    this.notificacoes = response.data.data;
                });
            }
        
        getCiclosVigentes(this.$store.getters['auth/usuarioAutenticado']).then(response => {
            if ( response.data.data.length == 0 ) {
                this.semCiclo = true;
            }
        });
    },
    methods: {
        visualizar(id, showReenviar){
            this.titulo = "Visualizar notificação para unidade de gestão de pessoas";
            this.btnReenviar = false;
            this.showCampo = false;
            this.disabledCampo = true;

            if (showReenviar) {
                this.titulo = "Reenviar notificação para unidade de gestão de pessoas";
                this.btnReenviar = true;
                this.showCampo = true;
                this.disabledCampo = false;
            }
            
            this.modalNotificacao =true;
            visualizarNotificacao(id).then(response => {
                var res= response.data.data;
                this.dataModel.orgaoRequisitante = res.nm_orgao_requisitante;
                this.dataModel.cd_orgao_requisitante = res.cd_orgao_requisitante;
                this.dataModel.nm_unidade_gestao = res.nm_unidade_gestao;
                this.dataModel.ds_email = res.ds_email;
                this.dataModel.nr_telefone = res.nr_telefone;
                this.dataModel.tx_assunto = res.tx_assunto;
                this.dataModel.tx_corpo = res.tx_corpo;

                if ( res.cd_orgao_requisitante !== undefined ) {
                    getServidorOrgaoRequisitante(res.cd_orgao_requisitante)
                        .then(response => {
                            this.servidores = response.data.data;
                            this.tableServidor = true;

                            this.headersServidor = [
                                { text: 'Nome do avaliado', value: 'nm_servidor', sortable: false },
                                { text: 'CPF do avaliado', value: 'nr_cpf', sortable: false },
                                { text: 'Cargo do avaliado', value: 'nm_servidor', sortable: false },
                                { text: 'Gratificação do avaliado', value: 'nr_cpf', sortable: false },
                            ];
                        });
                } else {
                    this.tableServidor = false;
                }

                if (res.anexos !== '') {
                    this.tableAnexos =true;
                    this.anexos = res.anexos;
                    this.headersAnexos = [
                        { text: 'Título do anexo', value: 'nm_arquivo', sortable: false },
                        { text: 'Ação', value: 'acao', sortable: false },
                    ];                
                }
            });
        },

        download(id_arquivo, nm_arquivo) {
            downloadAnexo(id_arquivo).then(response => {
                let blob = new Blob([response.data], { type: 'application/pdf' });
                let link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = nm_arquivo;                
                link.click();
            })
        },

        reenviarDados () {
            if ( this.semCiclo ) {
                this.msgDialog = 'Não há ciclo vigente, reenviar notificação não permitido.';
                return this.dialogError = true;
            }

            if ( !this.dataModel.tx_corpo ) {
                this.msgDialog = 'Corpo do email deve ser preenchido';
                return this.dialogError = true;
            }

             if ( !this.dataModel.tx_assunto ) {
                this.msgDialog = 'Assunto do email deve ser preenchido';
                return this.dialogError = true;
            }

            let data = new FormData();
            data.append('cd_orgao_requisitante', this.dataModel.cd_orgao_requisitante);
            data.append('nm_unidade_gestao', this.dataModel.nm_unidade_gestao);
            data.append('ds_email', this.dataModel.ds_email);
            data.append('nr_telefone', this.dataModel.nr_telefone);
            data.append('tx_assunto', this.dataModel.tx_assunto);
            data.append('tx_corpo', this.dataModel.tx_corpo);
            data.append('file', this.anexos[0].id_arquivo);
            createNotificacaoUnidadeGestao(data).then(response => {
                getNotificacoesUnidadeGestao().then(response => {
                    this.notificacoes = response.data.data;
                    this.modalNotificacao = false;
                    this.msgDialog = "Notificação reenviada com sucesso!"
                    this.dialogSuccess = true;
                });
            })
        },

        semCicloVigente () {
            this.msgDialog = 'Não há ciclo vigente, cadastro não permitido.';
            return this.dialogError = true;
        }
    },
}
</script>

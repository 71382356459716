import api from './index';

const getCiclos = () => api.get('ciclos');

const getCiclosVigentes = () => api.get('ciclos-vigentes');
const getCiclosNaoVigentes = () => api.get('ciclos-nao-vigentes');

const getCiclo = (id_ciclo) => api.get('ciclos/' + id_ciclo);
const getCicloPlanosTrabalho = (id_ciclo) => api.get('ciclo-planos-trabalho/' + id_ciclo);
const getCicloPlanosTrabalhoNaoVigentes = (id_ciclo) => api.get('ciclo-planos-trabalho-nao-vigentes/' + id_ciclo);

const createCiclo = (ciclo) => api.post('salvarNovoCiclo', ciclo);

const updateCiclo = (id_ciclo, ciclo) => api.put('ciclos/' + id_ciclo, ciclo);

const updateCicloFases = (id_ciclo, cicloFases) => api.put('ciclo-editar-fases/' + id_ciclo, cicloFases);

const getAllCiclos = () => api.get('getAllCiclos');

const getFaseAvaliacaoCicloVigente = () => api.get(
    'getFaseAvaliacaoCicloVigente'
);

const repetirNotasAnteriores = (data) => api.post(
    'repetirNotasAnteriores', 
    data
);

const getJustificativaRepetirAvaliacoes = () => api.get(
    'getJustificativaRepetirAvaliacoes'
);

const editarDtEncerramentoCiclo = (data) => api.post(
    'editarDtEncerramentoCiclo', 
    data
);

export {
    getCiclos,
    getCiclo,
    createCiclo,
    updateCiclo,
    updateCicloFases,
    getCiclosVigentes,
    getCiclosNaoVigentes,
    getCicloPlanosTrabalho,
    getCicloPlanosTrabalhoNaoVigentes,
    getAllCiclos,
    getFaseAvaliacaoCicloVigente,
    repetirNotasAnteriores,
    getJustificativaRepetirAvaliacoes,
    editarDtEncerramentoCiclo
}
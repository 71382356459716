<template>
    <v-dialog v-model="dialogDetalharAfastamento" width="70%" :fullscreen="$vuetify.breakpoint.xsOnly">
        <v-card>
            <v-card color="primary" dark class="mx-auto">
                <v-card-title>
                    <v-icon large left> mdi-account-box-multiple </v-icon>
                    <span class="text-h6 font-weight-light">Detalhe afastamentos do servidor</span>
                </v-card-title>
            </v-card>
            <br>
            <v-container>
                <v-row>
                    <v-col cols="3">
                        <v-textarea rows=1 auto-grow label="CPF" dense hide-details="auto" 
                        :value="servidor.cpf" readonly> </v-textarea>
                    </v-col>
                    <v-col cols="2">
                        <v-textarea rows=1 auto-grow label="SIAPE" dense hide-details="auto" 
                        :value="servidor.siape" readonly> </v-textarea>
                    </v-col>
                    <v-col cols="7">
                        <v-textarea rows=1 auto-grow label="Nome" dense hide-details="auto" 
                        :value="servidor.nome" readonly></v-textarea>
                    </v-col>
                    <v-col cols="12">
                        <v-textarea rows=1 auto-grow label="Gratificação" dense hide-details="auto" 
                        :value="servidor.gratificacao" readonly></v-textarea>
                    </v-col>
                    <v-col cols="6">
                        <v-textarea rows=1 auto-grow label="E-mail Institucional" dense hide-details="auto" 
                        :value="servidor.email_institucional" readonly></v-textarea>
                    </v-col>
                    <v-col cols="6">
                        <v-textarea rows=1 auto-grow label="E-mail Pessoal" dense hide-details="auto" 
                        :value="servidor.email_pessoal" readonly></v-textarea>
                    </v-col>
                    <v-col cols="12">
                        <v-textarea rows=1 auto-grow label="Unidade de Exercício" hide-details="auto" 
                        :value="servidor.unidade_exercicio" readonly></v-textarea>
                    </v-col>
                    <v-col cols="6">
                        <v-textarea rows=1 auto-grow label="Situação" dense hide-details="auto" 
                        :value="servidor.situacao_servidor" readonly></v-textarea>
                    </v-col>
                    <v-col cols="6">
                        <v-textarea rows=1 auto-grow label="Avaliado" dense hide-details="auto" 
                        :value="servidor.dsAvaliado" readonly></v-textarea>
                    </v-col>
                    <v-col cols="12">
                        <v-textarea rows=1 auto-grow label="Justificativa da avaliação" dense hide-details="auto" 
                        :value="servidor.dsJustificativaAvaliacao" readonly></v-textarea>
                    </v-col>
                    <v-col cols="12">
                        <v-textarea rows=1 auto-grow label="Ciclo vigente" dense hide-details="auto" 
                        :value="dsCiclo" readonly></v-textarea>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <label>Dados de afastamento</label>
                        <v-data-table
                            :headers="headers"
                            :items="listaAfastamento"
                            class="elevation-1"
                            hide-default-footer
                            disable-pagination
                        >
                        </v-data-table>
                    </v-col>
                </v-row>
            </v-container>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="dialogDetalharAfastamento = false"> Fechar </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
export default {
    data() {
        return {
            afastamento: {},
            servidor: {},
            dialogDetalharAfastamento: false,

            listaAfastamento: [],
            headers: [
                { text: 'Código SIAPE', value: 'co_afastamento_siape', sortable: false, width: '50px' },
                { text: 'Descrição do afastamento', value: 'ds_situacao', sortable: false },
                { text: 'Data de início', value: 'dt_inicio', sortable: false, width: '115px' },
                { text: 'Data fim', value: 'dt_fim', sortable: false, width: '115px' },
                { text: 'Total de dias afastados', value: 'qtdDiasAfastado', sortable: false, width: '100px' },
                { text: 'Total de dias entre o início do ciclo e o início do periodo de avaliação', value: 'qtdDiasAfastadoCicloVigente', sortable: false, width: '100px' },
            ],

            dsCiclo: ''
        }
    },
    methods: {
    	mostraAfastamentoDialog(afastamento) {
            this.servidor.siape = afastamento.servidor.nr_siape;
            this.servidor.cpf = afastamento.servidor.nr_cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
            this.servidor.nome = afastamento.servidor.nm_servidor;
            this.servidor.email_institucional = afastamento.servidor.ds_email_institucional;
            this.servidor.email_pessoal = afastamento.servidor.ds_email_pessoal;
            this.servidor.gratificacao = afastamento.servidor.nm_gratificacao;
            this.servidor.situacao_servidor = afastamento.servidor.nm_situacao;
            this.servidor.unidade_exercicio = afastamento.servidor.nm_unidade;
            this.servidor.dsAvaliado = afastamento.servidor.dsAvaliado;
            this.servidor.dsJustificativaAvaliacao = afastamento.servidor.dsJustificativaAvaliacao;
            this.dialogDetalharAfastamento = true;

            this.listaAfastamento = afastamento.listAfastamento;

            this.dsCiclo = afastamento.dsCiclo;
        }
    }
}

</script>

<template>
	<div>
		<!-- <v-btn rounded class="float-right" color="primary" @click="gerarConsolidacao()">Gerar Consolidação</v-btn> -->
	    <h1>{{ultimoCicloVigente && ultimoCicloVigente.nm_ciclo}}</h1>	
		<v-alert v-if="semCiclo" type="error" prominent>
            <div class="text-h6">
                <strong>Nenhuma avaliação.</strong>
            </div>
            <span>
                No momento não existe nenhum ciclo vigente.
            </span>
        </v-alert>
	    <v-alert v-if="ultimoCicloVigente && !avaliacoes" type="info" prominent>
            <div class="text-h6">
                <strong>Nenhuma avaliação.</strong>
            </div>
            <span>
                No momento você não possui nenhuma avaliação para realizar no {{ultimoCicloVigente && ultimoCicloVigente.nm_ciclo}}.
            </span>
        </v-alert>
        <v-card v-if="ultimoCicloVigente && avaliacoes" class="pa-3" color="grey lighten-4">
        	<v-card v-if="autoAvaliacao.length" class="mx-2 mb-2 mt-4 py-2 px-4">
		        <h3 class="mt-3">Autoavaliação</h3>
		        <small>Avaliação realizada pelo próprio avaliado quanto à percepção do seu desempenho funcional</small>
		        <v-simple-table class="mt-2">
				    <template v-slot:default>
				    	<thead>
				        	<tr>
				          		<th class="text-left" width="40%">Servidor</th>
				          		<th class="text-left">Situação</th>
				          		<th class="text-left" width="40%">Ação</th>
				        	</tr>
				      	</thead>
				      	<tbody>
					        <tr
					        	v-for="avaliacao in autoAvaliacao"
					          	:key="avaliacao.id_avaliacao"
					        >
					          	<td>{{ avaliacao.equipe_trabalho.servidor.nm_servidor }}</td>
					          	<td>
					          		<span v-if="avaliacao.st_pendente">
					          			<v-chip
					          				v-if="avaliacao.equipe_trabalho.ds_meta_individual && avaliacao.st_acordado && !avaliacao.st_bloqueado"
					          				small
					          				label
		      								color="warning"
		      								text-color="white"
		    							>	
		      								<span class="font-weight-medium">
		      									Pendente
		      								</span>
		   								</v-chip>
		   								<v-chip
					          				v-if="!avaliacao.equipe_trabalho.ds_meta_individual && !avaliacao.st_bloqueado"
					          				small
					          				label
		      								color="error"
		      								text-color="white"
		    							>	
		      								<span class="font-weight-medium">
		      									BLOQUEADA - Servidor sem meta individual
		      								</span>
		   								</v-chip>
										<v-chip
					          				v-if="avaliacao.st_bloqueado"
					          				small
					          				label
		      								color="error"
		      								text-color="white"
		    							>	
		      								<span class="font-weight-medium">
		      									BLOQUEADA - Servidor com avaliação bloqueada
		      								</span>
		   								</v-chip>
										<v-chip
					          				v-if="avaliacao.equipe_trabalho.ds_meta_individual && !avaliacao.st_acordado"
					          				label
		      								color="error"
		      								text-color="white"
		    							>	
		      								<span class="font-weight-medium">
												BLOQUEADA - servidor não deu ciência da meta individual.
		      								</span>
		   								</v-chip>
					          		</span>
					          		<span v-else>
					          			<v-chip
					          				small
					          				label
		      								color="success"
		      								text-color="white"
		    							>
		    								
		      								<span class="font-weight-medium">Concluída</span>
		   								</v-chip>
					          		</span>
					          	</td>
					          	<td>
									<v-btn
				          				v-if="(avaliacao.equipe_trabalho.ds_meta_individual && avaliacao.st_acordado && !avaliacao.st_bloqueado) || avaliacao.id_avaliacao"
				          				:to="{ name: 'avaliacoes-detalhar', params: { id: avaliacao.id_avaliacao ? avaliacao.id_avaliacao : 'autoavaliacao' } }"
				          				small
		  								outlined
		  								color="primary"
									>
										<span v-if="avaliacao.st_pendente">Avaliar</span>
										<span v-else>Visualizar/editar</span>
									</v-btn>
									<span v-if="!avaliacao.equipe_trabalho.ds_meta_individual && !avaliacao.st_bloqueado">
										Entre em contato com sua chefia para pactuar uma meta individual. Após o cadastro da meta, a avaliação será liberada automaticamente.
									</span> 
									<span v-if="avaliacao.equipe_trabalho.ds_meta_individual && !avaliacao.st_acordado && !avaliacao.st_bloqueado && !avaliacao.id_avaliacao">
										<span class="font-weight-bold" style="cursor: pointer;" @click.stop="openMetaIndividualDialog()">Clique aqui</span> para dar o acordo em sua meta individual.
									</span> 
									<span v-if="avaliacao.st_bloqueado">
										<strong>Justificativa do bloqueio:</strong> {{avaliacao.tx_observacao}} <br>
									</span> 
					          	</td>
								<v-dialog v-model="dialogMetaIndividual" width="50%" :fullscreen="$vuetify.breakpoint.xsOnly">
									<v-card>
										<v-card-title>
											<span class="headline">Meta individual</span>
											<v-card-subtitle>
												<v-icon color="primary">mdi-chevron-double-right</v-icon>
												{{ avaliacao.equipe_trabalho.servidor.nm_servidor }}
											</v-card-subtitle>
										</v-card-title>
										<v-card-text>
											<v-textarea solo rows="7" v-model="avaliacao.equipe_trabalho.ds_meta_individual" disabled></v-textarea>
										</v-card-text>
										<v-divider></v-divider>
										<v-card-actions>
											<v-spacer></v-spacer>
											<span>
												<v-btn color="success" @click="editarDeAcordoMetaIndividual(avaliacao.equipe_trabalho)" width="95%">
													Ciente
												</v-btn>
											</span>
											<v-btn color="default" @click="dialogMetaIndividual = false">
												Cancelar
											</v-btn>
										</v-card-actions>
									</v-card>
								</v-dialog>
					        </tr>
					     </tbody>
				    </template>
				</v-simple-table>
			</v-card>

			<v-card v-if="avaliacoesChefia.length" class="mx-2 mb-2 mt-4 py-2 px-4">
		        <h3 class="mt-3">Avaliação da chefia imediata</h3>
		        <small>Avaliação realizada pelo chefe da equipe de trabalho quanto à percepção do desempenho funcional dos integrantes da equipe</small>
		        <v-simple-table class="mt-2">
				    <template v-slot:default>
				    	<thead>
				        	<tr>
				          		<th class="text-left" width="40%">Servidor</th>
				          		<th class="text-left">Situação</th>
				          		<th class="text-left" width="40%">Ação</th>
				        	</tr>
				      	</thead>
				      	<tbody>
					        <tr
					        	v-for="avaliacao in avaliacoesChefia"
					          	:key="avaliacao.id_avaliacao"
					        >
					          	<td>{{ avaliacao.equipe_trabalho.servidor.nm_servidor }}</td>
					          	<td>
					          		<span v-if="avaliacao.st_pendente">
					          			<v-chip
					          				v-if="avaliacao.equipe_trabalho.ds_meta_individual && avaliacao.st_acordado && !avaliacao.st_bloqueado"
					          				small
					          				label
		      								color="warning"
		      								text-color="white"
		    							>	
		      								<span class="font-weight-medium">
		      									Pendente
		      								</span>
		   								</v-chip>
		   								<v-chip
					          				v-if="!avaliacao.equipe_trabalho.ds_meta_individual && !avaliacao.st_bloqueado"
					          				small
					          				label
		      								color="error"
		      								text-color="white"
		    							>	
		      								<span class="font-weight-medium">
		      									BLOQUEADA - Servidor sem meta individual
		      								</span>
		   								</v-chip>
										<v-chip
					          				v-if="avaliacao.st_bloqueado"
					          				small
					          				label
		      								color="error"
		      								text-color="white"
		    							>	
		      								<span class="font-weight-medium">
		      									BLOQUEADA - Servidor com avaliação bloqueada
		      								</span>
		   								</v-chip>
										<v-chip
					          				v-if="avaliacao.equipe_trabalho.ds_meta_individual && !avaliacao.st_acordado"
					          				label
		      								color="error"
		      								text-color="white"
		    							>	
		      								<span class="font-weight-medium">
												BLOQUEADA - servidor não deu ciência da meta individual.
		      								</span>
		   								</v-chip>
					          		</span>
					          		<span v-else>
					          			<v-chip
					          				small
					          				label
		      								color="success"
		      								text-color="white"
		    							>
		    								
		      								<span class="font-weight-medium">Concluída</span>
		   								</v-chip>
					          		</span>
					          	</td>
					          	<td>
				          		    <v-btn
				          				v-if="(avaliacao.equipe_trabalho.ds_meta_individual && avaliacao.st_acordado && !avaliacao.st_bloqueado) || avaliacao.id_avaliacao"
				          				:to="{ name: 'avaliacoes-detalhar', params: { id: avaliacao.id_avaliacao ? avaliacao.id_avaliacao : avaliacao.jsonDados } }"
				          				small
		  								outlined
		  								color="primary"
									>
										<span v-if="avaliacao.st_pendente">Avaliar</span>
										<span v-else>Visualizar/editar</span>
									</v-btn>
									<span v-if="!avaliacao.equipe_trabalho.ds_meta_individual && !avaliacao.st_bloqueado && !avaliacao.id_avaliacao">
										É necessário pactuar uma meta individual com o servidor. Após o cadastro da meta, a avaliação será liberada automaticamente.
									</span>
									<span v-if="avaliacao.st_bloqueado">
										<strong>Justificativa do bloqueio:</strong> {{avaliacao.tx_observacao}} <br>
									</span> 
					          	</td>
					        </tr>
					     </tbody>
				    </template>
				</v-simple-table>
			</v-card>

			<v-card v-if="avaliacoesEquipe.length" class="mx-2 mb-2 mt-4 py-2 px-4">
		        <h3 class="mt-3">Avaliação dos integrantes da equipe</h3>
		        <small>Avaliação quanto à percepção do desempenho funcional dos demais integrantes da equipe de trabalho</small>
		        <v-simple-table class="mt-2">
				    <template v-slot:default>
				    	<thead>
				        	<tr>
				          		<th class="text-left" width="40%">Servidor</th>
				          		<th class="text-left">Situação</th>
				          		<th class="text-left" width="40%">Ação</th>
				        	</tr>
				      	</thead>
				      	<tbody>
					        <tr
					        	v-for="avaliacao in avaliacoesEquipe"
					          	:key="avaliacao.id_avaliacao"
					        >
					          	<td>{{ avaliacao.equipe_trabalho.servidor.nm_servidor }}</td>
					          	<td>
					          		<span v-if="avaliacao.st_pendente">
					          			<v-chip
					          				v-if="avaliacao.equipe_trabalho.ds_meta_individual && avaliacao.st_acordado && !avaliacao.st_bloqueado"
					          				small
					          				label
		      								color="warning"
		      								text-color="white"
		    							>	
		      								<span class="font-weight-medium">
		      									Pendente
		      								</span>
		   								</v-chip>
		   								<v-chip
					          				v-if="!avaliacao.equipe_trabalho.ds_meta_individual && !avaliacao.st_bloqueado"
					          				small
					          				label
		      								color="error"
		      								text-color="white"
		    							>	
		      								<span class="font-weight-medium">
		      									BLOQUEADA - Servidor sem meta individual
		      								</span>
		   								</v-chip>
										<v-chip
					          				v-if="avaliacao.st_bloqueado"
					          				small
					          				label
		      								color="error"
		      								text-color="white"
		    							>	
		      								<span class="font-weight-medium">
		      									BLOQUEADA - Servidor com avaliação bloqueada
		      								</span>
		   								</v-chip>
										<v-chip
					          				v-if="avaliacao.equipe_trabalho.ds_meta_individual && !avaliacao.st_acordado"
					          				label
		      								color="error"
		      								text-color="white"
		    							>	
		      								<span class="font-weight-medium">
												BLOQUEADA - servidor não deu ciência da meta individual.
		      								</span>
		   								</v-chip>
					          		</span>
					          		<span v-else>
					          			<v-chip
					          				small
					          				label
		      								color="success"
		      								text-color="white"
		    							>
		    								
		      								<span class="font-weight-medium">Concluída</span>
		   								</v-chip>
					          		</span>
					          	</td>
					          	<td>
				          			<v-btn
				          				v-if="(avaliacao.equipe_trabalho.ds_meta_individual && avaliacao.st_acordado && !avaliacao.st_bloqueado) || avaliacao.id_avaliacao"
				          				:to="{ name: 'avaliacoes-detalhar', params: { id: avaliacao.id_avaliacao ? avaliacao.id_avaliacao : avaliacao.jsonDados } }"
				          				small
		  								outlined
		  								color="primary"
									>
										<span v-if="avaliacao.st_pendente">Avaliar</span>
										<span v-else>Visualizar/editar</span>
									</v-btn>
									<span v-if="!avaliacao.equipe_trabalho.ds_meta_individual && !avaliacao.st_bloqueado">
										O servidor deverá pactuar uma meta individual com a chefia para liberação da avaliação.
									</span>
									<span v-if="avaliacao.st_bloqueado">
										<strong>Justificativa do bloqueio:</strong> {{avaliacao.tx_observacao}} <br>
									</span> 
					          	</td>
					        </tr>
					     </tbody>
				    </template>
				</v-simple-table>
			</v-card>
		</v-card>
	</div>
</template>

<script>
	import { getAvaliacoes } from '../api/avaliacao';
	import { CreateAvaliacaoConsolidada } from '../api/avaliacao_consolidada';
    import { getUsuarioAutenticado } from '../api/auth';
    import { getCiclo, getCiclosVigentes } from '../api/ciclo';
    import { getEquipeTrabalhoByIdCiclo, updateEquipeTrabalho } from '../api/equipe_trabalho';
	
    export default {
        name: 'AvaliacoesListar',
        data() {
	        return {
	            avaliacoes: true,
	            autoAvaliacao: [],
	            avaliacoesChefia: [],
	            avaliacoesEquipe: [],
				ultimoCicloVigente: null,
				equipeTrabalhoServidor: null,
				dialogMetaIndividual: false,
				semCiclo: false
	        };
	    },
	    created() {
			getUsuarioAutenticado(this.$store.getters['auth/usuarioAutenticadoToken'])
                .then(response => {
                    let usuarioAutenticado = response.data.data;
                    getCiclosVigentes(this.$store.getters['auth/usuarioAutenticado'])
                        .then(response => {
                            this.ciclosVigentes = response.data.data;
                            let ultimoCicloVigente = this.ciclosVigentes[0];
                            getCiclo(ultimoCicloVigente.id_ciclo)
                                .then(response => {
                                    this.ultimoCicloVigente = response.data.data;
                                });
                            getEquipeTrabalhoByIdCiclo(usuarioAutenticado.id_servidor, ultimoCicloVigente.id_ciclo)
                                .then(response => {
                                    this.equipeTrabalhoServidor = response.data.data;
                                });
							getAvaliacoes()
								.then(response => {
									let avaliacoesColetadas = response.data.data;
									let temAvaliacoesNoCicloVigente = false;
									avaliacoesColetadas.map((ava,i) => {
										if (ava.in_tipo_avaliacao == 'auto' ||
											ava.in_tipo_avaliacao == 'chefia' || 
											ava.in_tipo_avaliacao == 'equipe')
										{
											temAvaliacoesNoCicloVigente = true;
										}
									})
									if (temAvaliacoesNoCicloVigente){
										this.avaliacoes = response.data.data;
										this.autoAvaliacao = this.avaliacoes.filter((avaliacao) => avaliacao.in_tipo_avaliacao == 'auto');

										this.avaliacoesChefia = this.avaliacoes.filter((avaliacao) => avaliacao.in_tipo_avaliacao == 'chefia');
										this.avaliacoesChefia = _.orderBy(this.avaliacoesChefia, 'equipe_trabalho.servidor.nm_servidor');

										this.avaliacoesEquipe = this.avaliacoes.filter((avaliacao) => avaliacao.in_tipo_avaliacao == 'equipe');
										this.avaliacoesEquipe = _.orderBy(this.avaliacoesEquipe, 'equipe_trabalho.servidor.nm_servidor');
									} else {
										this.avaliacoes = false,
										console.log('Não foram encontradas avaliações do servidor.');
									}
								});
                        }).catch(erro => {
							this.semCiclo = true;
                            console.log('erro na busca de ciclos vigentes');
                        });
				}).catch(erro => {
					console.log('Não foi possível recuperar o servidor autenticado');
				});
	    },
		methods: {
			gerarConsolidacao(){
				CreateAvaliacaoConsolidada();
			},

			openMetaIndividualDialog(equipe) {
                this.dialogMetaIndividual = true;
            },

            editarDeAcordoMetaIndividual(equipe) {
				equipe.id_servidor = equipe.servidor.id_servidor;
				equipe.st_acordado = true;
                equipe.dt_acordo = new Date().toISOString().slice(0, 10);
                updateEquipeTrabalho(equipe.id_equipe_trabalho, equipe)
                    .then(response => {
                        this.$store.dispatch('alertas/show', {
                            tipo: 'Sucesso',
                            titulo: 'Meta acordada com sucesso!',
                        });
                        this.$router.go(0);
                        this.dialogMetaIndividual = false;
                    });
            },
		},
    } 
</script>

<template>
    <div>
        <v-card
            class="mx-auto"
            max-width="800"
        >
            <v-sheet class="pa-4 primary">
            <v-text-field
                v-model="search"
                label="Procurar Unidade"
                dark
                flat
                solo-inverted
                hide-details
                clearable
                clear-icon="mdi-close-circle-outline"
            ></v-text-field>
            </v-sheet>
            <v-card-text>
                <v-treeview
                    :items="items"
                    :search="search"
                    :filter="filter"
                    :open.sync="open"
                >
                    <template slot="label" slot-scope="{ item }">
                        <span class="text-wrap">
                            {{ item.name }}
                            <router-link :to="{ name: 'unidades-detalhar', params: { id: item.id } }">
                                <v-icon dense color="primary">mdi-file-search-outline</v-icon>
                            </router-link>
                        </span>
                    </template>
                </v-treeview>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
    import { getUnidadesTree , getUnidadeDetalhes } from '../api/unidade';

    export default {
        name: 'UnidadesListar',
        data() {
            return {
                items: [],
                headers: [
                            {text: 'CPF', value: 'cpf'},
                            { text: 'NOME', value: 'nome' },
                            { text: 'SIAPE', value: 'siape' },
                            { text: 'Visualizar', value: 'actions', sortable: false },
                        ],
                open: [1, 2],
                search: null,
                caseSensitive: false, 
                dialog: false,
          };
        },
       created() {
            getUnidadesTree()
                .then(response => { 
                    this.items = response.data.data; 
            });
        },
        computed: {
            filter () {
                return this.caseSensitive
                ? (item, search, textKey) => item[textKey].indexOf(search) > -1
                : undefined
            }
        },
    }
</script>
import api from './index';

const adicionarAnexo = (anexo) => api.post(
    'adicionarAnexo', 
    anexo
);

const getAnexos = (idReconsideracao) => api.get(
    'getAnexos/'+ idReconsideracao
);

const downloadAnexoRecurso = (id_arquivo) => api.get(
    'downloadAnexoRecurso/'+id_arquivo,
    { responseType: 'arraybuffer' }
);

const removerAnexoRecurso = (id) => api.post(
    'removerAnexoRecurso', 
    id
);

const salvarRegistroRecurso = (dados) => api.post(
    'salvarRegistroRecurso', 
    dados
);

const getRecursoByIdReconsideracao = (idReconsideracao) => api.get(
    'getRecursoByIdReconsideracao/'+ idReconsideracao
);

const getRecursos = (filtros = []) => api.get(
    'getRecursos', 
    {params: {filtros: filtros}}
);

const getAnexosCadSad = (idReconsideracao) => api.get(
    'getAnexosCadSad/'+ idReconsideracao
);

const adicionarAnexoCadSad = (anexo) => api.post(
    'adicionarAnexoCadSad', 
    anexo
);

const salvarMembroCadSad = (data) => api.post(
    'salvarMembroCadSad', 
    data
);

const getMembros = (filtros = []) => api.get(
    'getMembros', 
    {params: {filtros: filtros}}
);

const removerMembroCadSad = (id) => api.post(
    'removerMembroCadSad', 
    id
);

const getAtaCadSad = (idReconsideracao) => api.get(
    'getAtaCadSad/'+ idReconsideracao
);

const adicionarAtaCadSad = (anexo) => api.post(
    'adicionarAtaCadSad', 
    anexo
);

const inserirNotaAposDecisaoRecurso = (id_recurso) => api.post(
    'inserirNotaAposDecisaoRecurso', 
    id_recurso
);

const getAnexosAposRecurso = (idReconsideracao) => api.get(
    'getAnexosAposRecurso/'+ idReconsideracao
);

const cienciaRecurso = (id) => api.put(
    'cienciaRecurso/'+id
);

const excluirRecurso = (id, data) => api.post(
    'excluirRecurso/'+id,
    data
);


export {
    adicionarAnexo,
    getAnexos,
    downloadAnexoRecurso,
    removerAnexoRecurso,
    salvarRegistroRecurso,
    getRecursoByIdReconsideracao,
    getRecursos,
    getAnexosCadSad,
    adicionarAnexoCadSad,
    salvarMembroCadSad,
    getMembros,
    removerMembroCadSad,
    getAtaCadSad,
    adicionarAtaCadSad,
    inserirNotaAposDecisaoRecurso,
    getAnexosAposRecurso,
    cienciaRecurso,
    excluirRecurso
}
import api from './index';

const getFatores = () => api.get('fatores');

const getFator = (id_fator) => api.get('fatores/' + id_fator);

const deleteFator = (id_fator) => api.delete('fatores/' + id_fator);

const createFator = (fator) => api.post('fatores', fator);

const updateFator = (id_fator, fator) => api.put('fatores/' + id_fator, fator);

export {
    getFatores,
    getFator,
    createFator,
    updateFator,
    deleteFator
}
<template>
    <div>
        <h1>Pedido de reconsideração</h1>
        <v-card v-if="avaliacao_consolidada && avaliacao_consolidada.reconsideracao" color="white lighten-4 elevation-6" >
            <v-container class="pa-5">
                <v-form class="pa-0" ref="form_parecer">
                    <v-row>
                        <v-col cols="12" class="d-flex flex-column align-center justify-center text-center">
                            <p>
                                Pedido de reconsideração enviado em 
                                {{avaliacao_consolidada.reconsideracao.created_at | moment("DD/MM/YYYY HH:mm") }} 
                                para a Chefia Imediata.
                            </p>
                            <v-sheet class="d-flex flex-column align-center white lighten-3 ml-n5 mt-5" width="90%">
                                <v-sheet v-for="item in avaliacao_consolidada.reconsideracao.fatores" :key="item.id_fator" width="100%" max-width="1200">
                                    <v-hover v-slot="{ hover }">
                                        <v-card class="ma-3 pt-5 pr-4 mb-4 rounded-lg grey lighten-4" width="100%" max-width="1200" :elevation="hover ? 8 : 3">
                                            <v-row dense class="px-4">
                                                <v-col cols="3">
                                                    <v-subheader class="font-weight-medium">Fator</v-subheader>
                                                </v-col>
                                                <v-col cols="9">
                                                    <v-text-field dense v-model="item.nm_fator" hide-details="auto" outlined readonly />
                                                </v-col>
                                            </v-row>
                                            <v-row dense class="px-4">
                                                <v-col cols="3">
                                                    <v-subheader class="font-weight-medium">Descrição</v-subheader>
                                                </v-col>
                                                <v-col cols="9">
                                                    <v-textarea dense v-model="item.ds_fator" hide-details="auto" outlined readonly rows="3" />
                                                </v-col>
                                            </v-row>
                                            <v-row dense class="px-4">
                                                <v-col cols="3">
                                                    <v-subheader class="font-weight-medium">Justificativa</v-subheader>
                                                </v-col>
                                                <v-col cols="9">
                                                    <v-textarea dense v-model="item.pivot.tx_justificativa" hide-details="auto" outlined readonly rows="3" />
                                                </v-col>
                                            </v-row>
                                            <br>
                                            <div v-if="true">
                                                <br>
                                                <v-divider />
                                                <br>
                                                <v-row dense class="px-4">
                                                    <v-col cols="3">
                                                        <v-subheader class="font-weight-medium">Parecer da Chefia Imediata</v-subheader>
                                                    </v-col>
                                                    <v-col cols="9">
                                                        <v-textarea :readonly="inputs_readonly" class="white" dense v-model="item.pivot.tx_parecer_chefia" hide-details="auto" outlined rows="3" :rules="[() => (item.pivot.tx_parecer_chefia && item.pivot.tx_parecer_chefia.length > 0) || 'Insira um texto.']"/>
                                                    </v-col>
                                                </v-row>
                                                <v-row dense class="mt-3 px-5">
                                                    <v-sheet class="d-flex flex-column justify-center align-center grey lighten-4">
                                                        <v-sheet class="mx-10 d-flex flex-column justify-center align-center grey lighten-4">
                                                            <v-radio-group row class="mx-10 grey lighten-4" :ref="'radio_parecer_'+item.id_fator" v-model="item.in_parecer_chefia_radio_value" :rules="[() => !!item.in_parecer_chefia_radio_value || 'Escolha uma opção.']">
                                                                <v-radio :readonly="inputs_readonly" v-for="n in item.in_parecer_chefia_radio_items" :key="n" :label="`${n}`" :value="n"></v-radio>
                                                            </v-radio-group>
                                                        </v-sheet>
                                                    </v-sheet>
                                                </v-row>
                                                <br>
                                            </div>
                                        </v-card>
                                    </v-hover>
                                </v-sheet>
                            </v-sheet>
                        </v-col>
                    </v-row>
                </v-form>
                <v-sheet class="d-flex flex-column align-center elevation-0">
                    <v-sheet class="pa-3 ma-5 elevation-6 rounded-lg grey lighten-5" width="70%">
                        <v-row class="my-1">
                            <v-col cols="10" class="d-flex flex-column align-end textjustify-center text-center">
                                <span><b>Resultado da Avaliação Individual</b></span>
                                <small>(considera apenas uma casa decimal)</small>
                            </v-col>
                            <v-col cols="2" class="d-flex flex-column align-center justify-center text-center">
                                <span><v-chip label class="pa-2 mx-1 primary" text-color="white"><span class="text-h6 font-weight-medium">{{avaliacao_consolidada.va_nota_final}}</span></v-chip></span>
                            </v-col>
                        </v-row>

                        <v-divider></v-divider>
                        <v-row class="my-1">
                            <v-col cols="10" class="d-flex flex-column align-end justify-end text-center">
                                <span class="text-h6"><b>Conversão da nota em pontos</b></span>
                                <v-chip small label class="mb-0 mx-0 blue lighten-5" text-color="white">
                                    <span class="font-weight-medium">
                                        <a href="https://www.in.gov.br/en/web/dou/-/portaria-n-528-de-26-de-setembro-de-2019-218534988" target="_blank">Anexo III - PORTARIA GM/ME Nº 528, DE 26/09/2019</a>
                                    </span>
                                </v-chip>
                            </v-col>
                            <v-col cols="2" class="d-flex flex-column align-center justify-center text-center">
                                <span><v-chip large label class="px-2 mx-1 primary" text-color="white"><span class="text-h4 font-weight-medium">{{avaliacao_consolidada.nr_pontos}}</span></v-chip></span>
                            </v-col>
                        </v-row>
                    </v-sheet>
                    <v-sheet class="pa-3 ma-5 elevation-6 rounded-lg grey lighten-5" width="70%">
                        <v-row class="my-1">
                            <v-col cols="10" class="d-flex flex-column align-end textjustify-center text-center">
                                <span><b>Insira aqui o novo Resultado da Avaliação Individual APÓS RECONSIDERAÇÃO</b></span>
                                <small>(considera apenas uma casa decimal)</small>
                            </v-col>
                            <v-col cols="2" class="d-flex flex-column align-center justify-center text-center">
                                <v-form class="pa-0" ref="form_va_nota_final_nova">
                                    <v-text-field :readonly="inputs_readonly" class="pa-2 mx-1" outlined v-model="va_nota_final_nova" :rules="[() => (va_nota_final_nova && Number(va_nota_final_nova) <= 5 && (Number(va_nota_final_nova) >= Number(avaliacao_consolidada.va_nota_final)) )|| 'Insira uma nota válida.']" hide-details="auto" required />
                                </v-form>
                            </v-col>
                        </v-row>

                        <v-divider></v-divider>
                        <v-row class="my-1">
                            <v-col cols="10" class="d-flex flex-column align-end justify-end text-center">
                                <span class="text-h6"><b>Conversão da nota em pontos APÓS RECONSIDERAÇÃO</b></span>
                                <v-chip small label class="mb-0 mx-0 blue lighten-5" text-color="white">
                                    <span class="font-weight-medium">
                                        <a href="https://www.in.gov.br/en/web/dou/-/portaria-n-528-de-26-de-setembro-de-2019-218534988" target="_blank">Anexo III - PORTARIA GM/ME Nº 528, DE 26/09/2019</a>
                                    </span>
                                </v-chip>
                            </v-col>
                            <v-col cols="2" class="d-flex flex-column align-center justify-center text-center">
                                <span><v-chip large label class="px-2 mx-1 green" text-color="white"><span class="text-h4 font-weight-medium">{{nr_pontos_novo}}</span></v-chip></span>
                            </v-col>
                        </v-row>
                    </v-sheet>
                </v-sheet>
                <v-sheet class="mb-3 d-flex flex-column justify-center align-center white">
                    <v-btn v-if="true" color="primary lighten-2 mx-5 mt-4 elevation-2 pa-2" @click="enviarParecer()" :disabled="inputs_readonly">
                        ENVIAR PARECER
                    </v-btn>	
                </v-sheet>	
            </v-container>
        </v-card>
    </div>
</template>

<script>
    import { getReconsideracao, updateReconsideracao } from '../api/reconsideracao';

    export default {
        name: 'ReconsideracoesListar',
        data() {
            return {
                avaliacao_consolidada: null,
                va_nota_final_nova: null,
                nr_pontos_novo: null,
                inputs_readonly: false,
                showEnviarParecer: false
            };
        },
        watch: {
            va_nota_final_nova: function (value) {
                if (Number(value) <= 5 && Number(value) >= 4){
                    this.nr_pontos_novo = 20
                } else if (Number(value) < 4 && Number(value) >= 3.5){
                    this.nr_pontos_novo = 17
                } else if (Number(value) < 3.5 && Number(value) >= 3){
                    this.nr_pontos_novo = 14
                } else if (Number(value) < 3 && Number(value) >= 2){
                    this.nr_pontos_novo = 10
                } else if (Number(value) < 2 && Number(value) >= 1){
                    this.nr_pontos_novo = 5
                } else {
                    this.nr_pontos_novo = 0
                }
            }
        },
        created() {
            getReconsideracao(this.$route.params.id)
                .then(response => {
                    this.avaliacao_consolidada = response.data.data;
                    this.showEnviarParecer = false;

                    if (this.$store.getters['auth/usuarioAutenticadoRoles'].indexOf("servidorExterno") >= 0) {
                        this.showEnviarParecer = true;
                    } else {

                        if (
                            this.avaliacao_consolidada.reconsideracao.chefia != null
                            && this.$store.getters['auth/usuarioAutenticado'] == this.avaliacao_consolidada.reconsideracao.chefia.id_servidor
                        ) {
                            this.showEnviarParecer = true;
                        } 
                    }

                    if (this.showEnviarParecer) {
                        this.inputs_readonly =  (this.avaliacao_consolidada.reconsideracao.fatores[0].pivot.in_parecer_chefia) ? true : false;
                    } else {
                        this.inputs_readonly = true;
                    }
                    this.va_nota_final_nova = this.avaliacao_consolidada.reconsideracao.va_nota
                    this.avaliacao_consolidada.reconsideracao.fatores.map(fator => {
                        fator.in_parecer_chefia_radio_items = ['Deferido', 'Parcialmente deferido','Indeferido'];
                        fator.in_parecer_chefia_radio_value = '';
                        if (fator.pivot.in_parecer_chefia) {
                            fator.in_parecer_chefia_radio_value = (fator.pivot.in_parecer_chefia === 'D') ?
                                'Deferido': (fator.pivot.in_parecer_chefia === 'P') ?
                                'Parcialmente deferido' : 'Indeferido';
                        } 
                    });
                });
        },
        methods:{
            enviarParecer(){
                if (
                    this.$refs.form_parecer.validate() 
                    && this.$refs.form_va_nota_final_nova.validate()
                ) {
                    if (Number(this.va_nota_final_nova) < this.avaliacao_consolidada.va_nota_final){
                        //console.log('nota final menor, nao pode')
                    } else {
                        this.postReconsideracao();
                    }
                } else {
                    //console.log('form_parecer nao validado')
                }
            },
			postReconsideracao() {
            	updateReconsideracao(this.avaliacao_consolidada.reconsideracao.id_reconsideracao, this.gerarJson())
                .then(response => {
                    this.avaliacao_consolidada = response.data.data
                    if (this.showEnviarParecer) {
                        this.inputs_readonly = (this.avaliacao_consolidada.reconsideracao.fatores[0].pivot.in_parecer_chefia) ? true : false;
                    } else {
                        this.inputs_readonly = true;
                    }
                    this.va_nota_final_nova = (this.avaliacao_consolidada.reconsideracao.va_nota) ? this.avaliacao_consolidada.reconsideracao.va_nota : this.avaliacao_consolidada.va_nota_final;
                    this.avaliacao_consolidada.reconsideracao.fatores.map(fator => {
                        fator.in_parecer_chefia_radio_items = ['Deferido', 'Parcialmente deferido','Indeferido'];
                        fator.in_parecer_chefia_radio_value = (fator.pivot.in_parecer_chefia === 'D') ?
                        'Deferido': (fator.pivot.in_parecer_chefia === 'P') ?
                        'Parcialmente deferido' : 'Indeferido';
                    });
					this.$store.dispatch('alertas/show', {
						tipo: 'Sucesso',
						titulo: 'Pedido de reconsideração enviado com sucesso!',
					})
                	this.$vuetify.goTo(0)
                });
       		 },
			gerarJson() {
                var idChefia = null;
                if (this.avaliacao_consolidada.equipe_trabalho) {
                    idChefia = this.avaliacao_consolidada.equipe_trabalho.avaliacao_chefia.id_avaliador_substituto
					? this.avaliacao_consolidada.equipe_trabalho.avaliacao_chefia.id_avaliador_substituto 
					: this.avaliacao_consolidada.equipe_trabalho.avaliacao_chefia.id_avaliador;
                } 
				let json = {
					id_avaliacao_consolidada: this.avaliacao_consolidada.id_avaliacao_consolidada,
					id_chefia: idChefia,
					va_nota: this.va_nota_final_nova,
                    nr_pontos: this.nr_pontos_novo,
                    dt_visualizacao_chefia: null,
                    dt_ciencia_chefia: null,
                    fatores: [],
				}
				this.avaliacao_consolidada.reconsideracao.fatores.map(fator => {
                    const in_parecer_chefia = (fator.in_parecer_chefia_radio_value === 'Deferido') ?
                        'D': (fator.in_parecer_chefia_radio_value === 'Parcialmente deferido') ?
                        'P' : 'I';
					let fatorToAdd = {
						id_fator: fator.id_fator,
						tx_justificativa: fator.pivot.tx_justificativa,
                        in_parecer_chefia: in_parecer_chefia,
                        tx_parecer_chefia: fator.pivot.tx_parecer_chefia,
					}
					json.fatores.push(fatorToAdd);
				})
				return JSON.stringify(json);
			}
        }
    } 
</script>

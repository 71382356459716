import AuthService from '../../services/auth.service';

const user = AuthService.getLocalUser();
const roles = AuthService.getLocalRoles();
const token = AuthService.getLocalToken();
const userExterno = AuthService.getLocalUserExterno();

export const auth = {
    namespaced: true,
    state: {
        usuarioAutenticado: user,
        usuarioAutenticadoRoles: roles,
        usuarioAutenticadoToken: token,
        isLoggedIn: !!user,
        isExternoLoggedIn: !!userExterno,
    },
    getters: {
        isLoggedIn(state) {
            return state.isLoggedIn;
        },
        isExternoLoggedIn(state) {
            return state.isExternoLoggedIn;
        },
        usuarioAutenticado(state) {
            return state.usuarioAutenticado;
        },
        usuarioAutenticadoRoles(state) {
            return state.usuarioAutenticadoRoles;
        },
        usuarioAutenticadoToken(state) {
            return state.usuarioAutenticadoToken;
        },
    },
    actions: {
        login({ commit }, code) {
            return AuthService.login(code).then(
                () => {;
                    commit('loginSuccess');
                    return Promise.resolve();
                },
                error => {
                    commit('loginFailure');
                    return Promise.reject(error);
                }
            );
        },
        logout({ commit }) {
            AuthService.logout();
            commit('menu/closeSideBar', null, { root: true });
            commit('logout');
        }
    },
    mutations: {
        loginSuccess(state) {
            if ( AuthService.getLocalRoles().includes('externo') ) {
                state.isExternoLoggedIn = true;
                state.isLoggedIn = false;
            } else {
                state.isLoggedIn = true;
                state.isExternoLoggedIn = false;
            }
            state.usuarioAutenticado = AuthService.getLocalUser();
            state.usuarioAutenticadoRoles = AuthService.getLocalRoles();
            state.usuarioAutenticadoToken = AuthService.getLocalToken();
        },
        loginFailure(state) {
            state.isLoggedIn = false;
            state.isExternoLoggedIn = false;
            state.usuarioAutenticado = null;
            state.usuarioAutenticadoRoles = null;
        },
        logout(state) {
            state.isLoggedIn = false;
            state.isExternoLoggedIn = false;
            state.usuarioAutenticado = null;
            state.usuarioAutenticadoRoles = null;
        }
    }
};
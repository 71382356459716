import api from './index';

const createPontuacaoServidor = (dados) => api.post('createPontuacaoServidor', dados);

const getPontuacaoSalvaServidor = (dados) => api.post('getPontuacaoSalvaServidor', dados);

const getValidarAvaliacoesServidorPontuacao = (dados) => api.post('getValidarAvaliacoesServidorPontuacao', dados);

export {
    createPontuacaoServidor,
    getPontuacaoSalvaServidor,
    getValidarAvaliacoesServidorPontuacao
}
import api from './index';

const salvarLegislacaoAplicada = (data) => api.post(
    'salvarLegislacaoAplicada', 
    data
);

const getLegislacaoAplicada = () => api.get(
    'getLegislacaoAplicada'
);

const addAnexoLegislacaoAplicada = (anexo) => api.post(
    'addAnexoLegislacaoAplicada', 
    anexo
);

const getAnexosLegislacaoAplicada = () => api.get(
    'getAnexosLegislacaoAplicada'
);

const downloadAnexoLegislacaoAplicada = (id_arquivo) => api.get(
    'downloadAnexoLegislacaoAplicada/'+id_arquivo,
    { responseType: 'arraybuffer' }
);

const removerAnexoLegislacaoAplicada = (id) => api.post(
    'removerAnexoLegislacaoAplicada', 
    id
);

export {
    salvarLegislacaoAplicada,
    getLegislacaoAplicada,
    addAnexoLegislacaoAplicada,
    getAnexosLegislacaoAplicada,
    downloadAnexoLegislacaoAplicada,
    removerAnexoLegislacaoAplicada
}
<template>
    <div>
        <br/>
        <h1>Permissão negada</h1>
        <div class="row">
            <p>Você não possui permissão para acessar esse endereço.</p>

        </div>
    </div>
</template>


<script>

    export default {
        name: 'Erro403',
        components: {
        },
        data() {
            return {
          };
        },
    }
</script>
import api from './index';

const salvarOrgao = (data) => api.post(
    'salvarOrgao', 
    data
);

const getOrgaos = () => api.get(
    'getOrgaos'
);

const getOrgao = (id_orgao) => api.get(
    'getOrgao/' + id_orgao
);

const editarOrgao = (data) => api.post(
    'editarOrgao', 
    data
);

const excluirOrgao = (id) => api.post(
    'excluirOrgao', 
    id
);

const getOrgaoGestorOrgao = () => api.get(
    'getOrgaoGestorOrgao'
);

const getOrgaoByReaproveitarUnidade = () => api.get(
    'getOrgaoByReaproveitarUnidade'
);

const validarServidorMesmoOrgaoGestor = () => api.get(
    'validarServidorMesmoOrgaoGestor'
);

const getOrgaoByCodigo = (cd_orgao) => api.get(
    'getOrgaoByCodigo/' + cd_orgao
);

const getOrgaosByFiltros = (page, itemsPerPage, filtrosAplicados = []) => api.get('getOrgaosByFiltros', {params: {current_page: page, limit: itemsPerPage, filtros: filtrosAplicados}});

const getTotalOrgaosByFiltros = (filtrosAplicados = []) => api.get('getTotalOrgaosByFiltros', {params: {filtros: filtrosAplicados}});

export {
    salvarOrgao,
    getOrgaos,
    getOrgao,
    editarOrgao,
    excluirOrgao,
    getOrgaoGestorOrgao,
    getOrgaoByReaproveitarUnidade,
    validarServidorMesmoOrgaoGestor,
    getOrgaoByCodigo,
    getOrgaosByFiltros,
    getTotalOrgaosByFiltros
}
<template>
    <v-dialog v-model="dialogDetalharServidor" width="60%" :fullscreen="$vuetify.breakpoint.xsOnly">
        <v-card>
            <v-card color="primary" dark class="mx-auto">
                <v-card-title>
                    <v-icon large left> mdi-account-box-multiple </v-icon>
                    <span class="text-h6 font-weight-light">{{tipoServidor}}</span>
                </v-card-title>
            </v-card>
            <br>
            <v-container>
                <v-row>
                    <v-col cols="2">
                        <v-textarea rows=1 auto-grow label="SIAPE" dense hide-details="auto" 
                        :value="servidor.siape" readonly> </v-textarea>
                    </v-col>
                    <v-col cols="3">
                        <v-textarea rows=1 auto-grow label="CPF" dense hide-details="auto" 
                        :value="servidor.cpf" readonly> </v-textarea>
                    </v-col>
                    <v-col cols="7">
                        <v-textarea rows=1 auto-grow label="Nome" dense hide-details="auto" 
                        :value="servidor.nome" readonly></v-textarea>
                    </v-col>
                    <v-col cols="2">
                        <v-textarea rows=1 auto-grow label="Telefone" dense hide-details="auto" 
                        :value="servidor.telefone" readonly></v-textarea>
                    </v-col>
                    <v-col cols="5">
                        <v-textarea rows=1 auto-grow label="E-mail Institucional" dense hide-details="auto" 
                        :value="servidor.email" readonly></v-textarea>
                    </v-col>
                    <v-col cols="5">
                        <v-textarea rows=1 auto-grow label="E-mail Pessoal" dense hide-details="auto" 
                        :value="servidor.email" readonly></v-textarea>
                    </v-col>
                    <v-col cols="12">
                        <v-textarea rows=1 auto-grow label="Gratificação" dense hide-details="auto" 
                        :value="servidor.gratificacao" readonly></v-textarea>
                    </v-col>
                    <v-col cols="12">
                        <v-textarea rows=1 auto-grow label="Cargo" dense hide-details="auto" 
                        :value="servidor.cargo" readonly></v-textarea>
                    </v-col>
                    <v-col cols="4">
                        <v-textarea rows=1 auto-grow label="Situação" dense hide-details="auto" 
                        :value="servidor.situacao_servidor" readonly></v-textarea>
                    </v-col>
                    <v-col cols="8">
                        <v-textarea rows=1 auto-grow label="Função" dense hide-details="auto" 
                        :value="servidor.funcao + ' - ' + servidor.codigo_funcao" readonly></v-textarea>
                    </v-col>
                    <v-col cols="12">
                        <v-textarea rows=1 auto-grow label="Órgão Exercício" hide-details="auto" 
                        :value="servidor.unidade_exercicio" readonly></v-textarea>
                    </v-col>
                </v-row>
            </v-container>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="dialogDetalharServidor = false"> Fechar </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
export default {
    data() {
        return {
        	tipoServidor: '',
            servidor: {
                siape: '',
                cpf: '',
                nome: '',
                telefone: '',
                email: '',
                gratificacao: '',
                cargo: '',
                situacao_servidor: '',
                funcao: '',
                codigo_funcao: '',
                unidade_exercicio: ''
            },
            dialogDetalharServidor: false
        }
    },
    methods: {
    	mostraServidorDialog(servidor, tipoServidor) {
        	this.tipoServidor = tipoServidor;
            this.servidor.siape = servidor.siape;
            this.servidor.cpf = servidor.cpf;
            this.servidor.nome = servidor.nome;
            this.servidor.telefone = servidor.telefone;
            this.servidor.email = servidor.email;
            this.servidor.gratificacao = servidor.gratificacao;
            this.servidor.cargo = servidor.cargo;
            this.servidor.situacao_servidor = servidor.situacao_servidor;
            this.servidor.funcao = servidor.funcao;
            this.servidor.codigo_funcao = servidor.codigo_funcao;
            this.servidor.unidade_exercicio = servidor.unidade_exercicio;
            this.dialogDetalharServidor = true;
        }
    }
}

</script>

import api from './index';

const updateUnidadeAvaliacao = (id_unidade_avaliacao, unidade_avaliacao) => api.put(
    'unidadesAvaliacao/'+id_unidade_avaliacao, unidade_avaliacao
);

const getUnidadesAvaliacaoByCiclo = (id_ciclo) => api.get(
    'unidadeAvaliacaoByCiclo/'+id_ciclo
);

const getUnidadeAvaliacaoByCicloOrgao = (id_ciclo, orgao) => api.get(
    'getUnidadeAvaliacaoByCicloOrgao/'+id_ciclo +'/'+ orgao
);

const salvarUnidadeAvaliacao = (data) => api.post(
    'salvarUnidadeAvaliacao', 
    data
);

const getUnidadeAvaliacaoById = (id) => api.get(
    'getUnidadeAvaliacaoById/' + id
);

const editarUnidadeDeAvaliacao = (id, data) => api.put(
    'editarUnidadeDeAvaliacao/'+id, 
    data
);

export {
    updateUnidadeAvaliacao,
    getUnidadesAvaliacaoByCiclo,
    salvarUnidadeAvaliacao,
    getUnidadeAvaliacaoById,
    editarUnidadeDeAvaliacao,
    getUnidadeAvaliacaoByCicloOrgao
}
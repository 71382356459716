import api from './index';

const createDesbloquearRepetirNota = (dados) => api.post(
    'createDesbloquearRepetirNota', 
    dados
);

const bloquearDesbloquearEmLoteAtivosEmOutroOrgao = (dados, filtrosAplicados =[]) => api.post(
    'bloquearDesbloquearEmLoteAtivosEmOutroOrgao', 
    dados,
    {params: {filtros: filtrosAplicados}}
);

const desbloquearAvaliacaoExterna = (dados) => api.post(
    'desbloquearAvaliacaoExterna', 
    dados
);

export {
    createDesbloquearRepetirNota,
    bloquearDesbloquearEmLoteAtivosEmOutroOrgao,
    desbloquearAvaliacaoExterna
}
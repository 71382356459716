<template>
    <v-dialog v-model="dialogVisualizarAvaliadorAvaliado" width="70%" scrollable>
        <v-card>
            <v-card color="primary" dark>
                <v-card-title>
                    <v-icon large left> mdi-eye </v-icon>
                    <span class="text-h6 font-weight-light">Visualizar avaliador/avaliado</span>
                </v-card-title>
            </v-card>
            <v-card-text>
                <v-row>
                    <v-col cols="12">
                        <h4 class="primary--text">Avaliador</h4>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="3">
                        <v-text-field rows=1 auto-grow label="CPF" dense hide-details="auto" :value="cpfAvaliador" readonly> </v-text-field>
                    </v-col>
                    <v-col cols="9">
                        <v-text-field rows=1 auto-grow label="Nome" dense hide-details="auto" :value="nmAvaliador" readonly> </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-text-field rows=1 auto-grow label="Órgão de lotação" dense hide-details="auto" :value="nmOrgaoAvaliador" readonly> </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-text-field rows=1 auto-grow label="Unidade de exercício" dense hide-details="auto" :value="nmUnidadeAvaliador" readonly> </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="6">
                        <v-text-field rows=1 auto-grow label="Cargo" dense hide-details="auto" :value="nmCargoAvaliador" readonly> </v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-text-field rows=1 auto-grow label="Função" dense hide-details="auto" :value="nmFuncaoAvaliador" readonly> </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="6">
                        <v-text-field rows=1 auto-grow label="Telefone" dense hide-details="auto" :value="nrTelefoneAvaliador" readonly> </v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-text-field rows=1 auto-grow label="E-mail pessoal" dense hide-details="auto" :value="dsEmailInstitucionalAvaliador" readonly> </v-text-field>
                    </v-col>
                </v-row>
                <br />
                <v-row>
                    <v-col cols="12">
                        <h4 class="primary--text">Avaliado</h4>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="3">
                        <v-text-field rows=1 auto-grow label="CPF" dense hide-details="auto" :value="cpfAvaliado" readonly> </v-text-field>
                    </v-col>
                    <v-col cols="9">
                        <v-text-field rows=1 auto-grow label="Nome" dense hide-details="auto" :value="nmAvaliado" readonly> </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="6">
                        <v-text-field rows=1 auto-grow label="E-mail pessoal" dense hide-details="auto" :value="dsEmailPessoalAvaliado" readonly> </v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-text-field rows=1 auto-grow label="E-mail institucional" dense hide-details="auto" :value="dsEmailInstitucionalAvaliado" readonly> </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="6">
                        <v-text-field rows=1 auto-grow label="Telefone" dense hide-details="auto" :value="nrTelefoneAvaliado" readonly> </v-text-field>
                    </v-col>
                </v-row>
                <br />
                <v-row>
                    <v-col cols="12">
                        <h4 class="primary--text">Criador</h4>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="3">
                        <v-text-field rows=1 auto-grow label="CPF" dense hide-details="auto" :value="cpfCriador" readonly> </v-text-field>
                    </v-col>
                    <v-col cols="9">
                        <v-text-field rows=1 auto-grow label="Nome" dense hide-details="auto" :value="nmCriador" readonly> </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="6">
                        <v-text-field rows=1 auto-grow label="E-mail" dense hide-details="auto" :value="dsEmailCriador" readonly> </v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-text-field rows=1 auto-grow label="Data criação" dense hide-details="auto" :value="dtCriacao" readonly> </v-text-field>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="dialogVisualizarAvaliadorAvaliado = false"> Fechar </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
export default {
    data() {
        return {
            cpfAvaliador: '',
            nmAvaliador: '',
            nmOrgaoAvaliador: '',
            nmUnidadeAvaliador: '',
            nmCargoAvaliador: '',
            nmFuncaoAvaliador: '',
            nrTelefoneAvaliador: '',
            dsEmailInstitucionalAvaliador: '',

            cpfAvaliado: '',
            nmAvaliado: '',
            nrTelefoneAvaliado: '',
            dsEmailPessoalAvaliado: '',
            dsEmailInstitucionalAvaliado: '',

            cpfCriador: '',
            nmCriador: '',
            dsEmailCriador: '',
            dtCriacao: '',

            dialogVisualizarAvaliadorAvaliado: false
        }
    },
    methods: {
    	mostraAvaliadorAvaliado(avaliadorAvaliado) {
            this.cpfAvaliador = avaliadorAvaliado.servidor_externo.nr_cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
        	this.nmAvaliador = avaliadorAvaliado.servidor_externo.nm_servidor;
            this.nmOrgaoAvaliador = avaliadorAvaliado.servidor_externo.nm_orgao_lotacao;
            this.nmUnidadeAvaliador = avaliadorAvaliado.servidor_externo.nm_unidade_exercicio;
            this.nmCargoAvaliador = avaliadorAvaliado.servidor_externo.nm_cargo;
            this.nmFuncaoAvaliador = avaliadorAvaliado.servidor_externo.nm_funcao;
            this.nrTelefoneAvaliador = avaliadorAvaliado.servidor_externo.nr_telefone_pessoal;
            this.dsEmailInstitucionalAvaliador = avaliadorAvaliado.servidor_externo.ds_email_institucional;

            this.cpfAvaliado = avaliadorAvaliado.servidor.nr_cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
        	this.nmAvaliado = avaliadorAvaliado.servidor.nm_servidor;
            this.nrTelefoneAvaliado = avaliadorAvaliado.servidor.nr_telefone;
            this.dsEmailPessoalAvaliado = avaliadorAvaliado.servidor.ds_email_pessoal;
            this.dsEmailInstitucionalAvaliado = avaliadorAvaliado.servidor.ds_email_institucional;

            this.cpfCriador = avaliadorAvaliado.nr_cpf_criador.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
        	this.nmCriador = avaliadorAvaliado.nm_criador;
            this.dsEmailCriador = avaliadorAvaliado.ds_email_criador;
            this.dtCriacao = new Date (avaliadorAvaliado.created_at).toLocaleDateString('pt-br');

            this.dialogVisualizarAvaliadorAvaliado = true;
        }
    }
}

</script>

import api from './index';

const getAvaliacaoConsolidada = () => api.get('avaliacoes_consolidadas');

const updateAvaliacaoConsolidada = (id_avaliacao_consolidada, avaliacao_consolidada) => api.put('avaliacoes_consolidadas/' + id_avaliacao_consolidada, avaliacao_consolidada);

const getAvaliacaoConsolidadaResultado = (idCiclo) => api.get('getAvaliacaoConsolidadaResultado/'+idCiclo);

const CreateAvaliacaoConsolidada = () => api.get('GerarConsolidacao');

const getListaResultadosAvaliacoes = (page, itemsPerPage) => api.get('getListaResultadosAvaliacoes', {params: {current_page: page, limit: itemsPerPage}});

const gerarResultadoPdf = (filtrosAplicados = []) => api.get('gerarResultadoPdf', { params: {filtros: filtrosAplicados}, responseType: 'blob' } );

const getAvaliacaoConsolidadaResultadoRelatorio = (idCiclo, idConsulta, sglTipo) => api.get('getAvaliacaoConsolidadaResultadoRelatorio/'+idCiclo+'/'+idConsulta+'/'+sglTipo);

const getAvaliacaoConsolidadaResultadoSemAvaliacao = (idCiclo,nr_cpf) => api.get('getAvaliacaoConsolidadaResultadoSemAvaliacao/'+idCiclo+'/'+nr_cpf);

export {
    getAvaliacaoConsolidada,
    updateAvaliacaoConsolidada,
    getAvaliacaoConsolidadaResultado,
    CreateAvaliacaoConsolidada,
    getListaResultadosAvaliacoes,
    gerarResultadoPdf,
    getAvaliacaoConsolidadaResultadoRelatorio,
    getAvaliacaoConsolidadaResultadoSemAvaliacao
}
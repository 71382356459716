<template>
    <v-dialog v-model="isVisible" width="70%" :fullscreen="$vuetify.breakpoint.xsOnly">
        <v-card>
            <v-card-title class="mb-2">
                <span class="text-h5">{{title}}</span>
            </v-card-title>
            <v-card-text>
                <v-row dense class="mx-2 mt-2 elevation-0">
                    <v-col cols="3">
                        <v-form class="pa-0" ref="form_servidor_siape_OU_cpf" v-on:submit.prevent dense>
                            <v-text-field v-if="isVisible" ref="siape" dense hide-details="auto" label="SIAPE/CPF" maxlength="11" v-model="campo_siape_OU_cpf" @click:append="buscaServidorPorSIAPEouCPF()" @keyup.enter="buscaServidorPorSIAPEouCPF()" append-icon="mdi-magnify" required @keypress.native="soNumero($event)" outlined :rules="rules.campoSiapeOuCPF"></v-text-field>
                        </v-form>
                    </v-col>
                    <v-col cols="2" class="ml-5">
                        <v-btn color="primary" @click="buscaServidorPorSIAPEouCPF()">
                            <v-icon>mdi-magnify</v-icon> Buscar
                        </v-btn>
                    </v-col>
                    <v-col cols="4" class="mx-0">
                        <h4 class="mx-2" v-if="!validations.campo_siape_OU_cpf"> Insira 7 dígitos para consultar por SIAPE ou 11 dígitos para buscar por CPF.</h4>
                    </v-col>
                </v-row>
                <v-row dense v-if="erroBuscaSiapeAlertShow" class="mx-2 mt-3 elevation-0">
                    <v-col cols="12">
                        <v-alert dense outlined type="error" class="mb-1">
                            {{erroBuscaSiapeMessage}}
                        </v-alert>
                    </v-col>
                </v-row>
                <v-row class="my-5">
                    <v-divider></v-divider>
                </v-row>
                <v-row dense class="ma-2 pa-0">
                    <v-col cols="2" class="mx-0">
                        <v-form class="pa-0" ref="form_servidor_siape" v-on:submit.prevent>
                            <v-text-field v-if="isVisible" ref="siape" dense hide-details label="SIAPE" v-model="servidor.nr_siape" v-mask="'#######'" required readonly>
                            </v-text-field>
                        </v-form>
                    </v-col>
                    <v-col cols="2" class="mx-0">
                        <v-form class="pa-0" ref="form_servidor_cpf" v-on:submit.prevent>
                            <v-text-field v-if="isVisible" dense hide-details label="CPF" v-model="servidor.nr_cpf" v-mask="'###.###.###-##'" required readonly> </v-text-field>
                        </v-form>
                    </v-col>
                    <v-col cols="8" class="mx-0">
                        <v-text-field v-if="isVisible" dense hide-details label="Nome" v-model="servidor.nm_servidor" required readonly></v-text-field>
                    </v-col>
                </v-row>
                <v-row dense class="ma-2 pa-0" v-if="servidor.unidade_exercicio">
                    <v-col cols="12" class="mx-0">
                        <v-text-field v-if="isVisible" dense hide-details label="Unidade de exercício" v-model="servidor.unidade_exercicio && servidor.unidade_exercicio.nm_unidade" required readonly></v-text-field>
                    </v-col>
                </v-row>
                <v-row dense class="ma-2 pa-0" v-else>
                    <v-col cols="12" class="mx-0">
                        <v-text-field v-if="isVisible" dense hide-details label="Unidade de exercício" v-model="servidor.sg_unidade_exercicio_externo && servidor.nm_unidade_exercicio_externo" required readonly></v-text-field>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="py-4">
                <v-spacer></v-spacer>
                <v-btn color="default" @click="toggleVisible()">
                    Cancelar
                </v-btn>
                <v-btn :disabled="!servidor.nm_servidor" color="success" @click="emitServidorToParent();toggleVisible();">
                    Adicionar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import { getServidorBySiape, getServidorByCPF } from '../api/servidor';

export default {
    name: 'BuscaServidorSiapeCPFdialogAdicionarMembro',
    props: ['title', 'tipo'],
    data() {
        return {
            campo_siape_OU_cpf: '',
            rules: {
                campoSiapeOuCPF: [
                    () => !!this.campo_siape_OU_cpf || 'Preencha com um SIAPE ou CPF.',
                ]
            },
            validations: {
                campo_siape_OU_cpf: true,
            },
            isVisible: false,
            servidor: {},
            erroBuscaSiapeAlertShow: false,
            erroBuscaSiapeMessage: '',
        }
    },
    created() {},
    methods: {
        soNumero(e) {
            let keyCode = (e.keyCode ? e.keyCode : e.which);
            if (keyCode > 31 && (keyCode < 48 || keyCode > 57)) {
                e.preventDefault();
            }
        },
        toggleVisible() {
            this.isVisible = !this.isVisible;
            this.servidor = {};
            this.campo_siape_OU_cpf = '';
            this.erroBuscaSiapeAlertShow = false;
            this.erroBuscaSiapeMessage = '';
        },

        buscaServidorPorSIAPEouCPF() {
            if (this.$refs['form_servidor_siape_OU_cpf'].validate() && this.campo_siape_OU_cpf) {
                this.validations.campo_siape_OU_cpf = true
                if (this.campo_siape_OU_cpf.length === 11) {
                    let cpf = this.campo_siape_OU_cpf;
                    getServidorByCPF(cpf).then(response => {
                        this.servidor = response.data.data;
                        this.erroBuscaSiapeAlertShow = false;
                    }).catch(erro => {
                        this.erroBuscaSiapeAlertShow = true
                        this.erroBuscaSiapeMessage = (erro.toString().indexOf('404') > -1) ? 'Não foi possível localizar servidor ativo com CPF ' + this.campo_siape_OU_cpf + '' : erro;
                        this.servidor = {}
                    });
                } else if (this.campo_siape_OU_cpf.length === 7) {
                    let siape = this.campo_siape_OU_cpf;
                    getServidorBySiape(siape).then(response => {
                        this.servidor = response.data.data;
                        this.erroBuscaSiapeAlertShow = false;
                    }).catch(erro => {
                        this.erroBuscaSiapeAlertShow = true
                        this.erroBuscaSiapeMessage = (erro.toString().indexOf('404') > -1) ? 'Não foi possível localizar servidor ativo com SIAPE ' + this.campo_siape_OU_cpf + '' : erro;
                        this.servidor = {}
                    });
                } else {
                    this.validations.campo_siape_OU_cpf = false
                    this.servidor = {}
                }
            }
        },

        toggleChildren() {
            this.showChildren = !this.showChildren;
        },
        childrenSelected(nodeId) {
            let startingShowChildrenState = this.showChildren;
            this.showChildren = false
            this.$emit('update:selected', nodeId, this.isSelected)
            const changeValue = (noArray, valueToSet) => {
                for (let node of noArray) {
                    changeValue(node.children, valueToSet)
                }

            }
            changeValue(this.nodes, this.isSelected)
            this.$emit('update:unidadesSelected')
            this.showChildren = startingShowChildrenState
            this.showChildren = false
        },
        emitToParentUpdateSelected(nodeId, new_value) {
            this.$emit('update:selected', nodeId, new_value)
        },
        emitToParentUpdateSelectedSet(nodeIdArray, new_value) {
            this.$emit('update:selectedSet', nodeIdArray, new_value)
        },
        emitToParentUpdateUnidadesSelected() {
            this.$emit('update:unidadesSelected')
        },

        emitServidorToParent() {
            this.$emit('result:servidor', this.servidor, this.tipo)
        }
    }
}

</script>

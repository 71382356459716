<template>
    <div class="pt-8">
        <p>
            O sistema eletrônico AvaliaMGI é o conjunto de funcionalidades eletrônicas, 
            desenvolvido para gerir a avaliação de desempenho individual e institucional e 
            responsável por ofertar suporte à elaboração de planos de trabalho, ao 
            estabelecimento de metas individuais e de metas intermediárias, ao 
            procedimento avaliativo e aos pedidos de reconsideração e de recurso, 
            de modo a dar ciência e transparência dos atos praticados para os servidores 
            públicos do quadro de pessoal do Ministério da Gestão e Inovação em Serviços 
            Públicos e para os órgãos que compõem o <strong>COLABORAGOV</strong>.		
        </p>
        <p>
            Para acessá-lo, é necessário pertencer ao MGI e ao <strong>COLABORAGOV</strong> e ter uma conta no  
            <a href="https://contas.acesso.gov.br/" target="_blank">
                <strong class="logoGovBr">gov.br</strong>.
            </a> 
        </p>
        <br>
        <v-btn color="warning" class="primary--text" href="/informacoes-legislacao-aplicada">
            <v-icon left>mdi mdi-scale-balance</v-icon>Legislacao Aplicada
        </v-btn>  
    </div>
</template>

<script>
    export default {
        name: 'home-app',
        created() {
		},
    }
</script>
<style>
.logoGovBr {
   font-size: 30px;
   background: linear-gradient(to right,
      #155BCB 20%,
      #FFCD07 20%, #FFCD07 42%,
      #168821 42%, #168821 58%,
      #155BCB 58%, #155BCB 87%,
      #FFCD07 87%
    );
   -webkit-background-clip: text;
   -webkit-text-fill-color: transparent;
    font-weight: 1000;
    font-family: arial;
    letter-spacing: -1px;
}
</style>
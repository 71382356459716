<template>
<div>
    <v-dialog v-model="dialog" width="100%" fullscreen hide-overlay
      transition="dialog-bottom-transition">
        <v-card>
            <v-toolbar dark color="primary">
            <v-btn icon dark>
                <v-icon>mdi-account-switch</v-icon>
            </v-btn>
            <v-toolbar-title>Cadastro de Avaliação</v-toolbar-title>            
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-btn dark text @click="closeDialog()">
                <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar-items>
            </v-toolbar>

            <v-container>
                <h2 class="text-center font-weight-light primary--text">Cadastro de Avaliação</h2>
                <p class="text-center blue-grey--text mt-2" v-if="!checkboxAvaliacaoSemestral">
                    <v-icon color="warning" class="mr-1">mdi-alert-circle-outline</v-icon>
                    Caso o servidor pertença a algum plano de trabalho, será <strong>removido</strong> da equipe no ciclo atual.<br>
                    A partir do cadastro gera uma avaliação de chefia e autoavaliação.
                </p>
                <!-- <v-row>
                    <v-col cols="12">
                        <v-checkbox class="mr-4" v-model="checkboxAvaliacaoSemestral" label="Avaliação Semestral"></v-checkbox>
                    </v-col>
                </v-row> -->
                <v-col cols="12" class="mt-1">
                    <v-card class="pb-3">
                        <h3 class="pa-5 primary--text">Avaliador</h3>

                            <v-row dense class="ma-0 pa-2">
                                <v-col cols="12" md="4" class="mx-0 px-3">
                                    <v-text-field id="cpf_avaliador" ref="cpfavaliador" dense hide-details="auto" label="CPF" v-model="avaliador.nr_cpf" v-mask="'###.###.###-##'" outlined clearable clear-icon="mdi-close-circle-outline" :rules="validaCampo" v-on:change="getServidor(`${avaliador.nr_cpf}`, 'avaliador')">
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" md="4" class="mx-0 px-3">
                                    <v-text-field id="nome_avaliador"  ref="nome_avaliador" dense hide-details="auto" label="Nome" outlined clearable clear-icon="mdi-close-circle-outline" v-model="avaliador.nm_servidor" disabled>
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" md="4" class="mx-0 px-3">
                                    <v-text-field id="telefone_avaliador"  ref="telefone_avaliador" dense hide-details="auto" label="Telefone" outlined clearable clear-icon="mdi-close-circle-outline" v-model="avaliador.nr_telefone" disabled>
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            
                            <v-row dense class="ma-0 pa-2">
                                <v-col cols="12" md="6" class="mx-0 px-3">
                                    <v-text-field id="email_avaliador_pessoal" v-model="avaliador.ds_email_pessoal" ref="email_avaliador_pessoal" dense hide-details="auto" label="E-mail pessoal" outlined clearable clear-icon="mdi-close-circle-outline" disabled>
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" md="6" class="mx-0 px-3">
                                    <v-text-field id="email_avaliador" v-model="avaliador.ds_email_institucional" ref="email_avaliador" dense hide-details="auto" label="E-mail institucional" outlined clearable clear-icon="mdi-close-circle-outline" disabled>
                                    </v-text-field>
                                </v-col>
                            </v-row>
                    </v-card>
                </v-col>

                <v-col cols="12" class="mt-4">
                    <v-card class="pb-3">
                        <h3 class="pa-5 primary--text">Avaliado</h3>
                            <v-row dense class="ma-0 pa-2">
                                <v-col cols="12" md="4" class="mx-0 px-3">
                                    <v-text-field id="cpf_avaliado" ref="cpfavaliado" dense hide-details="auto" label="CPF avaliado" v-model="avaliado.nr_cpf" v-mask="'###.###.###-##'" outlined clearable clear-icon="mdi-close-circle-outline" :rules="validaCampo" v-on:change="getServidor(`${avaliado.nr_cpf}`, 'avaliado')">
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" md="4" class="mx-0 px-3">
                                    <v-text-field id="nome_avaliado"  ref="nome_avaliado" dense hide-details="auto" label="Nome" outlined clearable clear-icon="mdi-close-circle-outline" v-model="avaliado.nm_servidor" disabled>
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" md="4" class="mx-0 px-3">
                                    <v-text-field id="telefone_avaliado"  ref="telefone_avaliado" dense hide-details="auto" label="Telefone" outlined clearable clear-icon="mdi-close-circle-outline" v-model="avaliado.nr_telefone" disabled>
                                    </v-text-field>
                                </v-col>
                            </v-row>

                            <v-row dense class="ma-0 pa-2">
                                <v-col cols="12" md="6" class="mx-0 px-3">
                                    <v-text-field id="email_avaliado_pessoal" v-model="avaliado.ds_email_pessoal" ref="email_avaliado_pessoal" dense hide-details="auto" label="E-mail pessoal" outlined clearable clear-icon="mdi-close-circle-outline" disabled>
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" md="6" class="mx-0 px-3">
                                    <v-text-field id="email_avaliado" v-model="avaliado.ds_email_institucional" ref="email_avaliado" dense hide-details="auto" label="E-mail institucional" outlined clearable clear-icon="mdi-close-circle-outline" disabled>
                                    </v-text-field>
                                </v-col>
                            </v-row>
                    </v-card>
                </v-col>
                <v-row dense class="ma-0" v-if="!checkboxAvaliacaoSemestral">
                    <p class="mt-2" >
                        <span class="red--text">* </span>Por favor, insira uma justificativa explicando o motivo desta ação.
                    </p>
                    <v-col cols="12" class="px-3">
                        <v-textarea
                        v-model="justificativa"
                        id="justificativa"
                        outlined
                        rows="2"
                        name="justificativa"
                        label="Justificativa"
                        :rules="validaCampo"
                        ></v-textarea>
                    </v-col>      
                </v-row>
                <v-row dense class="ma-0" v-else>
                    <v-col cols="12" class="px-3" v-if="avaliado.meta">
                        <h4 class="grey--text">Meta Individual do avaliado:</h4>
                        <h6 class="grey--text">{{avaliado.meta}}</h6>
                    </v-col>      
                </v-row>
            </v-container>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="grey darken-1" text @click="closeDialog()">Fechar</v-btn>
                <v-btn  color="primary" text @click="create()">Cadastrar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <v-dialog v-model="dialogAlert" width="570">
        <v-card>
            <v-card-title class="text-h5">
                <v-alert prominent type="warning" outlined width="100%">
                    <v-row align="center">
                        <v-col class="grow">
                            {{dialogMsg}}
                        </v-col>
                        <v-col class="shrink">
                        <v-btn color="warning"  @click="dialogAlert = false">Ok</v-btn>
                        </v-col>
                    </v-row>
                </v-alert>
            </v-card-title> 
        </v-card>
    </v-dialog>
    <v-dialog v-model="dialogSuccess" width="570" >
        <v-card>
            <v-card-title class="text-h5 text-center">
                <v-alert prominent type="success" width="100%">
                    <v-row align="center">
                        <v-col class="grow">
                            {{dialogMsg}}
                        </v-col>
                        <v-col class="shrink">
                        <v-btn outlined  @click="dialogSuccess = false">Ok</v-btn>
                        </v-col>
                    </v-row>
                </v-alert>
            </v-card-title> 
        </v-card>
    </v-dialog>
    <v-dialog v-model="dialogPossuiAvaliacao" max-width="500px">
        <v-card>
            <p class="text-center warning--text mt-2 pt-6">
                <v-icon color="warning" class="mr-1">mdi-alert-circle-outline</v-icon>
                O servidor já possuí uma avaliação.
                Deseja excluir avaliação existente para
                prosseguir com este cadastro?
            </p>
            <v-card-text>
                <v-container>
                    <v-row dense class="ma-0">
                        <v-col cols="12" class="mx-0 px-3">
                            <v-textarea
                            v-model="justificativa"
                            outlined
                            rows="2"
                            name="justificativa"
                            label="Justificativa para exclusão da avaliação existente"
                            ></v-textarea>
                        </v-col>      
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="warning darken-1" text @click="dialogPossuiAvaliacao = false">Cancelar</v-btn>
            <v-btn color="black"  text @click="create(true)">Prosseguir</v-btn>
            <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>
</div>
    
</template>
<script>
import ComumService from '../services/comum.service';
import { getServidorAvaliacaoCadastradaByCPF } from '../api/servidor';
import { createAvaliacaoCadastrada } from '../api/avaliacao';
import { getServidorByCpfForGestor } from '../api/servidor';

export default {
    data() {
        return {
            dialog: false,
            justificativa:'',
            avaliador: [],
            avaliado:[],
            validaCampo: [v => !!v || 'Campo obrigatório'],
            dialogAlert:false,
            dialogMsg: '',
            dialogSuccess: false,
            dialogPossuiAvaliacao: false,
            checkboxAvaliacaoSemestral: null,
            metaIndividual: null
        }
    },
    methods: {
    	showDialog(servidor = null) {
            if (servidor) {
                this.avaliado.id_servidor = servidor.id;
                this.avaliado.nr_cpf = servidor.cpf;
                this.avaliado.nm_servidor = servidor.nome;
                this.avaliado.nr_telefone = servidor.telefone;
                this.avaliado.ds_email_pessoal = servidor.email_pessoal;
                this.avaliado.ds_email_institucional = servidor.email_institucional;
                this.dialog = true;
            } else {
                this.dialog = true;
            }
            
        },

        closeDialog() {
            this.dialog = false;
            this.justificativa = '';
            this.avaliador= [];
            this.avaliado=[];
        },

        getServidor(cpf, tipo) {
            var cpfValido = ComumService.validaCPF(cpf);

            if (!cpfValido) {
                this.clearInputs(tipo);
                this.dialogMsg = "CPF informado está inválido!";
                return this.dialogAlert = true;
            }

            if (this.avaliador.nr_cpf && this.avaliado.nr_cpf) {
                if (this.avaliador.nr_cpf.replaceAll('.','').replaceAll('-','') == this.avaliado.nr_cpf.replaceAll('.','').replaceAll('-','')) {
                    this.clearInputs(tipo);
                    this.dialogMsg = "Não é permitido que servidor seja cadastrado como seu próprio avaliador";
                    return this.dialogAlert = true;
                }
            }

            if (
                this.$store.getters['auth/usuarioAutenticadoRoles'].indexOf("gestorOrgao") >= 0
                && this.$store.getters['auth/usuarioAutenticadoRoles'].indexOf("administrador") < 0
            ) {
                getServidorByCpfForGestor(cpf).then(response => { 

                    if (response.data.message == 'outroOrgao') {
                        this.dialogMsg = "Servidor de outro órgão!";
                        if (tipo == 'avaliador') {
                            this.avaliador = [];
                        } else {
                            this.avaliado = [];
                        }
                        return this.dialogAlert = true;
                    }else {

                        if (tipo == 'avaliador') {
                            this.avaliador = response.data.data;
                        } else {
                            this.avaliado = response.data.data;
                        } 
                    }
                }).catch(erro => {
                    this.dialogMsg = "CPF não encontrado.";
                    this.dialogAlert = true;
                    if (tipo == 'avaliador') {
                        this.avaliador = [];
                    } else {
                        this.avaliado = [];
                    }
                });
            } else {
                getServidorAvaliacaoCadastradaByCPF(cpf).then(response => {
                    
                    if (tipo == 'avaliador') {
                        this.avaliador = response.data.data;
                    } else {
                        this.avaliado = response.data.data;
                    }                
                }).catch(erro => {
                    this.dialogMsg = "CPF não encontrado.";
                    this.dialogAlert = true;
                    if (tipo == 'avaliador') {
                        this.avaliador = [];
                    } else {
                        this.avaliado = [];
                    }
                });
            }
        },

        clearInputs(tipo) {
            if (tipo == 'avaliador') {
                this.avaliador.nr_cpf = null;
                this.avaliador = [];
            } else {
                this.avaliado.id_servidor = '';
                this.avaliado.nr_cpf = null;
                this.avaliado.nm_servidor = '';
                this.avaliado.nr_telefone = '';
                this.avaliado.ds_email_pessoal = '';
                this.avaliado.ds_email_institucional = '';
            }
        },

        create(avaliacaoExistente = '') {
            if(this.justificativa == '' && this.checkboxAvaliacaoSemestral != true) {
                this.justificativa = null;
                this.dialogMsg = "Por favor, insira uma justificativa explicando o motivo desta ação.";
                return this.dialogAlert = true;                
            } 

            if(this.avaliador.nr_cpf == null || this.avaliado.nr_cpf == null ) {
                this.avaliador.nr_cpf = this.avaliador.nr_cpf != null ? this.avaliador.nr_cpf: null;
                this.avaliado.nr_cpf = this.avaliado.nr_cpf != null ? this.avaliado.nr_cpf: null;
                this.dialogMsg = "CPF é obrigatório.";
                return this.dialogAlert = true;                
            } 

            /* if (this.avaliador.cd_orgao != this.avaliado.cd_orgao) {
                this.dialogMsg = "Avaliador e avaliado não são do mesmo orgão";
                return this.dialogAlert = true; 
            } */
            
            let data = new FormData();

            if (this.checkboxAvaliacaoSemestral == true) {
                data.append('id_avaliador', this.avaliador.id_servidor);
                data.append('id_avaliado', this.avaliado.id_servidor);
                data.append('avaliacaoExistente', avaliacaoExistente);
                data.append('st_semestral', true);
                data.append('id_servidor_cadastrador', this.$store.getters['auth/usuarioAutenticado']);
            } else {
                data.append('tx_justificativa', this.justificativa);
                data.append('id_avaliador', this.avaliador.id_servidor);
                data.append('id_avaliado', this.avaliado.id_servidor);
                data.append('avaliacaoExistente', avaliacaoExistente);
                data.append('st_semestral', false);
                data.append('id_servidor_cadastrador', this.$store.getters['auth/usuarioAutenticado']);
            }
            
            createAvaliacaoCadastrada(data).then(response => {
                if (!response.data.data) {
                    
                    if (response.data.message == "registroDuplicado") {
                        this.dialogMsg = "Servidor já possui cadastro";
                        return this.dialogAlert = true;
                    }

                    if (response.data.message == "possuiAvaliacao") {
                        return this.dialogPossuiAvaliacao = true;
                    }
                    
                } else {
                    this.dialogPossuiAvaliacao = false;
                    this.justificativa = '';
                    this.dialog = false;
                    this.dialogMsg = response.data.message;
                    return this.dialogSuccess = true;
                }
            }).catch(erro => {
                this.dialogMsg = "Erro ao cadastrar.";
                this.dialogAlert = true;
                this.avaliador = [];
            }); 
        }
    }
}

</script>

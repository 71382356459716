import api from './index';

const getDetalheAfastamentoServidor = (id_servidor) => api.get(
    'getDetalheAfastamentoServidor/' + id_servidor
);

const getServidoresAfastados = (page, itemsPerPage, filtrosAplicados = []) => api.get(
    'getServidoresAfastados', 
    {params: {current_page: page, limit: itemsPerPage, filtros: filtrosAplicados}}
);

const getTotalServidoresAfastados = (filtrosAplicados = []) => api.get(
    'getTotalServidoresAfastados', 
    {params: {filtros: filtrosAplicados}}
);

const getRelatorioAtivosEmOutroOrgao = (page, itemsPerPage, filtrosAplicados = []) => api.get(
    'getRelatorioAtivosEmOutroOrgao', 
    {params: {current_page: page, limit: itemsPerPage, filtros: filtrosAplicados}}
);

const getTotalAtivosEmOutroOrgao = (filtrosAplicados = []) => api.get(
    'getTotalAtivosEmOutroOrgao', 
    {params: {filtros: filtrosAplicados}}
);

const gerarExtracaoServidorAfastamento = (filtrosAplicados = []) => api.get(
    'gerarExtracaoServidorAfastamento', 
    { params: {filtros: filtrosAplicados}, responseType: 'blob' } 
);

const exportarExcelAtivosEmOutroOrgao = (filtrosAplicados = []) => api.get(
    'exportarExcelAtivosEmOutroOrgao', 
    { params: {filtros: filtrosAplicados}, responseType: 'blob' } 
);

const getDetalheAtivoEmOutroOrgao = (id_servidor) => api.get(
    'getDetalheAtivoEmOutroOrgao/' + id_servidor
);

export {
    getDetalheAfastamentoServidor,
    getServidoresAfastados,
    getTotalServidoresAfastados,
    getRelatorioAtivosEmOutroOrgao,
    getTotalAtivosEmOutroOrgao,
    gerarExtracaoServidorAfastamento,
    exportarExcelAtivosEmOutroOrgao,
    getDetalheAtivoEmOutroOrgao
}
<template>
    <v-app-bar color="white" height="100" max-height="100" class="px-md-10">
        <v-app-bar-nav-icon v-if="loggedIn" @click.stop="toggleSideBar" id="btnMenu" color="primary"></v-app-bar-nav-icon>
         <img src="img/colaboragov.png" alt="COLABORAGOV" width="250">
         <v-spacer></v-spacer>
        <v-toolbar-title>
            AvaliaMGI
            <div  class="subtitle-2 grey--text lighten-1--text font-weight-regular">
                Sistema de avaliação de desempenho do Ministério da Gestão e Inovação em Serviços Públicos 
            </div>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn v-if="!loggedIn && !loggedExterno" @click="loginUnico" color="primary" rounded>
            Entrar com GOV.BR
        </v-btn>
        <div v-else>
            <v-menu v-if="loggedIn" v-model="showPerfis"  :close-on-content-click="false" :nudge-width="200" offset-x>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn text rounded v-bind="attrs" v-on="on" @click="showNomeAndPerfis()" class="grey--text">
                        <v-icon dark left color="primary">mdi-account</v-icon>
                        <v-icon dark right color="black">mdi-chevron-down</v-icon>
                    </v-btn>
                </template>
                <v-card>
                    <v-list>
                        <v-list-item>
                            <v-list-item-avatar>
                                <v-icon dark left color="primary">mdi-account</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title >{{servidorNome}}</v-list-item-title>
                                <v-list-item-subtitle>
                                    Perfis: 
                                    <span v-for="item in perfis" :key="item"> 
                                        ( {{item}} ) 
                                    </span>
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" text rounded @click="showPerfis = false">
                            Fechar
                        </v-btn>
                        <v-btn color="primary" rounded @click="logout">
                            Sair
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-menu>
            <v-btn  @click="logout" color="primary" rounded>
                Sair
            </v-btn>
        </div>
        
    </v-app-bar>
</template>

<script>
import { getServidor } from '../api/servidor';
import { getServidorExternoById } from '../api/servidor_externo';

export default {
    name: 'app-header',
    props: {
        loggedIn: Boolean,
        loggedExterno: Boolean
    },
    data() {
        return {
            ENV_MIX_APP_NAME: process.env.MIX_APP_NAME,
            ENV_MIX_APP_DESCRIPTION: process.env.MIX_APP_DESCRIPTION,
            showPerfis: false,
            perfis:[],
            servidorNome:'',
        };
    },
    
    mounted() {
        var self = this;
        setTimeout(function () { self.getPerfis()} , 2000);
        setTimeout(function () { self.getDadosServidor()} , 2000);
    },

            
    methods: {
        generateNonce() {
            var nonce = "";
            var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
            for (var i = 0; i < 50; i++) {
                nonce += possible.charAt(Math.floor(Math.random() * possible.length));
            }
            return nonce;
        },
        loginUnico() {
            const url = process.env.MIX_GOVBR_URL + process.env.MIX_GOVBR_AUTHORIZE_ENDPOINT;
            const params = `response_type=${process.env.MIX_GOVBR_RESPONSE_TYPE}&client_id=${process.env.MIX_GOVBR_CLIENT_ID}&scope=${process.env.MIX_GOVBR_SCOPE}&redirect_uri=${process.env.MIX_GOVBR_REDIRECT_URI}&nonce=${this.generateNonce()}`;

            window.open(`${url}?${params}`, '_self');
        },
        logout() { 
            const url = 'https://sso.staging.acesso.gov.br/logout';
            const params = 'post_logout_redirect_uri='+ location.origin+'/logout';
  
            this.$store.dispatch('auth/logout').then(
                () => {
                    window.location.href=`${url}?${params}`;
                },
                error => {
                    this.message =
                        (error.response && error.response.data) ||
                        error.message ||
                        error.toString();
                }
            ); 
        },
        toggleSideBar() {
            this.$store.commit('menu/toggleSideBar');
        },
        getDadosServidor() {
            if(this.loggedIn || this.loggedExterno) {
                if (this.$store.getters['auth/usuarioAutenticadoRoles'] && this.$store.getters['auth/usuarioAutenticadoRoles'].indexOf("servidorExterno") >= 0 && this.$store.getters['auth/usuarioAutenticadoRoles'].indexOf("servidor") == -1 ) {
                    getServidorExternoById(this.$store.getters['auth/usuarioAutenticado']).then(response => {
                        return this.servidorNome = response.data.data.nm_servidor;
                    });
                } else if (this.$store.getters['auth/usuarioAutenticado']) { 
                    getServidor(this.$store.getters['auth/usuarioAutenticado']).then(response => {
                        return this.servidorNome = response.data.data.nome;
                    });
                }
            }
        },
        getPerfis() {
            this.perfis = this.$store.getters['auth/usuarioAutenticadoRoles']?this.$store.getters['auth/usuarioAutenticadoRoles']:null;
        },
        showNomeAndPerfis(){
            this.getDadosServidor();
            this.getPerfis();
        }
    },
    computed: {}
}
</script>

<style>
header {
    z-index: 100 !important;
}
</style>

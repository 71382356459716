import Vue from 'vue';
import Vuetify from 'vuetify';
import pt from 'vuetify/es5/locale/pt';
import 'vuetify/dist/vuetify.min.css';

Vue.use(Vuetify)

const opts = {
    lang: {
        locales: { pt },
        current: 'pt',
    },
    theme: {
	    themes: {
	      light: {
	        primary: '#1351b4',
			warning: '#FFCD07'
	      },
	    },
  	},
}

export default new Vuetify(opts)
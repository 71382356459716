<template>
    <div>
        <h1 id="ds_titulo" class="text-center"  style="color:#0c326f" ref="ds_titulo"></h1>
        <div id="ds_texto" class="text-center" ref="ds_texto"></div>
        <br>
        <div  
            v-if="anexosLegislacao.length"
            class="text-center trocaCor pb-3" 
            style="background-color:#f8f8f8;" 
        >
            <h1 class="pt-3" style="color:#0c326f">Informações para download</h1>
            <v-hover
                v-slot="{ hover }"
                v-for="(value) in anexosLegislacao" :key="value.id_anexo_legislacao_aplicada"
            >
                <v-card
                    :elevation="hover ? 16 : 2"
                    :class="{ 'on-hover': hover }"
                    class="mx-auto mb-4 pb-4 d-inline-block mr-3 ml-3"
                    max-width="300"
                    width="300"
                >
                    <v-list-item three-line>
                        <v-list-item-content>
                            <v-list-item-subtitle>
                                <v-icon 
                                    x-large 
                                    color="#FFCD07" 
                                    v-if="value.arquivo.nm_arquivo.split('.').pop() == 'png'"
                                >
                                    mdi mdi-file-image
                                </v-icon>
                                <v-icon 
                                    x-large 
                                    color="#FFCD07" 
                                    v-if="value.arquivo.nm_arquivo.split('.').pop() == 'jpeg'"
                                >
                                    mdi mdi-file-image
                                </v-icon>
                                <v-icon 
                                    x-large 
                                    color="#FFCD07" 
                                    v-if="value.arquivo.nm_arquivo.split('.').pop() == 'jpg'"
                                >
                                    mdi mdi-file-image
                                </v-icon>
                                <v-icon 
                                    x-large 
                                    color="error" 
                                    v-if="value.arquivo.nm_arquivo.split('.').pop() == 'pdf'"
                                >
                                    mdi mdi-file-pdf
                                </v-icon>
                                <v-icon 
                                    x-large 
                                    color="#155BCB" 
                                    v-if="value.arquivo.nm_arquivo.split('.').pop() == 'doc'"
                                >
                                    mdi mdi-file-word
                                </v-icon>
                                <v-icon 
                                    x-large 
                                    color="#155BCB" 
                                    v-if="value.arquivo.nm_arquivo.split('.').pop() == 'docx'"
                                >
                                    mdi mdi-file-word
                                </v-icon>
                                <v-icon 
                                    x-large 
                                    color="#168821" 
                                    v-if="value.arquivo.nm_arquivo.split('.').pop() == 'xlsx'"
                                >
                                    mdi mdi-file-excel
                                </v-icon>
                                <v-icon 
                                    x-large 
                                    color="#168821" 
                                    v-if="value.arquivo.nm_arquivo.split('.').pop() == 'xls'"
                                >
                                    mdi mdi-file-excel
                                </v-icon>
                                <br>
                                {{ value.ds_titulo }}
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            outlined
                            rounded
                            text
                            @click="download(value.arquivo.id_arquivo, value.arquivo.nm_arquivo)"
                        >
                            Download
                        </v-btn>
                        <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
            </v-hover>
        </div>
    </div>
</template>

<script>
import { getLegislacaoAplicada } from '../api/legislacao_aplicada';
import { getAnexosLegislacaoAplicada } from '../api/legislacao_aplicada';
import { downloadAnexoLegislacaoAplicada } from '../api/legislacao_aplicada';

export default {
    name: 'LegislacaoAplicadaListar',
    data() {
        return {
            anexosLegislacao: [],
        };
    },

    mounted() {
    },
    created() {
        this.getLegislacao();
        this.getAnexos();
    },

    methods: {
        
        getLegislacao () {
            getLegislacaoAplicada()
            .then(response => {
                var res = response.data.data;

                if (res.id_legislacao_aplicada) {
                    var titulo = res.ds_titulo;
                    var texto =res.ds_texto;

                    this.$refs.ds_titulo.innerHTML = titulo;
                    this.$refs.ds_texto.innerHTML = texto;
                }  
            });
        },

        getAnexos() {
            getAnexosLegislacaoAplicada().then(response => {
                this.anexosLegislacao = response.data.data;
            }).catch(erro => {
                this.dialogMsg = "Erro ao listar anexos.";
                this.dialogWarning = true;
            });
        },

        download(id_arquivo, nm_arquivo) {
            downloadAnexoLegislacaoAplicada(id_arquivo).then(response => {
                let blob = new Blob([response.data], { type: 'application/pdf' });
                let link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = nm_arquivo;                
                link.click();
            }).catch(erro => {
                this.dialogMsg = "Erro ao realizar download.";
                this.dialogWarning = true;
            });
        }
    }
}
</script>




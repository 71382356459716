<template>
    <div>
    <avaliacaoCadastradaDialog ref="avaliacaoCadastradaDialog" />
       <h1>Avaliações Cadastradas</h1>
        <v-row>
            <v-col cols="12">
                <v-card>
                    <v-form class="mt-5" ref="form_ciclo">
                        <v-row dense class="ma-0 pa-2">
                            <v-col cols="12" md="6" class="mx-0 px-3">
                                <v-select
                                    id="ciclo"
                                    :items="ciclos"
                                    v-model="filtros.id_ciclo"
                                    item-text="nm_ciclo"
                                    item-value="id_ciclo"
                                    label="Ciclo"
                                    outlined
                                    dense
                                    hide-details="auto"
                                    required 
                                    :rules="[() => !!filtros.id_ciclo || 'Campo obrigatório.']"
                                ></v-select>
                            </v-col>
                        </v-row>
                        <v-row dense class="ma-0 pa-2">
                            <v-col cols="12" md="6" class="mx-0 px-3">
                                <v-select
                                    id="gratificacao"
                                    :items="gratificacoes"
                                    v-model="filtros.id_gratificacao"
                                    item-value="id_gratificacao"
                                    item-text="sg_gratificacao"
                                    label="Gratificação do avaliado"
                                    outlined
                                    dense
                                    hide-details="auto"
                                ></v-select>
                            </v-col>
                            <v-col cols="12" md="6" class="mx-0 px-3">
                                <v-combobox
                                    id="orgao"
                                    :items="orgaos"
                                    v-model="filtros.cd_orgao"
                                    item-value="cd_orgao"
                                    item-text="nm_orgao"
                                    label="Órgão"
                                    outlined
                                    dense
                                    hide-details="auto"
                                ></v-combobox>
                            </v-col> 
                        </v-row>
                    </v-form>
                    <v-row dense class="ma-0 pa-2">
                        <v-col cols="12" md="6" class="mx-0 px-3">
                            <v-text-field id="nomeAvaliado" ref="nome" dense hide-details="auto" label="Nome do avaliado" v-model="filtros.nm_avaliado" outlined clearable clear-icon="mdi-close-circle-outline"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" class="mx-0 px-3">
                            <v-text-field id="cpfAvaliado" ref="cpf" dense hide-details="auto" label="CPF do avaliado" v-model="filtros.nr_cpf_avaliado" v-mask="'###.###.###-##'" outlined clearable clear-icon="mdi-close-circle-outline"></v-text-field>
                        </v-col>                        
                    </v-row>
                    <v-row dense class="ma-0 pa-2">
                        <v-col cols="12" md="6" class="mx-0 px-3">
                            <v-text-field id="nomeAvaliador" ref="nome" dense hide-details="auto" label="Nome do avaliador" v-model="filtros.nm_avaliador" outlined clearable clear-icon="mdi-close-circle-outline"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" class="mx-0 px-3">
                            <v-text-field id="cpfAvaliador" ref="cpf" dense hide-details="auto" label="CPF do avaliador" v-model="filtros.nr_cpf_avaliador" v-mask="'###.###.###-##'" outlined clearable clear-icon="mdi-close-circle-outline" ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="ma-0 mb-4 pa-2 px-8 d-flex flex-row justify-end align-end text-right elevation-0">
                            <v-btn class="success mr-3" @click="() => modalAvaliacaoCadastrada()">
                                <v-icon>mdi-plus</v-icon> Cadastrar
                            </v-btn>
                            <v-btn id="btnFiltrar" color="primary" @click="getAvaliacoesCadastradas()">
                                <v-icon>mdi-magnify</v-icon> Filtrar
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
        <br>
        <v-row>
            <v-col cols="12">
                <v-data-table
                    :headers="headers"
                    :items="avaliacoesCadastradas"
                    :items-per-page="pagination.itemsPerPage"
                    :footer-props="{'items-per-page-options':[30, 50, 100]}"
                    :options.sync="pagination"
                    @update:options="getAvaliacoesCadastradas"
                    :server-items-length="pagination.total"
                    class="elevation-1"
                >
                    <template v-slot:[`item.st_semestral`]="{ item }">
                        <span v-if="item.st_semestral != true">
                            NÃO
                        </span>
                        <span v-else>
                            SIM
                        </span>
                    </template> 
                    <template v-slot:[`item.acao`]="{ item }">
                        <v-row justify="center">
                            <v-tooltip top>
                                <template v-slot:activator="{on, attrs}">
                                    <v-btn id="btnExcluir" icon small :color="'red'" v-bind="attrs" v-on="on" @click="showDialogDelete(item)">
                                        <v-icon> mdi-delete-forever </v-icon>
                                    </v-btn>
                                </template>
                                <span>Excluir</span>
                            </v-tooltip>
                        </v-row>
                    </template> 
                </v-data-table>
            </v-col>
        </v-row>
        <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
                <h4 class="primary--text text-center py-6">
                    Tem certeza que deseja excluir?
                 </h4>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="grey darken-1" text @click="dialogDelete = false">Fechar</v-btn>
                <v-btn color="red darken-1" text @click="excluir()">Excluir</v-btn>
                <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogPossuiAvaliacao" max-width="500px">
            <v-card>
                <p class="text-center warning--text mt-2 pt-6">
                    <v-icon color="warning" class="mr-1">mdi-alert-circle-outline</v-icon>
                    O servidor já possuí uma avaliação.
                    Deseja excluir avaliação existente para
                    prosseguir?
                </p>
                <v-card-text>
                    <v-container>
                        <v-row dense class="ma-0">
                            <v-col cols="12" class="mx-0 px-3">
                                <v-textarea
                                v-model="justificativa"
                                outlined
                                rows="2"
                                name="justificativa"
                                label="Justificativa para exclusão da avaliação existente"
                                ></v-textarea>
                            </v-col>      
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="black" text @click="dialogPossuiAvaliacao = false">Cancelar</v-btn>
                <v-btn color="red"  text @click="excluir(true)">Excluir</v-btn>
                <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogSuccess" width="570" >
            <v-card>
                <v-card-title class="text-h5 text-center">
                    <v-alert prominent type="success" width="100%">
                        <v-row align="center">
                            <v-col class="grow">
                                {{dialogMsg}}
                            </v-col>
                            <v-col class="shrink">
                            <v-btn outlined  @click="dialogSuccess = false">Ok</v-btn>
                            </v-col>
                        </v-row>
                    </v-alert>
                </v-card-title> 
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogAlert" width="570">
            <v-card>
                <v-card-title class="text-h5">
                    <v-alert prominent type="warning" outlined width="100%">
                        <v-row align="center">
                            <v-col class="grow">
                                {{dialogMsg}}
                            </v-col>
                            <v-col class="shrink">
                            <v-btn color="warning"  @click="dialogAlert = false">Ok</v-btn>
                            </v-col>
                        </v-row>
                    </v-alert>
                </v-card-title> 
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { getAllCiclos, getCiclosVigentes } from '../api/ciclo';
import { getGratificacoes } from '../api/gratificacao';
import AvaliacaoCadastradaDialog from '../components/AvaliacaoCadastradaDialog.vue';
import { getRelatorioAvaliacoesCadastradas, deleteAvaliacaoCadastrada, getAvaliacaoByAvaliado } from '../api/avaliacao';
import { getTotalRelatorioAvaliacoesCadastradas } from '../api/avaliacao';
import { getOrgaos } from '../api/orgao';
import { getOrgaoGestorOrgao } from '../api/orgao';


export default {
    name: 'AvaliacoesCadastradasListar',
    components: {
        AvaliacaoCadastradaDialog        
    },
    data() {
        return {
            ciclos: [],
            gratificacoes: [],  
            filtros: { },
            headers: [
                { text: 'Nome do avaliado', value: 'nm_avaliado', sortable: false},
                { text: 'CPF do avaliado', value: 'nr_cpf_avaliado', sortable: false},
                { text: 'Gratificação do avaliado', value: 'sg_gratificacao' , sortable: false},
                { text: 'Nome do avaliador', value: 'nm_avaliador', sortable: false},
                { text: 'CPF do avaliador', value: 'nr_cpf_avaliador', sortable: false},
                { text: 'Órgão', value: 'sg_sigla', sortable: false},
                { text: 'Ações', value: 'acao', align: 'center', sortable: false}
            ],
            avaliacoesCadastradas: [],
            pagination: {
                current: 1,
                total: 0,
                page: 1,
                itemsPerPage: 30
            },
            dialogDelete:false,
            dialogSuccess: false,
            itemDelete: null,
            dialogAlert: false,
            dialogMsg:'',
            justificativa:'',
            dialogPossuiAvaliacao:false,
            isGestorOrgao: false,
            orgaos: [],         
        };
    },
    mounted() {
        if (
            this.$store.getters['auth/usuarioAutenticadoRoles'].indexOf("gestorOrgao") >= 0
            && this.$store.getters['auth/usuarioAutenticadoRoles'].indexOf("administrador") < 0
        ) {
            this.isGestorOrgao = true;
            getOrgaoGestorOrgao().then(response => { 
                this.orgaos = response.data.data;
                if ( response.data.data.length === 1 ) {
                    this.filtros.cd_orgao = this.orgaos.find(org => org.cd_orgao == response.data.data[0].cd_orgao);
                } 
            });
        } else {
            getOrgaos().then(response => {
                this.orgaos = response.data.data; 
                this.orgaos.map(org =>{
                    org.text = org.sg_sigla + ' - ' + org.nm_orgao
                    org.value = org.cd_orgao
                })
                this.orgaos.unshift({cd_orgao: -1, nm_orgao: 'Todos'});
            });
        }

        getAllCiclos().then(response => { 
            this.ciclos = response.data.data; 
        });

        getGratificacoes().then(response => {
            this.gratificacoes = response.data.data;
            this.gratificacoes = _.orderBy(this.gratificacoes, 'sg_gratificacao');
            this.gratificacoes.unshift({id_gratificacao: -1, sg_gratificacao: 'Todas'});
        });
    },
    methods: {
        modalAvaliacaoCadastrada(servidor) {
            getCiclosVigentes(this.$store.getters['auth/usuarioAutenticado']).then(response => {
                 if ( response.data.data.length > 0 ) {
                    this.$refs.avaliacaoCadastradaDialog.showDialog(servidor);
                } else {
                    this.dialogMsg = "Não há ciclo vigente, cadastro não permitido.";
                    this.dialogAlert = true;
                }
            });
        },

        getFiltros() {
            var filtros = [];
            var obj = {};

            if ( this.filtros.id_ciclo ) {
                obj['id_ciclo'] = this.filtros.id_ciclo;
            }

            if( this.filtros.nm_avaliado ) {
                obj['nm_avaliado'] = this.filtros.nm_avaliado.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase();
            }

            if ( this.filtros.nr_cpf_avaliado ) {
                obj['nr_cpf_avaliado'] = this.filtros.nr_cpf_avaliado.replace(/[-.]/g,'');
            }

            if ( this.filtros.nm_avaliador ) {
                obj['nm_avaliador'] = this.filtros.nm_avaliador.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase();
            }

            if ( this.filtros.nr_cpf_avaliador ) {
                obj['nr_cpf_avaliador'] = this.filtros.nr_cpf_avaliador.replace(/[-.]/g,'');
            }

            if ( this.filtros.id_gratificacao ) {
                obj['id_gratificacao'] = this.filtros.id_gratificacao;
            }
            
            if ( 
                typeof this.filtros.cd_orgao === 'object' 
                && this.filtros.cd_orgao.cd_orgao !== -1
            ) {
                obj['cd_orgao'] = this.filtros.cd_orgao.cd_orgao;
            } 

            filtros.push(obj);
            return filtros;
        },

        getAvaliacoesCadastradas() {
            if ( this.$refs['form_ciclo'].validate() ) {

                if (this.isGestorOrgao && this.filtros.cd_orgao === undefined) {
                    this.dialogMsg = "Campo órgão obrigatório";
                    return this.dialogAlert = true;
                }

                var filtros = [];
                filtros = this.getFiltros();

                getRelatorioAvaliacoesCadastradas(
                    this.pagination.page, 
                    this.pagination.itemsPerPage, 
                    filtros
                ).then(response => { 
                    this.avaliacoesCadastradas = response.data.data;
                }).catch(erro => {
                    this.dialogMsg = "Erro ao filtrar";
                    this.dialogAlert = true;
                });

                getTotalRelatorioAvaliacoesCadastradas(filtros).then(response => { 
                    this.pagination.total = response.data;
                })
            }
        },

        showDialogDelete(item) {
            this.itemDelete = item;
            getAvaliacaoByAvaliado(item.id_avaliado).then(res => {
                if (res.data.data) {
                    this.dialogPossuiAvaliacao = true;
                } else {
                    this.dialogDelete = true;
                }
            }).catch(erro => {
                this.dialogMsg = "Erro ao tentar excluir";
                this.dialogAlert = true;
            });
        },

        excluir(possuiAvaliacao = false) {
            let data = new FormData();
            data.append('id_avaliacao_cadastrada', this.itemDelete.id_avaliacao_cadastrada);

            if (possuiAvaliacao) {
                data.append('ds_justificativa', this.justificativa);
                data.append('id_avaliado', this.itemDelete.id_avaliado);

                if(this.justificativa == '') {
                    this.dialogMsg = "Por favor, insira uma justificativa explicando o motivo desta ação.";
                    return this.dialogAlert = true;                
                } 
            }
            
            deleteAvaliacaoCadastrada(data).then(res => { 
                this.getAvaliacoesCadastradas();
                this.dialogDelete= false;
                this.dialogPossuiAvaliacao = false;
                this.dialogMsg = "Feito com sucesso."
                this.dialogSuccess = true;
            }).catch(E => {
                this.dialogMsg = "Erro ao excluir.";
                this.dialogAlert = true;
            }); 
        }, 
    }
}
</script>
<template>
    <div>
        <h1>Relatório de avaliações de desempenho</h1>
        <v-row>
            <v-col cols="12">
                <v-card>
                    <v-form class="mt-5" ref="form_ciclo">
                        <v-row dense class="ma-0 pa-2">
                            <v-col cols="6" class="mx-0 px-3">
                                <v-select
                                    id="ciclo"
                                    :items="ciclos"
                                    v-model="filtros.id_ciclo"
                                    item-text="nm_ciclo"
                                    item-value="id_ciclo"
                                    label="Ciclo"
                                    outlined
                                    dense
                                    hide-details="auto"
                                    required 
                                    :rules="[() => !!filtros.id_ciclo || 'Campo obrigatório.']"
                                    v-on:change="carregarUnidade(`${filtros.id_ciclo}`,`${filtros.cd_orgao}`)"
                                ></v-select>
                            </v-col>
                            <v-col cols="6" class="mx-0 pt-0 float-right">
                                <template>
                                    <v-radio-group v-model="filtros.servidorAtivo" row class="float-right" id="servidorAtivo">
                                        <template v-slot:label>
                                            <div>Situação do <strong>servidor avaliado:</strong></div>
                                        </template>
                                        <v-radio value="ativo">
                                            <template v-slot:label>
                                            <div><strong class="primary--text">Ativo</strong></div>
                                            </template>
                                        </v-radio>
                                        <v-radio value="inativo">
                                            <template v-slot:label>
                                            <div><strong class="error--text">Inativo</strong></div>
                                            </template>
                                        </v-radio>
                                    </v-radio-group>
                                </template>
                            </v-col>
                        </v-row>
                    </v-form>
                    
                    <v-row dense class="ma-0 pa-2">
                        <v-col cols="3" class="mx-0 px-3">
                            <v-text-field id="siape" ref="siape" dense hide-details="auto" label="SIAPE Servidor Avaliado" v-model="filtros.nr_siape" v-mask="'#######'" outlined clearable clear-icon="mdi-close-circle-outline" @keyup.enter="getServidoresFiltrados()">
                            </v-text-field>
                        </v-col>
                        <v-col cols="3" class="mx-0 px-3">
                            <v-text-field id="cpfAvaliado" ref="cpf" dense hide-details="auto" label="CPF Servidor Avaliado" v-model="filtros.nr_cpf" v-mask="'###.###.###-##'" outlined clearable clear-icon="mdi-close-circle-outline" @keyup.enter="getServidoresFiltrados()">
                            </v-text-field>
                        </v-col>
                        <v-col cols="6" class="mx-0 px-3">
                            <v-text-field id="nomeAvaliado" ref="nome" dense hide-details="auto" label="Nome Servidor Avaliado" v-model="filtros.nm_servidor" outlined clearable clear-icon="mdi-close-circle-outline" @keyup.enter="getServidoresFiltrados()">
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row dense class="ma-0 pa-2">
                        <v-col cols="3" v-if="filtros.id_ciclo != 1" class="mx-0 px-3">
                            <v-select
                                id="uf"
                                :items="ufs"
                                v-model="filtros.id_uf"
                                item-value="id_uf"
                                item-text="sg_uf"
                                label="UF"
                                outlined
                                dense
                                hide-details="auto"
                            ></v-select>
                        </v-col>
                        <v-col cols="6" v-if="filtros.id_ciclo == 1" class="mx-0 px-3">
                            <v-select
                                id="unidade"
                                :items="unidades"
                                v-model="filtros.id_unidade_avaliacao"
                                item-value="id_unidade_avaliacao"
                                item-text="nm_unidade_avaliacao"
                                label="Unidade de Avaliação"
                                outlined
                                dense
                                hide-details="auto"
                                v-on:change="carregarPlanosTrabalho(`${filtros.id_unidade_avaliacao}`,true)"
                            ></v-select>
                        </v-col>
                        <v-col cols="3" v-if="filtros.id_ciclo != 1" class="mx-0 px-3">
                            <v-select
                                id="unidade"
                                :items="unidades"
                                v-model="filtros.id_unidade_avaliacao"
                                item-value="id_unidade_avaliacao"
                                item-text="nm_unidade_avaliacao"
                                label="Unidade de Avaliação"
                                outlined
                                dense
                                hide-details="auto"
                                v-on:change="carregarPlanosTrabalho(`${filtros.id_unidade_avaliacao}`,true)"
                            ></v-select>
                        </v-col>
                        <v-col cols="3" class="mx-0 px-3">
                            <v-select
                                id="orgao"
                                :items="orgaos"
                                v-model="filtros.cd_orgao"
                                item-value="cd_orgao"
                                item-text="nm_orgao"
                                label="Órgão"
                                outlined
                                dense
                                hide-details="auto"
                                v-on:change="carregarUnidade(`${filtros.id_ciclo}`,`${filtros.cd_orgao}`)"
                            ></v-select>
                        </v-col>
                        <v-col cols="3" class="mx-0 px-3">
                            <v-combobox
                                id="planoTrabalho"
                                ref="planoTrabalho"
                                :items="planosTrabalho"
                                v-model="filtros.id_plano_trabalho"
                                hide-details
                                outlined 
                                dense
                                label="Plano de trabalho"
                                placeholder="Digite o plano de trabalho"
                                ></v-combobox>
                        </v-col>
                    </v-row>
                    <v-row dense class="ma-0 pa-2">
                        <v-col cols="3" class="mx-0 px-3">
                            <v-text-field id="siapeAvaliador" ref="siape" dense hide-details="auto" label="SIAPE Servidor Avaliador" v-model="filtros.nr_siape_avaliador" v-mask="'#######'" outlined clearable clear-icon="mdi-close-circle-outline" @keyup.enter="getServidoresFiltrados()">
                            </v-text-field>
                        </v-col>
                        <v-col cols="3" class="mx-0 px-3">
                            <v-text-field id="cpfAvaliador" ref="cpf" dense hide-details="auto" label="CPF Servidor Avaliador" v-model="filtros.nr_cpf_avaliador" v-mask="'###.###.###-##'" outlined clearable clear-icon="mdi-close-circle-outline" @keyup.enter="getServidoresFiltrados()">
                            </v-text-field>
                        </v-col>
                        <v-col cols="6" class="mx-0 px-3">
                            <v-text-field id="nomeAvaliador" ref="nome" dense hide-details="auto" label="Nome Servidor Avaliador" v-model="filtros.nome_avaliador" outlined clearable clear-icon="mdi-close-circle-outline" @keyup.enter="getServidoresFiltrados()">
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row dense class="ma-0 pa-2">
                        <v-col cols="3" class="mx-0 px-3">
                            <v-select
                                id="gratificacao"
                                :items="gratificacoes"
                                v-model="filtros.id_gratificacao"
                                item-value="id_gratificacao"
                                item-text="sg_gratificacao"
                                label="Gratificação "
                                outlined
                                dense
                                hide-details="auto"
                            ></v-select>
                        </v-col>
                        <v-col cols="3" class="mx-0 px-3">
                            <v-combobox
                                id="funcao"
                                ref="funcoes"
                                :items="funcoes"
                                v-model="filtros.funcao"
                                outlined
                                dense
                                placeholder="Digite a função"
                                ></v-combobox>
                        </v-col>
                        <v-col cols="2" class="mx-0 px-3">
                            <v-select
                                id="avaliado"
                                :items="avaliado"
                                v-model="filtros.in_avaliado"
                                label="Avaliado"
                                outlined
                                dense
                                hide-details="auto"
                            ></v-select>
                        </v-col>
                        <v-col cols="2" class="mx-0 px-3">
                            <v-select
                                id="reconsideracao"
                                :items="reconsideracao"
                                v-model="filtros.in_reconsideracao"
                                label="Reconsideração"
                                outlined
                                dense
                            ></v-select>
                        </v-col>
                        <v-col cols="2" class="mx-0 px-3">
                            <v-select
                                id="tipoAvaliacao"
                                :items="tipoAvaliacao"
                                v-model="filtros.in_tipo_avaliacao"
                                label="Tipo de avaliação"
                                outlined
                                dense
                            ></v-select>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="10" class="text-right"></v-col>
                        <v-col cols="2" class="ma-0 mb-4 pa-2 px-8 d-flex flex-row justify-end align-end text-right elevation-0">
                            <v-btn id="btnFiltrar" color="primary" @click="gerarRelatorioAvaliacao()">
                                <v-icon>mdi-magnify</v-icon> Filtrar
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
        <br />
        <v-row v-show="tableResult">
            <v-col cols="12">
                <v-data-table
                    :headers="headers"
                    :items="relatorioAvaliacao"
                    :items-per-page="pagination.itemsPerPage"
                    :footer-props="{'items-per-page-options':[30, 50, 100]}"
                    :options.sync="pagination" 
                    :server-items-length="pagination.total"
                    @update:options="gerarRelatorioAvaliacao"
                    class="elevation-1"
                >
                    <template v-slot:[`item.acao`]="{ item }">
                        <!-- <v-row justify="center" v-if="item.nr_pontos && item.id_equipe_trabalho">
                            <v-tooltip top>
                                <template v-slot:activator="{on, attrs}">
                                    <v-btn id="btnVisualizar" icon small :color="'primary'" v-bind="attrs" v-on="on" :to="{ name: 'detalheAvaliacaoServidor', params: { id: item.id_equipe_trabalho, tipo: 'i' } }">
                                        <v-icon> mdi-eye </v-icon>
                                    </v-btn>
                                </template>
                                <span>Resultado da avaliação de desempenho</span>
                            </v-tooltip>
                        </v-row>
                        <v-row justify="center" v-if="item.nr_pontos && item.id_equipe_trabalho_externo">
                            <v-tooltip top>
                                <template v-slot:activator="{on, attrs}">
                                    <v-btn id="btnVisualizar" icon small :color="'primary'" v-bind="attrs" v-on="on" :to="{ name: 'detalheAvaliacaoServidor', params: { id: item.id_equipe_trabalho_externo, tipo: 'e' } }">
                                        <v-icon> mdi-eye</v-icon>
                                    </v-btn>
                                </template>
                                <span>Resultado da avaliação de desempenho</span>
                            </v-tooltip>
                        </v-row>
                        <v-row justify="center" v-if="item.nr_pontos && item.id_avaliacao_cadastrada">
                            <v-tooltip top>
                                <template v-slot:activator="{on, attrs}">
                                    <v-btn id="btnVisualizar" icon small :color="'primary'" v-bind="attrs" v-on="on" :to="{ name: 'detalheAvaliacaoServidor', params: { id: item.id_avaliacao_cadastrada, tipo: 'a' } }">
                                        <v-icon> mdi-eye</v-icon>
                                    </v-btn>
                                </template>
                                <span>Resultado da avaliação de desempenho</span>
                            </v-tooltip>
                        </v-row>
                        <v-row justify="center" v-if="item.nr_pontos && item.id_servidor_afastado">
                            <v-tooltip top>
                                <template v-slot:activator="{on, attrs}">
                                    <v-btn id="btnVisualizar" icon small :color="'primary'" v-bind="attrs" v-on="on" :to="{ name: 'detalheAvaliacaoServidor', params: { id: item.id_avaliacao_consolidada, tipo: 'af' } }">
                                        <v-icon> mdi-eye</v-icon>
                                    </v-btn>
                                </template>
                                <span>Resultado da avaliação de desempenho</span>
                            </v-tooltip>
                        </v-row> -->
                        <v-row justify="center" v-if="item.nr_pontos">
                            <v-tooltip top>
                                <template v-slot:activator="{on, attrs}">
                                    <v-btn id="btnVisualizar" icon small :color="'primary'" v-bind="attrs" v-on="on" :to="{ name: 'detalheAvaliacaoServidor', params: { idCiclo: filtros.id_ciclo, idConsulta: item.idConsulta, sglTipo: item.sglTipo }}">
                                        <v-icon> mdi-eye</v-icon>
                                    </v-btn>
                                </template>
                                <span>Resultado da avaliação de desempenho</span>
                            </v-tooltip>
                        </v-row>
                        <v-row justify="center" v-else-if="booConsolidada">
                            <v-tooltip top>
                                <template v-slot:activator="{on, attrs}">
                                    <v-btn id="btnSemAvaliacao" icon small :color="'error'" v-bind="attrs" v-on="on" :to="{ name: 'detalheSemAvaliacaoServidor', params: { idCiclo: filtros.id_ciclo, nr_cpf: item.nr_cpf.replace(/[-.]/g,'') }}">
                                        <v-icon> mdi-eye</v-icon>
                                    </v-btn>
                                </template>
                                <span>Servidor sem avaliação</span>
                            </v-tooltip>
                        </v-row>
                        <v-row justify="center" v-else>
                            <v-tooltip top>
                                <template v-slot:activator="{on, attrs}">
                                    <v-btn id="btnVisualizar" icon small :color="'primary'" v-bind="attrs" v-on="on" >
                                        <v-icon> mdi-eye-off </v-icon>
                                    </v-btn>
                                </template>
                                <span>Sem avaliação consolidada</span>
                            </v-tooltip>
                        </v-row>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
        <v-row v-if="pagination.total">
            <v-col cols="10" class="text-right"></v-col>
            <v-col cols="2" class="ma-0 mb-6 pa-2 px-4 d-flex flex-row justify-end align-end text-right elevation-0">
                <v-btn color="success" @click="exportarRelatorioAvaliacao()">
                    <v-icon>mdi-download</v-icon> Exportar para excel
                </v-btn>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    // Lista de ciclos
    import { getAllCiclos } from '../api/ciclo';

    // Lista UF
    import { getUFs } from '../api/uf';

    // Lista gratificacoes
    import { getGratificacoes } from '../api/gratificacao';

    // Lista funcoes
    import { getFuncoes } from '../api/funcao';

    // Lista unidade avaliacao
    import { getUnidadeAvaliacaoByCicloOrgao } from '../api/unidade_avaliacao';

    // Lista plano de trabalho
    import { getPlanoTrabalhoByUnidadeAvaliacao } from '../api/plano_trabalho';

    import { gerarRelatorioAvaliacaoFiltrados,  gerarExtracaoRelatorioAvaliacao } from '../api/avaliacao';

    import { getOrgaoGestorOrgao } from '../api/orgao';

    export default {
        name: 'RelatorioAvaliacaoListar',
        data() {
            return {
                ciclos: [],
                gratificacoes: [],
                ufs: [],
                funcoes: [],
                unidades: [],
                planosTrabalho: [],
                orgaos: [],

                avaliado: [
                    { text: 'Todos', value: -1},
                    { text: 'Sim', value: 1},
                    { text: 'Não', value: 2}
                ],
                reconsideracao: [
                    { text: 'Todos', value: -1},
                    { text: 'Sim', value: 1},
                    { text: 'Não', value: 2}
                ],
                tipoAvaliacao: [
                    { text: 'Todas', value: -1},
                    { text: 'Avaliação padrão', value: 1},
                    { text: 'Avaliação dos servidores ativos em outro órgão', value: 2},
                    { text: 'Avaliação cadastrada pelo Administrador', value: 3},
                    { text: 'Avaliação de nota inserida diretamente pelo administrador', value: 4},
                    { text: 'Notas repetidas por afastamento', value: 5},
                    { text: 'Repetida com ultima avaliação', value: 6},
                ],

                tableResult: false,
                relatorioAvaliacao: [],
                colUf: '',
                headers: [],
                pagination: {
                    current: 1,
                    total: 0,
                    page: 1,
                    itemsPerPage: 30
                },
                filtros: { 
                    servidorAtivo: 'ativo'
                },

                booConsolidada: false,
            };
        },
        mounted() {
            getAllCiclos()
                .then(response => { 
                    this.ciclos = response.data.data; 
                });

            getUFs()
                .then(response => { 
                    this.ufs = response.data.data; 
                    this.ufs.unshift({id_uf: -1, sg_uf: 'Todas'});
            });

            getGratificacoes()
                .then(response => {
                    this.gratificacoes = response.data.data;
                    this.gratificacoes = _.orderBy(this.gratificacoes, 'sg_gratificacao');
                    this.gratificacoes.unshift({id_gratificacao: -2, sg_gratificacao: 'Sem gratificação'});
                    this.gratificacoes.unshift({id_gratificacao: -3, sg_gratificacao: 'Com gratificação'});
                    this.gratificacoes.unshift({id_gratificacao: -1, sg_gratificacao: 'Todas'});
            });

            getFuncoes()
                .then(response => { 
                    this.funcoes = response.data.data; 
                    this.funcoes.sort((a,b) => {   return a.nm_funcao.localeCompare(b.nm_funcao);});
                    this.funcoes.map(func =>{
                        func.text = func.cd_funcao + ' - ' + func.nm_funcao
                        func.value = func.id_funcao
                        func.nm_funcao_com_sigla = func.cd_funcao + ' - ' + func.nm_funcao
                    })
                    this.funcoes.unshift({text: 'Qualquer', value: -1, id_funcao: -1})                   
            });

            getOrgaoGestorOrgao()
                .then(response => { 
                    this.orgaos = response.data.data;
                    if ( response.data.data.length === 1 ) {
                        this.filtros.cd_orgao = this.orgaos.find(org => org.cd_orgao == response.data.data[0].cd_orgao);
                    } else {
                        this.orgaos.unshift({cd_orgao: -1, nm_orgao: 'Todos'});
                    }
            });
        },
        methods: { 
            getFiltros(){
                var filtros = [];
                var obj = {};

                if ( this.filtros.id_ciclo ) {
                    obj['id_ciclo'] = this.filtros.id_ciclo;
                }

                // Filtro SIAPE
                if ( this.filtros.nr_siape ) {
                    obj['nr_siape'] = this.filtros.nr_siape;
                }

                // Filtro CPF
                if ( this.filtros.nr_cpf ) {
                    obj['nr_cpf'] = this.filtros.nr_cpf.replace(/[-.]/g,'');
                }

                // Filtro nome do servidor
                if( this.filtros.nm_servidor ) {
                    obj['nm_servidor'] = this.filtros.nm_servidor.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase();
                }

                // Filtro UF
                if( this.filtros.id_uf ) {
                    obj['id_uf'] = this.filtros.id_uf;
                }

                // Filtro funcao
                if( typeof this.filtros.funcao === 'object' ) {
                    obj['funcao'] = this.filtros.funcao;
                } else {
                    this.$refs.funcoes.lazyValue = undefined;
                }

                // Filtro gratificacao
                if ( this.filtros.id_gratificacao ) {
                    obj['id_gratificacao'] = this.filtros.id_gratificacao;
                }

                // Filtro unidade de avaliacao
                if ( this.filtros.id_unidade_avaliacao ) {
                    obj['id_unidade_avaliacao'] = this.filtros.id_unidade_avaliacao;
                }

                // Filtro UF
                if ( this.filtros.id_uf ) {
                    obj['id_uf'] = this.filtros.id_uf;
                }

                // Filtro avaliado
                if ( this.filtros.in_avaliado ) {
                    obj['in_avaliado'] = this.filtros.in_avaliado;
                }

                // Filtro reconsideracao
                if ( this.filtros.in_reconsideracao ) {
                    obj['in_reconsideracao'] = this.filtros.in_reconsideracao;
                }

                // Filtro SIAPE Avaliador
                if ( this.filtros.nr_siape_avaliador ) {
                    obj['nr_siape_avaliador'] = this.filtros.nr_siape_avaliador;
                }

                // Filtro CPF avaliador
                if ( this.filtros.nr_cpf_avaliador ) {
                    obj['nr_cpf_avaliador'] = this.filtros.nr_cpf_avaliador.replace(/[-.]/g,'');
                }

                // Filtro nome avaliador
                if ( this.filtros.nome_avaliador ) {
                    obj['nome_avaliador'] = this.filtros.nome_avaliador.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase();
                }

                // Filtro plano de trabalho
                if ( typeof this.filtros.id_plano_trabalho === 'object' ) {
                    obj['id_plano_trabalho'] = this.filtros.id_plano_trabalho;
                } else {
                    this.$refs.planoTrabalho.lazyValue = undefined;
                }

                // Filtro tipo avaliacao
                if ( this.filtros.in_tipo_avaliacao && this.filtros.in_tipo_avaliacao !== -1 ) {
                    obj['in_tipo_avaliacao'] = this.filtros.in_tipo_avaliacao;
                }

                // Filtro servidor ativo ou inativo
                if ( this.filtros.servidorAtivo ) {
                    obj['situacao_avaliado'] = this.filtros.servidorAtivo;
                }

                // Filtro orgao
                if ( typeof this.filtros.cd_orgao === 'object' ) {
                    obj['cd_orgao'] = this.filtros.cd_orgao.cd_orgao;
                } else {
                    obj['cd_orgao'] = this.filtros.cd_orgao;
                }

                filtros.push(obj);

                return filtros;
            },

            gerarRelatorioAvaliacao() {
                if ( this.$refs['form_ciclo'].validate() ) {
                    this.tableResult = true;

                    var filtros = [];
                    filtros = this.getFiltros();

                    gerarRelatorioAvaliacaoFiltrados(this.pagination.page, this.pagination.itemsPerPage, filtros).then(response => { 
                        this.relatorioAvaliacao = response.data.data.avaliacoes;

                        this.pagination.total = response.data.data.total;
                        if ( this.pagination.total < 30 ) {
                            this.pagination.page = 1;
                        }

                        this.booConsolidada = response.data.data.booConsolidada;
                    });
                }

                if (this.filtros.id_ciclo != 1) {
                    this.colUf = { text: 'UF da unidade do plano', value: 'sg_uf', sortable: false , id:"ufs"};
                }

                this.headers = [
                    { text: 'CPF do avaliado', value: 'nr_cpf', sortable: false, width: '130px' },
                    { text: 'Nome do avaliado', value: 'nm_servidor', sortable: false },
                    { text: 'Órgão', value: 'nm_orgao', sortable: false },
                    { text: 'Nome do plano de trabalho', value: 'plano_trabalho', sortable: false },
                    this.colUf,
                    { text: 'Gratificação do avaliado', value: 'sg_gratificacao', sortable: false },
                    { text: 'Tipo de avaliação', value: 'str_avaliacao', sortable: false },
                    { text: 'Pontuação do avaliado', value: 'nr_pontos', sortable: false },
                    { text: 'Ações', value: 'acao', align: 'center', sortable: false },
                ];
            },

            carregarUnidade(id_ciclo,cd_orgao) {
                if (this.filtros.id_ciclo == 1) {
                    this.filtros.id_uf = undefined;
                }

                if ( id_ciclo !== undefined && cd_orgao !== 'undefined' ) {
                    getUnidadeAvaliacaoByCicloOrgao(id_ciclo,cd_orgao)
                        .then(response => { 
                            this.unidades = response.data.data; 
                            this.unidades = _.orderBy(this.unidades, 'nm_unidade_avaliacao');
                            this.unidades.unshift({id_unidade_avaliacao: -1, nm_unidade_avaliacao: 'Todas'});
                    });
                }
            },

            carregarPlanosTrabalho(id_unidade_avaliacao,booClear = false) {
                getPlanoTrabalhoByUnidadeAvaliacao(id_unidade_avaliacao)
                    .then(response => { 
                        if ( booClear ) {
                            this.$refs.planoTrabalho.internalSearch = '';
                            this.filtros.id_plano_trabalho = undefined;
                            this.planosTrabalho = []; 
                        }

                        this.planosTrabalho = response.data.data; 
                        this.planosTrabalho.map(plano =>{
                            plano.text = plano.nm_unidade
                            plano.value = plano.id_plano_trabalho
                        })
                        this.planosTrabalho.unshift({text: 'Todos', value: -1, id_funcao: -1})                   
                });
            },

            exportarRelatorioAvaliacao() {
                var filtros = [];
                filtros = this.getFiltros();

                gerarExtracaoRelatorioAvaliacao(filtros)
                    .then(response => {
                        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                        var fileLink = document.createElement('a');
                        fileLink.href = fileURL;
                        fileLink.setAttribute('download', 'Relatório de avaliações de desempenho.xlsx');
                        document.body.appendChild(fileLink);
                        fileLink.click();
                    });
            },
        }
    }
</script>

<template>
<v-form v-model="valid">
    <div>
        <h1>Editar avaliadores/Avaliados externos</h1>
        <v-col cols="12">
            <v-card>
                <h3 class="pa-5 primary--text">Avaliador</h3>
                <v-row dense class="ma-0 pa-2">
                    <v-col cols="3" class="mx-0 px-3">
                        <v-text-field id="cpf_avaliador" ref="cpf" dense hide-details="auto" label="CPF" v-model="avaliador.servidor_externo.nr_cpf" v-mask="'###.###.###-##'" outlined clearable clear-icon="mdi-close-circle-outline" :rules="validaCampo" v-on:blur="getAvaliadorByCPF(`${avaliador.servidor_externo.nr_cpf}`)"></v-text-field>
                    </v-col>
                    <v-col cols="9" class="mx-0 px-3">
                        <v-text-field id="nome_avaliador"  ref="nome_avaliador" dense hide-details="auto" label="Nome" outlined clearable clear-icon="mdi-close-circle-outline" v-model="avaliador.servidor_externo.nm_servidor" :rules="validaCampo" :disabled="nomeDisabled"></v-text-field>
                    </v-col>
                </v-row>
                <v-row dense class="ma-0 pa-2">
                    <v-col cols="6" class="mx-0 px-3">
                        <v-text-field id="orgao_lotacao_avaliador" v-model="avaliador.servidor_externo.nm_orgao_lotacao" ref="orgao_lotacao_avaliador" dense hide-details="auto" label="Órgão de exercício" outlined clearable clear-icon="mdi-close-circle-outline" :rules="validaCampo" :disabled="orgaoDisabled"></v-text-field>
                    </v-col>
                    <v-col cols="6" class="mx-0 px-3">
                        <v-text-field id="unidade_avaliador" v-model="avaliador.servidor_externo.nm_unidade_exercicio" ref="unidade_avaliador" dense hide-details="auto" label="Unidade de exercício" outlined clearable clear-icon="mdi-close-circle-outline" :rules="validaCampo" :disabled="unidadeDisabled"></v-text-field>
                    </v-col>
                </v-row>
                <v-row dense class="ma-0 pa-2">
                    <v-col cols="6" class="mx-0 px-3">
                        <v-text-field id="cargo_avaliador" v-model="avaliador.servidor_externo.nm_cargo" ref="cargo_avaliador" dense hide-details="auto" label="Cargo" outlined clearable clear-icon="mdi-close-circle-outline" :rules="validaCampo" :disabled="cargoDisabled"></v-text-field>
                    </v-col>
                    <v-col cols="6" class="mx-0 px-3">
                        <v-text-field id="funcao_avaliador" v-model="avaliador.servidor_externo.nm_funcao" ref="funcao_avaliador" dense hide-details="auto" label="Função" outlined clearable clear-icon="mdi-close-circle-outline" :rules="validaCampo" :disabled="funcaoDisabled"></v-text-field>
                    </v-col>
                </v-row>
                <v-row dense class="ma-0 pa-2">
                    <v-col cols="6" class="mx-0 px-3">
                        <v-text-field id="telefone_avaliador" v-model="avaliador.servidor_externo.nr_telefone_pessoal" ref="telefone_avaliador" dense hide-details="auto" label="Telefone" v-mask="'(##) ####-#####'" outlined clearable clear-icon="mdi-close-circle-outline" :rules="validaCampo"></v-text-field>
                    </v-col>
                    <v-col cols="6" class="mx-0 px-3">
                        <v-text-field id="email_avaliador" v-model="avaliador.servidor_externo.ds_email_institucional" ref="email_avaliador" dense hide-details="auto" label="E-mail institucional" outlined clearable clear-icon="mdi-close-circle-outline" :rules="validaEmail"></v-text-field>
                    </v-col>
                </v-row>
                <h3 class="pa-5 primary--text">Avaliado</h3>
                <v-row dense class="ma-0 pa-2">
                    <v-col cols="3" class="mx-0 px-3">
                        <v-text-field id="cpf_avaliado" dense hide-details="auto" label="CPF" v-model="avaliador.servidor.nr_cpf" v-mask="'###.###.###-##'" outlined clearable clear-icon="mdi-close-circle-outline" disabled></v-text-field>
                    </v-col>
                    <v-col cols="9" class="mx-0 px-3">
                        <v-text-field id="nome_avaliado" dense hide-details="auto" label="Nome" outlined clearable clear-icon="mdi-close-circle-outline" v-model="avaliador.servidor.nm_servidor" disabled></v-text-field>
                    </v-col>
                </v-row>
                <v-row dense class="ma-0 pa-2">
                    <v-col cols="3" class="mx-0 px-3">
                        <v-text-field id="telefone_avaliado" v-model="avaliador.servidor.nr_telefone" dense hide-details="auto" label="Telefone" v-mask="'(##) ####-#####'" outlined clearable clear-icon="mdi-close-circle-outline" disabled></v-text-field>
                    </v-col>
                    <v-col cols="5" class="mx-0 px-3">
                        <v-text-field id="email_pessoal_avaliado" v-model="avaliador.servidor.ds_email_pessoal" dense hide-details="auto" label="E-mail pessoal" outlined clearable clear-icon="mdi-close-circle-outline" disabled></v-text-field>
                    </v-col>
                    <v-col cols="4" class="mx-0 px-3">
                        <v-text-field id="email_insitucional_avaliado" v-model="avaliador.servidor.ds_email_institucional" dense hide-details="auto" label="E-mail institucional" outlined clearable clear-icon="mdi-close-circle-outline" disabled></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="10" class="text-right"></v-col>
                    <v-col cols="2" class="ma-0 mb-4 pa-2 px-8 d-flex flex-row justify-end align-end text-right elevation-0">
                        <v-btn id="btnSalvar" color="success" @click="enviarDados()">
                            Salvar
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card>
        </v-col>
    </div>

    <v-dialog v-model="dialogError" width="570" >
        <v-card>
            <v-card-title class="text-h5 text-center">
                <v-alert prominent type="warning" outlined width="100%">
                    <v-row align="center">
                        <v-col class="grow">{{msgDialog}}</v-col>
                        <v-col class="shrink">
                        <v-btn color="warning"  @click="dialogError = false">Ok</v-btn>
                        </v-col>
                    </v-row>
                </v-alert>
            </v-card-title> 
        </v-card>
    </v-dialog>
</v-form>
</template>

<script>
    import ComumService from '../services/comum.service'
    import { getServidorExternoInAvaliaInSiapeByCpf } from '../api/servidor_externo';
    import { getAvaliadorAvaliado } from '../api/avaliadores_avaliados';
    import { updateAvaliadorAvaliado } from '../api/equipe_trabalho_externo';

    export default {
        name: 'AvaliadoresAvaliadosExternoEditar',
        data() {
            return {
                valid: false,
                nomeDisabled:true,
                orgaoDisabled:true,
                cargoDisabled:true,
                funcaoDisabled:true,
                unidadeDisabled: true,

                avaliador: {
                    servidor_externo: {},
                    servidor: {}
                },

                dialogError : false,
                msgDialog: '',
                validaCampo: [
                    v => !!v || 'Campo obrigatório',
                ],
                validaEmail: [
                    v => !!v || 'E-mail é obrigatório',
                    v => /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail inválido',
                ],
            };
        },
        mounted() {
            getAvaliadorAvaliado(this.$route.params.id_equipe_trabalho_externo)
                .then(response => { 
                    this.avaliador = response.data.data; 
            });
        },
        methods: { 
            limparCamposServidor() {
                this.avaliador.servidor_externo.nr_cpf = '';
                this.avaliador.servidor_externo.nm_servidor = '';
                this.avaliador.servidor_externo.nm_orgao_lotacao = '';
                this.avaliador.servidor_externo.nm_unidade_exercicio = '';
                this.avaliador.servidor_externo.nm_cargo = '';
                this.avaliador.servidor_externo.nm_funcao = '';
                this.avaliador.servidor_externo.nr_telefone_pessoal = '';
                this.avaliador.servidor_externo.ds_email_institucional = '';

                this.nomeDisabled = false;
                this.orgaoDisabled = false;
                this.unidadeDisabled = false;
                this.cargoDisabled = false;
                this.funcaoDisabled = false;
            },

            getAvaliadorByCPF (cpf) {
                var cpfValido = ComumService.validaCPF(cpf);
                if (!cpfValido) {
                    this.limparCamposServidor();
                    this.msgDialog = "CPF informado está inválido!";
                    return this.dialogError = true;
                }

                var servidorExternoSiape = null;
                getServidorExternoInAvaliaInSiapeByCpf(cpf).then(response => {
                    servidorExternoSiape = response.data;
                    if ( servidorExternoSiape.data.length !== undefined ) {
                        this.limparCamposServidor();
                        this.msgDialog = servidorExternoSiape.message;
                        return this.dialogError = true;
                    } else {
                        this.avaliador.servidor_externo.id_servidor_externo = servidorExternoSiape.data.id_servidor_externo;
                        this.avaliador.servidor_externo.nr_cpf = cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
                        this.avaliador.servidor_externo.nm_servidor = servidorExternoSiape.data.nm_servidor;
                        this.avaliador.servidor_externo.nm_orgao_lotacao = servidorExternoSiape.data.nm_orgao_lotacao;
                        this.avaliador.servidor_externo.nm_unidade_exercicio = servidorExternoSiape.data.nm_unidade_exercicio;
                        this.avaliador.servidor_externo.nm_cargo = servidorExternoSiape.data.nm_cargo;
                        this.avaliador.servidor_externo.nm_funcao= servidorExternoSiape.data.nm_funcao;
                        this.avaliador.servidor_externo.nr_telefone_pessoal = servidorExternoSiape.data.nr_telefone_pessoal;
                        this.avaliador.servidor_externo.ds_email_institucional = servidorExternoSiape.data.ds_email_institucional;

                        this.nomeDisabled = servidorExternoSiape.data.nm_servidor == ''?false:true;
                        this.orgaoDisabled = servidorExternoSiape.data.nm_orgao_lotacao == ''?false:true;
                        this.unidadeDisabled = servidorExternoSiape.data.nm_unidade_exercicio == ''?false:true;
                        this.cargoDisabled = servidorExternoSiape.data.nm_cargo == ''?false:true;
                        this.funcaoDisabled = servidorExternoSiape.data.nm_funcao == ''?false:true;
                    }                    
                    }).catch(erro => {
                        this.limparCamposServidor();
                    });
            },

            enviarDados () {
                if ( this.valid ) {
                    this.avaliador.servidor_externo.nr_cpf = this.avaliador.servidor_externo.nr_cpf.replaceAll('.','').replaceAll('-','');

                    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.avaliador.servidor_externo.ds_email_institucional) == false) {
                        this.msgDialog = 'E-mail do avaliador está inválido';
                        return this.dialogError = true;
                    }
                
                    updateAvaliadorAvaliado(this.avaliador.id_equipe_trabalho_externo,JSON.stringify(this.avaliador.servidor_externo))
                        .then(response => {
                            this.$vuetify.goTo(0);
                            this.$router.push(
                                {
                                    name: 'Avaliadores/Avaliados externos',
                                },
                                () => this.$store.dispatch('alertas/show', {
                                    tipo: 'Sucesso',
                                    titulo: response.data.message,
                                })
                            );
                        })
                        .catch(erro => {
                            this.$vuetify.goTo(0);
                            this.$router.push(
                                {
                                    name: 'Avaliadores/Avaliados externos',
                                },
                                () => this.$store.dispatch('alertas/show', {
                                    tipo: 'Erro',
                                    titulo: 'Erro ao editar avaliador/avaliado.',
                                })
                            );
                        });
                }
            },
        }
    }
</script>

<template>
<div class="tree-menu">
    <div :style="indent">
        <v-sheet class="mt-0 d-flex flex-row elevation-0 rounded-lg" max-height="100" max-width="93%">
            <v-sheet class="elevation-0 hover">
                <v-list-item>
                    <template v-slot:default>
                        <v-sheet class="pa-2 elevation-0" color="rgb(0, 0, 0, 0.0)" min-width="30" @click="toggleChildren">
                            <i v-if="nodes.length >0" class="fa mt-2" :class="iconClasses"></i>
                        </v-sheet>
                        <v-list-item-action :style="showCheckboxStyle">
                            <v-checkbox v-model="isSelected" color="primary" @change="childrenSelected(id)"></v-checkbox>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title class="mx-2 text-wrap" elevation="10">{{label}}</v-list-item-title>
                        </v-list-item-content>
                    </template>
                </v-list-item>
            </v-sheet>
        </v-sheet>
        </v-sheet>
    </div>

    <tree-view v-if="showChildren" v-for="node in nodes" :nodes="node.children" :label="node.name" :depth="depth + 1" :key="node.id" :id="node.id" :selected="node.selected" @update:selected="emitToParentUpdateSelected" @update:selectedSet="emitToParentUpdateSelectedSet" @update:unidadesSelected="emitToParentUpdateUnidadesSelected" :checkbox="true">
    </tree-view>

</div>
</template>

<script>
export default {
    name: 'tree-view',
    props: ['nodes', 'label', 'depth', 'selected', 'id', 'checkbox', 'expanded'],
    data() {
        return {
            showCheckboxStyle: (this.checkbox === true) ? 'display:inline' : 'display:none',
            showChildren: this.expanded,
            isSelected: this.selected,
        }
    },
    computed: {
        iconClasses() {
            return {
                'fa-caret-right': !this.showChildren,
                'fa-caret-down': this.showChildren
            }

        },
        labelClasses() {
            return {
                'has-children': this.nodes
            }
        },
        display() {
            return (this.showChildren) ?
                'display:inline'
                //: 'display:inline';
                :
                'display:none';
        },
        indent() {
            return {
                transform: `translate(${this.depth * 35}px)`
            }
        },
        
    },
    watch: {
        selected: function (new_value) {
            //console.log('watch selected')
            this.isSelected = new_value
        }
    },
    methods: {
        toggleChildren() {
            this.showChildren = !this.showChildren;
        },
        childrenSelected(nodeId) {
            let startingShowChildrenState = this.showChildren;
            this.showChildren = false
            //console.log(nodeId + ' mudou para ' + this.isSelected)
            //console.log(this.nodes.length + ' filhos')
            this.$emit('update:selected', nodeId, this.isSelected)
            //let selectedIdsArray = [nodeId]
            const changeValue = (noArray, valueToSet) => {
                //console.log('children.length = ' + no.children.length)
                for (let node of noArray) {
                    //node.selected = valueToSet;
                    //selectedIdsArray.push(node.id)
                    //console.log(node.id + ' -> ' + node.selected)
                    changeValue(node.children, valueToSet)
                    //this.$emit('update:selected', node.id, this.isSelected)
                }
            
            }
            changeValue(this.nodes, this.isSelected)
            this.$emit('update:unidadesSelected')
            this.showChildren = startingShowChildrenState
            this.showChildren = false
        },
        emitToParentUpdateSelected(nodeId, new_value) {
            //console.log('vai emitir para cima... nodeId=' + nodeId + ' | new_value=' + new_value)
            this.$emit('update:selected', nodeId, new_value)
        },
        emitToParentUpdateSelectedSet(nodeIdArray, new_value) {
            //console.log('vai emitir para cima... nodeId=' + nodeId + ' | new_value=' + new_value)
            this.$emit('update:selectedSet', nodeIdArray, new_value)
        },
        emitToParentUpdateUnidadesSelected() {
            //console.log('vai emitir para cima... nodeId=' + nodeId + ' | new_value=' + new_value)
            this.$emit('update:unidadesSelected')
        }
    }
}
</script>

<style>
body {
    font-family: "Open Sans", sans-serif;
    font-size: 18px;
    font-weight: 300;
    line-height: 1em;
}

.container {
    width: 300px;
    margin: 0 auto;
}

.tree-menu {
    .label-wrapper {
        padding-bottom: 10px;
        margin-bottom: 10px;
        border-bottom: 1px solid #ccc;

        .has-children {
            cursor: pointer;
        }
    }
}

.hover:hover {
    background-color: rgba(111, 111, 111, 0.13) !important;
}
</style>

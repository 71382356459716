import api from './index';

const getPlanosTrabalho = () => api.get(
    'planos_trabalho'
);

const getPlanoTrabalho = (id_plano_trabalho) => api.get(
    'planos_trabalho/' + id_plano_trabalho
);

const updatePlanoTrabalho = (id_plano_trabalho, plano_trabalho) => api.put(
    'planos_trabalho/' + id_plano_trabalho, 
    plano_trabalho
);

const getPontosFocais = (id_plano_trabalho) => api.get(
    'plano_trabalho/' + id_plano_trabalho + '/pontos_focais'
);

const getPlanoByPontoFocalSiape = (nr_siape, id_ciclo) => api.get(
    'plano_trabalho/ponto_focal/siape-ciclo/' + nr_siape + '/' + id_ciclo
);

const getPlanoByPontoFocalCpf = (nr_cpf, id_ciclo) => api.get(
    'plano_trabalho/ponto_focal/cpf-ciclo/' + nr_cpf + '/' + id_ciclo
);

const getPlanoTrabalhoFiltrados = (page, itemsPerPage, filtrosAplicados = []) => api.get(
    'relatorioPlanosTrabalho', 
    {params: {current_page: page, limit: itemsPerPage, filtros: filtrosAplicados}}
);

const getTotalRelatorioPlanoTrabalho =  (filtrosAplicados = [], tipoPesquisa) => api.get(
    'totalRelatorioPlanosTrabalho', 
    {params: {filtros: filtrosAplicados, tipoPesquisa: tipoPesquisa}}
);

const getPlanoTrabalhoByUnidadeAvaliacao = (id_unidade_avaliacao) => api.get(
    'planosTrabalhoByUnidadeAvaliacao/'+id_unidade_avaliacao
);

const getChefiaByCicloAndPlano = (id_ciclo) => api.get(
    'getChefiaByCicloAndPlano/'+id_ciclo
);

const getChefiaSubstitutaByCicloAndPlano = (id_ciclo) => api.get(
    'getChefiaSubstitutaByCicloAndPlano/'+id_ciclo
);

const getPontoFocalByCicloAndPlano = (id_ciclo) => api.get(
    'getPontoFocalByCicloAndPlano/'+id_ciclo
);

const getPontoFocalSubstitutoByCicloAndPlano = (id_ciclo) => api.get(
    'getPontoFocalSubstitutoByCicloAndPlano/'+id_ciclo
);

const gerarExtracaoRelatorioPlanoTrabalho = (filtrosAplicados = []) => api.get(
    'gerarExtracaoRelatorioPlanoTrabalho', 
    { params: {filtros: filtrosAplicados}, responseType: 'blob' } 
);

const trocarChefiaPlano = (id_plano_trabalho, plano_trabalho) => api.put(
    'trocarChefiaPlano/' + id_plano_trabalho, plano_trabalho
);

const getPontoFocalPai = (id_plano) => api.get(
    'getPontoFocalPai/'+id_plano
);

const salvarPlanoDeTrabalho = (data) => api.post(
    'salvarPlanoDeTrabalho', 
    data
);

const getPlanoTrabalhoById = (id_plano_trabalho) => api.get(
    'getPlanoTrabalhoById/' + id_plano_trabalho
);

const editarPlanoDeTrabalho = (id, data) => api.put(
    'editarPlanoDeTrabalho/'+id, 
    data
);

const deletarPlanoDeTrabalho = (id) => api.post(
    'deletarPlanoDeTrabalho/'+id
);

const deletarUnidadeAvaliacao = (id, data) => api.post(
    'deletarUnidadeAvaliacao/'+id,
    data
);


export {
    getPlanoTrabalho,
    getPlanosTrabalho,
    updatePlanoTrabalho,
    getPontosFocais,
    getPlanoByPontoFocalSiape,
    getPlanoByPontoFocalCpf,
    getPlanoTrabalhoFiltrados,
    getTotalRelatorioPlanoTrabalho,
    getPlanoTrabalhoByUnidadeAvaliacao,
    getChefiaByCicloAndPlano,
    getChefiaSubstitutaByCicloAndPlano,
    getPontoFocalByCicloAndPlano,
    getPontoFocalSubstitutoByCicloAndPlano,
    gerarExtracaoRelatorioPlanoTrabalho,
    trocarChefiaPlano,
    getPontoFocalPai,
    salvarPlanoDeTrabalho,
    getPlanoTrabalhoById,
    editarPlanoDeTrabalho,
    deletarPlanoDeTrabalho,
    deletarUnidadeAvaliacao
}
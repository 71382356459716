<template>
    <div>
        <template>
            <br>
            <v-row justify="center">
                <v-card color="deep-purple lighten-5">
                    <v-card
                        color="primary"
                        dark
                        class="mx-auto"
                    >
                        <v-card-title>
                            <v-icon
                                large
                                left
                            >
                                mdi-factory
                            </v-icon>
                            <span class="text-h6 font-weight-light">{{ unidade.nm_unidade }}</span>
                        </v-card-title>
                    </v-card>
                    <br/>
                    <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col
                                cols="4"
                                sm="4"
                                md="4"
                            >
                                <v-text-field
                                label="Sigla Completa"
                                dense
                                hide-details="auto"
                                :value=unidade.sg_completa
                                readonly
                                ></v-text-field>
                            </v-col>
                            <v-col
                                cols="8"
                                sm="8"
                                md="8"
                            >
                                <v-text-field
                                label="Unidade"
                                dense
                                hide-details="auto"
                                :value=unidade.nm_unidade
                                readonly
                                ></v-text-field>
                            </v-col>
                            <v-col
                                cols="2"
                                sm="2"
                                md="2"
                            >
                                <v-text-field
                                label="Código UORG"
                                dense
                                hide-details="auto"
                                :value=unidade.cd_uorg
                                readonly
                                ></v-text-field>
                            </v-col>
                            <v-col
                                cols="10"
                                sm="10"
                                md="10"
                            >
                                <v-text-field
                                label="E-mail"
                                dense
                                hide-details="auto"
                                :value=unidade.ds_email
                                readonly
                                ></v-text-field>
                            </v-col>
                            <v-col
                                cols="2"
                                sm="2"
                                md="2"
                            >
                                <v-text-field
                                label="UF"
                                dense
                                hide-details="auto"
                                :value=unidade.uf.sg_uf
                                readonly
                                ></v-text-field>
                            </v-col>
                            <v-col
                                cols="10"
                                sm="10"
                                md="10"
                            >
                                <v-text-field
                                label="Estado"
                                dense
                                hide-details="auto"
                                :value=unidade.uf.nm_uf
                                readonly
                                ></v-text-field>
                            </v-col>
                        </v-row>

                        <template>
                            <v-row>
                                <v-col cols="12">
                                    <v-footer padless class="primary" dark>
                                        <v-col
                                        class="text-center"
                                        cols="12"
                                        >
                                        <strong>SERVIDORES EM EXERCÍCIO</strong>
                                        </v-col>
                                    </v-footer>
                                    <v-data-table
                                        :headers="headers"
                                        :items="unidade.servidoresExercicio"
                                        :items-per-page="5"
                                        class="elevation-1"   
                                    >
                                        <template v-slot:item.actions="{ item }">
                                            <v-row justify="center">
                                                <router-link :to="{ name: 'servidores-detalhar', params: { id: item.id } }">
                                                    <i class="icon fas fa-eye"></i>
                                                </router-link>
                                            </v-row>
                                        </template>
                                    </v-data-table>
                                </v-col>
                            </v-row>
                        </template>
                    </v-container>

                    <div class="text-center pt-2">
                        <v-btn
                            color="primary"
                            @click="$router.go(-1)"
                        >
                            Voltar
                        </v-btn>    
                    </div>

                    </v-card-text>

                </v-card>
            </v-row>
        </template>

    </div>
</template>

<script>
    import { getUnidadeDetalhes } from '../api/unidade';

    export default {
        name: 'UnidadesDetalhar',
        data() {
            return {
                unidade: {
                    uf: []
                },
                headers: [
                            {text: 'CPF', value: 'cpf'},
                            { text: 'NOME', value: 'nome' },
                            { text: 'SIAPE', value: 'siape' },
                            { text: 'Visualizar', value: 'actions', sortable: false },
                ],
          };
        },
       created() {
            let id_unidade = this.$route.params.id;
            getUnidadeDetalhes(id_unidade)
                .then(response => { 
                    this.unidade = response.data.data;
            });
        },
    }
</script>